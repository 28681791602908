import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { signOut } from "aws-amplify/auth";
import { UpdateAction } from "src/app/components/members/courses/courses.reducer";
import { CoursesActionTypes } from "src/app/components/members/courses/courses.reducer";
import { CoursesService } from "./services/courses.service";
import { select, Store } from "@ngrx/store";
import { CourseState } from "./components/members/courses/courses.reducer";
import { NGXLogger } from "ngx-logger";
import { Course } from "./models/course.model";
import { User } from "./models/user.model"; 
import { getSignedInUser } from "src/app/components/allusers/signin/signin.selector";
import { isCourseNotSelected } from "src/app/components/members/courses/courses.selector";
import { SignInState } from "src/app/components/allusers/signin/signin.model";
import { filter, Observable } from "rxjs";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "keysToDriveAdmin";

  constructor(public router: Router, private courseService: CoursesService) { }

  async logout() {
    try {
      console.log(`Signing out...`);
      await signOut({ global: true });
      this.router.navigate(["/home"]);
    } catch (error) {
      console.log(`Unable to signout...`);
    }
  }
}
