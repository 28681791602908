<div class="container white btn-margin backgrounDColor">
  <div class="row">
    <div class="col s12"><button mat-button color="primary" (click)="goBack()"><i class="material-icons">arrow_back</i>Back</button></div>

</div>
  <h4 class="center padding-row"> Banners List </h4>
  <div class="row padding-header" *ngIf="!loading">
    <div class="col s3"></div>
    <div class="col s6">
      <table class="striped">

        <tbody>
          <tr *ngFor="let banner of banners; index as i">
            <td class="center"> {{banner.Name}}</td>
            <td class="center"> <a class="waves-effect waves-light btn blue btn-border"
                (click)="goToBannerEdit(banner)">edit</a></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col 3"></div>
  </div>
  <div class="row" *ngIf="loading">
    <div class="col l4"></div>
    <div class="col l4">
      <div class="preloader-wrapper big active loader">
        <div class="spinner-layer spinner-blue-only">
          <div class="circle-clipper left">
            <div class="circle"></div>
          </div>
          <div class="gap-patch">
            <div class="circle"></div>
          </div>
          <div class="circle-clipper right">
            <div class="circle"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col l4"></div>
  </div>
</div>