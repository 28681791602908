import { Directive, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { filter } from 'rxjs';
import { ChapterViewState } from 'src/app/components/members/chapter-view/chapter-view.model';
import { Course } from 'src/app/models/course.model';
import { CourseState } from '../courses/courses.reducer';
import { isCourseNotSelected, isCourseSelected } from '../courses/courses.selector';

@Directive({
  selector: '[routeTransformer]'
})
export class RouteTransformerDirective {

  courseSelected$ = this.courseStore.pipe(select(isCourseSelected), filter(val => !val == false));
  courseNotSelected$ = this.courseStore.pipe(select(isCourseNotSelected), filter(val => val))
  currentCourse: Course;
  currentChapterId: string;

  constructor(private el: ElementRef, private router: Router,
    private courseStore: Store<CourseState>,
    private chapterViewStore: Store<ChapterViewState>,
  ) {
    this.courseSelected$.subscribe(val => this.currentCourse = val.course);
    this.courseNotSelected$.subscribe(val => this.currentCourse = undefined);
    this.chapterViewStore.subscribe(val => this.currentChapterId = val.chapterId);
  }

  @HostListener('click', ['$event'])
  public onClick(event) {
    let link: string = null;
    if (event.target.tagName !== 'A') {
      const parent = event.target.closest('a[href*="courses/' + this.currentCourse.id + '/chapters"]');
      if (!parent) {
        return;
      }

      link = parent.href;
    } else {
      link = event.target.getAttribute('href');
    }

    if (link && !link.startsWith("htt") && this.isPathInSection(link, 'courses/' + this.currentCourse.id + '/chapters')) {
      this.router.navigate([link]);
      event.preventDefault();
    } else if (link && this.isPathInSection(link, 'courses/' + this.currentCourse.id + '/chapters')) {
      this.router.navigate([new URL(link).pathname]);
      event.preventDefault();
    }
  }

  private isPathInSection(url: string, section: string): boolean {
    const urlPath = new URL(url, window.location.origin).pathname;
    return urlPath.includes(section);
  }

};