<div class="container-fluid">
  <div class="row">
    <div class="col s12">
      <div class="container">
        <div class="row"></div>
        <div class="row"></div>
        <div class="row" *ngIf="!loading">
          <div class="col s12">
            <div class="card">
              <div class="col s3"></div>
              <form class="col s6" [formGroup]="editQuestionForm" (ngSubmit)="changeForm()">
                <div class="input-field col m12 s12">
                  <label class="left grey-text active">Question ID</label>
                  <input type="text" formControlName="QuestionID" class="validate black-text" required />
                </div>
                <div class="input-field col m12 s12">
                  <label class="left grey-text active">Quiz ID</label>
                  <input type="text" formControlName="QuizID" class="validate black-text" required />
                </div>
                <div class="input-field col m12 s12">
                  <label class="left grey-text active">Question Text</label>
                  <input type="text" formControlName="QuestionText" class="validate black-text" required />
                </div>
                <div class="input-field col m12 s12">
                  <div class="col s12 m12">
                    <!-- <label class="left grey-text">question Type </label> -->
                    <select class="browser-default grey-text" formControlName="questiontype" required>
                      <option value="" disabled>Choose Question Type</option>
                      <option value="amch">amch</option>
                      <option value="mch">mch</option>
                      <option value="e">e</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col s3"></div>
                  <div class="col s6">
                    <div class="padding-card-button center">
                      <button class="waves-effect waves-light btn" type="submit">Update</button>
                    </div>
                  </div>
                </div>
              </form>
              <div class="col s12">
                <div class="col s3"></div>
                <div class="col s6">
                  <button class="waves-effect waves-light btn" (click)="goToQuestionOptions()">Answer Options
                    Question</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="loading">
          <div class="col l4"></div>
          <div class="col l4">
            <div class="preloader-wrapper big active loader">
              <div class="spinner-layer spinner-blue-only">
                <div class="circle-clipper left">
                  <div class="circle"></div>
                </div>
                <div class="gap-patch">
                  <div class="circle"></div>
                </div>
                <div class="circle-clipper right">
                  <div class="circle"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col l4"></div>
        </div>

      </div>
    </div>
  </div>
</div>