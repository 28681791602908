import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { NGXLogger } from "ngx-logger";
import { filter } from "rxjs/operators";
import { User } from "src/app/models/user.model";
import { AuthService } from "src/app/services/auth-service/auth.service";
import {
  NewPasswordRequiredFailed,
  NewPasswordRequiredSubmitted,
  NewPasswordRequiredSubmitting,
  SignedOutAction,
  SignInActionTypes
} from "../signin/signin.actions";
import { SignInState } from "../signin/signin.model";
import {
  getSignIn,
  isNewPasswordRequired,
  isNewPasswordRequiredFailed
} from "../signin/signin.selector";

@Component({
  selector: "app-new-password-required",
  templateUrl: "./new-password-required.component.html",
  styleUrls: ["./new-password-required.component.css"]
})
export class NewPasswordRequiredComponent implements OnInit {
  newPasswordRequiredForm = new FormGroup({
    newPassword: new FormControl("", Validators.required),
    reNewPassword: new FormControl("", Validators.required)
  });

  newPasswordRequiredUser$ = this.signInStore.pipe(
    select(isNewPasswordRequired)
  );
  newPasswordRequiredUser: User;
  newPasswordRequiredCognitoUser: any;

  newPasswordRequiredFailed$ = this.signInStore.pipe(
    select(isNewPasswordRequiredFailed),
    filter(val => (val ? true : false))
  );

  currentState$ = this.signInStore.pipe(select(getSignIn));
  currentState: string;

  errorMessage;

  constructor(
    private signInStore: Store<SignInState>,
    private authService: AuthService,
    private logger: NGXLogger
  ) {
    this.currentState$.subscribe(
      state => (this.currentState = state.currentState.toString())
    );
    this.newPasswordRequiredUser$.subscribe(loginState => {
      if (loginState && loginState.user) {
        this.newPasswordRequiredUser = loginState.user;
      }
      if (loginState && loginState.cognitoUser) {
        this.newPasswordRequiredCognitoUser = loginState.cognitoUser;
      }
    });

    this.newPasswordRequiredFailed$.subscribe(
      loginState => (this.errorMessage = loginState.error)
    );
  }

  ngOnInit() {}

  private clearMessages() {
    this.errorMessage = null;
  }

  gotoSignIn() {
    this.signInStore.dispatch(
      new SignedOutAction({
        currentState: SignInActionTypes.SignedOut
      } as SignInState)
    );
  }

  newPasswordRequired() {
    if (
      this.newPasswordRequiredForm.valid &&
      this.newPasswordRequiredForm.controls.newPassword.value ===
        this.newPasswordRequiredForm.controls.reNewPassword.value
    ) {
      this.signInStore.dispatch(
        new NewPasswordRequiredSubmitting({
          currentState: SignInActionTypes.NewPasswordRequiredSubmitting,
          cognitoUser: this.newPasswordRequiredCognitoUser
        } as SignInState)
      );

      this.authService
        .newPasswordRequired(
          this.newPasswordRequiredCognitoUser,
          this.newPasswordRequiredForm.controls.newPassword.value
        )
        .then(res => {
          this.logger.info("New Required password successful...");
          this.signInStore.dispatch(
            new NewPasswordRequiredSubmitted({
              currentState: SignInActionTypes.NewPasswordRequiredSubmitted
            } as SignInState)
          );
        })
        .catch(err => {
          this.logger.error(`Error - ${JSON.stringify(err)}`);
          this.signInStore.dispatch(
            new NewPasswordRequiredFailed({
              currentState: SignInActionTypes.NewPasswordRequiredFailed,
              error: err.message
            } as SignInState)
          );
        });
    } else {
      this.errorMessage = "Password's do not match";
    }
  }
}
