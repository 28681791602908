import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth-service/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  passwordChanged: Boolean = false;

  changePasswordForm = new FormGroup({
    oldPassword: new FormControl(''),
    newPassword: new FormControl(''),
    reNewPassword: new FormControl('')
  });

  constructor(
    public router: Router,
    public authService: AuthService) { }

  ngOnInit() {
  }

  onChangePassword() {
    if (this.changePasswordForm.value.newPassword == this.changePasswordForm.value.reNewPassword) {
      this.authService.updatePassword(this.changePasswordForm.value.oldPassword, this.changePasswordForm.value.newPassword)
        .then(res => {
          this.passwordChanged = true;
        })
    }
  }

}
