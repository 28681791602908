import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HearAbout } from "../models/hearabouts.model";

@Injectable({
  providedIn: "root"
})
export class HearaboutService {
  baseURL: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getHearAbouts(): Promise<HearAbout[]> {
    return this.http
      .get<HearAbout[]>(environment.apiUrl + "/hearabouts")
      .toPromise();
  }

  createHearAbout(hearAbout: HearAbout) {
    return this.http
      .post(environment.apiUrl + `/hearabouts`, hearAbout)
      .toPromise();
  }

  updateHearAbout(hearAbout: object, optionId: number) {
    return this.http
      .put(environment.apiUrl + `/hearabouts/${optionId}`, hearAbout)
      .toPromise();
  }

  deleteHearAbout(optionId) {
    return this.http
      .delete(environment.apiUrl + `/hearabouts/${optionId}`)
      .toPromise();
  }
}
