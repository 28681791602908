import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { faUsers } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"]
})
export class DashboardComponent implements OnInit {
  reportIcon = faUsers;
  adminStatus = "";

  constructor(private route: ActivatedRoute, private routerNav: Router) {}

  ngOnInit() {
    this.adminStatus = "";
  }

  gotoTeacherMenu(a, b) {
    let navigationExtras: NavigationExtras = {
      queryParams: { auId: a, schoolId: b }
      // fragment: 'anchor'
    };
    this.routerNav.navigate([`/admin/teacher-controll-menu`], navigationExtras);
  }
}
