import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { QuestionsService } from "../../../services/questions.service";

@Component({
  selector: "app-edit-question",
  templateUrl: "./edit-question.component.html",
  styleUrls: ["./edit-question.component.css"],
})
export class EditQuestionComponent implements OnInit {
  question;
  loading: Boolean = true;
  editQuestionForm = new FormGroup({
    QuestionText: new FormControl(""),
    QuizID: new FormControl(""),
    QuestionID: new FormControl(""),
    questiontype: new FormControl(""),
  });

  constructor(
    private route: ActivatedRoute,
    private questionsService: QuestionsService,
    private routerNav: Router,
    private location: Location
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.getQuestion(params["questionId"]);
    });
  }

  async getQuestion(id) {
    var question;
    try {
      question = await this.questionsService.findBy(id);
      this.question = question;
      this.editQuestionForm.patchValue(question);
      this.loading = false;
    } catch (err) {}
  }

  changeForm() {
    this.questionsService.create(this.editQuestionForm.value).then((res) => {
      this.location.back();
    });
  }

  goToQuestionOptions() {
    let navigationExtras: NavigationExtras = {
      queryParams: { questionId: this.question.QuestionID },
      // fragment: 'anchor'
    };
    this.routerNav.navigate(
      [`/admin/list-questions-options`],
      navigationExtras
    );
  }
}
