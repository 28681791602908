import * as LoginActions from "./signin.actions";
import { SignInState } from "./signin.model";

export type SignInAction = LoginActions.SignInActions;

const initialState: SignInState = {
  currentState: LoginActions.SignInActionTypes.SignedOut
} as SignInState;

export function signInReducer(
  state = initialState,
  action: SignInAction
): SignInState {
  switch (action.type) {
    case LoginActions.SignInActionTypes.SignedIn:
      return { ...state, ...action.payload };
    case LoginActions.SignInActionTypes.SigningIn:
      return { ...state, ...action.payload };
    case LoginActions.SignInActionTypes.SignInFailed:
      return { ...state, ...action.payload };
    case LoginActions.SignInActionTypes.SigningOut:
      return { ...state, ...action.payload };
    case LoginActions.SignInActionTypes.SignedOut:
      return { ...state, ...action.payload };
    case LoginActions.SignInActionTypes.ForgotPassword:
      return { ...state, ...action.payload };
    case LoginActions.SignInActionTypes.ForgotPasswordSubmitting:
      return { ...state, ...action.payload };
    case LoginActions.SignInActionTypes.ForgotPasswordSubmitted:
      return { ...state, ...action.payload };
    case LoginActions.SignInActionTypes.ForgotPasswordFailed:
      return { ...state, ...action.payload };
    case LoginActions.SignInActionTypes.NewPasswordRequired:
      return { ...state, ...action.payload };
    case LoginActions.SignInActionTypes.NewPasswordRequiredSubmitting:
      return { ...state, ...action.payload };
    case LoginActions.SignInActionTypes.NewPasswordRequiredSubmitted:
      return { ...state, ...action.payload };
    case LoginActions.SignInActionTypes.NewPasswordRequiredFailed:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
