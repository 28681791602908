import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { NGXLogger } from "ngx-logger";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { Asset } from "src/app/models/assets.model";
import { Chapter } from "src/app/models/chapters.model";
import { Question } from "src/app/models/question.model";
import { AssetsService } from "src/app/services/assets.service";
import { EdisonFedertedLogin } from "src/app/services/auth-service/base.token.provider";
import { ChaptersService } from "src/app/services/chapters.service";
import { commonTokenProvider } from "src/main";
import { SignInState } from "../../../../app/components/allusers/signin/signin.model";
import { getSignedInUser } from "../../../../app/components/allusers/signin/signin.selector";
import { Quiz } from "../../../../app/models/quiz.model";
import { AnswerOptionsService } from "../../../../app/services/answer-options.service";
import { MemberlocService } from "../../../../app/services/memberloc.service";
import { ProgressService } from "../../../../app/services/progress.service";
import { environment } from "../../../../environments/environment";
import { CertificatesService } from "../../../services/certificates.service";
import { QuestionsService } from "../../../services/questions.service";
import { QuizesService } from "../../../services/quizes.service";
import { CourseState } from "../courses/courses.reducer";
import { isCourseNotSelected, isCourseSelected } from "../courses/courses.selector";
import {
  AssetShowAction,
  AudioShowAction,
  FinalNotAllowedQuizIncompleteAction,
  FinalQuizAlreadyDoneAction,
  FinalQuizLoadedAction,
  FinalQuizShowAction,
  FinalQuizSubmittedAction,
  FinalQuizSubmittingAction,
  InitializationAction,
  QuizLoadedAction,
  QuizShowAction,
  QuizSubmittedAction,
  QuizSubmittingAction,
  QuizTestsActionTypes,
  VideoShowAction
} from "./quiz-tests.actions";
import { QuizTestsState } from "./quiz-tests.model";
import {
  getCurrentQuizTestsState,
  isAsset,
  isFinal,
  isFinalNotAllowedQuizIncomplete,
  isFinalShow,
  isQuiz,
  isQuizShow,
  isQuizSkippingNotAllowed
} from "./quiz-tests.selector";

@Component({
  selector: "app-quiz-tests",
  templateUrl: "./quiz-tests.component.html",
  styleUrls: ["./quiz-tests.component.css"],
})
export class QuizTestsComponent implements OnInit, OnDestroy {
  chapterId: string;
  quizId;
  videoId: string;
  soundId: string;
  assetId: string;

  currentState$: Subscription;
  currentState;
  activatedRoute$: Subscription;
  isQuizShow$: Subscription;
  quizState$: Subscription;
  quizState;

  isFinalShow$: Subscription;
  isFinalNotAllowedQuizIncomplete$ = this.quizTestsStore.pipe(
    select(isFinalNotAllowedQuizIncomplete)
  );
  isQuizSkippingNotAllowed$ = this.quizTestsStore.pipe(
    select(isQuizSkippingNotAllowed)
  );
  isAsset$: Subscription;

  chapter: Chapter;
  quiz: Quiz;
  asset: Asset;
  quizForm: FormGroup = new FormGroup({});
  result: any;

  currentUser$: Subscription;
  currentUser;
  quizzesPerChapter: Quiz[];

  courseSelected$ = this.courseStore.pipe(select(isCourseSelected), filter(val => !val == false));
  courseNotSelected$ = this.courseStore.pipe(select(isCourseNotSelected), filter(val => val))
  currentCourseState: CourseState;

  constructor(
    private logger: NGXLogger,
    private chapterService: ChaptersService,
    private quizesService: QuizesService,
    private questionsService: QuestionsService,
    private answerOptionsService: AnswerOptionsService,
    private certificatesService: CertificatesService,
    public router: Router,
    private courseStore: Store<CourseState>,
    private quizTestsStore: Store<QuizTestsState>,
    private loginStore: Store<SignInState>,
    private activatedRoute: ActivatedRoute,
    private progressService: ProgressService,
    private memberLocService: MemberlocService,
    private assetsService: AssetsService,
    private sanitizer: DomSanitizer,
  ) { }

  async ngOnInit() {
    this.courseSelected$.subscribe(async val => {
      this.currentCourseState = val;
    });
    this.courseNotSelected$.subscribe(val => {
      this.currentCourseState = undefined;
    });

    this.currentUser$ = this.loginStore
      .pipe(
        select(getSignedInUser),
        filter((val) => (val ? true : false))
      )
      .subscribe((user) => {
        this.currentUser = user;
      });

    this.activatedRoute$ = this.activatedRoute.params.subscribe(async (params) => {
      this.chapterId = params.chapterId;
      this.quizId = params.quizId;
      this.videoId = params.videoId;
      this.soundId = params.soundId;
      this.assetId = params.assetId;
      this.triggerState();
    });

    this.isQuizShow$ = this.quizTestsStore
      .pipe(
        select(isQuizShow),
        filter((val) => {
          if (val) {
            return true;
          }
          return false;
        })
      )
      .subscribe(async (state: any) => {
        this.quizId = state.quizId ? state.quizId.toString() : null;
        this.showQuiz();
      });

    this.currentState$ = this.quizTestsStore
      .pipe(select(getCurrentQuizTestsState))
      .subscribe((state) => {
        this.currentState = state;
      });

    this.quizState$ = this.quizTestsStore
      .pipe(select(isQuiz))
      .subscribe((state) => {
        this.quizState = state;
      });

    this.quizState$ = this.quizTestsStore
      .pipe(select(isFinal))
      .subscribe((state) => {
        this.quizState = state;
      });

    this.isFinalShow$ = this.quizTestsStore
      .pipe(
        select(isFinalShow),
        filter((val) => (val ? true : false))
      )
      .subscribe(async (state) => {
        this.quizId = state.quizId ? state.quizId.toString() : null;
        await this.showFinal();
      });

    this.isAsset$ = this.quizTestsStore
      .pipe(
        select(isAsset),
        filter((val) => (val ? true : false))
      )
      .subscribe(async (state) => {
        this.asset = await this.assetsService.findBy(state.assetId);
      });
  }

  ngOnDestroy() {
    if (this.isQuizShow$) {
      this.isQuizShow$.unsubscribe();
    }

    if (this.isFinalShow$) {
      this.isFinalShow$.unsubscribe();
    }

    if (this.activatedRoute$) {
      this.activatedRoute$.unsubscribe();
    }

    if (this.currentState$) {
      this.currentState$.unsubscribe();
    }

    if (this.currentUser$) {
      this.currentUser$.unsubscribe();
    }

    if (this.quizState$) {
      this.quizState$.unsubscribe();
    }

    if (this.isAsset$) {
      this.isAsset$.unsubscribe();
    }
  }

  async getSkippedQuizzes() {
    const skippedQuizes: Quiz[] = [];
    if (this.quizzesPerChapter && Array.isArray(this.quizzesPerChapter)) {
      this.quizzesPerChapter = this.quizzesPerChapter.sort((quizA, quizB) => {
        let aorder = Number.parseInt(quizA.QuizOrder);
        let border = Number.parseInt(quizB.QuizOrder);
        return aorder - border;
      });

      const index = this.quizzesPerChapter.findIndex(
        (q) => q.QuizID == parseInt(this.quizId)
      );
      const userProgresses = await this.progressService.getProgressByCourse(
        this.currentCourseState?.course.id,
        this.currentUser.memberid
      );
      for (let i = 0; i < index; i++) {
        if (
          !userProgresses.find(
            (p) => p.QuizID == this.quizzesPerChapter[i].QuizID && p.Score > 60
          )
        ) {
          skippedQuizes.push(this.quizzesPerChapter[i]);
        }
      }
    }
    return skippedQuizes;
  }

  async triggerState() {
    this.logger.info(`Triggering state - ${this.chapterId} - ${this.currentCourseState?.finalQuiz?.QuizID}`);
    if (this.chapterId === "final") {
      this.quizTestsStore.dispatch(
        new FinalQuizShowAction({
          currentState: QuizTestsActionTypes.FinalQuizShow,
          quizId: this.currentCourseState?.finalQuiz?.QuizID,
        } as QuizTestsState)
      );
    } else if (this.quizId) {
      const quizzes = await this.quizesService
        .findByCourseIdAndChapterId(this.currentCourseState?.course.id, parseInt(this.chapterId));
      this.quizzesPerChapter = quizzes;
      this.chapter = await this.chapterService.getChapterBy(this.currentCourseState?.course.id, parseInt(this.chapterId));

      const skippedQuizzes = await this.getSkippedQuizzes();
      if (skippedQuizzes && skippedQuizzes.length == 0) {
        this.quizTestsStore.dispatch(
          new QuizShowAction({
            currentState: QuizTestsActionTypes.QuizShow,
            quizId: parseInt(this.quizId),
          } as QuizTestsState)
        );
      } else {
        this.quizTestsStore.dispatch(
          new QuizShowAction({
            currentState: QuizTestsActionTypes.QuizSkipped,
            quizId: parseInt(this.quizId),
            incompleteQuizzes: skippedQuizzes,
          } as QuizTestsState)
        );
      }
    } else if (this.soundId) {
      this.quizTestsStore.dispatch(
        new AudioShowAction({
          currentState: QuizTestsActionTypes.AudioShow,
          soundId: this.soundId,
        } as QuizTestsState)
      );
    } else if (this.videoId) {
      this.quizTestsStore.dispatch(
        new VideoShowAction({
          currentState: QuizTestsActionTypes.VideoShow,
          videoId: this.videoId,
        } as QuizTestsState)
      );
    } else if (this.assetId) {
      this.quizTestsStore.dispatch(
        new AssetShowAction({
          currentState: QuizTestsActionTypes.AssetShow,
          assetId: this.assetId,
        } as QuizTestsState)
      );
    } else {
      this.quizTestsStore.dispatch(
        new InitializationAction({
          currentState: QuizTestsActionTypes.Initialization,
        } as QuizTestsState)
      );
    }
  }

  async getQuizData(quizId: number) {
    await this.updateMemberLocation();

    this.logger.info(`Getting quiz data for quizId: ${quizId}`);
    this.quiz = await this.quizesService.findByCourseIdAndQuizId(this.currentCourseState?.course.id, quizId);
    const questions = await this.questionsService.findByQuiz(this.quiz.QuizID);
    this.quiz.questions = questions;

    const answerOptions = await this.answerOptionsService.findAnswerOptionsForQuiz(
      quizId
    );

    for (const question of questions) {
      if (!question.answerOptions) {
        question.answerOptions = [];
      }
      const filteredOptions = answerOptions.filter(
        (o) => o.QuestionID === question.QuestionID
      );
      question.answerOptions.push(...filteredOptions);
    }

    this.quizForm = await this.toFormGroup();
  }

  async toFormGroup() {
    const group: any = {};

    for (const question of this.quiz.questions) {
      const answer = {
        AnswerOptID: new FormControl(""),
      };
      group[question.QuestionID] = new FormGroup(answer);
    }

    return new FormGroup(group);
  }

  getSound() {
    return `${environment.s3BucketUrl}assets/sounds/${this.soundId}`;
  }

  getVideo() {
    return `${environment.s3BucketUrl}assets/video/${this.videoId}`;
  }

  getImage(path) {
    return `${environment.s3BucketUrl}assets${path}`;
  }

  getAssett() {
    return `${environment.s3BucketUrl}/assets/${this.assetId}`;
  }

  public async submitQuiz() {
    if (this.quizId !== this.currentState.finalQuiz?.QuizID.toString()) {
      this.quizTestsStore.dispatch(
        new QuizSubmittingAction({
          currentState: QuizTestsActionTypes.QuizSubmitting,
          quizId: parseInt(this.quizId),
        } as QuizTestsState)
      );
      const result = await this.submitProgress();
      this.quizTestsStore.dispatch(
        new QuizSubmittedAction({
          currentState: QuizTestsActionTypes.QuizSubmitted,
          quizId: parseInt(this.quizId),
          result,
        } as QuizTestsState)
      );
    } else {
      this.quizTestsStore.dispatch(
        new FinalQuizSubmittingAction({
          currentState: QuizTestsActionTypes.FinalQuizSubmitting,
          quizId: this.currentState.finalQuiz?.QuizID,
        } as QuizTestsState)
      );
      const result = await this.submitProgress();
      this.quizTestsStore.dispatch(
        new FinalQuizSubmittedAction({
          currentState: QuizTestsActionTypes.FinalQuizSubmitted,
          quizId: this.currentState.finalQuiz?.QuizID,
          result,
        } as QuizTestsState)
      );
    }
  }

  private async submitProgress() {
    const data = Object.create({});
    data[this.quizId] = this.quizForm.value;
    const edisonFederatedLogin = commonTokenProvider.federatedLogin as EdisonFedertedLogin;
    return this.progressService.memberProgressByCourse(this.currentCourseState?.course.id,
      this.currentUser.memberid, data,
      { edisonDomain: edisonFederatedLogin?.edisonDomain, edisonRefreshToken: edisonFederatedLogin?.edisonRefreshToken });
  }

  getAnswer(question: Question) {
    const que = this.quiz.questions.find(
      (q) => q.QuestionID == question.QuestionID
    );
    return que.answerOptions.find((option) => option.Correct === "yes")
      .AnswerText;
  }

  retakeQuiz() {
    this.quizTestsStore.dispatch(
      new QuizShowAction({
        currentState: QuizTestsActionTypes.QuizShow,
        quizId: parseInt(this.quizId),
      } as QuizTestsState)
    );
  }

  async showQuiz() {
    this.getQuizData(parseInt(this.quizId)).then(() => {
      this.quizTestsStore.dispatch(
        new QuizLoadedAction({
          currentState: QuizTestsActionTypes.QuizLoaded,
          quizId: parseInt(this.quizId),
        } as QuizTestsState)
      );
    });
  }

  async showFinal() {
    try {
      this.logger.info(`Checking if final is already done...`);
      const isFinalAlreayDone = await this.isFinalAlreayDone();
      if (isFinalAlreayDone) {
        this.quizTestsStore.dispatch(
          new FinalQuizAlreadyDoneAction({
            currentState: QuizTestsActionTypes.FinalQuizAlreadyDone,
          } as QuizTestsState)
        );
        return;
      }

      this.logger.info(`Checking if there are incomplete quizzes...`);
      const incompleteQuizzes = await this.getIncompleteQuizzes();
      if (incompleteQuizzes && incompleteQuizzes.length) {
        this.quizTestsStore.dispatch(
          new FinalNotAllowedQuizIncompleteAction({
            currentState: QuizTestsActionTypes.FinalNotAllowedQuizIncomplete,
            incompleteQuizzes,
          } as QuizTestsState)
        );
        return;
      }

      this.logger.info(`Loading final quiz data...`);
      await this.getQuizData(this.currentCourseState?.finalQuiz?.QuizID);
      this.logger.info(`Final quiz data loaded`, this.quiz);
      this.quizTestsStore.dispatch(
        new FinalQuizLoadedAction({
          currentState: QuizTestsActionTypes.FinalQuizLoaded,
          quizId: this.currentCourseState?.finalQuiz?.QuizID,
        } as QuizTestsState)
      );
    } catch (err) {
      this.logger.error(err);
    }

  }

  private updateMemberLocation() {
    if (
      this.chapterId &&
      this.chapterId !== "final" &&
      parseInt(this.chapterId)
    ) {
      const chapter = parseInt(this.chapterId);
      return this.memberLocService.updateMemberLocationByCourse(
        this.currentCourseState?.course.id,
        this.currentUser.memberid,
        {
          memberid: this.currentUser.memberid,
          courseid: this.currentCourseState?.course.id,
          section: chapter.toString(),
          quid: this.quizId,
        }
      );
    }
    return this.memberLocService.updateMemberLocationByCourse(
      this.currentCourseState?.course.id,
      this.currentUser.memberid,
      {
        memberid: this.currentUser.memberid,
        courseid: this.currentCourseState?.course.id,
        section: this.chapterId,
        quid: this.quizId,
      }
    );
  }

  async isFinalAlreayDone() {
    if (this.currentUser.memberstatus === "admin") {
      return false;
    }

    const progresses = await this.progressService.getProgressByCourse(
      this.currentCourseState?.course.id,
      this.currentUser.memberid
    );

    return progresses.find((p) => p.QuizID == this.currentCourseState?.finalQuiz.QuizID);
  }

  async getIncompleteQuizzes() {
    let totalQuizes = await this.quizesService.findAllPerCourse(this.currentCourseState?.course.id);
    totalQuizes = totalQuizes.filter((q) => q.QuizID > 1 && q.QuizID < 11);

    let progresses = await this.progressService.getProgressByCourse(
      this.currentCourseState?.course.id,
      this.currentUser.memberid
    );
    progresses = progresses.filter((val) =>
      val ? val && val.QuizID > 1 && val.QuizID < 11 && val.Score > 60 : false
    );
    // unique quizzes
    progresses = progresses.filter(
      (item, pos, self) =>
        self.findIndex((v) => v.QuizID === item.QuizID) === pos
    );
    totalQuizes = totalQuizes.filter((q) => {
      const progress = progresses.find((p) => p.QuizID === q.QuizID);
      if (progress) {
        return false;
      }
      return true;
    });
    return totalQuizes;
  }

  getIncompleteLink(quiz) {
    return "/courses/" + this.currentCourseState?.course.id + "/chapters/" + quiz.QuizLocation2 + "/quizzes/" + quiz.QuizID;
  }

  downloadCertificate() {
    this.router.navigate(["courses", this.currentCourseState?.course.id, "download-certificate"]);
  }

  getAssetUrl() {
    const assetUrl = `${environment.s3BucketUrl}${this.asset.location}`;
    return assetUrl;
  }

  getPptUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`https://view.officeapps.live.com/op/embed.aspx?src=${environment.s3BucketUrl}${this.asset.location}`);
  }


}
