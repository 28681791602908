<div class="fx-layout-column fx-layout-align-center-center">
  <div class="fx-flex-20pc">
    <h4 style="color: white">{{ currentChapterName }} - Sections</h4>
  </div>

  <div class="fx-flex-80pc">
    <p-table #dt [columns]="cols" [value]="sections" [paginator]="true" selectionMode="single" [rows]="rows"
      [totalRecords]="totalRecords" [loading]="loading" (onRowSelect)="onRowSelect($event)">
      <!-- <ng-template pTemplate="caption">
          <div style="text-align: right">
            <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
            <input type="text" pInputText class="margin0"  size="50" placeholder="Global Filter"
              (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
          </div>
        </ng-template> -->
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
              ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order">
            </p-sortIcon>
          </th>
          <th></th>
        </tr>
        <tr>
          <th *ngFor="let col of columns" [ngSwitch]="col.field">
            <input *ngSwitchCase="'id'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')">
            <input *ngSwitchCase="'name'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')">
            <input *ngSwitchCase="'location'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')">
            <input *ngSwitchCase="'target'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')">
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData" style="background-color: lightgrey;" *ngIf="!rowData['parentId']">
          <td *ngFor="let col of columns">
            <span>{{rowData[col.field]}}</span>
          </td>
          <td>
            <button type="button" pButton (click)="addSubsection(rowData['id'])" class="ui-button-warning">Add
              Subsection</button>
          </td>
        </tr>
        <tr [pSelectableRow]="rowData" *ngIf="rowData['parentId']">
          <td *ngFor="let col of columns" style="max-width: 40em;">
            <span>{{rowData[col.field]}}</span>
          </td>
          <td>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary" let-rowData>
        <div style="text-align:left">
          <button type="button" pButton (click)="addHeader()" label="Add Header"></button>
        </div>
      </ng-template>
    </p-table>

    <p-dialog header="Chapter Section Details" [(visible)]="displayDialog" [responsive]="true" showEffect="fade"
      [modal]="true" [style]="{width: '700px', height: '900px'}" (visibleChange)="handleClose()">
      <div class="red-text" *ngIf="errorMessage">{{ errorMessage }}</div>
      <div class="alert-success" *ngIf="successMessage">{{ successMessage }}</div>
      <div class="ui-g ui-fluid" *ngIf="section">
        <div class="ui-g-12">
          <div class="ui-g-4">
            <label for="id">ID</label>
          </div>
          <div class="ui-g-8">
            {{ section.id }}
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4 pad0">
            <label for="chapterId">Chapter *</label>
          </div>
          <div class="ui-g-8 pad0">
            {{ currentChapterName }}
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4 pad0">
            <label for="name">Name*</label>
          </div>
          <div class="ui-g-8 pad0">
            <input pInputText class="margin0" id="name" [(ngModel)]="section.name" required />
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4 pad0">
            <label for="description">Description</label>
          </div>
          <div class="ui-g-8 pad0">
            <input pInputText class="margin0" id="description" [(ngModel)]="section.description" />
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4 pad0">
            <label for="hint">Hint</label>
          </div>
          <div class="ui-g-8 pad0">
            <input pInputText class="margin0" id="hint" [(ngModel)]="section.hint" />
          </div>
        </div>
        <div class="ui-g-12" *ngIf="section.parentId">
          <div class="ui-g-4 pad0">
            <label for="location">Location</label>
          </div>
          <div class="ui-g-8 pad0">
            <input pInputText class="margin0" id="location" [(ngModel)]="section.location" required />
          </div>
        </div>
        <div class="ui-g-12" *ngIf="section.parentId">
          <div class="ui-g-4 pad0">
            <label for="target">Target</label>
          </div>
          <div class="ui-g-8 pad0">
            <p-dropdown class="margin0" id="target" [options]="targets" [(ngModel)]="section.target">
            </p-dropdown>
          </div>
        </div>
        <div class="ui-g-12" *ngIf="section.parentId">
          <div class="ui-g-4 pad0">
            <label for="links">Links</label>
          </div>
          <div class="ui-g-8 pad0">
            <p-dropdown class="margin0" id="link" [options]="links" [(ngModel)]="section.location">
            </p-dropdown>
          </div>
        </div>
        <div class="ui-g-12" *ngIf="section.parentId">
          <div class="ui-g-4 pad0">
            <label for="parentId">Parent Section</label>
          </div>
          <div class="ui-g-8 pad0">
            <p-dropdown class="margin0" id="parentId" [options]="chapterSections" [(ngModel)]="section.parentId">
            </p-dropdown>
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4 pad0">
            <label for="order">After</label>
          </div>
          <div class="ui-g-8 pad0">
            <p-dropdown class="margin0" id="order" [options]="sectionOrders" [(ngModel)]="section.afterId">
            </p-dropdown>
          </div>
        </div>
        <div class="ui-g-12" *ngIf="section.content">
          <div class="ui-g-4 pad0">
            <label for="content">Content*</label>
          </div>
          <div class="ui-g-8 pad0">
            <editor #editor id="editor" [init]="editorConfig" [(ngModel)]="section.content" />
          </div>
        </div>
      </div>
      <p-footer>
        <div class="ui-dialog-buttonpane ui-helper-clearfix" *ngIf="section">
          <button type="button" pButton (click)="delete()" label="Delete" class="left"></button>
          <button type="button" pButton (click)="save()" [disabled]="!section.name || !section.chapterId"
            label="Save"></button>
        </div>
      </p-footer>
    </p-dialog>
  </div>
</div>
