<!-- <div class="container white btn-margin backgrounDColor">
  <div class="row">
      
    <div class="col s12"><button mat-button color="primary" (click)="goBack()"><i class="material-icons">arrow_back</i>Back</button></div>
</div>
  <h4 class="center padding-row"> Content List </h4>
  <div class="row padding-header" *ngIf="!loading">
    <div class="col s3"></div>
    <div class="col s6">
      <table class="striped">

        <tbody>
          <tr *ngFor="let chapter of chapters; index as i">
            <td class="center"> chapter {{chapter.ID}}</td>
            <td class="center"> <a class="waves-effect waves-light btn blue btn-border"
                (click)="goToContentEdit(chapter)">edit</a></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col 3"></div>
  </div>
  <div class="row" *ngIf="loading">
    <div class="col l4"></div>
    <div class="col l4">
      <div class="preloader-wrapper big active loader">
        <div class="spinner-layer spinner-blue-only">
          <div class="circle-clipper left">
            <div class="circle"></div>
          </div>
          <div class="gap-patch">
            <div class="circle"></div>
          </div>
          <div class="circle-clipper right">
            <div class="circle"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col l4"></div>
  </div>
</div> -->

<div class="fx-layout-row fx-layout-align-center-center">
  <div class="fx-flex-80pc">
    <p-table #dt [columns]="cols" [value]="chapters" [paginator]="true" selectionMode="single" [rows]="rows"
      [totalRecords]="totalRecords" [loading]="loading" (onRowSelect)="onRowSelect($event)">
      <!-- <ng-template pTemplate="caption">
          <div style="text-align: right">
            <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
            <input type="text" pInputText class="margin0"  size="50" placeholder="Global Filter"
              (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
          </div>
        </ng-template> -->
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
              ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order">
            </p-sortIcon>
          </th>
        </tr>
        <tr>
          <th *ngFor="let col of columns" [ngSwitch]="col.field">
            <input *ngSwitchCase="'ID'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')">
            <input *ngSwitchCase="'ChapterName'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')">
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td *ngFor="let col of columns">
            <span>{{rowData[col.field]}}</span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary" let-rowData>
        <div style="text-align:left">
          <button type="button" pButton (click)="showDialogToAdd()" label="Add"></button>
        </div>
      </ng-template>
    </p-table>

    <p-dialog header="Chapter Details" [(visible)]="displayDialog" [responsive]="true" showEffect="fade" [modal]="true"
      [style]="{width: '80%'}" (visibleChange)="handleClose()">
      <div class="red-text" *ngIf="errorMessage">{{ errorMessage }}</div>
      <div class="alert-success" *ngIf="successMessage">{{ successMessage }}</div>
      <div class="ui-g ui-fluid" *ngIf="chapter && displayDialog">
        <div class="ui-g-12">
          <div class="ui-g-4">
            <label for="ID">ID</label>
          </div>
          <div class="ui-g-8">
            {{ chapter.ID }}
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4 pad0">
            <label for="ChapterName">ChapterName*</label>
          </div>
          <div class="ui-g-8 pad0">
            <input pInputText class="margin0" id="chapterName" [(ngModel)]="chapter.ChapterName" required />
          </div>
        </div>
      </div>
      <ng-template pTemplate="footer">
        <div class="ui-dialog-buttonpane ui-helper-clearfix" *ngIf="chapter">
          <button type="button" pButton (click)="goToSections()" label="Sections" class="left"></button>
          <button type="button" pButton (click)="save()" [disabled]="!chapter.ChapterName || !chapter.ChapterContent"
            label="Save"></button>
        </div>

      </ng-template>

    </p-dialog>
  </div>
</div>
