import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as CryptoJS from 'crypto-js';
import { NGXLogger } from "ngx-logger";
import { Table } from "primeng/table";
import { Asset } from "src/app/models/assets.model";
import { ChapterSection } from "src/app/models/chapter-sections.model";
import { AssetsService } from "src/app/services/assets.service";
import { ChapterSectionsService } from "src/app/services/chapter-sections.service";
import { ChaptersService } from "src/app/services/chapters.service";
import { QuizesService } from "src/app/services/quizes.service";
import * as environment from "src/environments/environment";

@Component({
  selector: "app-chapter-sections",
  templateUrl: "./chapter-sections.component.html",
  styleUrls: ["./chapter-sections.component.css"],
})
export class ChapterSectionsComponent implements OnInit {
  @ViewChild("dt") private dt: Table;

  first = 0;
  rows = 15;
  sortField;
  sortOrder;
  filters;
  totalRecords = 0;

  displayDialog: boolean;

  selectedSection: ChapterSection;

  newSectionHeader: boolean;
  newSubsection: boolean;

  sections: ChapterSection[] = [];
  sectionHeaders: ChapterSection[] = [];
  subSections: { number: ChapterSection[] } = {
    number: []
  };
  section: ChapterSection;
  targets = [{ label: "Same page", value: "_self" }, { label: "New page", value: "_blank" }];

  cols: any[];

  errorMessage;
  successMessage;

  dateFilters: any;

  currentCourseId: number;
  currentChapterId: number;
  currentChapterName: string;

  courseChapters = [];
  chapterSections = [];
  links = [];

  sectionForm = new FormGroup({
    id: new FormControl("", Validators.required),
    chapterId: new FormControl("", Validators.required),
    name: new FormControl("", Validators.required),
    description: new FormControl(""),
    content: new FormControl(""),
    location: new FormControl("", Validators.required),
    target: new FormControl("", Validators.required),
    order: new FormControl("", Validators.required),
  });

  loading = true;
  sectionOrders: any[];

  editorConfig: any;
  tinyMceInit = {
    plugins: 'advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen media table code wordcount',
    toolbar:
      'undo redo | formatselect | bold italic backcolor | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | removeformat | link image',
    target_list: [
      { title: 'Same page', value: '_self' },
      { title: 'New page', value: '_blank' }
    ],
    default_link_target: '_blank',
    height: '700', width: '100%',
    paste_data_images: false,
    images_upload_handler: async (blobInfo) => {
      return await this.uploadToS3(blobInfo);
    },
  };

  constructor(
    private logger: NGXLogger,
    private sectionsService: ChapterSectionsService,
    private chaptersService: ChaptersService,
    private quizesService: QuizesService,
    private assetsService: AssetsService,
    public router: Router,
    private activateRoute: ActivatedRoute,
    private http: HttpClient
  ) { }

  async uploadToS3(blobInfo) {
    if (blobInfo?.blobUri()?.includes('assets/courses')) {
      return
    }

    const contentType = blobInfo.blob().type;
    const size = blobInfo.blob().size;

    const hash = CryptoJS.SHA256(contentType + size.toString()).toString(CryptoJS.enc.Hex);

    const assetName = `${hash}`;
    const assetRequest = {
      assetName: assetName,
      contentType: blobInfo.blob().type,
      assetType: 'image',
      course: { id: this.currentCourseId },
      chapterSection: this.section,
    } as Asset;

    const asset = await this.assetsService.getSignedUrl(assetRequest);
    await this.assetsService.putSignedUrl(asset.signedUrl, blobInfo.blob());
    return `${environment.environment.s3BucketUrl}${asset.location}`;
  }

  updateConfig() {
    this.editorConfig = {
      plugins: 'advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen media table code wordcount',
      toolbar:
        'undo redo | formatselect | bold italic backcolor | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | removeformat | link image',
      target_list: [
        { title: 'Same page', value: '_self' },
        { title: 'New page', value: '_blank' }
      ],
      default_link_target: '_blank',
      height: '700', width: '100%',
      paste_data_images: false,
      images_upload_handler: async (blobInfo) => {
        return await this.uploadToS3(blobInfo);
      },
    };
  }

  async loadSections() {
    this.sections = await this.sectionsService.findAll(this.currentCourseId, this.currentChapterId);
    this.chapterSections = this.sections.map((c) => {
      return { label: c.name, value: c.id };
    });

    this.sectionHeaders = this.sections.filter((s) => !s.parentId || s.parentId === 0).sort((a, b) => a.order - b.order);
    this.sectionHeaders.forEach((s, idx) => this.subSections[s.id] = []);
    this.sections.filter((s) => s.parentId && s.parentId !== 0).forEach((s) => {
      if (!this.subSections[s.parentId]) {
        this.subSections[s.parentId] = [];
      }
      if (this.subSections[s.parentId].find((ss) => ss.id === s.id)) {
        this.subSections[s.parentId] = this.subSections[s.parentId].map((ss) => ss.id === s.id ? s : ss).sort((a, b) => a.order - b.order);
        return;
      }

      this.subSections[s.parentId].push(s);
      this.subSections[s.parentId] = this.subSections[s.parentId].sort((a, b) => a.order - b.order);
    });
    this.sections.filter((s) => !s.parentId && !this.subSections[s.id]).forEach((s) => {
      this.subSections[s.parentId] = [];
    });

    // order sections by order and subsections by order
    this.sections = this.sectionHeaders.flatMap((s) => {
      return [s, ...(this.subSections[s.id] || [])];
    });

    this.totalRecords = this.sections.length;
    this.loading = false;
  }

  ngOnInit() {
    this.cols = [
      { field: "id", header: "ID" },
      { field: "name", header: "Name" },
      { field: "location", header: "Location" },
      { field: "target", header: "Target" },
    ];


    this.activateRoute.paramMap.subscribe(async (paramMap) => {
      const params = paramMap && paramMap["params"];
      if (params && params["courseId"] && params["chapterId"]) {
        this.currentCourseId = params["courseId"];
        this.currentChapterId = params["chapterId"];

        if (this.courseChapters.length === 0) {
          const chapters = await this.chaptersService.getChaptersBy(this.currentCourseId);
          this.courseChapters = chapters.map((c) => {
            return { label: c.ChapterName, value: c.ID };
          });
        }

        this.currentChapterName = this.courseChapters.find((c) => c.value == this.currentChapterId)?.label;

        if (this.links.length === 0) {
          const quizzes = await this.quizesService.findAllPerCourse(this.currentCourseId);
          this.links = quizzes.map((q) => {
            return { label: 'Quiz - ' + q.QuizTitle, value: `courses/${this.currentCourseId}/chapters/${this.currentChapterId}/quizzes/${q.QuizID}` };
          });
          const assets = await this.assetsService.findAll();
          this.links.push(...(assets.map((a) => {
            return { label: 'Asset - ' + a.assetName, value: `courses/${this.currentCourseId}/chapters/${this.currentChapterId}/assets/${a.id}` };
          })));
          // console.log(JSON.stringify(this.links));
        }

        await this.loadSections();
      } else {
        this.errorMessage = "No chapter sections found";
        this.loading = false;
        return;
      }
    });
    this.updateConfig();
  }

  addHeader() {
    this.clearAll();
    this.newSectionHeader = true;
    this.displayDialog = true;
    this.fillSectionHeaderOrders(0);
  }

  addSubsection(parentId: number) {
    this.clearAll();
    this.section.parentId = parentId;
    this.newSubsection = true;
    this.displayDialog = true;
    this.fillSubsectionOrders(0, parentId);
  }

  async save() {
    const afterId = this.section["afterId"];
    try {
      this.section.location = this.section.location?.trim();
      if (afterId) {
        if (this.section.parentId) {
          const afterSubsection = this.subSections[this.section.parentId].find((s) => s.id === afterId);
          if (afterSubsection) {
            const index = this.subSections[this.section.parentId].indexOf(afterSubsection);
            const section = this.section;
            delete section["afterId"];
            this.subSections[this.section.parentId].splice(index + 1, 0, section);
          }
          // reorder all subsections after the new section
          this.subSections[this.section.parentId]
            .forEach((s, idx) => s.order = idx + 1);

          await this.sectionsService.updateSections(this.currentCourseId, this.currentChapterId, this.subSections[this.section.parentId]);
        }

        if (!this.section.parentId) {
          const afterSection = this.sectionHeaders.find((s) => s.id === afterId);
          if (afterSection) {
            const index = this.sectionHeaders.indexOf(afterSection);
            const section = this.section;
            delete section["afterId"];
            this.sectionHeaders.splice(index + 1, 0, section);
          }

          this.sectionHeaders.forEach((s, idx) => s.order = idx + 1);

          await this.sectionsService.updateSections(this.currentCourseId, this.currentChapterId, this.sectionHeaders);
        }
      } else {
        const section = this.section;
        delete section["afterId"];
        if (this.section.id) {
          if (this.section.parentId) {
            const idx = this.subSections[this.section.parentId].findIndex((s) => s.id == this.section.id);
            if (idx < 0) {
              this.subSections[this.section.parentId].push(section);
            } else {
              this.subSections[this.section.parentId][idx] = section;
            }
            this.subSections[this.section.parentId]
              .forEach((s, idx) => s.order = idx + 1);
            await this.sectionsService.updateSections(this.currentCourseId, this.currentChapterId, this.subSections[this.section.parentId]);
          } else {
            const idx = this.sectionHeaders.findIndex((s) => s.id == this.section.id);
            this.sectionHeaders[idx] = section;
            this.sectionHeaders.forEach((s, idx) => s.order = idx + 1);
            await this.sectionsService.updateSections(this.currentCourseId, this.currentChapterId, this.sectionHeaders);
          }
        } else {
          if (this.section.parentId) {
            this.subSections[this.section.parentId].push(section);
            this.subSections[this.section.parentId]
              .forEach((s, idx) => s.order = idx + 1);

            await this.sectionsService.create(this.currentCourseId, this.currentChapterId, section);
          } else {
            this.sectionHeaders.push(section);
            this.sectionHeaders.forEach((s, idx) => s.order = idx + 1);
            await this.sectionsService.create(this.currentCourseId, this.currentChapterId, section);
          }
        }
      }
      await this.loadSections();
      this.successMessage = "Section saved successfully";
    } catch (err) {
      if (
        err &&
        err.status === 400 &&
        err.error &&
        err.error.error &&
        err.error.error.description
      ) {
        this.errorMessage = err.error.error.description;
      }
    }
  }

  onRowSelect(event) {
    this.newSectionHeader = false;
    this.clearAll();
    this.section = Object.assign({} as ChapterSection, event.data);
    this.loadSections();
    if (this.section.parentId) {
      const idx = this.subSections[this.section.parentId].findIndex((s) => s.id == this.section.id);
      this.section["afterId"] = idx > 0 ? this.subSections[this.section.parentId][idx - 1]?.id : null;
    } else {
      const idx = this.sectionHeaders.findIndex((s) => s.id == this.section.id);
      this.section["afterId"] = idx > 0 ? this.sectionHeaders[idx - 1]?.id : null;
    }
    this.section.parentId ? this.fillSubsectionOrders(this.section.id, this.section.parentId) : this.fillSectionHeaderOrders(this.section.id);
    this.displayDialog = true;
  }

  fillSectionHeaderOrders(id: number) {
    this.sectionOrders = this.sections
      .filter((s) => !s.parentId || s.parentId === 0)
      .filter((s) => s.id !== id)
      .sort((a, b) => a.order - b.order)
      .map((s) => {
        return { label: s.name, value: s.id };
      });
  }

  fillSubsectionOrders(id: number, parentId: number) {
    this.sectionOrders = this.sections
      .filter((c) => c.parentId === parentId)
      .filter((s) => s.id !== id)
      .sort((a, b) => a.order - b.order)
      .map((s) => {
        return { label: s.name, value: s.id };
      });
  }

  clearAll() {
    this.section = { chapterId: this.currentChapterId } as ChapterSection;
    this.errorMessage = null;
    this.successMessage = null;
    this.newSectionHeader = false;
    this.newSubsection = false;
  }

  async delete() {
    this.loading = true;
    await this.sectionsService.delete(this.currentCourseId, this.currentChapterId, this.section.id);
    if (this.sectionHeaders?.find((s) => s.id === this.section.id)) {
      this.sectionHeaders = this.sectionHeaders.filter((s) => s.id !== this.section.id);
      this.sectionHeaders.forEach((s, idx) => s.order = idx + 1);
      await this.sectionsService.updateSections(this.currentCourseId, this.currentChapterId, this.sectionHeaders);
    }
    if (this.subSections[this.section.parentId]?.find((s) => s.id === this.section.id)) {
      this.subSections[this.section.parentId] = this.subSections[this.section.parentId].filter((s) => s.id !== this.section.id);
      this.subSections[this.section.parentId].forEach((s, idx) => s.order = idx + 1);
      await this.sectionsService.updateSections(this.currentCourseId, this.currentChapterId, this.subSections[this.section.parentId]);
    }

    this.loadSections();
    this.successMessage = "Section deleted successfully";
  }

  handleClose() {
    this.clearAll();
    this.displayDialog = false;
    this.logger.info(`Closing dialog...`);
  }

  selectedParent() {
    console.log(`Parent changed to ${this.section.parentId} - resetting afterId`);
    this.fillSubsectionOrders(this.section.id, this.section.parentId);
    this.section["afterId"] = null;
  }


}
