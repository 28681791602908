import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, async, lastValueFrom, Observable, combineLatest } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Course } from '../models/course.model';
import { User } from '../models/user.model';
import { select } from '@ngrx/store';
import { SignInState } from 'src/app/components/allusers/signin/signin.model';
import { Store } from '@ngrx/store';
import { getSignedInUser, isSignedOut } from 'src/app/components/allusers/signin/signin.selector';
import { NavigationEnd, Router } from '@angular/router';
import { UpdateAction, CourseState } from 'src/app/components/members/courses/courses.reducer';
import { CoursesActionTypes } from 'src/app/components/members/courses/courses.reducer';
import { QuizesService } from 'src/app/services/quizes.service';
import { ChaptersService } from 'src/app/services/chapters.service';
import { NGXLogger } from 'ngx-logger';
import { RegmembersService } from 'src/app/services/regmembers.service';
import { isCourseNotSelected } from 'src/app/components/members/courses/courses.selector';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {  
  baseURL: string = environment.apiUrl;
  courses: Course[];  
  currentUser$ = new Observable<User>();
  currentUser: User;
  currentCourse: Course;

  constructor(private http: HttpClient,
    private loginStore: Store<SignInState>,
    private router: Router,
    private courseStore: Store<CourseState>,
    private regmemService: RegmembersService,
    private chapterService: ChaptersService,
    private quizesService: QuizesService,
    private logger: NGXLogger) {
    this.currentUser$ = this.loginStore.pipe(select(getSignedInUser));
    this.currentUser$.subscribe(user => {
      this.currentUser = user;      
    });

    this.loginStore.pipe(select(getSignedInUser), filter(val => !val)).subscribe(() => {
      console.log(`User is signed out, clearing course...`);
      this.courseStore.dispatch(new UpdateAction({ currentState: CoursesActionTypes.NoCourse, course: null }));
    });

    console.log(`Router events...`);    

    // Combine router events with course store state
    combineLatest([
      this.router.events.pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        filter(() => this.router.url.includes('/courses/'))
      ),
      this.courseStore.pipe(select(isCourseNotSelected), filter(val => val))
    ]).subscribe(async () => {      
        this.logger.info(`Course selected...`);
        const url = this.router.url;
        const courseIdMatch = url.match(/\/courses\/(\d+)/);
        if (courseIdMatch) {
          const courseId = courseIdMatch[1];
          const courses = await this.findAll();          
          const selectedCourse = courses.find(c => c.id == parseInt(courseId));
          if (selectedCourse) {
            this.courseStore.dispatch(new UpdateAction({ currentState: CoursesActionTypes.UpdateCourse, course: selectedCourse }));
          }
        }
      }
    );
  }

  async findAll() {
    if (this.courses) {
      return this.courses;
    }

    this.courses = await lastValueFrom(
      this.http
        .get<Array<Course>>(`${environment.apiUrl}/courses`)
    );
    return this.courses;
  }

  async findBy(id) {
    if (this.courses) {
      return this.courses.find(c => c.id == id);
    }

    return await lastValueFrom(
      this.http
        .get<Course>(`${environment.apiUrl}/courses/${id}`)
    );
  }

  async update(course: Course) {
    return await lastValueFrom(
      this.http
        .put<Course>(`${environment.apiUrl}/courses/${course.id}`, course)
    );
  }

  async create(course: Course): Promise<Course> {
    return await lastValueFrom(
      this.http
        .post<Course>(`${environment.apiUrl}/courses`, course)
    );
  }

  async delete(id: number) {
    return await lastValueFrom(
      this.http
        .delete(`${environment.apiUrl}/courses/${id}`)
    );
  }
}
