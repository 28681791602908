<div class="fx-layout-row fx-layout-align-center-center">
  <div class="fx-flex-80pc">
    <p-table #dt [columns]="cols" [value]="courses" [paginator]="true" selectionMode="single" [rows]="rows"
      [totalRecords]="totalRecords" [loading]="loading" (onRowSelect)="onRowSelect($event)">
      <!-- <ng-template pTemplate="caption">
          <div style="text-align: right">
            <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
            <input type="text" pInputText class="margin0"  size="50" placeholder="Global Filter"
              (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
          </div>
        </ng-template> -->
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
              ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order">
            </p-sortIcon>
          </th>
        </tr>
        <tr>
          <th *ngFor="let col of columns" [ngSwitch]="col.field">
            <input *ngSwitchCase="'id'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')">
            <input *ngSwitchCase="'courseName'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')">
            <input *ngSwitchCase="'courseDesc'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')">
            <input *ngSwitchCase="'status'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')">
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td *ngFor="let col of columns">
            <span *ngIf="col.field == 'courseDesc'">{{rowData[col.field] | slice:0:50}}...</span>
            <span *ngIf="col.field != 'courseDesc'">{{rowData[col.field]}}</span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary" let-rowData>
        <div style="text-align:left">
          <button type="button" pButton (click)="showDialogToAdd()" label="Add"></button>
        </div>
      </ng-template>
    </p-table>

    <p-dialog header="Course Details" [(visible)]="displayDialog" [responsive]="true" showEffect="fade" [modal]="true"
      [style]="{width: '500px'}" (visibleChange)="handleClose()">
      <div class="red-text" *ngIf="errorMessage">{{ errorMessage }}</div>

      <div class="ui-g ui-fluid" *ngIf="course">
        <div class="ui-g-12">
          <div class="ui-g-4">
            <label for="courseId">CourseId</label>
          </div>
          <div class="ui-g-8">
            {{ course.courseId }}
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4 pad0">
            <label for="courseCode">Course Code*</label>
          </div>
          <div class="ui-g-8 pad0">
            <input pInputText class="margin0" id="courseCode" [(ngModel)]="course.courseCode" required />
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4 pad0">
            <label for="courseName">Course Name*</label>
          </div>
          <div class="ui-g-8 pad0">
            <input pInputText class="margin0" id="courseName" [(ngModel)]="course.courseName" required />
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4 pad0">
            <label for="courseDesc">Course Description*</label>
          </div>
          <div class="ui-g-8 pad0" *ngIf="course.courseDesc == null || course.courseDesc == ''">
            <textarea [(ngModel)]="course.courseDesc"></textarea>
          </div>
          <div class="ui-g-8 pad0" *ngIf="course.courseDesc && course.courseDesc !== null && course.courseDesc !== ''">
            <editor #editor id="editor" [init]="editorConfig" [(ngModel)]="course.courseDesc" />
          </div>
        </div>        
        <div class="ui-g-12">
          <div class="ui-g-4 pad0">
            <label for="status">Status*</label>
          </div>
          <div class="ui-g-8 pad0">
            <p-dropdown class="margin0" id="status" [options]="courseStatuses" [(ngModel)]="course.status"></p-dropdown>
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4 pad0">
            <label for="config">Config</label>
          </div>
          <div class="ui-g-8 pad0">
            <textarea rows="10" cols="30" pInputTextarea class="margin0" id="config"
              [(ngModel)]="course.config"></textarea>
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4 pad0">
            <label for="stripePriceId">Stripe Price Id*</label>
          </div>
          <div class="ui-g-8 pad0">
            <input pInputText class="margin0" id="stripePriceId" [(ngModel)]="course.stripePriceId" required />
          </div>
        </div>


      </div>
      <ng-template pTemplate="footer">
        <div class="ui-dialog-buttonpane ui-helper-clearfix" *ngIf="course">
          <a target="_blank" (click)="gotoChapters($event)" type="button" pButton label="Course Chapters"
            class="left"></a>
          <button type="button" pButton (click)="delete()" label="Delete" class="left"></button>
          <button type="button" pButton (click)="save()"
            [disabled]="!course.courseCode || !course.courseName || !course.courseDesc || !course.status || !course.stripePriceId"
            label="Save"></button>
        </div>

      </ng-template>

    </p-dialog>
  </div>
</div>
