import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from 'rxjs';
import { environment } from "../../environments/environment";
import { Quiz } from "../models/quiz.model";

@Injectable({
  providedIn: "root",
})
export class QuizesService {
  baseURL: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  async findAllPerCourse(courseId: number) {
    return await lastValueFrom(
      this.http
        .get<Array<Quiz>>(
          `${environment.apiUrl}/courses/${courseId}/quizzes`
        )
    );
  }

  async findByCourseIdAndChapterId(courseId: number, chapterId: number) {
    return await lastValueFrom(
      this.http
        .get<Array<Quiz>>(
          `${environment.apiUrl}/courses/${courseId}/quizzes?quizLocation2=${chapterId}`
        )
    );
  }

  async findByCourseIdAndQuizId(courseId: number, quizId: number) {
    return await lastValueFrom(
      this.http
        .get<Quiz>(
          `${environment.apiUrl}/courses/${courseId}/quizzes/${quizId}`
        )
    );
  }

  async delete(courseId: number, quizId: number) {
    return await lastValueFrom(
      this.http
        .delete(
          `${environment.apiUrl}/courses/${courseId}/quizzes/${quizId}`
        ))
      ;
  }

  async update(courseId: number, quiz) {
    return await lastValueFrom(
      this.http
        .put<Quiz>(`${environment.apiUrl}/courses/${courseId}/quizzes/${quiz.QuizID}`, quiz)
    );
  }

  async create(courseId: number, quiz: Quiz): Promise<Quiz> {
    return await lastValueFrom(
      this.http
        .post<Quiz>(`${environment.apiUrl}/courses/${courseId}/quizzes`, quiz)
    );
  }
}
