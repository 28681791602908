import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from "../../environments/environment";
import { Chapter } from "../models/chapters.model";

@Injectable({
  providedIn: "root",
})
export class ChaptersService {
  baseURL: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  async getChapters(): Promise<Chapter[]> {
    return await lastValueFrom(this.http
      .get<Chapter[]>(`${environment.apiUrl}/chapters`));
  }

  async getChapter(id: number): Promise<Chapter> {
    return await lastValueFrom(this.http
      .get<Chapter>(`${environment.apiUrl}/chapters/${id}`));
  }

  async createChapter(chapter: Chapter) {
    return await lastValueFrom(this.http
      .post(`${environment.apiUrl}/chapters`, chapter));
  }

  async updateChapter(chapterId: number, chapter: Chapter) {
    return await lastValueFrom(this.http
      .put(`${environment.apiUrl}/chapters/${chapterId}`, chapter));
  }

  async getChaptersBy(courseId: number): Promise<Chapter[]> {
    return await lastValueFrom(this.http
      .get<Chapter[]>(`${environment.apiUrl}/courses/${courseId}/chapters`));
  }

  async getFinalChapterBy(courseId: number): Promise<Chapter> {    
    const chapters = await this.getChaptersBy(courseId);
    if (chapters) {
      for (let i = 0; i < chapters.length; i++) {
        if (chapters[i].ChapterName.trim().toUpperCase().indexOf('FINAL') !== -1) {
          return chapters[i];
        }
      }
    }
    return null;
  }

  async getChapterBy(courseId: number, id: number): Promise<Chapter> {
    return await lastValueFrom(this.http
      .get<Chapter>(`${environment.apiUrl}/courses/${courseId}/chapters/${id}`));
  }

  async createChapterBy(courseId: number, chapter: Chapter) {
    return await lastValueFrom(this.http
      .post(`${environment.apiUrl}/courses/${courseId}/chapters`, chapter));
  }

  async updateChapterBy(courseId: number, chapterId: number, chapter: Chapter) {
    return await lastValueFrom(this.http
      .put(`${environment.apiUrl}/courses/${courseId}/chapters/${chapterId}`, chapter));
  }

}
