import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ChapterSection } from 'src/app/models/chapter-sections.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChapterSectionsService {
  baseURL: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  async findAll(courseId: number, chapterId: number) {
    return await lastValueFrom(
      this.http
        .get<Array<ChapterSection>>(`${environment.apiUrl}/courses/${courseId}/chapters/${chapterId}/sections`)
    );
  }

  async findBy(courseId: number, chapterId: number, sectionId: number) {
    return await lastValueFrom(
      this.http
        .get<ChapterSection>(`${environment.apiUrl}/courses/${courseId}/chapters/${chapterId}/sections/${sectionId}`)
    );
  }

  async update(courseId: number, chapterId: number, sectionId: number, section: ChapterSection) {
    return await lastValueFrom(
      this.http
        .put<ChapterSection>(`${environment.apiUrl}/courses/${courseId}/chapters/${chapterId}/sections/${sectionId}`, section)
    );
  }

  async updateSections(courseId: number, chapterId: number, sections: ChapterSection[]) {
    return await lastValueFrom(
      this.http
        .put<ChapterSection>(`${environment.apiUrl}/courses/${courseId}/chapters/${chapterId}/sections`, sections)
    );
  }

  async create(courseId: number, chapterId: number, section: ChapterSection): Promise<ChapterSection> {
    return await lastValueFrom(
      this.http
        .post<ChapterSection>(`${environment.apiUrl}/courses/${courseId}/chapters/${chapterId}/sections`, section)
    );
  }

  async delete(courseId: number, chapterId: number, sectionId: number) {
    return await lastValueFrom(
      this.http
        .delete(`${environment.apiUrl}/courses/${courseId}/chapters/${chapterId}/sections/${sectionId}`)
    );
  }
}
