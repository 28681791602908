import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Asset } from 'src/app/models/assets.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {
  baseURL: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  async findAll() {
    return await lastValueFrom(
      this.http
        .get<Array<Asset>>(`${environment.apiUrl}/assets`)
    );
  }

  async findBy(id) {
    return await lastValueFrom(
      this.http
        .get<Asset>(`${environment.apiUrl}/assets/${id}`)
    );
  }

  async update(asset: Asset) {
    return await lastValueFrom(
      this.http
        .put<Asset>(`${environment.apiUrl}/assets/${asset.id}`, asset, {
          headers: {
            "Content-Type": asset.contentType,
            "X-Amz-Acl": "public-read",
          }
        })
    );
  }

  async create(asset: Asset): Promise<Asset> {
    return await lastValueFrom(
      this.http
        .post<Asset>(`${environment.apiUrl}/assets`, asset)
    );
  }

  async getSignedUrl(asset: Asset): Promise<Asset> {
    return await lastValueFrom(
      this.http
        .post<Asset>(`${environment.apiUrl}/assets/signedUrl`, asset)
    );
  }

  async putSignedUrl(signedUrl: string, file: File): Promise<string> {
    return await lastValueFrom(
      this.http
        .put<string>(signedUrl, file)
    );
  }

  async delete(assetId: number) {
    return await lastValueFrom(
      this.http
        .delete(
          `${environment.apiUrl}/assets/${assetId}`
        ))
      ;
  }
}
