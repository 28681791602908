import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { PagesService } from '../../../services/pages.service';

@Component({
  selector: 'app-page-list',
  templateUrl: './page-list.component.html',
  styleUrls: ['./page-list.component.css']
})
export class PageListComponent implements OnInit {

  pages;
  loading: Boolean = true;

  constructor(private route: ActivatedRoute,
    private pagesService: PagesService,
    private routerNav: Router) { }

  ngOnInit() {
    this.loadAllPages()
  }

  loadAllPages() {
    this.pagesService.getPages()
      .then(res => {
        this.pages = res;
        this.loading = false;
      })
  }

  goToPageEdit(page) {
    let navigationExtras: NavigationExtras = {
      queryParams: { 'pageId': page.ID },
      // fragment: 'anchor'
    };
    this.routerNav.navigate([`/admin/edit-page`], navigationExtras);
  }
}
