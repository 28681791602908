import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { jsPDF } from "jspdf";
import * as moment from "moment";
import { filter } from "rxjs/operators";
import { SignInState } from "src/app/components/allusers/signin/signin.model";
import { getSignedInUser } from "src/app/components/allusers/signin/signin.selector";
import { Certificate } from "src/app/models/certificate.model";
import { Course } from "src/app/models/course.model";
import { User } from "src/app/models/user.model";
import { CertificatesService } from "src/app/services/certificates.service";
import { RegmembersService } from "src/app/services/regmembers.service";
import { CourseState } from "../courses/courses.reducer";
import { getCourseSelected, isCourseNotSelected } from "../courses/courses.selector";
import * as data from "./data.json";

@Component({
  selector: "app-download-certificate",
  templateUrl: "./download-certificate.component.html",
  providers: [{ provide: "Window", useValue: window }],
  styleUrls: ["./download-certificate.component.css"],
})
export class DownloadCertificateComponent implements OnInit {
  certificateId;
  certificate: Certificate;
  errorMessage;

  currentUser$ = this.signInStore.pipe(
    select(getSignedInUser),
    filter((val) => (val ? true : false))
  );
  currentUser: User;

  courseSelected$ = this.courseStore.pipe(select(getCourseSelected), filter(val => !val));
  courseNotSelected$ = this.courseStore.pipe(select(isCourseNotSelected), filter(val => val))
  currentCourse: Course;

  constructor(
    private signInStore: Store<SignInState>,
    private certificatesService: CertificatesService,
    private route: ActivatedRoute,
    private regmemService: RegmembersService,
    @Inject("Window") private window: Window,
    private courseStore: Store<CourseState>
  ) { }

  async ngOnInit() {
    this.courseSelected$.subscribe(val => this.currentCourse = val);
    this.courseNotSelected$.subscribe(val => this.currentCourse = undefined);
    this.currentUser$.subscribe(val => {
      this.currentUser = val;
      this.getCertificate();
    });
  }

  download() {
    // var doc = new jsPDF();
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a4"
    });

    const { certTemplate } = data;

    doc.addImage(certTemplate, "PNG", 0, 0, 296, 209);
    doc.setFontSize(22);
    const nameXOffset = (doc.internal.pageSize.getWidth() - doc.getTextWidth(this.certificate.cert_name)) / 2;
    doc.text(this.certificate.cert_name, nameXOffset, 105);
    doc.setFontSize(13);
    const dateXOffset = (doc.internal.pageSize.getWidth() - (doc.getTextWidth(moment(this.certificate.cert_dt).format("MM-DD-YYYY"))) / 2) / 2 - 8;
    doc.text(moment(this.certificate.cert_dt).format("MM-DD-YYYY"), dateXOffset, 138);
    if (data[this.currentCourse.courseCode]) {      
      const licenseNumber = data[this.currentCourse.courseCode].licenseNumber;
      if (licenseNumber) {
        const licenseXOffset = (doc.internal.pageSize.getWidth() - doc.getTextWidth(licenseNumber)) - 90;        
        doc.text("License Number:", licenseXOffset - 5, 159);        
        doc.setTextColor(169 / 255, 169 / 255, 169 / 255);
        doc.setFontSize(13);       
        
        doc.text(licenseNumber, licenseXOffset, 166);
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(13);      
      }
    }
    doc.save("certificate.pdf");
  }

  async getCertificate() {
    try {
      const certificates = await this.certificatesService
        .getCertificateByCourseIdAndMemberId(this.currentCourse.id, this.currentUser.memberid);
      if (certificates.length > 0) {
        this.certificate = certificates[0];
      }
    } catch (error) {
      this.errorMessage =
        "Unable to retrieve your certificate, please contact us directly"
        ;
    }
  }
}
