<div class="row padding-row backgrounDColor">

  <div class="col s12 m4 l4">
    <div class="card">
      <div class="card-content black-text">
        <div class="center">
          <fa-icon [icon]="reportIcon" size="7x"></fa-icon>
        </div>
        <p class="center">Area Units Reports
        </p>
      </div>
      <div class="card-action center">

        <a class="waves-effect waves-light btn-small btn-border" routerLink="/teacher/area-unit-reports/16/{{schoolId}}"
          *ngIf="currentUser.memberstatus === 'teacherclu'">CLIU Web </a>
        <a class="waves-effect waves-light btn-small btn-border" routerLink="/teacher/area-unit-reports/16"
          *ngIf="currentUser.memberstatus === 'cluadmin'">CLIU Web </a>
        <a class="waves-effect waves-light btn-small btn-border" routerLink="/teacher/area-unit-reports/33"
          *ngIf="currentUser.memberstatus === 'csiuadmin'">CSIU </a>

      </div>
    </div>
  </div>

</div>