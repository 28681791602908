import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quick-tips',
  templateUrl: './quick-tips.component.html',
  styleUrls: ['./quick-tips.component.css']
})
export class QuickTipsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
