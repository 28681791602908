
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TextToSpeechState, TextToSpeechStates } from 'src/app/components/members/text-to-speech/text-to-speech.model';

export const selectTextToSpeechState = createFeatureSelector<TextToSpeechState>('textToSpeech');

export const isTtsNotInitialized = createSelector(
  selectTextToSpeechState,
  (currTts: TextToSpeechState) => currTts && currTts.state === TextToSpeechStates.NOT_INITIALIZED && currTts
);

export const isTtsInitialized = createSelector(
  selectTextToSpeechState,
  (currTts: TextToSpeechState) => currTts && currTts.state === TextToSpeechStates.INITIALIZED && currTts
);

export const isTtsPlaying = createSelector(
  selectTextToSpeechState,
  (currTts: TextToSpeechState) => currTts && currTts.state === TextToSpeechStates.PLAYING && currTts
);

export const isTtsPaused = createSelector(
  selectTextToSpeechState,
  (currTts: TextToSpeechState) => currTts && currTts.state === TextToSpeechStates.PAUSED && currTts
);

export const isTtsStopped = createSelector(
  selectTextToSpeechState,
  (currTts: TextToSpeechState) => currTts && currTts.state === TextToSpeechStates.STOPPED && currTts
);

export const isTtsCompleted = createSelector(
  selectTextToSpeechState,
  (currTts: TextToSpeechState) => currTts && currTts.state === TextToSpeechStates.COMPLETED && currTts
);