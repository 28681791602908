<div class="chapter-sections">
  <table class="table table-bordered table-hover table-striped" style="width:100%">
    <thead>
      <tr>
        <th style="background-color: rgb(32, 162, 134);">
        <h4><span style="color:rgb(255, 255, 255)"><strong><span style="font-size:11px">Chapter 2<br />
        Signals, Signs, Pavement Markings, Speed Regulations, Special Circumstances, Types of Adverse Conditions, Scanning Your Environment &amp; Managing your Space</span></strong></span></h4>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style="background-color: rgb(229, 229, 229);"><span style="font-size:12px"><strong><u><a href="/course-view/12/sounds/ignals.wav" style="color: rgb(114, 114, 114);" >Signals</a>&nbsp;</u></strong></span><br />
        <span style="font-size:8pt">(Click on all links in this website to become familar with the topic)</span></td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><a href="http://www.dot.state.pa.us/Public/DVSPubsForms/BDL/BDL%20Manuals/Manuals/PA%20Drivers%20Manual%20By%20Chapter/English/chapter_2.pdf" target="_blank"><span style="color:rgb(0, 0, 0)"><span style="font-size:12px"><u><strong>Visit</strong></u></span></span></a><span style="font-size:11px"><u><a href="http://www.dot.state.pa.us/Public/DVSPubsForms/BDL/BDL%20Manuals/Manuals/PA%20Drivers%20Manual%20By%20Chapter/English/chapter_2.pdf" target="_blank"><span style="color:rgb(0, 0, 0)"><strong>&nbsp;</strong>this website</span></a></u><a href="http://www.dot.state.pa.us/Public/DVSPubsForms/BDL/BDL%20Manuals/Manuals/PA%20Drivers%20Manual%20By%20Chapter/English/chapter_2.pdf" target="_blank"> </a>&nbsp;(Read about Traffic Signals)</span></p>
  
        <p style="margin-left: 40px;"><span style="font-size:11px">(Read&nbsp;PAGES 7 to 9 ONLY)</span></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><a href="https://bklyner.com/what-really-causes-traffic-congestion-sheepshead-bay/" target="_blank"><span style="color:rgb(0, 0, 0)"><span style="font-size:12px"><u><strong>Visit</strong></u></span></span></a><span style="font-size:11px"><a href="https://bklyner.com/what-really-causes-traffic-congestion-sheepshead-bay/" target="_blank"><u><span style="color:rgb(0, 0, 0)">&nbsp;this website</span></u></a><a href="http://www.ehow.com/about_5403551_causes-traffic-congestion.html" target="_blank"> </a>&nbsp;(Causes of Congestion)&nbsp;</span></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><a href="http://www.tech-faq.com/how-do-traffic-lights-work.html" target="_blank"><span style="color:rgb(0, 0, 0)"><span style="font-size:12px"><u><strong>Visit</strong></u></span></span></a><span style="font-size:11px"><u><a href="http://www.tech-faq.com/how-do-traffic-lights-work.html" target="_blank"><span style="color:rgb(0, 0, 0)">&nbsp;this website&nbsp;</span></a><span style="color:rgb(0, 0, 0)">&nbsp;</span></u><span style="color:rgb(0, 0, 0)"> (How traffic lights work)</span></span></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><span style="font-size:12px"><u><a href="http://www.crashdataservices.net/traffic_signal_accident_reconstruction_expert.html" style="color: rgb(114, 114, 114);" target="_blank"><span style="color:rgb(0, 0, 0)"><strong>Visit</strong></span></a></u></span><span style="font-size:11px"><u><a href="http://www.crashdataservices.net/traffic_signal_accident_reconstruction_expert.html" style="color: rgb(114, 114, 114);" target="_blank">&nbsp;this website&nbsp;&nbsp;</a></u>&nbsp;&nbsp;(Traffic Lights and Accidents)</span></p>
  
        <p style="margin-left: 40px;">&nbsp;</p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><span style="font-size:12px"><u><a href="/course-view/12/quizzes/15" style="color: rgb(114, 114, 114);" ><span style="color:rgb(0, 0, 0)"><strong><span style="background-color:rgb(255, 255, 0)">Click</span></strong></span></a></u></span><span style="font-size:11px"><u><a href="/course-view/12/quizzes/15" style="color: rgb(114, 114, 114);" ><span style="background-color:rgb(255, 255, 0)">&nbsp;</span></a><span style="background-color:rgb(255, 255, 0)">here to record your multiple choice answer</span>&nbsp;</u>&nbsp;&nbsp;<strong> </strong></span><strong><span style="font-size:14px">(</span>Chapter 2 #1 of 10)</strong></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><span style="font-size:12px"><u><a href="/course-view/12/quizzes/3" style="color: rgb(114, 114, 114);" ><span style="color:rgb(0, 0, 0)"><span style="background-color:rgb(255, 255, 0)"><strong>Take</strong> </span></span></a></u></span><span style="font-size:11px"><u><a href="/course-view/12/quizzes/3" style="color: rgb(114, 114, 114);" ><span style="background-color:rgb(255, 255, 0)">this&nbsp;</span></a></u></span><span style="font-size:12px"><u><a href="/course-view/12/quizzes/3" style="color: rgb(114, 114, 114);" ><strong><span style="background-color:rgb(255, 255, 0)">quiz</span></strong></a></u></span><span style="font-size:14px"> <strong>(</strong></span><strong>Chapter 2 # Quiz 2)</strong><span style="font-size:12px"><u> </u></span></p>
        </td>
      </tr>
      <tr>
        <td style="background-color: rgb(229, 229, 229);"><span style="font-size:12px"><strong><u><a href="/course-view/12/sounds/signs.wav" style="color: rgb(114, 114, 114);" >Signs</a>&nbsp;</u></strong></span></td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><span style="font-size:12px"><u><a href="http://www.trafficsign.us/regsign.html" style="color: rgb(114, 114, 114);" target="_blank"><span style="color:rgb(0, 0, 0)"><strong>Visit</strong></span></a></u></span><span style="font-size:11px"><u><a href="http://www.trafficsign.us/regsign.html" style="color: rgb(114, 114, 114);" target="_blank"><span style="color:rgb(0, 0, 0)">&nbsp;</span>this website</a></u><a href="http://www.trafficsign.us/regsign.html" style="color: rgb(114, 114, 114);" target="_blank">&nbsp;</a>&nbsp;(Discriptions of Road Signs)</span></p>
  
        <p style="margin-left: 40px;"><u><a href="https://www.yourmechanic.com/article/the-guide-to-right-of-way-laws-in-pennsylvania-by-valerie-mellema" target="_blank"><span style="font-size:12px"><span style="color:rgb(0, 0, 0)"><strong>Visit</strong></span></span><span style="color:rgb(0, 0, 0)"><span style="font-size:11px">&nbsp;this website</span></span></a></u>&nbsp; <span style="font-size:11px">(Guide to Right-of-Way Laws in PA)</span></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><strong><span style="font-size:12px"><u><span style="color:rgb(0, 0, 0)">V</span><a href="/members/chapter2/2_video2_streetsofdanger.cfm" style="color: rgb(114, 114, 114);" ><span style="color:rgb(0, 0, 0)">ideo:</span></a></u></span></strong><span style="font-size:11px"><u><a href="/members/chapter2/2_video2_streetsofdanger.cfm" style="color: rgb(114, 114, 114);" > Streets of Danger and the Road to Safety</a></u></span></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><span style="font-size:12px"><u><span style="color:rgb(0, 0, 0)"><strong>O</strong></span><a href="/members/handouts/rural%20roads%20sm.pdf" style="color: rgb(114, 114, 114);" ><span style="color:rgb(0, 0, 0)"><strong>nline</strong></span></a></u></span><span style="font-size:11px"><u><a href="/members/handouts/rural%20roads%20sm.pdf" style="color: rgb(114, 114, 114);" > handout (pdf)</a></u></span><br />
        <span style="font-size:8pt">(Please use the controls in the pdf program to increase/decrease font size and turn the page)</span></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><span style="font-size:12px"><u><a href="/course-view/12/quizzes/16" style="color: rgb(114, 114, 114);" ><span style="color:rgb(0, 0, 0)"><strong><span style="background-color:rgb(255, 255, 0)">Click</span></strong></span></a></u></span><span style="font-size:11px"><u><a href="/course-view/12/quizzes/16" style="color: rgb(114, 114, 114);" ><span style="color:rgb(0, 0, 0)"><span style="background-color:rgb(255, 255, 0)">&nbsp;</span></span></a><span style="background-color:rgb(255, 255, 0)">here to record your multiple choice answer</span></u>&nbsp;&nbsp; </span><strong><span style="font-size:14px">(</span>Chapter 2&nbsp;#2 of 10)</strong></p>
        </td>
      </tr>
      <tr>
        <td style="background-color: rgb(229, 229, 229);"><strong><span style="font-size:12px"><u><a href="/course-view/12/sounds/pavementmarkings.wav" style="color: rgb(114, 114, 114);" >Pavement Markings</a>&nbsp;</u></span></strong></td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><u><a href="http://www.dot.state.pa.us/Public/DVSPubsForms/BDL/BDL%20Manuals/Manuals/PA%20Drivers%20Manual%20By%20Chapter/English/PUB%2095.pdf" target="_blank"><span style="color:rgb(0, 0, 0)"><span style="font-size:12px"><strong>Visit</strong></span><span style="font-size:11px">&nbsp;this website</span></span></a></u><span style="font-size:8pt"><u>&nbsp;</u> (Scroll down but be sure to read all &nbsp;of Page 22 Roadway Markings)</span><br />
        <a href="https://blog.nationwide.com/highway-driving-tips-teens/" target="_blank"><u><span style="color:rgb(0, 0, 0)"><span style="font-size:12px"><strong>Visit</strong></span><span style="font-size:11px">&nbsp;this website</span></span><span style="font-size:8pt">&nbsp;</span></u></a>&nbsp;<span style="font-size:11px">(Tackle Highway Driving)</span></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><span style="font-size:12px"><u><a href="/course-view/12/quizzes/17" style="color: rgb(114, 114, 114);" ><span style="color:rgb(0, 0, 0)"><strong><span style="background-color:rgb(255, 255, 0)">Click</span></strong></span></a></u></span><span style="font-size:11px"><u><a href="/course-view/12/quizzes/17" style="color: rgb(114, 114, 114);" >&nbsp;<span style="background-color:rgb(255, 255, 0)">here</span></a><span style="background-color:rgb(255, 255, 0)"> to record your&nbsp;multiple choice answer</span></u></span><strong><span style="font-size:14px">&nbsp; (</span>Chapter 2 #3 of 10)</strong></p>
        </td>
      </tr>
      <tr>
        <td style="background-color: rgb(229, 229, 229);"><span style="font-size:12px"><u><a href="/course-view/12/sounds/speed.wav" style="color: rgb(114, 114, 114);" >Speed Regulations</a>&nbsp;</u></span></td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><span style="font-size:12px"><u><a href="http://www.pacode.com/secure/data/067/chapter601/chap601toc.html" style="color: rgb(114, 114, 114);" target="_blank"><span style="color:rgb(0, 0, 0)"><strong>Visit</strong></span></a></u></span><span style="font-size:11px"><u><a href="http://www.pacode.com/secure/data/067/chapter601/chap601toc.html" style="color: rgb(114, 114, 114);" target="_blank">&nbsp;this website</a></u></span>&nbsp;<span style="font-size:11px">(Speed Regulations)</span><br />
        <span style="font-size:11px">(SPECIFICALLY CLICK ON ARTICLES&nbsp;<a href="http://www.pacode.com/secure/data/067/chapter601/s601.1.html" style="color: rgb(114, 114, 114);" target="_blank">601.1</a>&nbsp;AND&nbsp;<a href="http://www.pacode.com/secure/data/067/chapter601/s601.4.html" style="color: rgb(114, 114, 114);" target="_blank">601.4</a>,&nbsp;the 1st and 4th link in series)</span></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><span style="font-size:12px"><u><a href="/course-view/12/quizzes/18" style="color: rgb(114, 114, 114);" ><span style="color:rgb(0, 0, 0)"><strong><span style="background-color:rgb(255, 255, 0)">Click</span></strong></span><span style="background-color:rgb(255, 255, 0)"><span style="color:rgb(0, 0, 0)">&nbsp;</span></span></a></u></span><span style="font-size:11px"><u><a href="/course-view/12/quizzes/18" style="color: rgb(114, 114, 114);" ><span style="background-color:rgb(255, 255, 0)">here</span></a><span style="background-color:rgb(255, 255, 0)"> to record your&nbsp;multiple choice answer</span></u>&nbsp;&nbsp; </span><strong><span style="font-size:14px">(</span>Chapter 2 #4 of 10)</strong></p>
        </td>
      </tr>
      <tr>
        <td style="background-color: rgb(229, 229, 229);"><span style="font-size:12px"><u><a href="/course-view/12/sounds/specialcircumstances.wav" style="color: rgb(114, 114, 114);" >Special Circumstances</a>&nbsp;&amp; Aggressive Driving&nbsp;</u></span></td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><span style="font-size:12px"><u><a href="http://newsroom.aaa.com/2013/10/wildlife-crossing-aaa-provides-tips-to-avoid-animal-vehicle-collisions/" style="color: rgb(114, 114, 114);" target="_blank"><span style="color:rgb(0, 0, 0)"><strong>Visit</strong></span></a></u></span><span style="font-size:11px"><u><a href="http://newsroom.aaa.com/2013/10/wildlife-crossing-aaa-provides-tips-to-avoid-animal-vehicle-collisions/" style="color: rgb(114, 114, 114);" target="_blank"><span style="color:rgb(0, 0, 0)">&nbsp;</span>this website</a></u> (Animals vs vehicles&nbsp;in roadways)</span></p>
  
        <p style="margin-left: 40px;"><a href="https://www.esurance.com/info/car/3-types-of-distracted-driving" target="_blank"><span style="background-color:rgb(255, 255, 255); color:rgb(51, 51, 51); font-family:sans-serif,arial,verdana,trebuchet ms; font-size:12px"><u><span style="color:rgb(0, 0, 0)"><strong>Visit</strong></span></u></span><span style="background-color:rgb(255, 255, 255); color:rgb(51, 51, 51); font-family:sans-serif,arial,verdana,trebuchet ms; font-size:11px"><u><span style="color:rgb(0, 0, 0)">&nbsp;</span>this website</u></span></a> <span style="font-size:11px">(Identifying driver distractions)</span></p>
  
        <p style="margin-left: 40px;"><span style="font-size:11px"><span style="font-size:12px"><u><a href="http://www.fcc.gov/guides/texting-while-driving" style="color: rgb(114, 114, 114);" target="_blank"><span style="color:rgb(0, 0, 0)"><strong>Visit</strong>&nbsp;</span></a></u></span><span style="font-size:11px"><u><a href="http://www.fcc.gov/guides/texting-while-driving" style="color: rgb(114, 114, 114);" target="_blank"><span style="color:rgb(0, 0, 0)">this website</span></a>&nbsp;</u>(Dangers of distracted driving)</span></span></p>
  
        <p style="margin-left: 40px;"><a href="https://www.nhtsa.gov/sites/nhtsa.dot.gov/files/documents/driverdistractions.pdf" target="_blank"><span style="font-size:11px"><span style="font-size:12px"><u><span style="color:rgb(0, 0, 0)"><strong>Visit</strong>&nbsp;</span></u></span><span style="font-size:11px"><u><span style="color:rgb(0, 0, 0)">this website</span>&nbsp;</u></span></span></a>&nbsp;<span style="font-size:11px">(Distractions inside &amp; outside vehicle)</span></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><span style="font-size:12px"><u><a href="http://www.cnn.com/2003/TRAVEL/08/06/distracted.drivers/index.html" style="color: rgb(114, 114, 114);" target="_blank"><span style="color:rgb(0, 0, 0)"><strong>Visit</strong>&nbsp;</span></a></u></span><span style="font-size:11px"><u><a href="http://www.cnn.com/2003/TRAVEL/08/06/distracted.drivers/index.html" style="color: rgb(114, 114, 114);" target="_blank">this website</a></u>&nbsp;(Distracted by cellphones)</span></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><span style="font-size:12px"><u><a href="http://exchange.aaa.com/safety/driving-advice/aggressive-driving/#.WZB2U4ldljo" target="_blank"><span style="color:rgb(0, 0, 0)"><strong>Visit</strong></span></a><a href="https://midatlantic.aaa.com/Foundation/~/link.aspx?_id=4487A5FB9D6A4A3B9B04298F32752388&amp;_z=z" style="color: rgb(114, 114, 114);" target="_blank"><span style="color:rgb(0, 0, 0)">&nbsp;</span></a></u></span><span style="font-size:11px"><u><span style="color:rgb(0, 0, 0)">this website</span></u></span> <span style="font-size:11px">(Aggressive Driving)</span></p>
  
        <p style="margin-left: 40px;"><a href="http://www.roadandtravel.com/safetyandsecurity/2007/road-rage.htm" target="_blank"><u><span style="font-size:12px"><span style="color:rgb(0, 0, 0)"><strong>Visit</strong></span></span></u></a><span style="font-size:11px"><u><a href="http://www.roadandtravel.com/safetyandsecurity/2007/road-rage.htm" target="_blank">&nbsp;</a>this website</u><span style="font-size:8pt">&nbsp;(Scroll down and read&nbsp;about Road Rage)</span></span></p>
  
        <p style="margin-left: 40px;"><span style="font-size:12px"><u><span style="color:rgb(0, 0, 0)"><strong>O</strong></span><a href="/members/handouts/aggressive%20sm.pdf" style="color: rgb(114, 114, 114);" ><span style="color:rgb(0, 0, 0)"><strong>nline</strong></span></a></u></span><span style="font-size:11px"><u><a href="/members/handouts/aggressive%20sm.pdf" style="color: rgb(114, 114, 114);" > handout (pdf)</a></u></span><br />
        <span style="font-size:8pt">(Please use the controls in the pdf program to increase/decrease font size and turn the page)</span></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><span style="font-size:12px"><strong><u><span style="color:rgb(0, 0, 0)">V</span><a href="/members/chapter2/2_video4_safedriving.cfm" style="color: rgb(114, 114, 114);" ><span style="color:rgb(0, 0, 0)">ideo</span></a></u></strong></span><span style="font-size:11px"><u><a href="/members/chapter2/2_video4_safedriving.cfm" style="color: rgb(114, 114, 114);" ><span style="font-size:12px">:</span> Safe and Courteous Driving</a></u></span></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><span style="font-size:12px"><strong><u><a href="http://www.kidshealth.org/teen/safety/driving/driving_safety.html" style="color: rgb(114, 114, 114);" target="_blank"><span style="color:rgb(0, 0, 0)">Visit</span></a></u></strong></span><span style="font-size:11px"><u><a href="http://www.kidshealth.org/teen/safety/driving/driving_safety.html" style="color: rgb(114, 114, 114);" target="_blank"><span style="color:rgb(0, 0, 0)"><strong>&nbsp;</strong></span>this website</a></u>&nbsp; (Defensive Driving to develop avoidence strategies)</span></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><span style="font-size:12px"><strong><u><span style="color:rgb(0, 0, 0)">V</span><a href="/members/chapter2/2_video5_lifeinthefastlane.cfm" style="color: rgb(114, 114, 114);" ><span style="color:rgb(0, 0, 0)">ideo</span></a></u></strong></span><span style="font-size:11px"><u><a href="/members/chapter2/2_video5_lifeinthefastlane.cfm" style="color: rgb(114, 114, 114);" >: Life in the Fast Lane</a></u></span></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><span style="font-size:12px"><u><span style="color:rgb(0, 0, 0)"><strong>O</strong></span><a href="/members/handouts/drive%20aware.pdf" style="color: rgb(114, 114, 114);" ><span style="color:rgb(0, 0, 0)"><strong>nline</strong> </span></a></u></span><span style="font-size:11px"><u><a href="/members/handouts/drive%20aware.pdf" style="color: rgb(114, 114, 114);" >handout (pdf)</a></u></span><br />
        <span style="font-size:8pt">(Please use the controls in the pdf program to increase/decrease font size and turn the page)</span></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><span style="font-size:12px"><u><a href="/course-view/12/quizzes/19" style="color: rgb(114, 114, 114);" ><span style="color:rgb(0, 0, 0)"><strong><span style="background-color:rgb(255, 255, 0)">Click</span></strong></span></a></u></span><span style="font-size:11px"><u><a href="/course-view/12/quizzes/19" style="color: rgb(114, 114, 114);" ><span style="background-color:rgb(255, 255, 0)">&nbsp;here</span></a><span style="background-color:rgb(255, 255, 0)"> to record your muliple choice answer</span></u></span><strong><span style="font-size:14px"> (</span>Chapter 2 #5 of 10)</strong></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><span style="font-size:11px"><u><a href="/course-view/12/quizzes/4" style="color: rgb(114, 114, 114);" ><span style="background-color:rgb(255, 255, 0)"><strong><span style="color:rgb(0, 0, 0)">Take</span></strong> this&nbsp;</span></a></u></span><span style="font-size:12px"><u><a href="/course-view/12/quizzes/4" style="color: rgb(114, 114, 114);" ><strong><span style="background-color:rgb(255, 255, 0)">quiz</span></strong></a></u>&nbsp; </span><strong><span style="font-size:14px">(</span>Chapter 2 # Quiz 3)</strong></p>
        </td>
      </tr>
      <tr>
        <td style="background-color: rgb(229, 229, 229);"><span style="font-size:12px"><u><a href="/course-view/12/sounds/adverse2.wav" style="color: rgb(114, 114, 114);" >The Types of Adverse Conditions</a>&nbsp;</u></span></td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><strong><span style="font-size:12px"><u><a href="http://kidshealth.org/teen/safety/driving/driving_conditions.html#" style="color: rgb(114, 114, 114);" target="_blank"><span style="color:rgb(0, 0, 0)">Visit</span></a></u></span></strong><span style="font-size:11px"><u><a href="http://kidshealth.org/teen/safety/driving/driving_conditions.html#" style="color: rgb(114, 114, 114);" target="_blank"><span style="color:rgb(0, 0, 0)">&nbsp;</span>this website</a></u>&nbsp;(Bad weather driving)</span></p>
  
        <p style="margin-left: 40px;"><a href="https://www.yourmechanic.com/article/how-to-drive-your-vehicle-through-a-flood-by-cheryl-knight" target="_blank"><strong><span style="font-size:12px"><u><span style="color:rgb(0, 0, 0)">Visit</span></u></span></strong><span style="background-color:rgb(255, 255, 255); color:rgb(51, 51, 51); font-family:sans-serif,arial,verdana,trebuchet ms; font-size:11px"><u><span style="color:rgb(0, 0, 0)">&nbsp;</span>this website</u></span></a>&nbsp;<span style="font-size:11px">(Driving in Floods)</span></p>
  
        <p style="margin-left: 40px;"><a href="https://www.usatoday.com/story/news/local/2015/05/11/driving-tips-when-smoke-from-wildfires-covers-the-roads/27136615/" target="_blank"><strong><span style="font-size:12px"><u><span style="color:rgb(0, 0, 0)">Visit</span></u></span></strong><span style="color:rgb(51, 51, 51); font-family:sans-serif,arial,verdana,trebuchet ms; font-size:11px"><u><span style="color:rgb(0, 0, 0)">&nbsp;</span>this website</u></span></a>&nbsp;<span style="font-size:11px">(Tips for Driving in Smoke/Wildfire)</span></p>
  
        <p style="margin-left: 40px;"><a href="https://www.artofmanliness.com/articles/what-to-do-when-your-vehicle-hydroplanes/" target="_blank"><strong><span style="font-size:12px"><u><span style="color:rgb(0, 0, 0)">Visit</span></u></span></strong><span style="color:rgb(51, 51, 51); font-family:sans-serif,arial,verdana,trebuchet ms; font-size:11px"><u><span style="color:rgb(0, 0, 0)">&nbsp;</span>this website</u></span></a>&nbsp;&nbsp;<span style="font-size:11px">(How to handle Hydroplanning)</span></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><strong><span style="font-size:12px"><u><span style="color:rgb(0, 0, 0)">V</span><a href="/members/chapter2/2_video6_adverse.cfm" style="color: rgb(114, 114, 114);" ><span style="color:rgb(0, 0, 0)">ideo</span></a></u></span></strong><span style="font-size:11px"><u><a href="/members/chapter2/2_video6_adverse.cfm" style="color: rgb(114, 114, 114);" ><span style="color:rgb(0, 0, 0)">:</span> Driving Under Adverse Conditions</a></u></span></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><span style="font-size:12px"><u><a href="/course-view/12/quizzes/20" style="color: rgb(114, 114, 114);" ><span style="color:rgb(0, 0, 0)"><strong><span style="background-color:rgb(255, 255, 0)">Click</span></strong></span></a></u></span><span style="font-size:11px"><u><a href="/course-view/12/quizzes/20" style="color: rgb(114, 114, 114);" >&nbsp;<span style="background-color:rgb(255, 255, 0)">her</span></a><span style="background-color:rgb(255, 255, 0)">e to record your muliple choice answer</span></u>&nbsp; </span><strong><span style="font-size:14px">(</span>Chapter 2 #6 of 10)</strong></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><strong><span style="font-size:12px"><u><span style="color:rgb(0, 0, 0)">V</span><a href="/members/chapter2/2_video7_ruraldriving.cfm" style="color: rgb(114, 114, 114);" ><span style="color:rgb(0, 0, 0)">ideo</span></a></u></span></strong><span style="font-size:11px"><u><a href="/members/chapter2/2_video7_ruraldriving.cfm" style="color: rgb(114, 114, 114);" ><span style="color:rgb(0, 0, 0)">: </span>Rural Driving - Not Just Another Drive in the Country</a></u></span></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><span style="font-size:12px"><u><a href="/course-view/12/quizzes/21" style="color: rgb(114, 114, 114);" ><span style="background-color:rgb(255, 255, 0)"><strong><span style="color:rgb(0, 0, 0)">Click</span></strong>&nbsp;</span></a></u></span><span style="font-size:11px"><u><a href="/course-view/12/quizzes/21" style="color: rgb(114, 114, 114);" ><span style="background-color:rgb(255, 255, 0)">here</span></a><span style="background-color:rgb(255, 255, 0)"> to record your&nbsp;multiple choice answer</span></u></span><strong><span style="font-size:14px">&nbsp; (</span>Chapter 2 #7 of 10)</strong></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><u><span style="font-size:12px"><a href="http://www.reedberry.com/rain.html" style="color: rgb(114, 114, 114);" target="_blank"><span style="color:rgb(0, 0, 0)"><strong>Visit</strong></span></a></span></u><span style="font-size:11px"><u><a href="http://www.reedberry.com/rain.html" style="color: rgb(114, 114, 114);" target="_blank"><span style="color:rgb(0, 0, 0)">&nbsp;</span>this website</a>&nbsp;</u> (Rainy Weather Tips)</span></p>
  
        <p style="margin-left: 40px;"><strong><a href="http://icyroadsafety.com/tips.shtml" target="_blank"><span style="color:rgb(0, 0, 0)"><span style="font-size:12px"><u>Visit</u></span></span></a></strong><span style="font-size:11px"><u>&nbsp;this website</u> (Tips for Icy Conditions)</span></p>
  
        <p style="margin-left: 40px;"><span style="font-size:11px">(</span><span style="font-size:12px"><strong>Hint</strong> </span><span style="font-size:11px">: be sure to read the 1st paragraph)</span></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><span style="font-size:12px"><u><a href="/course-view/12/quizzes/22" style="color: rgb(114, 114, 114);" ><span style="color:rgb(0, 0, 0)"><strong><span style="background-color:rgb(255, 255, 0)">Click</span></strong></span></a></u></span><span style="font-size:11px"><u><a href="/course-view/12/quizzes/22" style="color: rgb(114, 114, 114);" ><span style="background-color:rgb(255, 255, 0)">&nbsp;to</span></a><span style="background-color:rgb(255, 255, 0)"> record your muliple choice answer</span></u></span><strong><span style="font-size:14px">&nbsp; (</span>Chapter 2 #8 of 10)</strong></p>
        </td>
      </tr>
      <tr>
        <td style="background-color: rgb(229, 229, 229);"><span style="font-size:12px"><u><a href="/course-view/12/sounds/scanning.wav" style="color: rgb(114, 114, 114);" >The Methods of Scanning the Environment</a>&nbsp;</u></span><br />
        <span style="font-size:8pt">(Click on all links in this website to become familar with the topic)</span></td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><u><span style="font-size:12px"><strong><a href="https://www.dot.state.pa.us/Public/DVSPubsForms/BDL/BDL%20Manuals/Manuals/PA%20Drivers%20Manual%20By%20Chapter/English/PUB%2095.pdf" target="_blank"><span style="color:rgb(0, 0, 0)">Visit </span></a></strong><a href="https://www.dot.state.pa.us/Public/DVSPubsForms/BDL/BDL%20Manuals/Manuals/PA%20Drivers%20Manual%20By%20Chapter/English/PUB%2095.pdf" target="_blank"><span style="color:rgb(0, 0, 0)">this website</span></a><span style="color:rgb(0, 0, 0)">&nbsp;</span></span></u><span style="font-size:12px"><span style="color:rgb(0, 0, 0)"> </span></span><span style="font-size:11px">(</span><span style="font-size:12px"><strong>Read pages</strong></span><span style="font-size:11px"><strong> 35-37 </strong>How to Manage&nbsp;your Space)</span></p>
  
        <p style="margin-left: 40px;"><span style="font-size:11px"><span style="font-size:11px"><a href="http://www.dmv.state.pa.us/pdotforms/pa_driversmanual/chapter_3.pdf#page=35" style="color: rgb(114, 114, 114);" target="_blank">Specifically&nbsp;Managing Space, Looking ahead, to the side and behind you only</a></span>&nbsp;<span style="font-size:12px">(</span><span style="font-size:11px">Please use the controls in the PDF to increase/decrease the size and to turn the page.&nbsp;<a href="http://www.adobe.com/products/acrobat/readstep2.html" style="color: rgb(114, 114, 114);" target="_blank">requires the free Adobe Acrobat Reader.</a>)</span></span></p>
  
        <p style="margin-left: 40px;"><a href="https://www.drive-safely.net/safe-following-distance/" target="_blank"><span style="color:rgb(0, 0, 0)"><span style="font-size:12px"><u><strong>Visit</strong></u></span></span><span style="font-size:11px"><u>&nbsp;<span style="color:rgb(0, 0, 0)">this website&nbsp;</span></u></span></a><span style="font-size:11px">&nbsp;(Keeping a safe distance, managing your space)</span><strong>&nbsp;</strong></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><strong><span style="font-size:12px"><u><span style="color:rgb(0, 0, 0)">V</span><a href="/members/chapter2/2_video8_perceiving.cfm" style="color: rgb(114, 114, 114);" ><span style="color:rgb(0, 0, 0)">ideo</span></a></u></span></strong><span style="font-size:11px"><u><a href="/members/chapter2/2_video8_perceiving.cfm" style="color: rgb(114, 114, 114);" >: Perceiving and Reacting in Traffic</a></u></span></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><span style="font-size:12px"><u><a href="/course-view/12/quizzes/23" style="color: rgb(114, 114, 114);" ><span style="color:rgb(0, 0, 0)"><strong><span style="background-color:rgb(255, 255, 0)">Click</span></strong></span></a></u></span><span style="font-size:11px"><u><a href="/course-view/12/quizzes/23" style="color: rgb(114, 114, 114);" >&nbsp;<span style="background-color:rgb(255, 255, 0)">record</span></a><span style="background-color:rgb(255, 255, 0)"> your multiple choice answer</span></u><strong>&nbsp;</strong></span><strong><span style="font-size:14px"> (</span>Chapter 2 #9 of 10)</strong></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><a href="http://www.valleybmwriders.com/documents/The%20Spide%20process.pdf" target="_blank"><span style="font-size:12px"><u><span style="color:rgb(0, 0, 0)"><strong>Visit</strong></span></u></span></a><span style="font-size:11px"><u>&nbsp;this website</u>&nbsp;(SIPDE Model for car drivers)</span></p>
  
        <p style="margin-left: 40px;"><a href="https://www.safemotorist.com/articles/IPDE_Method.aspx" target="_blank"><span style="font-size:12px"><u><span style="color:rgb(0, 0, 0)"><strong>Visit</strong></span></u></span><span style="color:rgb(51, 51, 51); font-family:sans-serif,arial,verdana,trebuchet ms; font-size:11px"><u>&nbsp;this website</u></span></a>&nbsp;<span style="font-size:11px">(IPDE Model for car drivers)</span></p>
  
        <p style="margin-left: 40px;"><a href="https://forcardrivers.com/why-it-helps-to-see/appendix/"><span style="font-size:12px"><u><span style="color:rgb(0, 0, 0)"><strong>Visit</strong></span></u></span><span style="color:rgb(51, 51, 51); font-family:sans-serif,arial,verdana,trebuchet ms; font-size:11px"><u>&nbsp;this website</u></span></a>&nbsp;<span style="font-size:11px">(SEE Model, for&nbsp;Motorcycle drivers)</span></p>
  
        <p style="margin-left: 40px;"><a href="https://www.yourmechanic.com/article/how-to-safely-drive-in-stop-and-go-traffic-by-brady-klopfer" target="_blank"><span style="font-size:12px"><u><span style="color:rgb(0, 0, 0)"><strong>Visit</strong></span></u></span><span style="color:rgb(51, 51, 51); font-family:sans-serif,arial,verdana,trebuchet ms; font-size:11px"><u>&nbsp;this website</u></span></a><span style="background-color:rgb(255, 255, 255); color:rgb(51, 51, 51); font-family:sans-serif,arial,verdana,trebuchet ms; font-size:13px">&nbsp;</span><span style="background-color:rgb(255, 255, 255); color:rgb(51, 51, 51); font-family:sans-serif,arial,verdana,trebuchet ms; font-size:11px">(STOP, THINK GO&nbsp;Model, for Start &amp; Stop City Traffic)</span></p>
  
        <p style="margin-left: 40px;"><a href="https://safety.fhwa.dot.gov/geometric/pubs/mitigationstrategies/chapter3/3_stopdistance.cfm" target="_blank"><span style="font-size:12px"><u><span style="color:rgb(0, 0, 0)"><strong>Visit</strong></span></u></span><span style="color:rgb(51, 51, 51); font-family:sans-serif,arial,verdana,trebuchet ms; font-size:11px"><u>&nbsp;this website</u></span></a>&nbsp;<span style="font-size:11px">(Article on Limited Sight Distance)</span></p>
  
        <p style="margin-left: 40px;"><a href="https://www.aceable.com/safe-driving-videos/vision-while-driving/" target="_blank"><span style="font-size:12px"><u><span style="color:rgb(0, 0, 0)"><strong>Visit</strong></span></u></span><span style="color:rgb(51, 51, 51); font-family:sans-serif,arial,verdana,trebuchet ms; font-size:11px"><u>&nbsp;this website</u></span></a>&nbsp;<span style="font-size:11px">(Central &amp; Peripheral Vision when&nbsp;Driving)</span></p>
  
        <p style="margin-left: 40px;"><a href="http://abcnews.go.com/Technology/multitasking-driving-texting-tasks-spell-danger/story?id=16973777"><span style="font-size:12px"><u><span style="color:rgb(0, 0, 0)"><strong>Visit</strong></span></u></span><span style="color:rgb(51, 51, 51); font-family:sans-serif,arial,verdana,trebuchet ms; font-size:11px"><u>&nbsp;this website</u></span>&nbsp;</a><span style="font-size:11px">(Multitasking while Driving)</span></p>
        </td>
      </tr>
      <tr>
        <td>
        <p style="margin-left: 40px;"><span style="font-size:12px"><u><a href="/course-view/12/quizzes/24" style="color: rgb(114, 114, 114);" ><span style="color:rgb(0, 0, 0)"><strong><span style="background-color:rgb(255, 255, 0)">Click</span></strong></span></a></u></span><span style="font-size:11px"><u><a href="/course-view/12/quizzes/24" style="color: rgb(114, 114, 114);" ><span style="background-color:rgb(255, 255, 0)"><span style="color:rgb(0, 0, 0)">&nbsp;</span>record</span></a><span style="background-color:rgb(255, 255, 0)"> your multiple choice answer</span>&nbsp;</u>&nbsp;&nbsp; </span><strong><span style="font-size:14px">(</span>Chapter 2 #10 of 10) </strong></p>
        </td>
      </tr>
      <tr>
        <td>
        <p><span style="font-size:11px"><strong>Click</strong></span><span style="color:rgb(114, 114, 114); font-family:tahoma; font-size:11px"><span style="font-size:12px">&nbsp;</span>on the&nbsp;&nbsp;</span><span style="color:rgb(255, 0, 0)"><span style="font-size:12px"><span style="font-family:tahoma">Chapter 3 TAB&nbsp;</span></span></span><span style="color:rgb(114, 114, 114); font-family:tahoma; font-size:11px">&nbsp;LOCATED above to continue</span></p>
  
        <p><span style="font-size:12px">Dont like ads? Install a pop-up blocker! If you need help installing one, <u><strong><a href="http://www.ehow.com/how_6595746_install-pop_up-blocker.html" target="_blank"><span style="color:rgb(255, 0, 0)"><span style="background-color:rgb(255, 255, 0)">CLICK HERE</span></span></a></strong></u></span></p>
        </td>
      </tr>
    </tbody>
  </table>
  </div>
  
  <h4><br />
  &nbsp;</h4>
  
  <p>&nbsp;</p>
  