import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store, createFeatureSelector, createSelector, select } from "@ngrx/store";
import { loadStripe } from '@stripe/stripe-js';
import { filter } from "rxjs/operators";
import { SignInState } from "../../../../app/components/allusers/signin/signin.model";
import { getSignedInUser } from "../../../../app/components/allusers/signin/signin.selector";
import { PaymentsService } from "../../../../app/services/payments.service";
import { environment } from "../../../../environments/environment";
import { PaymentActionTypes, PaymentFailedAction, PaymentLoadingAction, PaymentShowAction, PaymentSubmittingAction, PaymentSuccessfulAction } from "./payment.actions";
import { PaymentState } from "./payment.model";

export const getPaymentState = createFeatureSelector<PaymentState>("payment");

const getPayment = createSelector(
  getPaymentState,
  (paymentState: PaymentState) => paymentState
);

const showCC = createSelector(
  getPayment,
  (paymentState: PaymentState) => (paymentState &&
    (paymentState.currentState === PaymentActionTypes.PaymentShow ||
      paymentState.currentState === PaymentActionTypes.PaymentFailed) && paymentState) ? paymentState : null as unknown as PaymentState
)

const hideCC = createSelector(
  showCC,
  (paymentState: PaymentState) => !paymentState
)

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class PaymentComponent implements OnInit {
  @ViewChild("cardElement") cardElement: ElementRef;

  currentUser$ = this.signInStore.pipe(
    select(getSignedInUser),
    filter(val => (val ? true : false))
  );
  currentUser: any;
  paymentIntent: any;
  stripe: any;
  stripeElements: any;
  paymentElement: any;
  courseId: number;
  amount: string;

  currentPayment$ = this.paymentStore.pipe(
    select(getPayment)
  )

  showCC$ = this.paymentStore.pipe(
    select(showCC)
  )

  hideCC$ = this.paymentStore.pipe(
    select(hideCC),
    filter(val => (val ? true : false))
  )


  constructor(
    private signInStore: Store<SignInState>,
    private paymentStore: Store<PaymentState>,
    private paymentsService: PaymentsService,
    public elementRef: ElementRef,
    public activedRoute: ActivatedRoute,
    private router: Router
  ) {
  }

  async ngOnInit() {
    this.currentUser$.subscribe(user => (this.currentUser = user));
    this.paymentStore.dispatch(new PaymentLoadingAction({ currentState: PaymentActionTypes.PaymentLoading }));
    this.stripe = await loadStripe(environment.stripeKey);
    this.activedRoute.params.subscribe(params => {
      this.courseId = params["courseId"];
    });

    this.paymentIntent = await this.paymentsService.getPaymentIntent(this.courseId, this.currentUser.membername);
    this.amount = (this.paymentIntent["amount"] / 100).toFixed(2);
    this.stripeElements = await this.stripe.elements({ clientSecret: this.paymentIntent["client_secret"] });
    // Customize which fields are collected by the Payment Element
    this.paymentElement = await this.stripeElements.create('payment');
    this.paymentElement.mount('#card-element');
    this.paymentElement.on('ready', this.onReady.bind(this));
    this.showCC$.subscribe(i => {
      this.cardElement.nativeElement.style.display = 'block'
    });
    this.hideCC$.subscribe(i => {
      this.cardElement.nativeElement.style.display = 'none'
    });
  }

  onReady() {
    this.paymentStore.dispatch(new PaymentShowAction({ currentState: PaymentActionTypes.PaymentShow }))
  }

  async paymentSubmit() {
    // Confirm the payment given the clientSecret
    // from the payment intent that was just created on
    // the server.
    console.log(`Submitting payment...`);
    this.paymentStore.dispatch(new PaymentSubmittingAction({ currentState: PaymentActionTypes.PaymentSubmitting }))
    const { error: stripeError } = await this.stripe.confirmPayment({
      elements: this.stripeElements,
      confirmParams: {
        return_url: `${window.location.origin}/home`,
      },
      redirect: 'if_required'
    });

    if (stripeError) {
      console.log(`Payment Error - ${JSON.stringify(stripeError)}`);
      this.paymentStore.dispatch(new PaymentFailedAction({ currentState: PaymentActionTypes.PaymentFailed, error: stripeError.message }));
      return;
    }

    this.paymentStore.dispatch(new PaymentSuccessfulAction({ currentState: PaymentActionTypes.PaymentSuccessful }));
  }

  goToCourse() {
    this.router.navigate([
      `courses`
    ]);
  }
}

