import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { } from "@ngrx/router-store";
import { Store, select } from "@ngrx/store";
import { fetchAuthSession } from "aws-amplify/auth";
import { NGXLogger } from "ngx-logger";
import { filter } from "rxjs/operators";
import { FederatedLogin } from "src/app/services/auth-service/base.token.provider";
import { commonTokenProvider } from "src/main";
import { isConfirmSignup } from "../../../../app/components/allusers/signup/signup.selector";
import { AuthService } from "../../../../app/services/auth-service/auth.service";
import { BannerService } from "../../../../app/services/banner.service";
import { SignUpState } from "../../allusers/signup/signup.model";

@Component({
  selector: "app-login-home",
  templateUrl: "./login-home.component.html",
  styleUrls: ["./login-home.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class LoginHomeComponent implements OnInit {
  bannerContent;
  selectedTab = 0;
  jwtHelperService: JwtHelperService;

  onConfirmSignUpForm = new FormGroup({
    otp: new FormControl("")
  });

  forgotPasswordForm = new FormGroup({
    email: new FormControl("")
  });

  forgotPasswordOtpForm = new FormGroup({
    otp: new FormControl(""),
    newPassword: new FormControl(""),
    reNewPassword: new FormControl("")
  });

  private isConfirmSignUp$ = this.signUpStore.pipe(
    select(isConfirmSignup),
    filter(val => (val ? true : false))
  );

  constructor(
    private route: ActivatedRoute,
    private bannerService: BannerService,
    private signUpStore: Store<SignUpState>,
    private authService: AuthService,
    private logger: NGXLogger
  ) {
    this.jwtHelperService = new JwtHelperService();
  }

  ngOnInit() {
    this.logger.info("Login Home Component Initiated...");
    this.getBanner();
    this.isConfirmSignUp$.subscribe(() => (this.selectedTab = 1));
    this.route.queryParams.subscribe(async params => {
      this.logger.info(`Query Params - ${JSON.stringify(params)}`);
      this.caolaLogin(params);
    });
  }

  async caolaLogin(params) {
    if (params.token && params.membername) {
      const { sub, exp } = this.jwtHelperService.decodeToken(params.token);
      const now = new Date().getTime() / 1000;
      if (now > exp) {
        this.logger.info(`Token is expired.`);
        return;
      }

      try {
        commonTokenProvider.loadFederatedLogin({
          domain: "login.keystodriving.caola",
          token: params.token,
        } as FederatedLogin);
        const session = await fetchAuthSession();
        this.logger.info(`AMR - ${JSON.stringify(session)}`);
        const { amr } = session.tokens.accessToken.payload;
        this.logger.info(`AMR - ${JSON.stringify(amr)}`);
        if (Array.isArray(amr)) {
          const username = amr[2]
            ? (<string>amr[2]).split(":")
              ? (<string>amr[2]).split(":")[3]
              : ""
            : "";
          if (username) {
            await this.authService.loggedInUser({ username });
          }
        }
      } catch (error) {
        this.logger.error(`Error - ${JSON.stringify(error)}`);
      }
    }
  }

  getBanner() {
    return this.bannerService
      .getBannerBy(1)
      .then(banner => {
        if (banner.Active) {
          this.bannerContent = banner.Content;
        }
      })
      .catch(() => {
        this.logger.error("Failed to get Banner...");
      });
  }

}
