

export enum TextToSpeechStates {
  NOT_INITIALIZED = "not_initialized",
  INITIALIZED = "initialized",
  PLAYING = "playing",
  PAUSED = "paused",
  STOPPED = "stopped",
  COMPLETED = "completed",
}

export interface TextToSpeechState {
  text: string;
  rate: number;
  pitch: number;
  state: TextToSpeechStates;
}
