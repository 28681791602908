import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from "../../environments/environment";
import { Certificate } from "../models/certificate.model";

@Injectable({
  providedIn: "root"
})
export class CertificatesService {
  constructor(private http: HttpClient) { }

  getCertificateById(memberId, ignoreCount: boolean) {
    if (ignoreCount) {
      return lastValueFrom(this.http
        .get<Certificate>(
          `${environment.apiUrl}/certificates/${memberId}?ignoreCount=true`
        ));
    }
    return lastValueFrom(this.http
      .get<Certificate>(`${environment.apiUrl}/certificates/${memberId}`));
  }

  getCertificateByCourseIdAndMemberId(courseId: number, memberId: number) {
    return lastValueFrom(this.http
      .get<Certificate[]>(`${environment.apiUrl}/courses/${courseId}/regmembers/${memberId}/certificates`));
  }
}
