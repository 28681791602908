<div class="fx-layout-row fx-layout-align-center-center">
    <div class="fx-flex-80pc">
      <div class="row">
        <h4>Course Progress for {{ member?.fname }} {{ member?.lname }} in {{ course?.courseName }}</h4>
      </div>
  <div class="error-message" *ngIf="errorMessage">{{errorMessage}} </div>
  <p-table #dt [columns]="cols" [value]="memberCourseProgressList" [paginator]="true" selectionMode="single" [rows]="rows"
  [totalRecords]="totalRecords" [loading]="loading"
  [tableStyle]="{'table-layout':'auto'}" styleClass="p-datatable-gridlines">
    <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
              ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order">
            </p-sortIcon>
          </th>
          <th></th>
        </tr>
        <tr>
          <th *ngFor="let col of columns" [ngSwitch]="col.field">
            <input *ngSwitchCase="'quizTitle'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')">
            <input *ngSwitchCase="'score'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')">
            <input *ngSwitchCase="'DTStamp'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')">            
          </th>          
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">                        
            <span *ngIf="col.field !== 'DTStamp'">{{rowData[col.field]}}</span>
            <span *ngIf="col.field === 'DTStamp'">{{ rowData[col.field] | date: 'shortDate'  }}</span>
          </td>
          <td>
            <button pButton type="button" (click)="selectProgress(rowData)">Delete Progress</button>
          </td>
        </tr>
      </ng-template>    
  </p-table>

  <p-dialog header="Edit Progress" [(visible)]="displayDialog" [responsive]="true" showEffect="fade" [modal]="true"
      [style]="{width: '25rem'}">
      <span class="p-text-secondary block mb-8">Are you sure you want to delete this progress?</span>      
      <div class="ui-g ui-fluid" *ngIf="memberProgress">
        <div class="ui-g-12">
          <div class="ui-g-4 pad0">
            <label for="quizTitle">Quiz Title</label>
          </div>
          <div class="ui-g-8 pad0">
            {{memberProgress.quizTitle}}
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4 pad0">
            <label for="score">Score</label>
          </div>
          <div class="ui-g-8 pad0">
            {{memberProgress.Score}}
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4 pad0">
            <label for="DTStamp">Date</label>
          </div>
          <div class="ui-g-8 pad0">
            {{memberProgress.DTStamp | date: 'shortDate'  }}
          </div>
        </div>      
        <div class="flex justify-end gap-2">
            <p-button label="Cancel" severity="secondary" (click)="displayDialog = false" />
            <p-button label="Yes" (click)="deleteProgress(memberProgress)" />
        </div>
      </div>
  </p-dialog>

</div>