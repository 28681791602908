import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { jsPDF } from "jspdf";
import * as moment from "moment";
import { filter } from "rxjs/operators";
import { SignInState } from "src/app/components/allusers/signin/signin.model";
import { getSignedInUser } from "src/app/components/allusers/signin/signin.selector";
import { Certificate } from "src/app/models/certificate.model";
import { Course } from "src/app/models/course.model";
import { User } from "src/app/models/user.model";
import { CertificatesService } from "src/app/services/certificates.service";
import { RegmembersService } from "src/app/services/regmembers.service";
import { CourseState } from "../courses/courses.reducer";
import { isCourseNotSelected, isCourseSelected } from "../courses/courses.selector";
import * as data from "./data.json";

// console.log(data2)

@Component({
  selector: "app-download-certificate",
  templateUrl: "./download-certificate.component.html",
  providers: [{ provide: "Window", useValue: window }],
  styleUrls: ["./download-certificate.component.css"],
})
export class DownloadCertificateComponent implements OnInit {
  certificateId;
  certificate: Certificate;
  errorMessage;

  currentUser$ = this.signInStore.pipe(
    select(getSignedInUser),
    filter((val) => (val ? true : false))
  );
  currentUser: User;

  courseSelected$ = this.courseStore.pipe(select(isCourseSelected), filter(val => !val == false));
  courseNotSelected$ = this.courseStore.pipe(select(isCourseNotSelected), filter(val => val))
  currentCourse: Course;

  constructor(
    private signInStore: Store<SignInState>,
    private certificatesService: CertificatesService,
    private route: ActivatedRoute,
    private regmemService: RegmembersService,
    @Inject("Window") private window: Window,
    private courseStore: Store<CourseState>
  ) { }

  async ngOnInit() {
    this.courseSelected$.subscribe(val => this.currentCourse = val.course);
    this.courseNotSelected$.subscribe(val => this.currentCourse = undefined);
    this.currentUser$.subscribe(val => {
      this.currentUser = val;
      this.getCertificate();
    });
  }

  download() {
    // var doc = new jsPDF();
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a4"
    });

    doc.addImage(data[this.currentCourse.courseCode], "PNG", 0, 0, 296, 209);
    doc.setFontSize(22);
    const nameXOffset = (doc.internal.pageSize.getWidth() - doc.getTextWidth(this.certificate.cert_name)) / 2;
    doc.text(this.certificate.cert_name, nameXOffset, 105);
    doc.setFontSize(15);
    const dateXOffset = (doc.internal.pageSize.getWidth() - doc.getTextWidth(moment(this.certificate.cert_dt).format("MM-DD-YYYY"))) / 2;
    doc.text(moment(this.certificate.cert_dt).format("MM-DD-YYYY"), dateXOffset, 140);
    doc.save("certificate.pdf");
  }

  async getCertificate() {
    try {
      const certificates = await this.certificatesService
        .getCertificateByCourseIdAndMemberId(this.currentCourse.id, this.currentUser.memberid);
      if (certificates.length > 0) {
        this.certificate = certificates[0];
      }
    } catch (error) {
      this.errorMessage =
        "Unable to retrieve your certificate, please contact us directly"
        ;
    }
  }
}
