import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { filter } from "rxjs/operators";
import {
  SignInActionTypes,
  SignedOutAction
} from "src/app/components/allusers/signin/signin.actions";
import { SignInState } from "src/app/components/allusers/signin/signin.model";
import {
  getSignedInUser,
  isSignedIn,
  isSignedOut
} from "src/app/components/allusers/signin/signin.selector";
import { Course } from "src/app/models/course.model";
import { User } from "src/app/models/user.model";
import { AuthService } from "src/app/services/auth-service/auth.service";
import { RouterService } from "src/app/services/route-service/router.service";
import { CourseState } from "../../members/courses/courses.reducer";
import { getCourseSelected, isCourseNotSelected } from "../../members/courses/courses.selector";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"]
})
export class HeaderComponent implements OnInit {
  isUserLoggedIn: boolean;
  isLoggedIn$ = this.signInStore.pipe(
    select(isSignedIn),
    filter(val => val)
  );
  isLoggedOut$ = this.signInStore.pipe(
    select(isSignedOut),
    filter(val => val)
  );
  currentUser: User;

  courseSelected$ = this.courseStore.pipe(select(getCourseSelected), filter(val => !val));
  courseNotSelected$ = this.courseStore.pipe(select(isCourseNotSelected), filter(val => val))
  currentCourse: Course;

  constructor(
    private authService: AuthService,
    private routeService: RouterService,
    private signInStore: Store<SignInState>,
    public router: Router,
    private courseStore: Store<CourseState>
  ) { }

  async ngOnInit() {
    this.isLoggedIn$.subscribe(val => (this.isUserLoggedIn = true));
    this.isLoggedOut$.subscribe(val => (this.isUserLoggedIn = false));
    this.signInStore
      .pipe(select(getSignedInUser))
      .subscribe(user => (this.currentUser = user));

    this.courseSelected$.subscribe(val => this.currentCourse = val);
    this.courseNotSelected$.subscribe(val => this.currentCourse = undefined)
  }

  logout() {
    this.authService
      .signOut()
      .then(() => {
        this.signInStore.dispatch(
          new SignedOutAction({
            currentState: SignInActionTypes.SignedOut
          } as SignInState)
        );
      })
      .catch(err => {
        this.signInStore.dispatch(
          new SignedOutAction({
            currentState: SignInActionTypes.SignedOut
          } as SignInState)
        );
      })
      .finally(() => {
        this.routeService.gotoHome();
      });
  }

  currentCourseLink() {
    if (this.currentCourse) {
      return [`/courses/${this.currentCourse.id}/course-progress`];
    }
    return ["/courses"];
  }
}
