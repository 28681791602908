import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { Timer } from "easytimer.js";
import { NGXLogger } from "ngx-logger";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { SignInState } from "src/app/components/allusers/signin/signin.model";
import { getSignedInUser } from "src/app/components/allusers/signin/signin.selector";
import { Chapter } from "src/app/models/chapters.model";
import { Course } from "src/app/models/course.model";
import { Progress } from "src/app/models/progress.model";
import { Quiz } from "src/app/models/quiz.model";
import { User } from "src/app/models/user.model";
import { CourseState } from "../courses/courses.reducer";
import { getCourseSelected, isCourseNotSelected } from "../courses/courses.selector";
import { CourseTimerActionTypes } from "./course-timer.actions";
import { CourseTimerState } from "./course-timer.model";
import { getCurrentCourseTimerState, isTimerCompleted, isTimerIncomplete, isTimerRunning, isTimerStart, isTimerStopped } from "./course-timer.selector";

interface ChapterQuizzes {
  [key: string]: Quiz[];
}

@Component({
  selector: "app-course-timer",
  templateUrl: "./course-timer.component.html",
  styleUrls: ["./course-timer.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class CourseTimerComponent implements OnInit {
  @Input() showTimer: boolean;
  @Input() showTimerIncomplete: boolean;
  @Input() courseChapters: Chapter[];
  @Input() courseQuizzes: Quiz[];
  @Input() progresses: Progress[];

  currentUser: User;
  subscriptions$: Array<Subscription> = [];

  timer: Timer;
  timerString: string;

  courseSelected$ = this.courseStore.pipe(select(getCourseSelected), filter(val => !val));
  courseNotSelected$ = this.courseStore.pipe(select(isCourseNotSelected), filter(val => val))
  currentCourse: Course;

  currCourseTimerState$ = this.courseTimerStore.pipe(
    select(getCurrentCourseTimerState),
    filter((state) => state !== null && state.chapterId !== null),
  );

  currCourseTimerState: CourseTimerState;
  isTimerCompleted$ = this.courseTimerStore.pipe(
    select(isTimerCompleted),
    filter((state) => state !== false)
  );
  isTimerRunning$ = this.courseTimerStore.pipe(
    select(isTimerRunning),
    filter((state) => state !== false)
  );
  isTimerIncomplete$ = this.courseTimerStore.pipe(
    select(isTimerIncomplete),
    filter((state) => state !== false)
  );
  isTimerStarted$ = this.courseTimerStore.pipe(
    select(isTimerStart),
    filter((state) => state !== false)
  );
  isTimerStopped$ = this.courseTimerStore.pipe(
    select(isTimerStopped),
    filter((state) => state !== false)
  );


  constructor(
    public router: Router,
    private logger: NGXLogger,
    private route: ActivatedRoute,
    private signInStore: Store<SignInState>,
    private courseStore: Store<CourseState>,
    private courseTimerStore: Store<CourseTimerState>,
  ) {
    this.signInStore
      .pipe(select(getSignedInUser))
      .subscribe((user) => (this.currentUser = user));
  }

  async ngOnInit() {
    this.courseSelected$.subscribe(val => this.currentCourse = val);
    this.courseNotSelected$.subscribe(val => this.currentCourse = undefined);

    if (this.showTimer) {
      this.subscriptions$.push(
        this.currCourseTimerState$.subscribe((state) => {
          this.currCourseTimerState = state;
        })
      );

      this.subscriptions$.push(
        this.isTimerCompleted$.subscribe((state) => {
          this.logger.log(`Timer is completed... ${JSON.stringify(state)}`);
          this.stopTimer()
        })
      );

      this.subscriptions$.push(
        this.isTimerIncomplete$.subscribe((state) => {
          this.logger.log("Timer is incomplete...");
        })
      );

      this.subscriptions$.push(
        this.isTimerStarted$.subscribe(async (state) => {
          this.logger.log(`Timer is started... - ${JSON.stringify(state)}`);

          const timeRemaining = await this.getTimeRemaining();
          if (timeRemaining > 0) {
            await this.startTimer();
          } else {
            this.courseTimerStore.dispatch({ type: CourseTimerActionTypes.TimerCompleted });
          }
        })
      );

      this.subscriptions$.push(
        this.isTimerRunning$.subscribe(async (state: CourseTimerState) => {
          // this.logger.log("Timer is running...");
          if (state && state.timeRemaining == 0) {
            this.courseTimerStore.dispatch({ type: CourseTimerActionTypes.TimerCompleted });
          }
        })
      );

      this.subscriptions$.push(
        this.isTimerStopped$.subscribe((state) => {
          this.logger.log("Timer is stopped...");
        })
      );

      this.route.params.subscribe(async (params) => {
        if (params.chapterId) {
          const idx = this.courseChapters.findIndex((item) => item.ID == params.chapterId);
          if (idx > 0) {
            const prevChapterId = this.courseChapters[idx - 1]?.ID;
            const prevChapterTimeRemaining = await this.prevChapterTimeRemaining(prevChapterId);
            if (prevChapterTimeRemaining == 0) {
              this.courseTimerStore.dispatch({ type: CourseTimerActionTypes.TimerStart, payload: { chapterId: params.chapterId, timeRemaining: 27000, prevChapterId: 0, prevChapterTimeRemaining: 0 } });
            }
          } else if (idx == 0) {
            this.courseTimerStore.dispatch({ type: CourseTimerActionTypes.TimerStart, payload: { chapterId: params.chapterId, timeRemaining: 27000, prevChapterId: 0, prevChapterTimeRemaining: 0 } });
          }
        }
      });
    } else if (this.showTimerIncomplete) {
      this.route.params.subscribe(async (params) => {
        if (params.chapterId) {
          const idx = this.courseChapters.findIndex((item) => item.ID == params.chapterId);
          if (idx > 0) {
            const prevChapterId = this.courseChapters[idx - 1]?.ID;
            const prevChapterTimeRemaining = await this.prevChapterTimeRemaining(prevChapterId);
            if (prevChapterTimeRemaining > 0) {
              this.courseTimerStore.dispatch({ type: CourseTimerActionTypes.TimerIncomplete, payload: { chapterId: params.chapterId, prevChapterId, prevChapterTimeRemaining, timeRemaing: await this.getTimeRemaining() } });
            }
          }
        }
      });
    }


  }

  ngOnDestroy() {
    this.stopTimer();

    if (this.subscriptions$ && this.subscriptions$.length > 0) {
      this.subscriptions$.forEach((item) => item.unsubscribe());
    }
  }

  async prevChapterTimeRemaining(chapterId?): Promise<number> {
    if (!chapterId) {
      chapterId = this.currCourseTimerState.chapterId;
    }

    const requiredTimePerChapter = this.getRequiredTimePerChapter();
    if (requiredTimePerChapter == 0) {
      return 0;
    }
    let timeRemaining = 0;

    const prevChapter = this.courseChapters.find((item) => item.ID === chapterId);
    if (prevChapter) {
      const prevChapterQuizzes = this.courseQuizzes.filter(item => item.QuizLocation2 == prevChapter.ID.toString())
      const prevChapterQuizIds = prevChapterQuizzes.map((item) => item.QuizID);

      const prevChapterQuizProgress = this.progresses
        .filter((item) => prevChapterQuizIds.includes(item.QuizID))
        .sort(
          (a, b) =>
            new Date(a.DTStamp).getTime() - new Date(b.DTStamp).getTime()
        );

      if (prevChapterQuizProgress.length > 0) {
        timeRemaining =
          requiredTimePerChapter -
          (new Date().getTime() -
            new Date(prevChapterQuizProgress[0].DTStamp).getTime()) /
          1000;
        if (timeRemaining < 0) {
          timeRemaining = 0;
        }
      } else {
        timeRemaining = requiredTimePerChapter;
      }

      return timeRemaining;
    }
  }

  getRequiredTimePerChapter(): number {
    if (!this.currentCourse || !this.currentCourse.config) {
      return 0;
    }

    const courseConfig = JSON.parse(this.currentCourse.config);
    if (!courseConfig || !courseConfig.timerSettings) {
      return 0;
    }

    return courseConfig.timerSettings.requiredTimePerChapter;
  }

  async getTimeRemaining(): Promise<number> {
    if (!this.currentCourse || !this.currentCourse.config) {
      return 0;
    }

    const courseConfig = JSON.parse(this.currentCourse.config);
    if (!courseConfig || !courseConfig.timerSettings) {
      return 0;
    }

    const requiredTimePerChapter = this.getRequiredTimePerChapter();

    let timeRemaining = requiredTimePerChapter;

    const chapterQuizIds = this.courseQuizzes.map((item) => item.QuizID);

    const chapterQuizProgress = this.progresses
      .filter((item) => chapterQuizIds.includes(item.QuizID))
      .sort(
        (a, b) => new Date(a.DTStamp).getTime() - new Date(b.DTStamp).getTime()
      );

    if (chapterQuizProgress.length > 0) {
      timeRemaining =
        requiredTimePerChapter -
        (new Date().getTime() -
          new Date(chapterQuizProgress[0].DTStamp).getTime()) /
        1000;
    }

    return timeRemaining;
  }


  async startTimer() {
    if (this.timer) {
      this.timer.stop();
      this.timerString = "";
    }

    this.timer = new Timer();
    this.timer.start({
      startValues: {
        seconds: await this.getTimeRemaining(),
      },
      countdown: true,
    });

    this.timer.addEventListener("secondsUpdated", (e) => {
      this.timerString = this.timer.getTimeValues().toString();
      if ([CourseTimerActionTypes.TimerStart, CourseTimerActionTypes.TimerRunning].find(i => i == this.currCourseTimerState.currentState)) {
        this.courseTimerStore.dispatch({ type: CourseTimerActionTypes.TimerRunning, payload: { timeRemaining: this.timer.getTimeValues().seconds } });
      } else {
        this.timer.stop();
      }
    });
  }

  stopTimer() {
    if (this.timer) {
      this.timer.stop();
    }
    this.courseTimerStore.dispatch({ type: CourseTimerActionTypes.TimerStopped, payload: { timeRemaining: 0 } });
  }
}
