import { Action } from "@ngrx/store";
import { ChapterViewState } from "./chapter-view.model";

export enum ChapterViewActionTypes {
  ChapterShow = "ChapterShow",
  FinalShow = "ChapterFinalShow",
  QuizzesIncomplete = "QuizzesIncomplete",
  UpdateChapter = "UpdateChapter",
}

export class ChapterShowAction implements Action {
  readonly type = ChapterViewActionTypes.ChapterShow;
  constructor(public payload: ChapterViewState) { }
}

export class UpdateChapterAction implements Action {
  readonly type = ChapterViewActionTypes.UpdateChapter;
  constructor(public payload: ChapterViewState) { }
}

export class FinalShowAction implements Action {
  readonly type = ChapterViewActionTypes.FinalShow;
  constructor(public payload: ChapterViewState) { }
}

export class QuizzesIncompleteAction implements Action {
  readonly type = ChapterViewActionTypes.QuizzesIncomplete;
  constructor(public payload: ChapterViewState) { }
}

export type ChapterViewActions =
  | ChapterShowAction
  | FinalShowAction
  | QuizzesIncompleteAction
  | UpdateChapterAction;
