import {
  Component,
  OnInit,
  ViewEncapsulation
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { NGXLogger } from "ngx-logger";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { SignInState } from "src/app/components/allusers/signin/signin.model";
import { getSignedInUser } from "src/app/components/allusers/signin/signin.selector";
import { TextToSpeechCompleted, TextToSpeechInitialized, TextToSpeechPlaying } from "src/app/components/members/text-to-speech/text-to-speech.actions";
import { Course } from "src/app/models/course.model";
import { Quiz } from "src/app/models/quiz.model";
import { User } from "src/app/models/user.model";
import { CourseState } from "../courses/courses.reducer";
import { getCourseSelected, isCourseNotSelected } from "../courses/courses.selector";
import { TextToSpeechPaused } from "./text-to-speech.actions";
import { TextToSpeechState, TextToSpeechStates } from "./text-to-speech.model";
import { isTtsCompleted, isTtsInitialized, isTtsNotInitialized, isTtsPaused, isTtsPlaying, isTtsStopped, selectTextToSpeechState } from "./text-to-speech.selector";

interface ChapterQuizzes {
  [key: string]: Quiz[];
}

@Component({
  selector: "app-text-to-speech",
  templateUrl: "./text-to-speech.component.html",
  styleUrls: ["./text-to-speech.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class TextToSpeechComponent implements OnInit {
  synth = window.speechSynthesis;

  currentUser: User;
  subscriptions$: Array<Subscription> = [];

  courseSelected$ = this.courseStore.pipe(select(getCourseSelected), filter(val => !val));
  courseNotSelected$ = this.courseStore.pipe(select(isCourseNotSelected), filter(val => val))
  currentCourse: Course;

  currTextToSpeechState$ = this.textToSpeechStore.pipe(select(selectTextToSpeechState));
  currTextToSpeechState: TextToSpeechState;

  isTtsNotInitialized$ = this.textToSpeechStore.pipe(select(isTtsNotInitialized));
  isTtsInitialized$ = this.textToSpeechStore.pipe(select(isTtsInitialized));
  isTtsPlaying$ = this.textToSpeechStore.pipe(select(isTtsPlaying));
  isTtsPaused$ = this.textToSpeechStore.pipe(select(isTtsPaused));
  isTtsCompleted$ = this.textToSpeechStore.pipe(select(isTtsCompleted));
  isTtsStopped$ = this.textToSpeechStore.pipe(select(isTtsStopped));

  rates = [0.5, 1, 1.5, 2];
  rate = 0.5;
  pitches = [1, 1.5, 2];
  pitch = 1;

  constructor(
    public router: Router,
    private logger: NGXLogger,
    private route: ActivatedRoute,
    private signInStore: Store<SignInState>,
    private courseStore: Store<CourseState>,
    private textToSpeechStore: Store<TextToSpeechState>,
  ) {
    this.signInStore
      .pipe(select(getSignedInUser))
      .subscribe((user) => (this.currentUser = user));
  }

  async ngOnInit() {
    this.courseSelected$.subscribe(val => this.currentCourse = val);
    this.courseNotSelected$.subscribe(val => this.currentCourse = undefined);

    this.subscriptions$.push(
      this.currTextToSpeechState$.subscribe((state) => {
        this.currTextToSpeechState = state;
        this.logger.info(`Text to speech state - ${JSON.stringify(state)}`);
      })
    );

    this.subscriptions$.push(
      this.isTtsNotInitialized$.subscribe((state) => {
        this.logger.debug(`Text to speech not initialized - ${JSON.stringify(state)}`);
      })
    );

    this.subscriptions$.push(
      this.isTtsInitialized$.subscribe((state) => {
        this.logger.debug(`Text to speech initialized - ${JSON.stringify(state)}`);
      })
    );

    this.subscriptions$.push(
      this.isTtsPlaying$.subscribe((state) => {
        this.logger.debug(`Text to speech playing - ${JSON.stringify(state)}`);
      })
    );
    this.subscriptions$.push(
      this.isTtsPaused$.subscribe((state) => {
        this.logger.debug(`Text to speech paused - ${JSON.stringify(state)}`);
      })
    );
    this.subscriptions$.push(
      this.isTtsStopped$.subscribe((state) => {
        this.logger.debug(`Text to speech stopped - ${JSON.stringify(state)}`);
      })
    );
    this.subscriptions$.push(
      this.isTtsCompleted$.subscribe((state) => {
        this.logger.debug(`Text to speech completed - ${JSON.stringify(state)}`);
      })
    );
  }

  ngOnDestroy() {
    if (this.subscriptions$ && this.subscriptions$.length > 0) {
      this.subscriptions$.forEach((item) => item.unsubscribe());
    }
  }

  ttsInitialize() {
    const speech = new SpeechSynthesisUtterance();
    // find user selection, highlight it and read it
    const selection = window.getSelection();
    if (selection && selection.toString().trim() === "") {
      const element = document.getElementsByClassName("quiz-content")[0];
      selection.selectAllChildren(element);
    }

    speech.text = selection.toString();
    speech.pitch = this.pitch;
    speech.rate = this.rate;

    this.textToSpeechStore.dispatch(new TextToSpeechInitialized({
      ...this.currTextToSpeechState,
      state: TextToSpeechStates.INITIALIZED,
      text: selection.toString(),
      pitch: this.pitch,
      rate: this.rate,
    }));

    // highlight the reading sentence
    speech.onstart = () => {
      const range = selection.getRangeAt(0);
      const span = document.createElement("span");
      span.style.backgroundColor = "yellow";
      range.surroundContents(span);

      this.textToSpeechStore.dispatch(new TextToSpeechPlaying(
        { ...this.currTextToSpeechState, state: TextToSpeechStates.PLAYING }
      ));
    };
    // remove the highlight
    speech.onend = () => {
      const span = document.querySelector("span");
      const text = span.textContent;
      const parent = span.parentNode;
      parent.replaceChild(document.createTextNode(text), span);

      this.textToSpeechStore.dispatch(new TextToSpeechCompleted({ ...this.currTextToSpeechState, state: TextToSpeechStates.COMPLETED }));
    };

    speech.onpause = () => {
      this.textToSpeechStore.dispatch(new TextToSpeechPaused({ ...this.currTextToSpeechState, state: TextToSpeechStates.PAUSED }));
    };
    speech.onresume = () => {
      this.textToSpeechStore.dispatch(new TextToSpeechPlaying({ ...this.currTextToSpeechState, state: TextToSpeechStates.PLAYING }));
    }

    debugger;
    this.synth.speak(speech);

  }

  ttsToggle() {
    if (!this.synth.paused) {
      this.synth.pause();
      return;
    } else {
      this.synth.resume();
    }
  }

  ttsStop() {
    window.getSelection().removeAllRanges();
    this.synth.cancel();
  }

}
