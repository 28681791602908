import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store, createFeatureSelector, createSelector, select } from "@ngrx/store";
import { loadStripe } from '@stripe/stripe-js';
import { filter } from "rxjs/operators";
import { SignInState } from "../../../../app/components/allusers/signin/signin.model";
import { getSignedInUser } from "../../../../app/components/allusers/signin/signin.selector";
import { PaymentsService } from "../../../../app/services/payments.service";
import { environment } from "../../../../environments/environment";
import { PaymentActionTypes, PaymentFailedAction, PaymentLoadingAction, PaymentShowAction, PaymentSubmittingAction, PaymentSuccessfulAction } from "./payment.actions";
import { PaymentState } from "./payment.model";
import { getCourseSelected } from "src/app/components/members/courses/courses.selector";
import { CourseState } from "src/app/components/members/courses/courses.reducer";
import { Course } from "src/app/models/course.model";

export const getPaymentState = createFeatureSelector<PaymentState>("payment");

const getPayment = createSelector(
  getPaymentState,
  (paymentState: PaymentState) => paymentState
);

const showCC = createSelector(
  getPayment,
  (paymentState: PaymentState) => (paymentState &&
    (paymentState.currentState === PaymentActionTypes.PaymentShow ||
      paymentState.currentState === PaymentActionTypes.PaymentFailed) && paymentState) ? paymentState : null as unknown as PaymentState
)

const hideCC = createSelector(
  showCC,
  (paymentState: PaymentState) => !paymentState
)

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class PaymentComponent implements OnInit {
  @ViewChild("checkoutElement") checkoutElement: ElementRef;

  currentUser$ = this.signInStore.pipe(
    select(getSignedInUser),
    filter(val => (val ? true : false))
  );
  currentUser: any;    
  courseId: number;
  course: Course;  

  currentPayment$ = this.paymentStore.pipe(
    select(getPayment)
  )

  courseSelected$ = this.courseStore.pipe(select(getCourseSelected), filter(val => val !== null));

  constructor(
    private signInStore: Store<SignInState>,
    private paymentStore: Store<PaymentState>,
    private courseStore: Store<CourseState>,
    private paymentsService: PaymentsService,
    public elementRef: ElementRef,
    public activedRoute: ActivatedRoute,
    private router: Router
  ) {
  }

  async ngOnInit() {
    this.currentUser$.subscribe(user => (this.currentUser = user));
    this.paymentStore.dispatch(new PaymentLoadingAction({ currentState: PaymentActionTypes.PaymentLoading }));    
    this.activedRoute.params.subscribe(params => {
      this.courseId = params["courseId"];
      if (params["paymentStatus"] === "cancel") {
        this.paymentStore.dispatch(new PaymentFailedAction({ currentState: PaymentActionTypes.PaymentFailed, error: "Payment cancelled" }));
        return;
      }
      if (params["paymentStatus"] === "success") {
        this.paymentStore.dispatch(new PaymentSuccessfulAction({ currentState: PaymentActionTypes.PaymentSuccessful }));
        return;
      }
      this.onReady();
    });
    this.courseSelected$.subscribe(course => {
      this.course = course;
    });    
  }

  onReady() {
    this.paymentStore.dispatch(new PaymentShowAction({ currentState: PaymentActionTypes.PaymentShow }))
  }

  async paymentSubmit() {    
    console.log(`Submitting payment...`);
    this.paymentStore.dispatch(new PaymentSubmittingAction({ currentState: PaymentActionTypes.PaymentSubmitting }))
    
    try {      
      const session = await this.paymentsService
        .createCheckoutSession(this.courseId.toString(), this.currentUser.membername);
        
      // redirect to stripe checkout 
      window.location.href = session.url;
    } catch (err: any) {
      console.log(`Payment Error - ${JSON.stringify(err)}`);
      this.paymentStore.dispatch(new PaymentFailedAction({ currentState: PaymentActionTypes.PaymentFailed, error: err.message }));
      return;
    }     
  }

  goToCourse() {
    this.router.navigate([
      `courses`
    ]);
  }

  goBack() {
    this.router.navigate([
      `courses`
    ]);
  }
}

