import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { AnswerOptions } from "../models/answer-options.model";

@Injectable({
  providedIn: "root",
})
export class AnswerOptionsService {
  constructor(private http: HttpClient) {}

  async findAnswerOptionsForQuestion(
    questionId: number
  ): Promise<AnswerOptions[]> {
    return this.http
      .get<AnswerOptions[]>(
        `${environment.apiUrl}/answeroptions?questionId=${questionId}`
      )
      .toPromise();
  }

  async findAnswerOptionsForQuiz(quizId: number): Promise<AnswerOptions[]> {
    return this.http
      .get<AnswerOptions[]>(
        `${environment.apiUrl}/answeroptions?quizId=${quizId}`
      )
      .toPromise();
  }

  findAnswerOptionBy(answerOptId: number): Promise<AnswerOptions> {
    return this.http
      .get<AnswerOptions>(`${environment.apiUrl}/answeroptions/${answerOptId}`)
      .toPromise();
  }

  createAnswerOptions(answerOptions: AnswerOptions): Promise<AnswerOptions> {
    return this.http
      .post<AnswerOptions>(`${environment.apiUrl}/answeroptions`, answerOptions)
      .toPromise();
  }

  updateAnswerOptions(answerOptId: number, answerOptions: AnswerOptions) {
    return this.http
      .put(`${environment.apiUrl}/answeroptions/${answerOptId}`, answerOptions)
      .toPromise();
  }

  deleteAnswerOptions(answerOptId: number) {
    return this.http
      .delete(`${environment.apiUrl}/answeroptions/${answerOptId}`)
      .toPromise();
  }
}
