import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { CountryCode, parsePhoneNumber } from "libphonenumber-js/min";
import { Table } from "primeng/table";
import { filter } from "rxjs/operators";
import { School } from "src/app/models/school.model";
import { User } from "src/app/models/user.model";
import { AffiliatesService } from "src/app/services/affiliates.service";
import { AuthService } from "src/app/services/auth-service/auth.service";
import { CertificatesService } from "src/app/services/certificates.service";
import { ProgressService } from "src/app/services/progress.service";
import { RegmembersService } from "src/app/services/regmembers.service";
import { SchoolsService } from "src/app/services/schools.service";
import { SignInState } from "../../allusers/signin/signin.model";
import { getSignedInUser } from "../../allusers/signin/signin.selector";

@Component({
  selector: "app-teacher-reports",
  templateUrl: "./teacher-reports.component.html",
  styleUrls: ["./teacher-reports.component.css"],
})
export class TeacherReportsComponent implements OnInit {
  @ViewChild("dt") private dt: Table;

  constructor(
    private regmembersService: RegmembersService,
    private affiliatesService: AffiliatesService,
    private schoolService: SchoolsService,
    private progressService: ProgressService,
    private certificateService: CertificatesService,
    private authService: AuthService,
    private signInStore: Store<SignInState>,
    private activateRoute: ActivatedRoute,
    private router: Router
  ) {
    this.currentUser$.subscribe((user) => (this.currentUser = user));
  }

  first = 0;
  rows = 15;
  sortField;
  sortOrder;
  filters;
  totalRecords = 0;

  displayDialog: boolean;

  member: User = {} as User;
  memberinfo = {
    areaunit: "",
    lastworkeddate: "",
    certificate: "",
    certificateattempt: "",
  };

  selectedRegmem: User;

  newRegmem: boolean;

  members: User[];

  cols: any[];

  schoolsSelect: School[];

  errorMessage;
  successMessage;

  dateFilters: any;
  skipFilters: any;
  inactiveFilters: any;

  userform = new FormGroup({
    membername: new FormControl("", Validators.required),
    memberstatus: new FormControl("", Validators.required),
    fname: new FormControl("", Validators.required),
    lname: new FormControl(""),
    eaddress: new FormControl("", Validators.required),
    phone1: new FormControl("", Validators.required),
  });

  loading = true;

  currentUser$ = this.signInStore.pipe(
    select(getSignedInUser),
    filter((val) => (val ? true : false))
  );
  currentUser: User;

  teacherSchoolMap = {
    7237: [464],
    7238: [466],
    7239: [467],
    7240: [495],
    7241: [468],
    7242: [496],
    7243: [497],
    7244: [469],
    7245: [498],
    7246: [471],
    7247: [482],
    7248: [499],
    7249: [473],
    7250: [474],
    7251: [475],
    7252: [476],
    7253: [478],
    7945: [508],
    8545: [509],
  };

  async ngOnInit() {
    this.cols = [
      { field: "membername", header: "User Name" },
      { field: "fname", header: "First Name" },
      { field: "lname", header: "Last Name" },
      { field: "address", header: "Address" },
      { field: "schoolName", header: "School" },
      { field: "f_annote", header: "Note" },
      { field: "dateregistered", header: "Registered" },
      { field: "skip_notification", header: "Skipped Date" },
      { field: "inactive", header: "Inactive Since" },
    ];

    if (this.currentUser && this.currentUser.memberstatus === "admin") {
      this.cols.push({ field: "createdByName", header: "Created By" });
    }
  }

  async loadRegmems() {
    const id = this.activateRoute.snapshot.paramMap.get("id");
    const membersAndCount = await this.regmembersService.getUsersByAreaUnit(
      parseInt(id),
      this.first,
      this.rows,
      this.sortField,
      this.sortOrder,
      this.filters
    );

    this.members = membersAndCount[0];
    this.totalRecords = membersAndCount[1];
    await this.fillMemberDetails();
  }

  async fillMemberDetails() {
    const id = this.activateRoute.snapshot.paramMap.get("id");
    if (id) {
      let schools = await this.schoolService.getSchoolsBy(parseInt(id));

      if (
        this.currentUser.memberid &&
        this.teacherSchoolMap[this.currentUser.memberid]
      ) {
        schools = schools.filter((school) => {
          const schoolIds = this.teacherSchoolMap[this.currentUser.memberid];
          if (schoolIds && schoolIds.length > 0) {
            return schoolIds.includes(parseInt(school.schoolid.toString()));
          }

          return false;
        });
      }

      this.schoolsSelect = schools;

      for (const item of this.members) {
        if (item.sourceschool) {
          const school = schools.find(
            (val) => parseInt(item.sourceschool) == val.schoolid
          );

          if (school) {
            item["schoolName"] = school.schoolname;
          }
        }

        item[
          "address"
        ] = `${item.streetaddress}, ${item.city}, ${item.state} ${item.postalcode}`;
      }
    }
  }

  async loadMemberInfo() {
    if (!this.member.memberid) {
      return;
    }

    // const progresses = await this.progressService.getProgressBy(
    //   this.member.memberid
    // );
    // if (progresses) {
    //   const sortedProgresses = progresses.sort((a, b) => {
    //     return new Date(a.DTStamp).getTime() <= new Date(b.DTStamp).getTime()
    //       ? 1
    //       : -1;
    //   });

    //   if (sortedProgresses && sortedProgresses.length > 0) {
    //     this.memberinfo.lastworkeddate = new Date(
    //       sortedProgresses[0].DTStamp
    //     ).toLocaleDateString();

    //     const finalProgress = sortedProgresses.find(
    //       (item) => item.QuizID == 44 && item.Score > 60
    //     );
    //     if (finalProgress) {
    //       this.memberinfo.certificate = `${finalProgress.Score}% on ${new Date(
    //         finalProgress.DTStamp
    //       ).toLocaleDateString()}`;
    //     } else {
    //       this.memberinfo.certificate = `Not yet issued`;
    //     }
    //   }
    // }

    // const certificate = await this.certificateService.getCertificateById(
    //   this.member.memberid,
    //   true
    // );
    // if (certificate) {
    //   this.memberinfo.certificateattempt = new Date(
    //     certificate.DTStamp
    //   ).toLocaleDateString();
    // }

    // const id = this.activateRoute.snapshot.paramMap.get("id");
    // const affiliate = await this.affiliatesService.getAffiliate(parseInt(id));
    // if (affiliate) {
    //   this.memberinfo.areaunit = affiliate.company;
    // }
  }

  showDialogToAdd() {
    this.newRegmem = true;
    this.displayDialog = true;
    this.clearAll();
    this.setMemberDefaults();
  }

  save() {
    let p: Promise<any>;
    if (this.member && this.member["schoolName"]) {
      delete this.member["schoolName"];
    }

    if (this.member && this.member["address"]) {
      delete this.member["address"];
    }

    if (this.newRegmem) {
      if (
        !this.member.sourceschool &&
        this.schoolsSelect &&
        this.schoolsSelect.length > 0
      ) {
        this.member.sourceschool = this.schoolsSelect[0].schoolid + "";
      }
      if (this.member.phone1) {
        this.member.phone1 = parsePhoneNumber(
          this.member.phone1,
          "US" as CountryCode
        ).format("E.164");
      }
      p = this.regmembersService.createRegmem(this.member);
    } else {
      p = this.regmembersService.updateRegmem(
        this.member.membername,
        this.member
      );
    }

    return p
      .then(() => {
        this.loadRegmems();
        this.clearAll();
        this.displayDialog = false;
      })
      .catch((err) => {
        if (
          err &&
          err.status === 400 &&
          err.error &&
          err.error.error &&
          err.error.error.description
        ) {
          this.errorMessage = err.error.error.description;
        }
      });
  }

  setMemberDefaults() {
    const id = this.activateRoute.snapshot.paramMap.get("id");
    this.member.sourceaffiliateid = id;
    this.member.memberstatus = "paid";
    this.member.dateregistered = new Date();
    this.member.createdby = this.currentUser.memberid.toString();
  }

  delete() {
    this.regmembersService.deleteRegmem(this.member.membername).then(() => {
      this.loadRegmems();
      this.clearAll();
      this.displayDialog = false;
    });
  }

  onRowSelect(event) {
    this.newRegmem = false;
    this.clearAll();
    this.member = this.cloneRegmem(event.data);
    this.loadMemberInfo();
    this.displayDialog = true;
  }

  clearAll() {
    this.member = {} as User;
    this.memberinfo = {
      areaunit: "",
      certificate: "",
      certificateattempt: "",
      lastworkeddate: "",
    };
    this.errorMessage = null;
  }

  cloneRegmem(s: User): User {
    const member = {} as User;
    for (const prop in s) {
      member[prop] = s[prop];
    }
    return member;
  }

  async loadRegmemLazy(event) {
    this.loading = true;
    const id = this.activateRoute.snapshot.paramMap.get("id");
    if (event.filters.dateregistered) {
      event.filters.dateregistered.value = this.dateFilters;
    }
    if (event.filters.skip_notification) {
      event.filters.skip_notification.value = this.skipFilters;
    }
    if (event.filters.inactive) {
      event.filters.inactive.value = this.inactiveFilters;
    }
    const membersAndCount = await this.regmembersService.getUsersByAreaUnit(
      parseInt(id),
      event.first,
      event.rows,
      event.sortField,
      event.sortOrder === 1 ? "ASC" : "DESC",
      JSON.stringify(event.filters)
    );

    this.members = membersAndCount[0];
    this.totalRecords = membersAndCount[1];
    this.fillMemberDetails();
    this.loading = false;
  }

  onToggleCreateActionInput() {
    console.log("On Change");
  }

  gotoProgress() {
    this.router.navigate([
      "teacher",
      "course-progress",
      this.member.membername,
    ]);
  }

  resetPassword() {
    this.regmembersService
      .resetPassword(this.member)
      .then(() => {
        this.successMessage = "Reset Password Successful !!";
      })
      .catch((err) => {
        if (
          err &&
          err.status === 400 &&
          err.error &&
          err.error.error &&
          err.error.error.description
        ) {
          this.errorMessage = err.error.error.description;
        }
      });
  }
}
