import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AssetsListComponent } from "src/app/components/admins/assets-list/assets-list.component";
import { ChapterSectionsComponent } from "src/app/components/admins/chapter-sections/chapter-sections.component";
import { EdisonHomeComponent } from "src/app/components/members/edison-home/edison-home.component";
import { AdminGuard, canActivateMemberGuard } from "./_guards";
import { AnonymousGuard } from "./_guards/anonymous.guard";
import { TeacherGuard } from "./_guards/teacher.guard";
import { AnswerOptionsListComponent } from "./components/admins/answer-options-list/answer-options-list.component";
import { AreaUnitReportsComponent } from "./components/admins/area-unit-reports/area-unit-reports.component";
import { AreaUnitComponent } from "./components/admins/area-unit/area-unit.component";
import { BannerListComponent } from "./components/admins/banner-list/banner-list.component";
import { ChaptersComponent } from "./components/admins/chapters/chapters.component";
import { CourseListComponent } from "./components/admins/course-list/course-list.component";
import { DashboardComponent } from "./components/admins/dashboard/dashboard.component";
import { EditBannerComponent } from "./components/admins/edit-banner/edit-banner.component";
import { EditPageComponent } from "./components/admins/edit-page/edit-page.component";
import { MemberCoursesComponent } from "./components/admins/member-courses/member-courses.component";
import { PageListComponent } from "./components/admins/page-list/page-list.component";
import { QuestionsListComponent } from "./components/admins/questions-list/questions-list.component";
import { QuizListComponent } from "./components/admins/quiz-list/quiz-list.component";
import { ReferredByComponent } from "./components/admins/referred-by/referred-by.component";
import { SchoolManagerComponent } from "./components/admins/school-manager/school-manager.component";
import { SearchStudentComponent } from "./components/admins/search-student/search-student.component";
import { AboutusComponent } from "./components/allusers/aboutus/aboutus.component";
import { ContactusComponent } from "./components/allusers/contactus/contactus.component";
import { EductaionDetailsComponent } from "./components/allusers/eductaion-details/eductaion-details.component";
import { LearningComponent } from "./components/allusers/learning/learning.component";
import { NotificationsComponent } from "./components/allusers/notifications/notifications.component";
import { PresignupLayoutComponent } from "./components/allusers/presignup-layout/presignup-layout.component";
import { PrivacyComponent } from "./components/allusers/privacy/privacy.component";
import { TestimoniolsComponent } from "./components/allusers/testimoniols/testimoniols.component";
import { TheoryCourseComponent } from "./components/allusers/theory-course/theory-course.component";
import { AreaUnitDashboardComponent } from "./components/area-unit-admins/area-unit-dashboard/area-unit-dashboard.component";
import { TeacherReportsComponent } from "./components/area-unit-admins/teacher-reports/teacher-reports.component";
import { ChangePasswordComponent } from "./components/common/change-password/change-password.component";
import { HomeComponent } from "./components/common/home/home.component";
import { ChapterViewComponent } from "./components/members/chapter-view/chapter-view.component";
import { CourseProgressComponent } from "./components/members/course-progress/course-progress.component";
import { CourseinfoComponent } from "./components/members/courseinfo/courseinfo.component";
import { CoursesComponent } from "./components/members/courses/courses.component";
import { DownloadCertificateComponent } from "./components/members/download-certificate/download-certificate.component";
import { LoginHomeComponent } from "./components/members/login-home/login-home.component";
import { PaymentComponent } from "./components/members/payment/payment.component";
import { ProfileComponent } from "./components/members/profile/profile.component";
import { QuickTipsComponent } from "./components/members/quick-tips/quick-tips.component";
import { MemberCourseProgressComponent } from "src/app/components/admins/member-course-progress/member-course-progress.component";

export const routes: Routes = [
  {
    path: "members",
    redirectTo: "courses",
    pathMatch: "full",
  },
  {
    path: "ssologin",
    component: LoginHomeComponent,
  },
  {
    path: "edison/register",
    component: EdisonHomeComponent,
  },
  {
    path: "home",
    component: PresignupLayoutComponent,
    canActivate: [AnonymousGuard],
    children: [
      { path: "", component: LoginHomeComponent },
      { path: "testimoniols", component: TestimoniolsComponent },
      { path: "contact-us", component: ContactusComponent },
      { path: "course-Info", component: CourseinfoComponent },
      { path: "learning", component: LearningComponent },
      { path: "about-us", component: AboutusComponent },
      { path: "privacy", component: PrivacyComponent },
      { path: "education-details", component: EductaionDetailsComponent },
      { path: "theory-course", component: TheoryCourseComponent },
    ],
  },
  {
    path: "",
    component: HomeComponent,
    canActivate: [canActivateMemberGuard],
    children: [
      { path: "", component: LoginHomeComponent },
      { path: "quick-tips", component: QuickTipsComponent },
      { path: "edit-notification", component: NotificationsComponent },
      { path: "courses", component: CoursesComponent },
      { path: "courses/:courseId/course-progress", component: CourseProgressComponent },
      { path: "profile", component: ProfileComponent },
      { path: "change-password", component: ChangePasswordComponent },

      { path: "courses/:courseId/chapters", component: ChapterViewComponent },
      { path: "courses/:courseId/chapters/:chapterId", component: ChapterViewComponent },
      {
        path: "courses/:courseId/chapters/:chapterId/quizzes/:quizId",
        component: ChapterViewComponent,
      },
      {
        path: "courses/:courseId/chapters/:chapterId/videos/:videoId",
        component: ChapterViewComponent,
      },
      {
        path: "courses/:courseId/chapters/:chapterId/sounds/:soundId",
        component: ChapterViewComponent,
      },
      {

        path: "courses/:courseId/chapters/:chapterId/assets/:assetId",
        component: ChapterViewComponent,
      },
      {
        path: "courses/:courseId/chapters/:chapterId/sections/:sectionId",
        component: ChapterViewComponent,
      },
      { path: "courses/:courseId/download-certificate", component: DownloadCertificateComponent },
      { path: "courses/:courseId/payments", component: PaymentComponent },
      { path: "courses/:courseId/payments/:paymentStatus", component: PaymentComponent },      
    ],  
  },
  {
    path: "admin",
    component: HomeComponent,
    canActivate: [AdminGuard],
    children: [
      { path: "dashboard", component: DashboardComponent },
      { path: "courses/:courseId/chapters", component: ChaptersComponent },
      { path: "courses/:courseId/chapters/:chapterId/sections", component: ChapterSectionsComponent },
      { path: "edit-banner", component: EditBannerComponent },
      { path: "edit-page", component: EditPageComponent },

      {
        path: "courses/:courseId/quizzes/:quizId/questions/:questionId/answerOptions",
        component: AnswerOptionsListComponent,
      },
      { path: "page-list", component: PageListComponent },
      {
        path: "courses/:courseId/quizzes/:quizId/questions",
        component: QuestionsListComponent,
      },
      { path: "quizzes", component: QuizListComponent },
      { path: "courses", component: CourseListComponent },
      { path: "assets", component: AssetsListComponent },
      { path: "referred-by", component: ReferredByComponent },
      { path: "area-unit", component: AreaUnitComponent },
      { path: "school-manager", component: SchoolManagerComponent },
      { path: "regmembers", component: SearchStudentComponent },
      { path: "regmembers/:membername/courses", component: MemberCoursesComponent },
      { path: "regmembers/:membername/courses/:courseId/course-progress", component: CourseProgressComponent },
      { path: "regmembers/:membername/courses/:courseId", component: MemberCourseProgressComponent },
      { path: "area-unit-reports/:id", component: AreaUnitReportsComponent },
      { path: "banner-list", component: BannerListComponent },
      {
        path: "course-progress/:membername",
        component: CourseProgressComponent,
      },
      {
        path: "courses",
        component: CoursesComponent,
      },
      {
        path: "download-certificate/:membername",
        component: DownloadCertificateComponent,
      },
    ],
  },
  {
    path: "teacher",
    component: HomeComponent,
    canActivate: [TeacherGuard],
    children: [
      { path: "dashboard", component: AreaUnitDashboardComponent },
      { path: "area-unit-reports/:id", component: TeacherReportsComponent },
      {
        path: "course-progress/:membername",
        component: CourseProgressComponent,
      },
      {
        path: "download-certificate/:membername",
        component: DownloadCertificateComponent,
      },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor() { }

}
