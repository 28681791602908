import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgFor } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AmplifyAuthenticatorModule, AuthenticatorService } from '@aws-amplify/ui-angular';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { EditorModule, TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular";
import { HighchartsChartModule } from "highcharts-angular";
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { ToastrModule } from 'ngx-toastr';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from "primeng/calendar";
import { DialogModule } from "primeng/dialog";
import { DropdownModule } from "primeng/dropdown";
import { FileUploadModule } from 'primeng/fileupload';
import { ListboxModule } from 'primeng/listbox';
import { ScrollerModule } from 'primeng/scroller';
import { TableModule } from "primeng/table";
import { AssetsListComponent } from 'src/app/components/admins/assets-list/assets-list.component';
import { ChapterSectionsComponent } from 'src/app/components/admins/chapter-sections/chapter-sections.component';
import { EdisonHomeComponent } from 'src/app/components/members/edison-home/edison-home.component';
import { environment } from '../environments/environment';
import { AdminGuard, MemberGuard } from "./_guards";
import { AnonymousGuard } from "./_guards/anonymous.guard";
import { TeacherGuard } from "./_guards/teacher.guard";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AnswerOptionsListComponent } from "./components/admins/answer-options-list/answer-options-list.component";
import { AreaUnitReportsComponent } from "./components/admins/area-unit-reports/area-unit-reports.component";
import { PhonePipe } from "./components/admins/area-unit-reports/phone-pipe.transform";
import { AreaUnitComponent } from "./components/admins/area-unit/area-unit.component";
import { BannerListComponent } from "./components/admins/banner-list/banner-list.component";
import { ChaptersComponent } from "./components/admins/chapters/chapters.component";
import { CourseListComponent } from './components/admins/course-list/course-list.component';
import { DashboardComponent } from "./components/admins/dashboard/dashboard.component";
import { EditBannerComponent } from "./components/admins/edit-banner/edit-banner.component";
import { EditChapterContentComponent } from "./components/admins/edit-chapter-content/edit-chapter-content.component";
import { EditPageComponent } from "./components/admins/edit-page/edit-page.component";
import { EditQuestionOptionComponent } from "./components/admins/edit-question-option/edit-question-option.component";
import { EditQuestionComponent } from "./components/admins/edit-question/edit-question.component";
import { MemberCoursesComponent } from './components/admins/member-courses/member-courses.component';
import { PageListComponent } from "./components/admins/page-list/page-list.component";
import { QuestionsListComponent } from "./components/admins/questions-list/questions-list.component";
import { QuizListComponent } from "./components/admins/quiz-list/quiz-list.component";
import { ReferredByComponent } from "./components/admins/referred-by/referred-by.component";
import { SchoolManagerComponent } from "./components/admins/school-manager/school-manager.component";
import { SearchStudentComponent } from "./components/admins/search-student/search-student.component";
import { AboutusComponent } from "./components/allusers/aboutus/aboutus.component";
import { AgreementComponent } from "./components/allusers/agreement/agreement.component";
import { ContactusComponent } from "./components/allusers/contactus/contactus.component";
import { CustomerHelpComponent } from "./components/allusers/customer-help/customer-help.component";
import { CustomerSupportComponent } from "./components/allusers/customer-support/customer-support.component";
import { EductaionDetailsComponent } from "./components/allusers/eductaion-details/eductaion-details.component";
import { ForgotPasswordComponent } from "./components/allusers/forgot-password/forgot-password.component";
import { LearningComponent } from "./components/allusers/learning/learning.component";
import { NewPasswordRequiredComponent } from "./components/allusers/new-password-required/new-password-required.component";
import { NotificationsComponent } from "./components/allusers/notifications/notifications.component";
import { PresignupLayoutComponent } from "./components/allusers/presignup-layout/presignup-layout.component";
import { PrivacyComponent } from "./components/allusers/privacy/privacy.component";
import { SigninComponent } from "./components/allusers/signin/signin.component";
import { signInReducer } from "./components/allusers/signin/signin.reducer";
import { SignupComponent } from "./components/allusers/signup/signup.component";
import { signupReducer } from "./components/allusers/signup/signup.reducer";
import { TestimoniolsComponent } from "./components/allusers/testimoniols/testimoniols.component";
import { TheoryCourseComponent } from "./components/allusers/theory-course/theory-course.component";
import { AreaUnitDashboardComponent } from "./components/area-unit-admins/area-unit-dashboard/area-unit-dashboard.component";
import { TeacherReportsComponent } from "./components/area-unit-admins/teacher-reports/teacher-reports.component";
import { ChangePasswordComponent } from "./components/common/change-password/change-password.component";
import { FooterComponent } from "./components/common/footer/footer.component";
import { HeaderComponent } from "./components/common/header/header.component";
import { HomeComponent } from "./components/common/home/home.component";
import { ChapterViewComponent } from "./components/members/chapter-view/chapter-view.component";
import { chapterViewReducer } from "./components/members/chapter-view/chapter-view.reducer";
import { RouteTransformerDirective } from "./components/members/chapter-view/route-transformer.directive";
import { CourseProgressComponent } from "./components/members/course-progress/course-progress.component";
import { CourseTimerComponent } from './components/members/course-timer/course-timer.component';
import { courseTimerReducer } from './components/members/course-timer/course-timer.reducer';
import { CourseinfoComponent } from "./components/members/courseinfo/courseinfo.component";
import { CoursesComponent } from "./components/members/courses/courses.component";
import { coursesReducer } from './components/members/courses/courses.reducer';
import { DownloadCertificateComponent } from "./components/members/download-certificate/download-certificate.component";
import { LoginHomeComponent } from "./components/members/login-home/login-home.component";
import { PaymentComponent } from "./components/members/payment/payment.component";
import { paymentReducer } from "./components/members/payment/payment.reducer";
import { ProfileComponent } from "./components/members/profile/profile.component";
import { QuickTipsComponent } from "./components/members/quick-tips/quick-tips.component";
import { QuizTestsComponent } from "./components/members/quiz-tests/quiz-tests.component";
import { quizTestsReducer } from "./components/members/quiz-tests/quiz-tests.reducer";
import { TakeQuizComponent } from "./components/members/take-quiz/take-quiz.component";
import { PagesComponent } from "./components/pages/pages.component";
import { HighlightDirective } from "./directives/highlight.directive";
import { amplifyReducer } from "./services/auth-service/amplify.reducer";
import { AuthIntercepter } from "./services/auth-service/auth.intercepter";
import { AuthService } from "./services/auth-service/auth.service";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DashboardComponent,
    ChaptersComponent,
    TakeQuizComponent,
    EditBannerComponent,
    PagesComponent,
    QuickTipsComponent,
    NotificationsComponent,
    LoginHomeComponent,
    CoursesComponent,
    CourseListComponent,
    CourseProgressComponent,
    ProfileComponent,
    HighlightDirective,
    ChangePasswordComponent,
    CustomerHelpComponent,
    CustomerSupportComponent,
    TestimoniolsComponent,
    ContactusComponent,
    CourseinfoComponent,
    LearningComponent,
    PresignupLayoutComponent,
    AboutusComponent,
    PrivacyComponent,
    ChapterViewComponent,
    EductaionDetailsComponent,
    TheoryCourseComponent,
    PaymentComponent,
    QuizTestsComponent,
    ChaptersComponent,
    EditPageComponent,
    PageListComponent,
    QuestionsListComponent,
    QuizListComponent,
    EditQuestionComponent,
    AnswerOptionsListComponent,
    EditQuestionOptionComponent,
    EditChapterContentComponent,
    ReferredByComponent,
    AreaUnitComponent,
    SchoolManagerComponent,
    SearchStudentComponent,
    BannerListComponent,
    DownloadCertificateComponent,
    SigninComponent,
    SignupComponent,
    ForgotPasswordComponent,
    HeaderComponent,
    FooterComponent,
    AgreementComponent,
    RouteTransformerDirective,
    AreaUnitReportsComponent,
    AreaUnitDashboardComponent,
    PhonePipe,
    NewPasswordRequiredComponent,
    TeacherReportsComponent,
    CourseTimerComponent,
    MemberCoursesComponent,
    AssetsListComponent,
    ChapterSectionsComponent,
    EdisonHomeComponent,
  ],
  imports: [
    AmplifyAuthenticatorModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HighchartsChartModule,
    EditorModule,
    FontAwesomeModule,
    LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG }),
    NgFor,
    ToastrModule.forRoot(),
    ClipboardModule,
    PdfViewerModule,

    // Material Modules
    MatButtonModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatSelectModule,
    MatSidenavModule,
    MatCardModule,
    MatTableModule,
    MatDialogModule,
    MatCheckboxModule,
    MatRadioModule,
    MatToolbarModule,

    // primeng modules
    TableModule,
    DialogModule,
    DropdownModule,
    CalendarModule,
    ListboxModule,
    FileUploadModule,
    AccordionModule,
    ScrollerModule,

    StoreModule.forRoot({}),
    StoreModule.forFeature("authState", amplifyReducer),
    StoreModule.forFeature("signIn", signInReducer),
    StoreModule.forFeature("signup", signupReducer),
    StoreModule.forFeature("quizTests", quizTestsReducer),
    StoreModule.forFeature("chapterView", chapterViewReducer),
    StoreModule.forFeature("payment", paymentReducer),
    StoreModule.forFeature("course", coursesReducer),
    StoreModule.forFeature("courseTimer", courseTimerReducer),

    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
  ],
  providers: [
    AuthenticatorService,
    MemberGuard,
    AdminGuard,
    AnonymousGuard,
    TeacherGuard,
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthIntercepter, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: AuthService) => () =>
        authService.getLoggedInUser(),
      deps: [AuthService],
      multi: true,
    },
    { provide: TINYMCE_SCRIPT_SRC, useValue: "tinymce/tinymce.min.js" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
