<div class="backgroundColor fx-layout-column fx-flex-fill fx-layout-align-space-evenly-center">
  <div class="green" *ngIf="bannerContent">
    <div [innerHTML]="bannerContent"></div>
  </div>
  <div class="backgroundColor fx-layout-row fx-layout-align-row fx-layout-align-space-evenly-center">
    <div class="col s7 fx-flex-50pc">
      <img src="{{'assets/img/banner.png'}}" class="responsive-img">
    </div>
    <div class="col s5 fx-flex-50pc">
      <div class="col s12">
        <div class="row signin-bottom-margin">
          <div class="col s10">
            <mat-tab-group [selectedIndex]="selectedTab">
              <mat-tab label="Sign In">
                <app-signin></app-signin>
              </mat-tab>
              <mat-tab label="Sign Up">
                <app-signup></app-signup>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="fx-layout-row fx-layout-align-space-around-center">
    <mat-card class="fx-flex-30pc">
      <mat-card-content>
        <div class="center">
          <img src="{{'assets/img/rubber-stamp.png'}}">
          <h6>Approved by the PA Dept of Education</h6>
          <p>
            Our school is approved by PA department of education to provide you with a 30-hour Classroom
            Online Theory Course
          </p>
        </div>
      </mat-card-content>
      <!-- <mat-card-actions class="center">
          <button mat-raised-button color="primary" routerLink="/home/education-details">VIEW DETAILS</button>
        </mat-card-actions> -->
    </mat-card>

    <mat-card class="fx-flex-30pc">
      <mat-card-content>
        <div class="center">
          <img src="{{'assets/img/calendar.png'}}">
          <h6>30-Hour Online Theory Course</h6>
          <p>
            The Department of Public Education mandates a minimum of number of topics to qualify as a 30-hour course
            taken online. Keystodriving.com exceeds expectations by providing additional curriculum in areas that are
            NOT mandated, but that we deem important for novice drivers:
          </p>
        </div>
      </mat-card-content>
      <!-- <mat-card-actions class="center">
          <button mat-raised-button color="primary" routerLink="/home/theory-course">VIEW DETAILS</button>
        </mat-card-actions> -->
    </mat-card>

    <mat-card class="fx-flex-30pc">
      <mat-card-content>
        <div class="center">
          <img src="{{'assets/img/diploma.png'}}">
          <h6 class="center">Approved by the PA Dept of Education</h6>
          <p>
            Use your certifcate to get high school credit, get your senior license early at 17 1/2 or an insurance
            discount
          </p>
        </div>
      </mat-card-content>
      <!-- <mat-card-actions class="center">
          <button mat-raised-button color="primary" routerLink="/home/completion-cirtificate">VIEW DETAILS</button>
        </mat-card-actions> -->
    </mat-card>
  </div>
</div>

<!-- </main> -->
