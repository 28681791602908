import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { filter } from 'rxjs';
import { CourseState } from 'src/app/components/members/courses/courses.reducer';
import { isCourseNotSelected, isCourseSelected } from 'src/app/components/members/courses/courses.selector';
import { Course } from 'src/app/models/course.model';
import { AuthService } from '../../../services/auth-service/auth.service';
import { RouterService } from '../../../services/route-service/router.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  courseSelected$ = this.courseStore.pipe(select(isCourseSelected), filter(val => !val == false));
  courseNotSelected$ = this.courseStore.pipe(select(isCourseNotSelected), filter(val => val))
  currentCourse: Course;

  constructor(public routeService: RouterService,
    private courseStore: Store<CourseState>,
    private authService: AuthService) { }

  ngOnInit() {
    this.courseSelected$.subscribe(val => this.currentCourse = val.course);
    this.courseNotSelected$.subscribe(val => this.currentCourse = undefined);
  }

}
