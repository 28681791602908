import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { SignInActionTypes } from "src/app/components/allusers/signin/signin.actions";
import { SignInState } from "src/app/components/allusers/signin/signin.model";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"]
})
export class FooterComponent implements OnInit {
  year = new Date().getFullYear();
  isUserLoggedIn: boolean;

  constructor(private loginStore: Store<SignInState>) {}

  ngOnInit() {
    this.loginStore.subscribe(
      loginState =>
        (this.isUserLoggedIn =
          loginState.currentState === SignInActionTypes.SignedIn)
    );
  }
}
