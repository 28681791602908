<div class="row media" class="fx-layout-column fx-layout-align-center-center"
  *ngIf="currentState === 'QuizSubmitting' || currentState === 'FinalQuizSubmitting' || currentState === 'FinalQuizShow'">
  <mat-spinner strokeWidth="3" diameter="100"></mat-spinner>
</div>

<div class="row" *ngIf="currentState === 'QuizLoaded'">
  <div class="fx-layout-column">
    <div class="row" id="quizForm">
      <b>{{ chapter?.ChapterName }} #{{ quiz?.QuizOrder }} of {{ quizzesPerChapter?.length }}</b>
      <h5>{{ quiz?.QuizTitle }}</h5>
      <b>Please click on the answer text to select the option</b>
      <form [formGroup]="quizForm" (ngSubmit)="submitQuiz()" *ngIf="quiz">
        <div class="fx-layout-column" *ngFor="let question of quiz.questions">
          <div class="fx-flex" formGroupName="{{ question.QuestionID }}">
            <label class="black-text">{{question.QuestionText}}</label>
            <mat-radio-group class="quiz-radio-group" formControlName="AnswerOptID">
              <mat-radio-button class="quiz-radio-button" *ngFor="let option of question.answerOptions"
                [value]="option.AnswerOptID">
                <div class="black-text wrap" *ngIf="option.AnswerType === 'TEXT'">{{ option.AnswerText }}</div>
                <div *ngIf="option.AnswerType === 'IMAGE'"><img width="100px" src="{{ getImage(option.AnswerText) }}">
                </div>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <button mat-raised-button color="primary" type="submit">Submit</button>
      </form>
    </div>
  </div>
</div>

<div class="row media" *ngIf="currentState === 'QuizSkipped'">
  <div class="fx-layout-column" *ngIf="isQuizSkippingNotAllowed$ | async as state">
    <div class="row">
      <h5>Notice</h5>
      <div *ngFor="let quiz of state.incompleteQuizzes">
        <p class="red-text">Quiz Not Complete: <a [routerLink]="getIncompleteLink(quiz)">{{ quiz.QuizLocation }} - quiz
            {{ quiz.QuizOrder }} - {{ quiz.QuizTitle }}</a></p>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="currentState === 'QuizSubmitted'">
  <div class="fx-layout-column">
    <div class="row" *ngIf="quizState && quizState.result">
      <h5>Result</h5>
      <p>There are {{ quiz.questions.length }} questions in the quiz you submitted.</p>
      <p>Your grade is {{ quizState.result.score }}%</p>
      <p>You answered {{ quiz.questions.length - quizState.result.wrongQuestions.length }} questions correctly.</p>

      <h5>Incorrect Answers are as follows:</h5>
      <div *ngFor="let question of quizState.result.wrongQuestions">
        <p class="red-text">Question: {{ question.QuestionText }}</p>
        <p class="red-text">Answer: {{ getAnswer(question) }}</p>
      </div>
      <p>Would you like to take this quiz again? If so, <a style="cursor: pointer;" (click)="retakeQuiz()">click
          here</a></p>
    </div>
  </div>
</div>

<div class="row media" *ngIf="currentState === 'AssetShow' && asset">
  <div class="fx-layout-column">
    <div class="row" style="width: 640px; height: 800px">
      <video style="width: 100%" controls *ngIf="asset.contentType === 'video/mp4'">
        <source [src]="getAssetUrl()" type="video/mp4">
        <!-- <source src="{{getVideo()}}.ogv" type="video/ogv">
        <source src="{{getVideo()}}.webm" type="video/webm"> -->
      </video>
      <pdf-viewer [src]="getAssetUrl()" [render-text]="true" [original-size]="false" style="width: 100%; height: 100%"
        *ngIf="asset.contentType === 'application/pdf'">
      </pdf-viewer>

      <iframe [src]="getPptUrl()" style="width: 100%; height: 100%" frameborder='0'
        *ngIf="asset.contentType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'">
      </iframe>
    </div>
  </div>
</div>

<div class=" row media" *ngIf="currentState === 'VideoShow'">
  <div class="fx-layout-column">
    <div class="row" style="width: 640px; height: 70vh;">
      <video style="width: 100%" controls>
        <source src="{{getVideo()}}.mp4" type="video/mp4">
        <!-- <source src="{{getVideo()}}.ogv" type="video/ogv">
        <source src="{{getVideo()}}.webm" type="video/webm"> -->
      </video>
    </div>
  </div>
</div>

<div class="row media" *ngIf="currentState === 'AudioShow'">
  <div class="fx-layout-column">
    <div class="row">
      <audio class="responsive-audio" controls>
        <source src="{{getSound()}}.wav" type="audio/wav">
      </audio>
    </div>
  </div>
</div>

<div class="row" *ngIf="currentState === 'FinalQuizLoaded'">
  <div class="fx-layout-column">
    <div class="row" id="finalForm" *ngIf="quiz && quiz.questions">
      <h5 class="red-text">PLEASE CLICK ON THE CORRECT ANSWER AND PRESS THE SUBMIT BUTTON AT THE COMPLETION OF THE TEST.
      </h5>
      <div class="row">
        <h5>{{ quiz.QuizTitle }}</h5>
        <form [formGroup]="quizForm" (ngSubmit)="submitQuiz()">
          <div class="fx-layout-column" *ngFor="let question of quiz.questions">
            <div class="fx-flex" formGroupName="{{ question.QuestionID }}">
              <label class="black-text">{{question.QuestionText}}</label>
              <mat-radio-group class="quiz-radio-group" formControlName="AnswerOptID">
                <mat-radio-button class="quiz-radio-button" *ngFor="let option of question.answerOptions"
                  [value]="option.AnswerOptID">
                  <div class="black-text" *ngIf="option.AnswerType === 'TEXT'">{{ option.AnswerText }}</div>
                  <div *ngIf="option.AnswerType === 'IMAGE'"><img src="{{ getImage(option.AnswerText) }}"> </div>
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <button mat-raised-button color="primary" type="submit">Submit</button>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="row media" *ngIf="currentState === 'FinalQuizSubmitted'">
  <div class="fx-layout-column">
    <div class="row" *ngIf="quizState && quizState.result && (quizState.result.score < 60)">
      <h5>Result</h5>
      <p>There are {{ quiz.questions.length }} questions in the quiz you submitted.</p>
      <p>Your grade is {{ quizState.result.score }}%</p>
      <p>You answered {{ quiz.questions.length - quizState.result.wrongQuestions.length }} questions correctly.</p>
    </div>
    <div class="row" *ngIf="quizState && quizState.result && (quizState.result.score >= 60)">
      <h5>Final Quiz Completed</h5>
      <div class="row">
        <button mat-raised-button color="primary" (click)="downloadCertificate()">Print Certificate</button>
      </div>
    </div>
  </div>
</div>

<div class="row media" *ngIf="currentState === 'FinalQuizAlreadyDone'">
  <div class="fx-layout-column">
    <div class="row">
      <h5>Notice</h5>
      <p class="red-text">We're sorry, but you are not allowed to take the final test right now. As you have
        completed
        final quiz</p>
    </div>
  </div>
</div>

<div class="row media" *ngIf="currentState === 'FinalNotAllowedQuizIncomplete'">
  <div class="fx-layout-column" *ngIf="isFinalNotAllowedQuizIncomplete$ | async as state">
    <div class="row">
      <h5>Notice</h5>
      <div *ngFor="let quiz of state.incompleteQuizzes">
        <p class="red-text">Quiz Not Complete: <a [routerLink]="getIncompleteLink(quiz)">{{ quiz.QuizLocation }} -
            quiz
            {{ quiz.QuizID }} - {{ quiz.QuizTitle }}</a></p>
      </div>
    </div>
  </div>
</div>
