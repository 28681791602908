import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ChapterViewActionTypes } from "./chapter-view.actions";
import { ChapterViewState } from "./chapter-view.model";

export const getChapterView = createFeatureSelector<ChapterViewState>(
  "chapterView"
);

export const getCurrentChapterViewState = createSelector(
  getChapterView,
  (state: ChapterViewState) => state
);

export const isUnknownChapterShow = createSelector(
  getChapterView,
  (state: ChapterViewState) => state && !state.chapterId && state
);

export const isQuizzesIncomplete = createSelector(
  getChapterView,
  (state: ChapterViewState) =>
    state &&
    state.chapterId &&
    state.chapterId !== "11" &&
    state.incompleteQuizzes &&
    state.incompleteQuizzes.length > 0 &&
    state.currentState !== ChapterViewActionTypes.QuizzesIncomplete &&
    state
);

export const isChapterShow = createSelector(
  getChapterView,
  (state: ChapterViewState) => {
    return (
      state &&
      state.chapterId &&
      state.chapterId !== "final" &&
      state.quizId !== "final" &&
      state.incompleteQuizzes &&
      state.incompleteQuizzes.length == 0 &&
      !state.timerError &&
      state.currentState !== ChapterViewActionTypes.ChapterShow &&
      state
    );
  }
);

export const isFinalShow = createSelector(
  getChapterView,
  (state: ChapterViewState) =>
    state &&
    state.chapterId &&
    state.chapterId == "final" &&
    state.incompleteQuizzes &&
    state.incompleteQuizzes.length == 0 &&
    !state.timerError &&
    state.currentState !== ChapterViewActionTypes.FinalShow &&
    state
);
