<div>
  <a mat-tab-link disabled style="color: white;"
    *ngIf="showTimer && (currCourseTimerState$ | async).currentState === 'TimerRunning'">
    <span>Time remaining in Chapter:
      {{ timerString }}</span></a>

  <div class="fx-layout-row fx-layout-align-space-evenly-start"
    *ngIf="(currCourseTimerState$ | async).currentState === 'TimerIncomplete' && showTimerIncomplete">
    <div class="final fx-flex-auto" routeTransformer>
      <div class="row media">
        <div class="fx-layout-column fx-layout-align-space-around-center">

          <h5 style="padding-left: 10px;">Notice - Our 30 Hour Online Theory Course complies with all educational
            regulations put
            forth by the
            Dept of Education. This course will take a full 30 hours to complete. Please reread the previous
            material or wait until the timer expires, then you can proceed with the next section.</h5>

          <h5 style="color: red;" *ngIf="timerString">Time Remaining: {{ timerString }}</h5>
        </div>
      </div>
    </div>
  </div>
</div>
