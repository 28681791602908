import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { NGXLogger } from "ngx-logger";
import { SignInState } from "src/app/components/allusers/signin/signin.model";
import { getSignedInUser } from "src/app/components/allusers/signin/signin.selector";
import { Course } from "src/app/models/course.model";
import { MemberCourse } from "src/app/models/member-course.model";
import { User } from "src/app/models/user.model";
import { ChaptersService } from "src/app/services/chapters.service";
import { CoursesService } from "src/app/services/courses.service";
import { QuizesService } from "src/app/services/quizes.service";
import { RegmembersService } from "src/app/services/regmembers.service";
import { CourseState, CoursesActionTypes, UpdateAction } from "./courses.reducer";
import { DomSanitizer } from "@angular/platform-browser";
// import 'rxjs/Rx' ;
@Component({
  selector: "app-courses",
  templateUrl: "./courses.component.html",
  styleUrls: ["./courses.component.css"],
})
export class CoursesComponent implements OnInit {
  memberCourses: MemberCourse[];
  courses: Course[];

  getLoggedInUser$ = this.loginStore.pipe(select(getSignedInUser));
  currentUser: User = null;

  constructor(
    private loginStore: Store<SignInState>,
    private courseStore: Store<CourseState>,
    private logger: NGXLogger,
    private route: ActivatedRoute,
    private regmemService: RegmembersService,
    private coursesSerivce: CoursesService,
    private chapterService: ChaptersService,
    private quizesService: QuizesService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) { }

  async ngOnInit() {
    const membername = this.route.snapshot.paramMap.get("membername");
    if (membername) {
      this.currentUser = await this.regmemService.getUserBy(membername);
    } else {
      this.getLoggedInUser$.subscribe((user) => (this.currentUser = user));
    }

    this.memberCourses = await this.regmemService.getCoursesBy(this.currentUser.membername);
    this.courses = await this.coursesSerivce.findAll();
    this.courses = this.courses.filter(i => this.memberCourses.find(mc => mc.courseId == i.id && (this.currentUser.memberstatus === 'admin' || i.status == "active")));
    this.memberCourses = this.memberCourses.filter(mc => this.courses.find(c => c.id == mc.courseId));
    this.courseStore.dispatch(new UpdateAction({ currentState: CoursesActionTypes.UpdateCourse, course: null }));
  }

  findCourse(courseId) {
    return this.courses && this.courses.find(i => i.id == courseId);
  }

  sanitizeHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  async selectCourse(courseId) {
    const course: Course = await this.courses.find(i => i.id == courseId);    
    this.router.navigate([
      `courses`,
      courseId,
      "course-progress"
    ]);
  }

}
