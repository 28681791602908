<div class="row padding-bottom">
  <div class="row col s12" *ngIf="errorMessage">
    <span class="red-text">{{ errorMessage }}</span>
  </div>
  <div class="row" *ngIf="currentState === 'ForgotPasswordSubmitting'">
    <div class="row"></div>
    <div class="row"></div>
    <div class="row"></div>
    <div class="row"></div>
    <div class="row"></div>
    <div class="col s5"></div>
    <div class="col s7">
      <mat-spinner strokeWidth="1" diameter="25"></mat-spinner>
    </div>
  </div>
</div>

<div id="forgotPassword" class="row s12" *ngIf="currentState === 'ForgotPassword'">
  <p *ngIf="forgotPasswordUser && forgotPasswordUser.eaddress" class="green-text">Verification code was sent to email -
    {{ forgotPasswordUser.eaddress }}</p>
  <b class="grey-text">Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more
    characters</b>
  <form class="col s12" [formGroup]="forgotPasswordVerifyCodeForm" (ngSubmit)="forgotPasswordVerifyCode()">
    <div class="center forgotpwd-container">
      <mat-form-field>
        <mat-label>Verification Code</mat-label>
        <input matInput formControlName="verificationCode" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>New Password</mat-label>
        <input matInput formControlName="newPassword" type="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
          title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Re Enter New Password</mat-label>
        <input matInput formControlName="reNewPassword" type="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
          title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters">
      </mat-form-field>

      <div class="padding-card-button center ">
        <button class="waves-effect waves-light btn sign-in-btn button-width" type="submit"
          [disabled]="!forgotPasswordVerifyCodeForm.valid">Forgot Password</button>
      </div>
      <div class="center-align">
        <label class="center-align">
          <!-- <span class="white-text" (click)="changeUserPassword()">Change Password</span>/ -->
          <a><span class="grey-text" (click)="gotoSignIn()" style="font-size: 14px;">Sign In</span></a>
        </label>
      </div>
    </div>
  </form>
</div>
