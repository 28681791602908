import { Action } from "@ngrx/store";
import { SignInState } from "./signin.model";

export enum SignInActionTypes {
  SignedIn = "SignedIn",
  SigningIn = "SigningIn",
  SigningOut = "SigningOut",
  SignedOut = "SignedOut",
  SignInFailed = "SignInFailed",
  ForgotPassword = "ForgotPassword",
  ForgotPasswordSubmitting = "ForgotPasswordSubmitting",
  ForgotPasswordSubmitted = "ForgotPasswordSubmitted",
  ForgotPasswordFailed = "ForgotPasswordFailed",
  NewPasswordRequired = "NewPasswordRequired",
  NewPasswordRequiredSubmitting = "NewPasswordRequiredSubmitting",
  NewPasswordRequiredSubmitted = "NewPasswordRequiredSubmitted",
  NewPasswordRequiredFailed = "NewPasswordRequiredFailed"
}

export class SignedInAction implements Action {
  readonly type = SignInActionTypes.SignedIn;

  constructor(public payload: SignInState) {}
}

export class SigningInAction implements Action {
  readonly type = SignInActionTypes.SigningIn;

  constructor(public payload: SignInState) {}
}

export class SignInFailedAction implements Action {
  readonly type = SignInActionTypes.SignInFailed;

  constructor(public payload: SignInState) {}
}

export class SigningOutAction implements Action {
  readonly type = SignInActionTypes.SigningOut;

  constructor(public payload: SignInState) {}
}

export class SignedOutAction implements Action {
  readonly type = SignInActionTypes.SignedOut;
  constructor(public payload: SignInState) {}
}

export class NewPasswordRequiredAction implements Action {
  readonly type = SignInActionTypes.NewPasswordRequired;
  constructor(public payload: SignInState) {}
}

export class NewPasswordRequiredSubmitting implements Action {
  readonly type = SignInActionTypes.NewPasswordRequiredSubmitting;
  constructor(public payload: SignInState) {}
}

export class NewPasswordRequiredSubmitted implements Action {
  readonly type = SignInActionTypes.NewPasswordRequiredSubmitted;
  constructor(public payload: SignInState) {}
}

export class NewPasswordRequiredFailed implements Action {
  readonly type = SignInActionTypes.NewPasswordRequiredFailed;
  constructor(public payload: SignInState) {}
}

export class ForgotPasswordAction implements Action {
  readonly type = SignInActionTypes.ForgotPassword;
  constructor(public payload: SignInState) {}
}

export class ForgotPasswordSubmitting implements Action {
  readonly type = SignInActionTypes.ForgotPasswordSubmitting;
  constructor(public payload: SignInState) {}
}

export class ForgotPasswordSubmitted implements Action {
  readonly type = SignInActionTypes.ForgotPasswordSubmitted;
  constructor(public payload: SignInState) {}
}

export class ForgotPasswordFailed implements Action {
  readonly type = SignInActionTypes.ForgotPasswordFailed;
  constructor(public payload: SignInState) {}
}

export type SignInActions =
  | SignedInAction
  | SigningInAction
  | SignInFailedAction
  | SigningOutAction
  | SignedOutAction
  | ForgotPasswordAction
  | ForgotPasswordSubmitting
  | ForgotPasswordSubmitted
  | ForgotPasswordFailed
  | NewPasswordRequiredAction
  | NewPasswordRequiredSubmitted
  | NewPasswordRequiredSubmitting
  | NewPasswordRequiredFailed;
