<div class="fx-layout-row fx-layout-align-center-center">
  <div class="fx-flex-80pc">
    <div class="row">
      <h4>Courses for {{ member.fname }} {{ member.lname }}</h4>
    </div>
    <p-table #dt [columns]="cols" [value]="memberCourses" [paginator]="true" selectionMode="single" [rows]="rows"
      [totalRecords]="totalRecords" [loading]="loading" (onRowSelect)="onRowSelect($event)"
      [tableStyle]="{'table-layout':'auto'}" styleClass="p-datatable-gridlines">
      <!-- <ng-template pTemplate="caption">
        <div style="text-align: right">
          <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
          <input type="text" pInputText class="margin0"  size="50" placeholder="Global Filter"
            (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
        </div>
      </ng-template> -->
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
              ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order">
            </p-sortIcon>
          </th>
          <th></th>
        </tr>
        <tr>
          <th *ngFor="let col of columns" [ngSwitch]="col.field">
            <input *ngSwitchCase="'courseName'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')">
            <input *ngSwitchCase="'status'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')">
            <input *ngSwitchCase="'registeredDate'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')">
            <input *ngSwitchCase="'lastVisitedDate'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')">
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td *ngFor="let col of columns">
            <span
              *ngIf="col.field === 'registeredDate' || col.field === 'lastVisitedDate'">{{ rowData[col.field] | date: 'shortDate'  }}</span>
            <span
              *ngIf="col.field !== 'registeredDate' && col.field !== 'lastVisitedDate'">{{rowData[col.field]}}</span>
          </td>
          <td>
            <div class="ui-g-8 pad0">
              <button class="btn-small btn-border" (click)="gotoProgress(rowData['courseId'])">Student Progress</button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary" let-rowData>
        <div style="text-align:left">
          <button type="button" pButton (click)="showDialogToAdd()" label="Add"></button>
        </div>
      </ng-template>
    </p-table>

    <p-dialog header="Course Details" [(visible)]="displayDialog" [responsive]="true" showEffect="fade" [modal]="true"
      [style]="{width: '700px', height: '500px'}">
      <div class="red-text" *ngIf="errorMessage">{{ errorMessage }}</div>
      <div class="alert-success" *ngIf="successMessage">{{ successMessage }}</div>
      <div class="ui-g ui-fluid" *ngIf="memberCourse">
        <div class="ui-g-12">
          <div class="ui-g-4 pad0">
            <label for="memberId">Member Id*</label>
          </div>
          <div class="ui-g-8 pad0">
            <input pInputText class="margin0" id="memberId" [(ngModel)]="memberCourse.memberId" [disabled]="true"
              required />
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4 pad0">
            <label for="courseName">Course Name*</label>
          </div>
          <div class="ui-g-8 pad0">
            <p-dropdown class="margin0" [options]="coursesSelect" [(ngModel)]="memberCourse.courseId"></p-dropdown>
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4 pad0">
            <label for="status">Status*</label>
          </div>
          <div class="ui-g-8 pad0">
            <p-dropdown class="margin0" [options]="memberCourseStatuses" [(ngModel)]="memberCourse.status"></p-dropdown>
          </div>
        </div>
      </div>
      <p-footer>
        <div class="ui-dialog-buttonpane ui-helper-clearfix">
          <button type="button" pButton (click)="save()"
            [disabled]="!memberCourse || !memberCourse.memberId || !memberCourse.courseId || !memberCourse.status"
            label="Save"></button>
        </div>
      </p-footer>

    </p-dialog>
  </div>
</div>
