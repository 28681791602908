import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { PagesService } from "src/app/services/pages.service";

@Component({
  selector: "app-contactus",
  templateUrl: "./contactus.component.html",
  styleUrls: ["./contactus.component.css"]
})
export class ContactusComponent implements OnInit {
  contactUsContent;

  constructor(
    private pagesService: PagesService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.getPage();
  }

  getPage() {
    this.pagesService
      .getPage(3)
      .then(
        res =>
        (this.contactUsContent = this.sanitizer.bypassSecurityTrustHtml(
          res["PageContent"]
        ))
      );
  }
}
