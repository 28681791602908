<div id="signin" class="row padding-bottom">
  <div class="row col s12" *ngIf="errorMessage">
    <span class="red-text">{{ errorMessage }}</span>
  </div>
  <div class="row col s12" *ngIf="showForgotPasswordSuccess">
    <span class="green-text">Password changed successfully, try signing in again.</span>
  </div>
  <div class="row"
    *ngIf="currentState === 'SigningIn' || currentState === 'ForgotPasswordSubmitting' || currentState === 'NewPasswordRequiredSubmitting'">
    <div class=" row"></div>
    <div class="row"></div>
    <div class="row"></div>
    <div class="row"></div>
    <div class="row"></div>
    <div class="col s5"></div>
    <div class="col s7">
      <mat-spinner strokeWidth="1" diameter="25"></mat-spinner>
    </div>
  </div>

  <div class="row col s12" *ngIf="currentState === 'SignedOut' || currentState === 'SignInFailed'">
    <form class="col s12 signin-form" [formGroup]="userForm" (ngSubmit)="signin()">
      <div class="center signin-container">
        <mat-form-field class="singin-form-field">
          <mat-label>User Name</mat-label>
          <input matInput formControlName="username" type="text"/>
        </mat-form-field>

        <mat-form-field class="singin-form-field">
          <mat-label>Password</mat-label>
          <input matInput formControlName="password" type="password">
        </mat-form-field>

        <div class="padding-card-button center">
          <button class="waves-effect waves-light btn sign-in-btn button-width" type="submit"
            [disabled]="!userForm.valid">sign in</button>
        </div>
        <div class="center-align">
          <label class="center-align">
            <!-- <span class="white-text" (click)="changeUserPassword()">Change Password</span>/ -->
            <a><span class="grey-text" (click)="forgotUserPassword()" style="font-size: 14px;">forgot
                Password</span></a>
          </label>
        </div>
      </div>
    </form>
  </div>

  <div class="row col s12" id="forgotPassword" *ngIf="currentState === 'ForgotPassword'">
    <app-forgot-password></app-forgot-password>
  </div>

  <div class="row col s12" id="newPasswordRequired" *ngIf="currentState === 'NewPasswordRequired'">
    <app-new-password-required></app-new-password-required>
  </div>
</div>