<h2 mat-dialog-title>ENROLLMENT AGREEMENT</h2>
<mat-dialog-content>
    <p>This website (the "website") contains driver's education material that is intended to be used by individuals who are at least 15 years of age or older.  This website requires that you read and confirm your acceptance of the following terms and conditions before continuing any further:</p>
    <ol>
      <li>I am legally 15 years of age or older;</li>
      <li>I, or a consenting adult 18 years of age or older, has agreed to pay all monetary charges associated with my use of this website that I may incur.  These charges include the cost of the driver's education program, which is $125, including all applicable taxes, and $15.00 for any replacement or additional certificates, if required.  It is understood and agreed that a $35 non-refundable charge will be incurred once an individual agrees to enroll in the online course offered by this website (the "Attendee");</li>
      <li>This website holds the attendee responsible for completing all requirements necessary to obtain the completion certificate.  Edward Savioz LLC does not represent that the courses and materials offered on this website will comply with any specific requirements of the Attendee's auto insurance carrier;</li>
      <li>I will not copy or distribute any of the materials or content of this website, including, but not limited to the driver's education actions depicted in text, pictures, illustrations or online movie clips, nor will I permit anyone else who may gain access to the website through me to do so;</li>
      <li>I agree to release, hold harmless, and indemnify Edward Savioz LLC, its officers, directors, shareholders, employees, and agents from and against any claims, liability, losses, costs, damages or expenses (including attorneys' fees) arising from my use of, or participation in the online classroom and/or other content provided by this website;</li>
      <li>If you agree with all the terms and conditions set forth in 1 through 5 above, please check the box.  If you disagree with any or all of these terms and conditions set forth in 1 through 5 above, please exit the site now.</li>
    </ol>      
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>