import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { filter } from "rxjs/operators";
import { User } from "src/app/models/user.model";
import { AffiliatesService } from "src/app/services/affiliates.service";
import { AuthService } from "src/app/services/auth-service/auth.service";
import { CertificatesService } from "src/app/services/certificates.service";
import { ProgressService } from "src/app/services/progress.service";
import { RegmembersService } from "src/app/services/regmembers.service";
import { SignInState } from "../../allusers/signin/signin.model";
import { getSignedInUser } from "../../allusers/signin/signin.selector";

@Component({
  selector: "app-search-student",
  templateUrl: "./search-student.component.html",
  styleUrls: ["./search-student.component.css"],
})
export class SearchStudentComponent implements OnInit {
  constructor(
    private regmembersService: RegmembersService,
    private affiliatesService: AffiliatesService,
    private progressService: ProgressService,
    private certificateService: CertificatesService,
    private authService: AuthService,
    private signInStore: Store<SignInState>,
    private router: Router
  ) { }

  first = 0;
  rows = 15;
  sortField;
  sortOrder;
  filters;
  totalRecords = 0;

  displayDialog: boolean;

  member: User = {} as User;
  memberinfo = {
    areaunit: "",
    lastworkeddate: "",
    certificate: "",
    certificateattempt: "",
  };

  selectedRegmem: User;

  newRegmem: boolean;

  members: User[];

  cols: any[];

  dateFilters: any;

  userform = new FormGroup({
    membername: new FormControl("", [Validators.required, Validators.pattern("[a-z0-9]+")]),
    memberstatus: new FormControl("", Validators.required),
    fname: new FormControl("", Validators.required),
    lname: new FormControl(""),
    eaddress: new FormControl("", Validators.required),
    phone1: new FormControl("", Validators.required),
  });
  loading = true;

  currentUser$ = this.signInStore.pipe(
    select(getSignedInUser),
    filter((val) => (val ? true : false))
  );
  currentUser: User;
  errorMessage;
  successMessage;

  ngOnInit() {
    this.loadRegmems();

    this.cols = [
      { field: "membername", header: "User Name" },
      { field: "fname", header: "First Name" },
      { field: "lname", header: "Last Name" },
      { field: "memberstatus", header: "Status" },
      { field: "eaddress", header: "Email" },
      { field: "phone1", header: "Phone" },
      { field: "admin_note", header: "Note" },
      { field: "dateregistered", header: "Registered" },
      { field: "createdByName", header: "Created By" },
    ];
    this.currentUser$.subscribe((user) => (this.currentUser = user));
  }

  loadRegmems() {
    this.regmembersService
      .getUsersBy(
        this.first,
        this.rows,
        this.sortField,
        this.sortOrder,
        this.filters
      )
      .then((membersAndCount) => {
        this.members = membersAndCount[0];
        this.totalRecords = membersAndCount[1];
        this.loadCreatedBy();
      });
  }

  async loadCreatedBy() {
    const createdBys = {};
    for (const item of this.members) {
      if (item.createdby) {
        if (createdBys[item.createdby]) {
          item["createdByName"] = createdBys[item.createdby];
        } else {
          if (parseInt(item.createdby)) {
            const user = await this.regmembersService.getUserBy(
              item.membername
            );
            createdBys[`${user.memberid}`] = user.membername;
            item["createdByName"] = user.membername;
          }
        }
      }
    }
  }

  // async loadMemberInfo() {
  //   if (this.member.sourceaffiliateid) {
  //     const affiliates = await this.affiliatesService.getAffiliates();
  //     const aff = affiliates.find(
  //       (item) => item.affiliateid == parseInt(this.member.sourceaffiliateid)
  //     );
  //     this.memberinfo.areaunit = aff ? aff.company : "";
  //   }

  //   if (this.member.memberid) {
  //     const progresses = await this.progressService.getProgressBy(
  //       this.member.memberid
  //     );
  //     if (progresses) {
  //       const sortedProgresses = progresses.sort((a, b) => {
  //         return new Date(a.DTStamp).getTime() <= new Date(b.DTStamp).getTime()
  //           ? 1
  //           : -1;
  //       });

  //       if (sortedProgresses && sortedProgresses.length > 0) {
  //         this.memberinfo.lastworkeddate = new Date(
  //           sortedProgresses[0].DTStamp
  //         ).toLocaleDateString();

  //         const finalProgress = sortedProgresses.find(
  //           (item) => item.QuizID == 44 && item.Score > 60
  //         );
  //         if (finalProgress) {
  //           this.memberinfo.certificate = `${
  //             finalProgress.Score
  //           }% on ${new Date(finalProgress.DTStamp).toLocaleDateString()}`;
  //         } else {
  //           this.memberinfo.certificate = `Not yet issued`;
  //         }
  //       }
  //     }

  //     const certificate = await this.certificateService.getCertificateById(
  //       this.member.memberid,
  //       true
  //     );
  //     if (certificate) {
  //       this.memberinfo.certificateattempt = new Date(
  //         certificate.DTStamp
  //       ).toLocaleDateString();
  //     }
  //   }
  // }

  showDialogToAdd() {
    this.newRegmem = true;
    this.displayDialog = true;
    this.clearAll();
    this.setMemberDefaults();
  }

  save() {
    let p: Promise<any>;
    if (this.member && this.member["createdByName"]) {
      delete this.member["createdByName"];
    }
    if (this.newRegmem) {
      p = this.regmembersService.createRegmem(this.member);
    } else {
      p = this.regmembersService.updateRegmem(
        this.member.membername,
        this.member
      );
    }

    return p
      .then(() => {
        this.loadRegmems();
        this.clearAll();
        this.displayDialog = false;
      })
      .catch((err) => {
        if (
          err &&
          err.status === 400 &&
          err.error &&
          err.error.error &&
          err.error.error.description
        ) {
          this.errorMessage = err.error.error.description;
        }
      });
  }

  setMemberDefaults() {
    this.member.memberstatus = "paid";
    this.member.dateregistered = new Date();
    this.member.createdby = this.currentUser.memberid.toString();
  }

  delete() {
    this.regmembersService.deleteRegmem(this.member.membername).then(() => {
      this.loadRegmems();
      this.clearAll();
      this.displayDialog = false;
    });
  }

  onRowSelect(event) {
    this.newRegmem = false;
    this.clearAll();
    this.member = this.cloneRegmem(event.data);
    this.displayDialog = true;
    // this.loadMemberInfo();
  }

  clearAll() {
    this.member = {} as User;
    this.memberinfo = {
      areaunit: "",
      certificate: "",
      certificateattempt: "",
      lastworkeddate: "",
    };
    this.errorMessage = null;
    this.successMessage = null;
  }

  cloneRegmem(s: User): User {
    const member = {} as User;
    for (const prop in s) {
      member[prop] = s[prop];
    }
    return member;
  }

  loadRegmemLazy(event) {
    this.loading = true;
    if (event.filters.dateregistered) {
      event.filters.dateregistered.value = this.dateFilters;
    }
    if (event.sortField == "schoolName") {
      event.sortField = "sourceschool";
    }
    if (event.sortField == "createdByName") {
      event.sortField = "createdby";
    }

    this.regmembersService
      .getUsersBy(
        event.first,
        event.rows,
        event.sortField,
        event.sortOrder === 1 ? "ASC" : "DESC",
        JSON.stringify(event.filters)
      )
      .then((membersAndCount) => {
        this.members = membersAndCount[0];
        this.totalRecords = membersAndCount[1];
        this.loadCreatedBy();
      })
      .finally(() => (this.loading = false));
  }

  resetPassword() {
    this.regmembersService
      .resetPassword(this.member)
      .then(() => {
        this.successMessage = "Reset Password Successful !!";
      })
      .catch((err) => {
        if (
          err &&
          err.status === 400 &&
          err.error &&
          err.error.error &&
          err.error.error.description
        ) {
          this.errorMessage = err.error.error.description;
        }
      });
  }

  _dataTable = null;
  exportCSV(dataTable) {
    this._dataTable = dataTable;
    this.regmembersService
      .getUsersBy(
        0,
        1000,
        dataTable.sortField,
        dataTable.sortOrder === 1 ? "ASC" : "DESC",
        JSON.stringify(dataTable.filters)
      )
      .then((membersAndCount) => {
        this.members = membersAndCount[0];
        this.totalRecords = membersAndCount[1];
        this._dataTable.value = this.members;
        this._dataTable.exportCSV();
      })
      .finally(() => (this.loading = false));
  }
}
