import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NGXLogger } from "ngx-logger";
import { FileSelectEvent, FileUpload } from "primeng/fileupload";
import { Table } from "primeng/table";
import { Asset } from "src/app/models/assets.model";
import { Course } from "src/app/models/course.model";
import { AssetsService } from "src/app/services/assets.service";


@Component({
  selector: "app-assets-list",
  templateUrl: "./assets-list.component.html",
  styleUrls: ["./assets-list.component.css"],
})
export class AssetsListComponent implements OnInit {
  @ViewChild("dt") private dt: Table;
  @ViewChild('fileUpload', { static: false }) fileUpload: FileUpload;

  first = 0;
  rows = 15;
  sortField;
  sortOrder;
  filters;
  totalRecords = 0;

  displayDialog: boolean;

  selectedAsset: Asset;

  newAsset: boolean;

  assetTypes = [
    { label: "Image", value: "image" },
    { label: "Video", value: "video" },
    { label: "PDF", value: "pdf" },
    { label: "Powerpoint", value: "pptx" },
  ];
  asset: Asset;
  assets: Asset[] = [];

  cols: any[];

  successMessage;
  errorMessage;

  dateFilters: any;

  assetForm = new FormGroup({
    assetName: new FormControl("", Validators.required),
    type: new FormControl("", Validators.required),
    data: new FormControl(""),
  });

  loading = true;
  selectedFile: File;

  constructor(
    private logger: NGXLogger,
    private assetsService: AssetsService,
    public router: Router,
    private activateRoute: ActivatedRoute
  ) { }

  async loadAssets() {
    this.assets = await this.assetsService.findAll();

    this.totalRecords = this.assets.length;
    this.loading = false;
  }

  ngOnInit() {
    this.cols = [
      { field: "id", header: "ID" },
      { field: "assetName", header: "Asset Name" },
      { field: "contentType", header: "Content Type" },
      { field: "createdDatetime", header: "Created Time" },
    ];

    this.loadAssets();
  }

  showDialogToAdd() {
    this.newAsset = true;
    this.displayDialog = true;
    this.clearAll();
  }

  async save() {
    if (this.selectedFile) {
      await this.assetsService.putSignedUrl(this.asset.signedUrl, this.selectedFile);
    }
    let p: Promise<any>;
    if (this.newAsset) {
      p = this.assetsService.create(this.asset);
    } else {
      p = this.assetsService.update(this.asset);
    }

    return p
      .then((asset) => {
        this.loadAssets();
        this.asset = asset;
        this.successMessage = "Asset saved successfully";
      })
      .catch((err) => {
        if (
          err &&
          err.status === 400 &&
          err.error &&
          err.error.error &&
          err.error.error.description
        ) {
          this.errorMessage = err.error.error.description;
        }
      });
  }

  onRowSelect(event) {
    this.newAsset = false;
    this.clearAll();
    this.asset = Object.assign({} as Course, event.data);
    this.loadAssets();
    this.displayDialog = true;
  }

  clearAll() {
    this.asset = {} as Asset;
    this.errorMessage = null;
    this.successMessage = null;
    this.selectedFile = null;
    if (this.fileUpload) {
      this.fileUpload.clear();
    }
  }

  async getSignedUrl() {
    this.loading = true;
    this.asset = await this.assetsService.getSignedUrl(this.asset);
    this.loading = false;
  }

  async onSelect(event: FileSelectEvent) {
    this.selectedFile = event.currentFiles[0];
    this.asset.contentType = event.currentFiles[0].type;
    await this.getSignedUrl();
  }

  handleClose() {
    this.displayDialog = false;
    this.clearAll();
  }

  async delete() {
    try {
      this.errorMessage = null;
      await this.assetsService.delete(this.asset.id);
      this.successMessage = "Asset deleted successfully";
      this.asset = {} as Asset;
      await this.loadAssets();
    } catch (err) {
      console.error(err);
      this.errorMessage = err;
    }
  }

  onChangeAssetType(event) {
    this.asset.assetType = event.value;
  }
}
