import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CourseState, CoursesActionTypes } from "./courses.reducer";
import { getRouterSelectors } from '@ngrx/router-store';

// `router` is used as the default feature name. You can use the feature name
// of your choice by creating a feature selector and pass it to the `getRouterSelectors` function
// export const selectRouter = createFeatureSelector<RouterReducerState>('yourFeatureName');

export const {
  selectRouteParams, // select the current route params
} = getRouterSelectors();


export const getCourse = createFeatureSelector<CourseState>("course");

export const isCourseChanging = createSelector(
  getCourse,
  selectRouteParams,
  (courseState: CourseState, routeParams) =>
    courseState &&
    courseState.course &&
    courseState.course.id !== routeParams["courseId"] &&
    courseState
);

export const isCourseSelected = createSelector(
  getCourse,
  (courseState: CourseState) =>
    courseState &&
    courseState.currentState &&
    courseState.currentState === CoursesActionTypes.UpdateCourse &&
    courseState.course &&
    courseState.course.id !== 0 
); 

export const getCourseSelected = createSelector(
  getCourse,
  isCourseSelected,
  (courseState: CourseState, isSelected: boolean) => isSelected ? courseState.course : null
);

export const isCourseNotSelected = createSelector(
  getCourse,
  (courseState: CourseState) =>
    courseState &&
    courseState.currentState &&
    courseState.currentState === CoursesActionTypes.NoCourse
);

