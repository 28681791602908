import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NGXLogger } from "ngx-logger";
import { Table } from "primeng/table";
import { AnswerOptions } from "src/app/models/answer-options.model";
import { Question } from "src/app/models/question.model";
import { Quiz } from "src/app/models/quiz.model";
import { AnswerOptionsService } from "src/app/services/answer-options.service";
import { QuestionsService } from "src/app/services/questions.service";
import { QuizesService } from "src/app/services/quizes.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-answeroptions-list",
  templateUrl: "./answer-options-list.component.html",
  styleUrls: ["./answer-options-list.component.css"],
})
export class AnswerOptionsListComponent implements OnInit {
  @ViewChild("dt") private dt: Table;

  first = 0;
  rows = 15;
  sortField;
  sortOrder;
  filters;
  totalRecords = 0;

  displayDialog: boolean;

  quiz: Quiz;
  question: Question;

  selectedAnswerOption: AnswerOptions;

  newAnswerOption: boolean;

  answeroptions: AnswerOptions[];
  answeroption: AnswerOptions;
  correctOptions = [
    { label: "No", value: "no" },
    { label: "Yes", value: "yes" },
  ];

  answerTypeOptions = [
    { label: "Text", value: "TEXT" },
    { label: "Image", value: "IMAGE" },
  ];

  cols: any[];

  errorMessage;

  answeroptionForm = new FormGroup({
    AnswerOptionText: new FormControl("", Validators.required),
    answeroptiontype: new FormControl("", Validators.required),
  });

  loading = true;
  courseId: number;

  constructor(
    private logger: NGXLogger,
    private answerOptionsService: AnswerOptionsService,
    private quizesService: QuizesService,
    private questionsService: QuestionsService,
    public router: Router,
    private activateRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activateRoute.params.subscribe(async (params) => {
      if (params["courseId"]) {
        this.courseId = parseInt(params["courseId"]);
      }
      this.quiz = await this.quizesService.findByCourseIdAndQuizId(this.courseId, params["quizId"]);
      this.question = await this.questionsService.findBy(params["questionId"]);
      this.loadAnswerOptions();
    });
    this.cols = [
      { field: "AnswerText", header: "AnswerText" },
      { field: "Correct", header: "Correct" },
      { field: "AnswerType", header: "Type" },
    ];
  }

  async loadAnswerOptions() {
    if (!this.question) {
      return;
    }

    this.answeroptions = await this.answerOptionsService.findAnswerOptionsForQuestion(
      this.question.QuestionID
    );

    this.totalRecords = this.answeroptions.length;
    this.loading = false;
  }

  showDialogToAdd() {
    this.newAnswerOption = true;
    this.displayDialog = true;
    this.clearAll();
    this.answeroption = {
      QuestionID: this.question.QuestionID,
    } as AnswerOptions;
  }

  save() {
    let p: Promise<any>;
    if (this.answeroption.Correct == "no") {
      this.answeroption.Correct = null;
    }

    if (this.newAnswerOption) {
      p = this.answerOptionsService.createAnswerOptions(this.answeroption);
    } else {
      p = this.answerOptionsService.updateAnswerOptions(
        this.answeroption.AnswerOptID,
        this.answeroption
      );
    }

    return p
      .then(() => {
        this.loadAnswerOptions();
        this.clearAll();
        this.displayDialog = false;
      })
      .catch((err) => {
        if (
          err &&
          err.status === 400 &&
          err.error &&
          err.error.error &&
          err.error.error.description
        ) {
          this.errorMessage = err.error.error.description;
        }
      });
  }

  onRowSelect(event) {
    this.newAnswerOption = false;
    this.clearAll();
    this.answeroption = Object.assign({} as AnswerOptions, event.data);
    this.answeroption.Correct =
      this.answeroption.Correct == "yes" ? "yes" : "no";
    this.loadAnswerOptions();
    this.displayDialog = true;
  }

  clearAll() {
    this.answeroption = {} as AnswerOptions;
    this.errorMessage = null;
  }

  onChangeCorrect(event) {
    this.answeroption.Correct = event.value;
  }

  onChangeAnswerType(event) {
    this.answeroption.AnswerType = event.value;
  }

  delete() {
    let c = confirm("Are you sure you want to delete this answer option ?");
    if (c) {
      this.answerOptionsService
        .deleteAnswerOptions(this.answeroption.AnswerOptID)
        .then(() => {
          this.loadAnswerOptions();
          this.clearAll();
          this.displayDialog = false;
        });
    }
  }

  getImage(path) {
    return `${environment.s3BucketUrl}assets${path}`;
  }
}
