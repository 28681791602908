import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Page } from "src/app/models/pages.model";
import { PagesService } from "../../../services/pages.service";

@Component({
  selector: "app-edit-page",
  templateUrl: "./edit-page.component.html",
  styleUrls: ["./edit-page.component.css"],
})
export class EditPageComponent implements OnInit {
  page: Page;
  editPage: FormGroup;
  mycontent: string;
  log: string = "";

  constructor(
    private route: ActivatedRoute,
    private pagesService: PagesService,
    private routerNav: Router,
    private location: Location,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.editPage = this.formBuilder.group({
      ID: ["", Validators.required],
      PageName: ["", Validators.required],
      PageContent: ["", Validators.required],
    });

    this.route.queryParams.subscribe((params) => {
      this.getPage(params["pageId"]);
    });
  }

  async getPage(id) {
    try {
      this.page = await this.pagesService.getPage(id);
      this.editPage.patchValue(this.page);

    } catch (err) { }
  }

  onSubmit() {
    this.pagesService.updatePage(this.page.ID, { ...this.page, PageContent: this.editPage.toString() }).then((res) => {
      this.routerNav.navigate([`/admin/page-list`]);
    });
  }

  goBack() {
    this.location.back();
  }
}
