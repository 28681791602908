import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SignInActionTypes } from "./signin.actions";
import { SignInState } from "./signin.model";

export const getSignIn = createFeatureSelector<SignInState>("signIn");

export const getSignInCurrentState = createSelector(
  getSignIn,
  (signInState: SignInState) =>
    signInState &&
    signInState.currentState &&
    signInState.currentState.toString()
);

export const isForgotPassword = createSelector(
  getSignIn,
  (signInState: SignInState) =>
    signInState &&
    signInState.currentState === SignInActionTypes.ForgotPassword &&
    signInState
);

export const isForgotPasswordSubmitting = createSelector(
  getSignIn,
  (signInState: SignInState) =>
    signInState &&
    signInState.currentState === SignInActionTypes.ForgotPasswordSubmitting
);

export const isForgotPasswordSubmitted = createSelector(
  getSignIn,
  (signInState: SignInState) =>
    signInState &&
    signInState.currentState === SignInActionTypes.ForgotPasswordSubmitted
);

export const isForgotPasswordFailed = createSelector(
  getSignIn,
  (signInState: SignInState) =>
    signInState &&
    signInState.currentState === SignInActionTypes.ForgotPasswordFailed &&
    signInState
);

export const isNewPasswordRequired = createSelector(
  getSignIn,
  (signInState: SignInState) =>
    signInState &&
    signInState.currentState === SignInActionTypes.NewPasswordRequired &&
    signInState
);

export const isNewPasswordRequiredSubmitting = createSelector(
  getSignIn,
  (signInState: SignInState) =>
    signInState &&
    signInState.currentState === SignInActionTypes.NewPasswordRequiredSubmitting
);

export const isNewPasswordRequiredSubmitted = createSelector(
  getSignIn,
  (signInState: SignInState) =>
    signInState &&
    signInState.currentState === SignInActionTypes.NewPasswordRequiredSubmitted
);

export const isNewPasswordRequiredFailed = createSelector(
  getSignIn,
  (signInState: SignInState) =>
    signInState &&
    signInState.currentState === SignInActionTypes.NewPasswordRequiredFailed &&
    signInState
);

export const isSignedIn = createSelector(
  getSignIn,
  (signInState: SignInState) =>
    signInState && signInState.currentState === SignInActionTypes.SignedIn
);

export const isSigningIn = createSelector(
  getSignIn,
  (signInState: SignInState) =>
    signInState && signInState.currentState === SignInActionTypes.SigningIn
);

export const isSignedOut = createSelector(
  getSignIn,
  (signInState: SignInState) =>
    signInState &&
    signInState.currentState !== SignInActionTypes.SignedIn &&
    signInState.currentState !== SignInActionTypes.SigningIn
);

export const isSignInFailure = createSelector(
  getSignIn,
  (signInState: SignInState) =>
    signInState &&
    signInState.currentState === SignInActionTypes.SignInFailed &&
    signInState
);

export const getSignedInUser = createSelector(
  getSignIn,
  isSignedIn,
  (signInState: SignInState, loggedIn: boolean) => loggedIn && signInState.user
);
