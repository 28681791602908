import {
  CourseTimerActions,
  CourseTimerActionTypes,
} from "./course-timer.actions";
import { CourseTimerState } from "./course-timer.model";

const initialState: CourseTimerState = { currentState: CourseTimerActionTypes.TimerStopped } as CourseTimerState;

export function courseTimerReducer(
  state = initialState,
  action: CourseTimerActions
): CourseTimerState {
  switch (action.type) {
    case CourseTimerActionTypes.TimerStart:
      return {
        ...state,
        ...action.payload,
        currentState: CourseTimerActionTypes.TimerStart
      } as CourseTimerState;
    case CourseTimerActionTypes.TimerStopped:
      return {
        ...state,
        ...action.payload,
        currentState: CourseTimerActionTypes.TimerStopped
      };
    case CourseTimerActionTypes.TimerRunning:
      return {
        ...state,
        ...action.payload,
        currentState: CourseTimerActionTypes.TimerRunning
      };
    case CourseTimerActionTypes.TimerCompleted:
      return {
        ...state,
        ...action.payload,
        currentState: CourseTimerActionTypes.TimerCompleted
      };
    case CourseTimerActionTypes.TimerIncomplete:
      return {
        ...state,
        ...action.payload,
        currentState: CourseTimerActionTypes.TimerIncomplete
      };
    default:
      return state;
  }
}
