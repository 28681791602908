<div class="fx-layout-row fx-layout-align-center-center">
  <div class="fx-flex-80pc">
    <p-table [columns]="cols" [value]="affiliates" selectionMode="single" [(selection)]="selectedAffiliate"
      (onRowSelect)="onRowSelect($event)" [paginator]="true" [rows]="15">
      <ng-template pTemplate="caption">
        List of Area Units
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td *ngFor="let col of columns">
            <a [routerLink]="['/admin/school-manager']" [queryParams]="{areaUnitId: rowData['affiliateid']}"
              *ngIf="col.field === 'schoolLength'">{{rowData[col.field]}}</a>
            <span *ngIf="col.field !== 'schoolLength'">{{rowData[col.field]}}</span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary" let-rowData>
        <div style="text-align:left">
          <button type="button" pButton (click)="showDialogToAdd()" label="Add"></button>
        </div>
      </ng-template>
    </p-table>

    <p-dialog header="Hear About Details" [(visible)]="displayDialog" [responsive]="true" showEffect="fade"
      [modal]="true" [style]="{width: '300px'}">
      <div class="ui-g ui-fluid" *ngIf="affiliate">
        <div class="ui-g-12">
          <div class="ui-g-4">
            <label for="company">Company</label>
          </div>
          <div class="ui-g-8">
            <input pInputText id="affiliateid" [(ngModel)]="affiliate.company" />
          </div>
        </div>
      </div>
      <p-footer>
        <div class="ui-dialog-buttonpane ui-helper-clearfix">
          <button type="button" pButton (click)="delete()" label="Delete"></button>
          <button type="button" pButton (click)="save()" label="Save"></button>
        </div>
      </p-footer>
    </p-dialog>
  </div>
</div>
