import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Chapter } from "src/app/models/chapters.model";
import { ChaptersService } from "../../../services/chapters.service";

@Component({
  selector: "app-edit-chapter-content",
  templateUrl: "./edit-chapter-content.component.html",
  styleUrls: ["./edit-chapter-content.component.css"],
})
export class EditChapterContentComponent implements OnInit {
  chapter: Chapter;
  courseId: number;

  constructor(
    private activedRoute: ActivatedRoute,
    private chaptersService: ChaptersService,
    private routerNav: Router,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.activedRoute.params.subscribe((params) => {
      this.courseId = params["courseId"];
    });

    this.activedRoute.queryParams.subscribe(async (params) => {
      this.chapter = await this.chaptersService.getChapterBy(this.courseId, params.chapterId);
    });
  }

  async onSubmit() {
    const createdChapter = await this.chaptersService.createChapter(this.chapter);
    this.routerNav.navigate([`/admin`, `courses`, this.courseId, `content-list`]);
  }

  goBack() {
    this.routerNav.navigate([`/admin`, `courses`, this.courseId, `content-list`]);
  }
}
