import {
  PaymentActions,
  PaymentActionTypes
} from "./payment.actions";
import { PaymentState } from "./payment.model";

const initialState: PaymentState = { currentState: PaymentActionTypes.PaymentLoading } as PaymentState;

export function paymentReducer(
  state = initialState,
  action: PaymentActions
): PaymentState {
  switch (action.type) {
    case PaymentActionTypes.PaymentLoading:
    case PaymentActionTypes.PaymentShow:
    case PaymentActionTypes.PaymentSubmitting:
    case PaymentActionTypes.PaymentSuccessful:
    case PaymentActionTypes.PaymentFailed:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
