import { QuizTestsActions, QuizTestsActionTypes } from "./quiz-tests.actions";
import { QuizTestsState } from "./quiz-tests.model";

const initialState: QuizTestsState = {} as QuizTestsState;

export function quizTestsReducer(
  state = initialState,
  action: QuizTestsActions
): QuizTestsState {
  switch (action.type) {
    case QuizTestsActionTypes.Initialization:
      return { ...state, ...action.payload };
    case QuizTestsActionTypes.QuizShow:
      return { ...state, ...action.payload };
    case QuizTestsActionTypes.QuizSkipped:
      return { ...state, ...action.payload };
    case QuizTestsActionTypes.QuizLoaded:
      return { ...state, ...action.payload };
    case QuizTestsActionTypes.QuizSubmitting:
      return { ...state, ...action.payload };
    case QuizTestsActionTypes.QuizSubmitted:
      return { ...state, ...action.payload };
    case QuizTestsActionTypes.Failure:
      return { ...state, ...action.payload };
    case QuizTestsActionTypes.AudioShow:
      return { ...state, ...action.payload };
    case QuizTestsActionTypes.VideoShow:
      return { ...state, ...action.payload };
    case QuizTestsActionTypes.AssetShow:
      return { ...state, ...action.payload };
    case QuizTestsActionTypes.FinalQuizShow:
      return { ...state, ...action.payload };
    case QuizTestsActionTypes.FinalQuizLoaded:
      return { ...state, ...action.payload };
    case QuizTestsActionTypes.FinalQuizSubmitting:
      return { ...state, ...action.payload };
    case QuizTestsActionTypes.FinalQuizSubmitted:
      return { ...state, ...action.payload };
    case QuizTestsActionTypes.FinalQuizAlreadyDone:
      return { ...state, ...action.payload };
    case QuizTestsActionTypes.FinalNotAllowedQuizIncomplete:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
