import { Component, OnInit } from "@angular/core";
import { Affiliate } from "src/app/models/affiliate.model";
import { AffiliatesService } from "src/app/services/affiliates.service";

@Component({
  selector: "app-area-unit",
  templateUrl: "./area-unit.component.html",
  styleUrls: ["./area-unit.component.css"]
})
export class AreaUnitComponent implements OnInit {
  displayDialog: boolean;

  affiliate: Affiliate = {} as Affiliate;

  selectedAffiliate: Affiliate;

  newAffiliate: boolean;

  affiliates: Affiliate[];

  cols: any[];

  constructor(private affiliateService: AffiliatesService) {}

  ngOnInit() {
    this.loadAffiliates();
    this.cols = [
      { field: "company", header: "Company" },
      { field: "schoolLength", header: "Associated Schools" }
    ];
  }

  private loadAffiliates() {
    this.affiliateService.getAffiliates().then(affiliates => {
      this.affiliates = affiliates;
      this.affiliates.forEach(
        item => (item["schoolLength"] = item.schools ? item.schools.length : 0)
      );
    });
  }

  showDialogToAdd() {
    this.newAffiliate = true;
    this.affiliate = {} as Affiliate;
    this.displayDialog = true;
  }

  save() {
    let p: Promise<any>;
    if (this.newAffiliate) {
      p = this.affiliateService.createAffiliate(this.affiliate);
    } else {
      p = this.affiliateService.updateAffiliate(
        this.affiliate,
        this.affiliate.affiliateid
      );
    }

    return p.then(() => {
      this.loadAffiliates();
      this.affiliate = null;
      this.displayDialog = false;
    });
  }

  delete() {
    this.affiliateService
      .deleteAffiliate(this.selectedAffiliate.affiliateid)
      .then(() => {
        this.loadAffiliates();
        this.affiliate = null;
        this.displayDialog = false;
      });
  }

  onRowSelect(event) {
    this.newAffiliate = false;
    this.affiliate = this.cloneAffiliate(event.data);
    this.displayDialog = true;
  }

  cloneAffiliate(h: Affiliate): Affiliate {
    const affiliate = {} as Affiliate;
    for (const prop in h) {
      affiliate[prop] = h[prop];
    }
    return affiliate;
  }
}
