import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom, Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { StripeCheckoutSession } from "@stripe/stripe-js";

@Injectable({
  providedIn: "root"
})
export class PaymentsService {
  constructor(private http: HttpClient) { }

  async getPaymentIntent(courseId: number, membername: string) {
    return await lastValueFrom(this.http
      .get(`${environment.apiUrl}/payments/intent/courses/${courseId}/regmembers/${membername}`));
  }

  async createCheckoutSession(courseId: string, membername: string) {
    return await lastValueFrom(this.http.post<any>(`${environment.apiUrl}/payments/checkoutSession/courses/${courseId}/regmembers/${membername}`, {
      courseId,
      membername
    }));
  }

}
