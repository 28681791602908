import { Action } from "@ngrx/store";
import { CourseTimerState } from "./course-timer.model";

export enum CourseTimerActionTypes {
  TimerStart = "TimerStart",
  TimerStopped = "TimerStopped",
  TimerRunning = "TimerRunning",
  TimerCompleted = "TimerCompleted",
  TimerIncomplete = "TimerIncomplete",
}

export class TimerCompletedAction implements Action {
  readonly type = CourseTimerActionTypes.TimerCompleted;
  constructor(public payload: CourseTimerState) { }
}

export class TimerStartAction implements Action {
  readonly type = CourseTimerActionTypes.TimerStart;
  constructor(public payload: CourseTimerState) { }
}

export class TimerRunningAction implements Action {
  readonly type = CourseTimerActionTypes.TimerRunning;
  constructor(public payload: CourseTimerState) { }
}

export class TimerStoppedAction implements Action {
  readonly type = CourseTimerActionTypes.TimerStopped;
  constructor(public payload: CourseTimerState) { }
}

export class TimerIncompleteAction implements Action {
  readonly type = CourseTimerActionTypes.TimerIncomplete;
  constructor(public payload: CourseTimerState) { }
}

export type CourseTimerActions =
  | TimerStartAction
  | TimerStoppedAction
  | TimerCompletedAction
  | TimerRunningAction
  | TimerIncompleteAction;
