import { Component, OnInit } from '@angular/core';
import { BannerService } from '../../../services/banner.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-banner-list',
  templateUrl: './banner-list.component.html',
  styleUrls: ['./banner-list.component.css']
})
export class BannerListComponent implements OnInit {

  banners;
  loading: Boolean = true;

  constructor(private bannerService: BannerService,
    private route: ActivatedRoute,
    private routerNav: Router) { }

  ngOnInit() {
    this.listAllBanners()
  }

  listAllBanners() {
    this.bannerService.getBanners()
      .then(res => {
        this.banners = res;
        this.loading = false;
      })
  }

  goToBannerEdit(banner) {
    let navigationExtras: NavigationExtras = {
      queryParams: { 'bannerId': banner.Id },
      // fragment: 'anchor'
    };
    this.routerNav.navigate([`/admin/edit-banner`], navigationExtras);
  }

  goBack() {
    this.routerNav.navigate([`/admin/dashboard`]);
  }

}
