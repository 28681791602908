<div class="fx-layout-row fx-layout-align-center-center">
  <div class="fx-flex-80pc">
    <h4 class="white" *ngIf="quiz">{{ quiz.QuizTitle }}</h4>
    <h4 class="white" *ngIf="question">{{ question.QuestionText }}</h4>
    <p-table #dt [columns]="cols" [value]="answeroptions" [paginator]="true" selectionMode="single" [rows]="rows"
      [totalRecords]="totalRecords" [loading]="loading" (onRowSelect)="onRowSelect($event)">
      <!-- <ng-template pTemplate=" caption">
      <div style="text-align: right">
        <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
        <input type="text" pInputText class="margin0" size="50" placeholder="Global Filter"
          (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
      </div>
      </ng-template> -->
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
              ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order">
            </p-sortIcon>
          </th>
        </tr>
        <tr>
          <th *ngFor="let col of columns" [ngSwitch]="col.field">
            <input *ngSwitchCase="'AnswerText'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')">
            <input *ngSwitchCase="'Correct'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')">
            <input *ngSwitchCase="'Type'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')">
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td *ngFor="let col of columns">
            <span>{{rowData[col.field]}}</span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary" let-rowData>
        <div style="text-align:left">
          <button type="button" pButton (click)="showDialogToAdd()" label="Add"></button>
        </div>
      </ng-template>
    </p-table>

    <p-dialog header="Answer Options Details" [(visible)]="displayDialog" [responsive]="true" showEffect="fade"
      [modal]="true" [style]="{width: '500px', height: '600px'}">
      <div class="red-text" *ngIf="errorMessage">{{ errorMessage }}</div>

      <div class="ui-g ui-fluid" *ngIf="answeroption">
        <div class="ui-g-12">
          <div class="ui-g-4">
            <label for="sourceschool">Quiz</label>
          </div>
          <div class="ui-g-8">
            {{ quiz.QuizTitle }}
          </div>
        </div>

        <div class="ui-g-12">
          <div class="ui-g-4">
            <label for="sourceschool">Question</label>
          </div>
          <div class="ui-g-8">
            {{ question.QuestionText }}
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4">
            <label for="answerType">AnswerType</label>
          </div>
          <div class="ui-g-8">
            <p-dropdown id="answerType" [options]="answerTypeOptions" [(ngModel)]="answeroption.AnswerType"
              (onChange)="onChangeAnswerType($event)"></p-dropdown>
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4">
            <label for="answerText" *ngIf="answeroption.AnswerType == 'TEXT'">AnswerText</label>
            <label for="answerText" *ngIf="answeroption.AnswerType == 'IMAGE'">Image URL</label>
          </div>
          <div class="ui-g-8">
            <input pInputText class="margin0" id="AnswerText" [(ngModel)]="answeroption.AnswerText" required />
          </div>
        </div>
        <div class="ui-g-12" *ngIf="answeroption.AnswerType == 'IMAGE'">
          <div class="ui-g-4">
            <label for="answerText">Show Image</label>
          </div>
          <div class="ui-g-8">
            <img style="max-width: 100px; max-height: 100px;" src="{{ getImage(answeroption.AnswerText) }}" />
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4 pad0">
            <label for="Correct">Correct</label>
          </div>
          <div class="ui-g-8 pad0">
            <p-dropdown id="Correct" [options]="correctOptions" [(ngModel)]="answeroption.Correct"
              (onChange)="onChangeCorrect($event)"></p-dropdown>
          </div>
        </div>

      </div>
      <p-footer>
        <div class="ui-dialog-buttonpane ui-helper-clearfix" *ngIf="answeroption">
          <button type="button" pButton (click)="delete()" label="Delete" class="left"></button>
          <button type="button" pButton (click)="save()"
            [disabled]="!answeroption.AnswerText || !answeroption.AnswerType" label="Save"></button>
        </div>

      </p-footer>

    </p-dialog>
  </div>
</div>
