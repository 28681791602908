<div class="container-fluid">
  <div class="row backgrounDColor">
    <div class="col s12">
      <div class="row"></div>
      <div class="row"></div>
      <div class="row">

        <div class="col s12 white">
          <div class="card black-text">
            <div class="row"></div>
            <div class="col s12"><button mat-button color="primary" (click)="goBack()"><i
                  class="material-icons">arrow_back</i>Back</button></div>

            <div class="col s1"></div>
            <div class="col s10" *ngIf="chapter">

              <div class="card-content grey lighten-3">
                <span class="card-title">Edit Content</span>
              </div>

              <table>

                <tbody>
                  <tr>
                    <td>

                      <b>Chapter</b></td>

                    <td>
                      <div class="col s1"></div>
                      <div class="col s8">
                        <input type="text" [(ngModel)]="chapter.ChapterName">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><b>Content</b></td>
                    <td>
                      <div class="col s1"></div>
                      <div class="col s11">
                        <editor [init]="{plugins: 'advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen media table code wordcount',                                                
                        toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | link',
                        target_list: [    
                          {title: 'Same page', value: '_self'},
                          {title: 'New page', value: '_blank'}    
                        ],
                        default_link_target: '_blank',
                        height : '480'}" [(ngModel)]="chapter.ChapterContent">
                        </editor>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <div class="col s1"></div>
                      <div class="col s4"><button class="waves-effect waves-light btn sign-in-btn button-width"
                          type="submit" (click)="onSubmit()">Save</button></div>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>
            <div class="col s1"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
