import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AmplifyAuthEvent } from "./amplify.actions";


export const getAuthState = createFeatureSelector<AmplifyAuthEvent>("authState");

export const isAmplifySignedIn = createSelector(
  getAuthState,
  (authEvent: AmplifyAuthEvent) =>
    authEvent && authEvent.event === "signIn" && authEvent
);

export const isAmplifySignedOut = createSelector(
  getAuthState,
  (authEvent: AmplifyAuthEvent) =>
    authEvent && authEvent.event === "signOut" && authEvent
);

export const isAmplifyConfirmSignUp = createSelector(
  getAuthState,
  (authEvent: AmplifyAuthEvent) =>
    (authEvent && authEvent.event === "confirmSignUp" && authEvent) ? authEvent : null as unknown as AmplifyAuthEvent
);
