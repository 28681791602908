import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environments/environment";
import { MemberLoc } from "../models/memberloc.model";

@Injectable({
  providedIn: "root"
})
export class MemberlocService {
  constructor(private http: HttpClient) { }

  updateMemberLocationByCourse(courseId: number, memberId: number, body: MemberLoc): Promise<MemberLoc> {
    return lastValueFrom(this.http
      .put<MemberLoc>(`${environment.apiUrl}/courses/${courseId}/regmembers/${memberId}/memberloc`, body));
  }

  getMemberLocationByCourse(courseId: number, memberId: number): Promise<MemberLoc> {
    return lastValueFrom(this.http
      .get<MemberLoc>(`${environment.apiUrl}/courses/${courseId}/regmembers/${memberId}/memberloc`));
  }
}
