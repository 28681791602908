import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { PagesService } from "../../../services/pages.service";

@Component({
  selector: "app-aboutus",
  templateUrl: "./aboutus.component.html",
  styleUrls: ["./aboutus.component.css"]
})
export class AboutusComponent implements OnInit {
  aboutUsContent;

  constructor(
    private pagesService: PagesService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.getPage();
  }

  getPage() {
    this.pagesService
      .getPage(1)
      .then(
        res =>
        (this.aboutUsContent = this.sanitizer.bypassSecurityTrustHtml(
          res["PageContent"]
        ))
      );
  }
}
