<div class="fx-layout-row fx-layout-align-center-center">
  <div class="fx-flex-80pc">
    <p-table #dt [columns]="cols" [value]="assets" [paginator]="true" selectionMode="single" [rows]="rows"
      [totalRecords]="totalRecords" [loading]="loading" (onRowSelect)="onRowSelect($event)">
      <!-- <ng-template pTemplate="caption">
          <div style="text-align: right">
            <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
            <input type="text" pInputText class="margin0"  size="50" placeholder="Global Filter"
              (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
          </div>
        </ng-template> -->
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
              ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order">
            </p-sortIcon>
          </th>
        </tr>
        <tr>
          <th *ngFor="let col of columns" [ngSwitch]="col.field">
            <input *ngSwitchCase="'id'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')">
            <input *ngSwitchCase="'assetName'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')">
            <input *ngSwitchCase="'type'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')">
            <input *ngSwitchCase="'createdDatetime'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')">
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td *ngFor="let col of columns">
            <span>{{rowData[col.field]}}</span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary" let-rowData>
        <div style="text-align:left">
          <button type="button" pButton (click)="showDialogToAdd()" label="Add"></button>
        </div>
      </ng-template>
    </p-table>

    <p-dialog header="Asset Details" [(visible)]="displayDialog" [responsive]="true" showEffect="fade" [modal]="true"
      [style]="{width: '700px', height: '500px'}" (visibleChange)="handleClose()">
      <div class="red-text" *ngIf="errorMessage">{{ errorMessage }}</div>
      <div class="green-text" *ngIf="successMessage">{{ successMessage }}</div>
      <div class="ui-g ui-fluid" *ngIf="asset">
        <div class="ui-g-12">
          <div class="ui-g-4">
            <label for="assetId">AssetId</label>
          </div>
          <div class="ui-g-8">
            {{ asset.id }}
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4 pad0">
            <label for="assetName">Asset Name*</label>
          </div>
          <div class="ui-g-8 pad0">
            <input pInputText class="margin0" id="assetName" [(ngModel)]="asset.assetName" required />
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4 pad0">
            <label for="assetType">Asset Type*</label>
          </div>
          <div class="ui-g-8 pad0">
            <p-dropdown id="type" [options]="assetTypes" [(ngModel)]="asset.assetType"
              (onChange)="onChangeAssetType($event)">
            </p-dropdown>
          </div>
        </div>
        <div>
          <div class="ui-g-12">
            <div class="ui-g-4 pad0">
              <label for="location">Location*</label>
            </div>
            <div class="ui-g-8 pad0">
              {{ asset.location }}
            </div>
          </div>

          <div class="ui-g-12">
            <div class="ui-g-4 pad0">
              <label for="type">Content Type*</label>
            </div>
            <div class="ui-g-8 pad0">
              {{ asset.contentType }}
            </div>
          </div>

          <div class="ui-g-12" *ngIf="!selectedFile">
            <div class="ui-g-4 pad0">
              <label for="upload">Upload Asset</label>
            </div>
            <div class="ui-g-8 pad0">
              <p-toast></p-toast>
              <p-fileUpload #fileUpload mode="basic" chooseLabel="Choose" [url]="asset.signedUrl" multiple="false"
                method="PUT"
                accept="image/*, application/pdf, video/mp4, application/vnd.openxmlformats-officedocument.presentationml.presentation,"
                maxFileSize="500000000" (uploadHandler)="uploadHandler($event)" (onSelect)="onSelect($event)"
                showUploadButton="false" [disabled]="!asset.assetName">

              </p-fileUpload>
            </div>
          </div>
        </div>

      </div>
      <ng-template pTemplate="footer">
        <div class="ui-dialog-buttonpane ui-helper-clearfix" *ngIf="asset">
          <button type="button" pButton (click)="delete()" label="Delete" class="left"></button>
          <button type="button" pButton (click)="save()"
            [disabled]="!asset.assetName || !asset.assetType || !asset.location" label="Save"></button>
        </div>

      </ng-template>

    </p-dialog>
  </div>
</div>
