<nav class="white" role="navigation">
  <div class="nav-wrapper fx-layout-row fx-layout-align-space-between-center">
    <div class="fx-layout-row fx-flex-80pc fx-layout-align-flex-start-center">
      <div class="valign-wrapper">
        <a class=""><img id="logo-container" src="{{'assets/img/navLogo.jpeg'}}"
            style="cursor: pointer; max-height: 5rem; padding-top: 20px; padding-left: 10px;"
            class="responsive-img icons-height" [routerLink]="currentCourseLink()"></a>
      </div>
      <div *ngIf="currentUser && currentUser.memberstatus == 'admin'">
        <button mat-raised-button color="primary" [routerLink]="['/admin/dashboard']">Dashboard</button>
      </div>
      <div style="margin: 10px;" *ngIf="currentCourse">
        <h5 style="color: black">{{ currentCourse?.courseName }}</h5>
      </div>
    </div>

    <div *ngIf="isUserLoggedIn" class="fx-layout-row fx-flex-30pc fx-layout-align-flex-end-center profile-img">
      <div></div>
      <div>
        <a [matMenuTriggerFor]="menu"><img src="{{'assets/img/profileImage.png'}}"
            style="cursor: pointer; max-height: 53px; margin-top: 5px;" class="responsive-img icons-height"></a>
        <mat-menu #menu="matMenu">
          <a mat-menu-item class="black-text" routerLink="/profile">profile</a>
          <button mat-menu-item><a class="black-text" routerLink="/change-password">change password</a></button>
          <button mat-menu-item><a class="black-text" (click)="logout()">logout</a></button>
        </mat-menu>
      </div>
    </div>
    <div class="brand-logo right fx-flex-60pc" *ngIf="!isUserLoggedIn">
      <ul class="right hide-on-med-and-down">
        <li><a class="black-text" routerLink="/home/course-Info">About Our Online Course</a></li>
        <!-- <li><a class="black-text" routerLink="/home/testimoniols">Testimoniols</a></li> -->
        <li><a class="black-text" routerLink="/home/privacy">Privacy</a></li>
        <li><a class="black-text" routerLink="/home/about-us">About Us</a></li>
        <li><a class="black-text" routerLink="/home/contact-us">Contact Us</a></li>
      </ul>
    </div>

  </div>
</nav>
