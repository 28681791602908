import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Store, select } from "@ngrx/store";
import { NGXLogger } from "ngx-logger";
import { filter } from "rxjs/operators";
import {
  ForgotPasswordSubmitting,
  SignInActionTypes,
  SignedOutAction,
  SigningInAction
} from "../../../../app/components/allusers/signin/signin.actions";
import { SignInState } from "../../../../app/components/allusers/signin/signin.model";
import {
  getSignInCurrentState,
  getSignedInUser,
  isForgotPasswordFailed,
  isForgotPasswordSubmitted,
  isSignInFailure,
  isSignedIn,
  isSigningIn,
} from "../../../../app/components/allusers/signin/signin.selector";
import { AuthService } from "../../../../app/services/auth-service/auth.service";
import { RouterService } from "../../../../app/services/route-service/router.service";
import { SignUpState } from "../signup/signup.model";

@Component({
  selector: "app-signin",
  templateUrl: "./signin.component.html",
  styleUrls: ["./signin.component.css"],
})
export class SigninComponent implements OnInit {
  isSigningIn$ = this.signInStore.pipe(select(isSigningIn));
  isSignedIn$ = this.signInStore.pipe(select(isSignedIn));
  isForgotPasswordSubmitted$ = this.signInStore.pipe(
    select(isForgotPasswordSubmitted),
    filter((val) => val)
  );
  showForgotPasswordSuccess = false;
  isForgotPasswordFailed$ = this.signInStore.pipe(
    select(isForgotPasswordFailed),
    filter((val) => !!val.error)
  );


  currentState$ = this.signInStore.pipe(select(getSignInCurrentState));
  currentState = "";
  currentUser$ = this.signInStore.pipe(select(getSignedInUser));
  currentUser;

  successMessage: any = null;
  errorMessage: any = null;
  isSignInFailure$ = this.signInStore.pipe(
    select(isSignInFailure),
    filter((val) => (val ? true : false))
  );

  userForm = new FormGroup({
    username: new FormControl("", [Validators.required, Validators.pattern("[a-z0-9]+")]),
    password: new FormControl(""),
  });

  constructor(
    private authService: AuthService,
    private signInStore: Store<SignInState>,
    private signUpStore: Store<SignUpState>,
    private routeService: RouterService,
    private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.isSignInFailure$.subscribe((val: any) =>
      val.error ? (this.errorMessage = val.error) : (this.errorMessage = null)
    );

    this.currentState$.subscribe((state) => {
      this.currentState = state ? state : "";
    });

    this.currentUser$.subscribe((user) => {
      this.currentUser = user;
    });

    this.isSignedIn$.subscribe((user) => {
      if (user) {
        this.routeService.gotoDashboard();
      }
    });

    this.isForgotPasswordSubmitted$.subscribe(() => {
      this.showForgotPasswordSuccess = true;
      this.signInStore.dispatch(
        new SignedOutAction({
          currentState: SignInActionTypes.SignedOut,
        } as SignInState)
      );
    });

    this.isForgotPasswordFailed$.subscribe((state) => {
      this.errorMessage = state.error;
    });
  }

  initMessages() {
    this.showForgotPasswordSuccess = false;
    this.errorMessage = null;
  }

  async signin() {
    if (this.userForm.valid) {
      this.initMessages();
      this.signInStore.dispatch(
        new SigningInAction({
          currentState: SignInActionTypes.SigningIn,
          user: { membername: this.userForm.value.username },
        } as SignInState)
      );

      await this.authService
        .signIn(this.userForm.value.username, this.userForm.value.password);
    }
  }

  async forgotUserPassword() {
    if (this.userForm.controls.username.valid) {
      this.signInStore.dispatch(
        new ForgotPasswordSubmitting({
          currentState: SignInActionTypes.ForgotPasswordSubmitting,
          user: { membername: this.userForm.value.username },
        } as SignInState)
      );

      this.initMessages();
      try {
        await this.authService.forgotPassword(
          this.userForm.controls.username.value ? this.userForm.controls.username.value : ""
        );
      } catch (error) {
        this.errorMessage = error.message;
      }

    } else {
      this.errorMessage = "Username is required.";
    }
  }
}
