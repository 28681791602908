import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { EdisonCode } from 'src/app/models/edison-code.model';

@Component({
  selector: 'app-presignup-layout',
  templateUrl: './presignup-layout.component.html',
  styleUrls: ['./presignup-layout.component.css']
})
export class PresignupLayoutComponent implements OnInit {
  edisonCode: EdisonCode;

  constructor(private activatedRoute: ActivatedRoute,
    private logger: NGXLogger,
  ) { }

  ngOnInit() {
    console.log('PresignupLayoutComponent');
    this.activatedRoute.queryParams.subscribe(async params => {
      this.logger.info(`Query Params - ${JSON.stringify(params)}`);
      this.edisonLogin(params);
    });
  }


  async edisonLogin(params) {
    if (params && params.code) {
      const domain = new URL(document.referrer).hostname;
      this.edisonCode = { code: params.code, domain: domain } as EdisonCode;
      console.log(`Edison redirect - ${JSON.stringify(this.edisonCode)}`);
    }
  }

}
