import { Injectable, inject } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { NGXLogger } from "ngx-logger";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { CourseState } from "src/app/components/members/courses/courses.reducer";
import { isCourseNotSelected, isCourseSelected } from "src/app/components/members/courses/courses.selector";
import { Course } from "src/app/models/course.model";
import { CoursesService } from "src/app/services/courses.service";
import { RegmembersService } from "src/app/services/regmembers.service";
import { SignInState } from "../components/allusers/signin/signin.model";
import {
  getSignedInUser,
  isSignedOut
} from "../components/allusers/signin/signin.selector";
import { AuthService } from "../services/auth-service/auth.service";
import { CoursesActionTypes, UpdateAction } from "src/app/components/members/courses/courses.reducer";
import { QuizesService } from "src/app/services/quizes.service";
import { MemberCourse } from "src/app/models/member-course.model";
import { ChaptersService } from "src/app/services/chapters.service";
import { User } from "src/app/models/user.model";



@Injectable()
export class MemberGuard {
  getLoggedInUser$ = this.loginStore.pipe(
    select(getSignedInUser),
    filter(val => (val ? true : false))
  );
  loggedInUser: any;
  isLoggedOut$ = this.loginStore.pipe(
    select(isSignedOut),
    filter(val => (val ? true : false))
  );
  
  currentUser$ = this.loginStore.pipe(select(getSignedInUser));
  currentUser: User;
  routeParamsSubscription$: Subscription;

  constructor(
    private router: Router,
    private authService: AuthService,
    private loginStore: Store<SignInState>,        
    private logger: NGXLogger
  ) {
    this.getLoggedInUser$.subscribe(user => (this.loggedInUser = user));
    this.isLoggedOut$.subscribe(() => (this.loggedInUser = null));    
    this.currentUser$.subscribe(user => this.currentUser = user);
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.
      loggedInUser) {        
      if (this.loggedInUser.memberstatus === "paid") {        
        return true;
      } else if (this.loggedInUser.memberstatus === "admin") {        
        return true;
      } else if (
        this.loggedInUser.memberstatus === null ||
        this.loggedInUser.memberstatus === ""
      ) {
        return true;
      } else if (
        this.loggedInUser.memberstatus.indexOf("teacher") > -1 ||
        this.loggedInUser.memberstatus.indexOf("admin") > -1
      ) {
        this.router.navigate(["/teacher/dashboard"]);
        return true;
      }
    } else {      
      try {
        this.loggedInUser = await this.authService.getLoggedInUser();
      } catch (err) {
        this.logger.info('User not loggedin - ', err);
      }
      if (!this.loggedInUser) {
        console.log("User not logged in");
        this.router.navigate(["/home"], {
          queryParams: route.queryParams
        });
      }
    }

    return false;
  }  
}


export const canActivateMemberGuard: CanActivateFn =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(MemberGuard).canActivate(route, state);
  };