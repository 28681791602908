<div *ngIf="currentUser">
  <div class="row" style="margin: 10px;"
    *ngIf="currentCourseState.memberCourse?.status === 'paid' || currentUser.memberstatus === 'admin'">
    <div class="fx-layout-row fx-layout-align-space-around-center" *ngIf="memberProgress$ | async">
      <div class="fx-flex-59pc">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Course Progress for {{ currentUser.fname }} {{ currentUser.lname }}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="center">
              <table mat-table [dataSource]="memberProgress$">

                <ng-container matColumnDef="Location">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Location </th>
                  <td mat-cell *matCellDef="let element"> {{element.quiz && element.quiz.QuizLocation}} - Quiz
                    {{element.quiz && element.quiz.QuizOrder}}
                    of
                    {{element.quiz && element.quiz.QuizLocation2}} </td>
                </ng-container>

                <ng-container matColumnDef="QuizTitle">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Quiz </th>
                  <td mat-cell *matCellDef="let element"> {{element.quiz && element.quiz.QuizTitle}} </td>
                </ng-container>

                <ng-container matColumnDef="Score">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Highest Score </th>
                  <td mat-cell *matCellDef="let element"> {{element.quiz && element.Score}} %</td>
                </ng-container>

                <ng-container matColumnDef="Attempts">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Attempts </th>
                  <td mat-cell *matCellDef="let element"> {{element.quiz && element.attempts }} </td>
                </ng-container>

                <ng-container matColumnDef="DTStamp">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Date </th>
                  <td mat-cell *matCellDef="let element"> {{ element.quiz && getLocalDate(element.DTStamp) }} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>

            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="fx-flex-1pc"></div>
      <div class="fx-flex-40pc">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Your progress</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div>
              <highcharts-chart [Highcharts]="highcharts" [options]="chartOptions" style="width: 100%; display: block">
              </highcharts-chart>
            </div>
          </mat-card-content>
          <mat-card-actions class="fx-layout-align-center-center">
            <button mat-raised-button color="primary" (click)="gotoCourseView()">Continue
              Course</button>
            &nbsp;
            <button mat-raised-button color="primary" (click)="downloadCertificate()" [disabled]="!completedFinal">Print
              Certificate</button>
          </mat-card-actions>
        </mat-card>
        <br>
        <mat-card>
          <mat-card-header>
            <mat-card-title>Average Quiz Score</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div>
              <highcharts-chart [Highcharts]="highcharts" [options]="scoreChartOptions"
                style="width: 100%; display: block">
              </highcharts-chart>
            </div>
          </mat-card-content>

        </mat-card>
      </div>
    </div>
  </div>
</div>
