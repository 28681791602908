<div class="fx-layout-row fx-flex-fill fx-wrap" style="flex-flow: column;">
  <div class="fx-flex-4rem">
    <app-header></app-header>
  </div>
  <div class="background-color fx-flex-auto">
    <main>
      <router-outlet></router-outlet>
    </main>
  </div>
  <div class="grey lighten-4 fx-flex-3rem">
    <app-footer></app-footer>
  </div>
</div>
