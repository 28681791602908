import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { signOut } from "aws-amplify/auth";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "keysToDriveAdmin";

  constructor(public router: Router) {
    // if (!!localStorage.getItem('userData')) {
    //   this.router.navigate(['/dashboard']);
    // }
  }

  async logout() {
    try {
      console.log(`Signing out...`);
      await signOut({ global: true });
      this.router.navigate(["/home"]);
    } catch (error) {
      console.log(`Unable to signout...`);
    }
  }
}
