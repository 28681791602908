<div class="container white btn-margin backgrounDColor">

  <h4 class="center padding-row"> Content List </h4>
  <div class="row padding-header" *ngIf="!loading">
    <div class="col s3"></div>
    <div class="col s6">
      <table class="striped">

        <tbody>
          <tr *ngFor="let page of pages; index as i">
            <td class="center"> {{page.PageName}}</td>
            <td class="center"> <a class="waves-effect waves-light btn blue btn-border"
                (click)="goToPageEdit(page)">edit</a></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col 3"></div>
  </div>
  <div class="row" *ngIf="loading">
    <div class="col l4"></div>
    <div class="col l4">
      <div class="preloader-wrapper big active loader">
        <div class="spinner-layer spinner-blue-only">
          <div class="circle-clipper left">
            <div class="circle"></div>
          </div>
          <div class="gap-patch">
            <div class="circle"></div>
          </div>
          <div class="circle-clipper right">
            <div class="circle"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col l4"></div>
  </div>
</div>