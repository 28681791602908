<div class="row col s12" *ngIf="errorMessage">
  <span class="red-text">{{ errorMessage }}</span>
</div>
<div class="row col s12" *ngIf="successMessage">
  <span class="green-text">{{ successMessage }}</span>
</div>
<div class="row col s12" *ngIf="currentState === 'SignUpInProcess'">
  <mat-spinner strokeWidth="1" diameter="25"></mat-spinner>
</div>

<div id="signup" class="row" *ngIf="currentState === 'SignUpUserInfo' || currentState === 'SignUpFailed'">
  <div class="row">
    <form class="col s12" [formGroup]="signUpForm" (ngSubmit)="signUp()">
      <div id="signupForm" *ngIf="currentState === 'SignUpUserInfo' || currentState === 'SignUpFailed'">
        <div class="row signup-container">
          <mat-form-field>
            <input matInput placeholder="User Name*" formControlName="username" required />
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Password*" formControlName="password" type="password" required
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters">
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Password*" formControlName="rePassword" type="password" required
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters">
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="First Name*" formControlName="firstName" placeholder="Student First Name*"
              required>
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Last Name*" placeholder="Student Last Name" formControlName="lastName">
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Email*" formControlName="email" required>
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Phone Number*" formControlName="phoneNumber" required>
          </mat-form-field>


          <mat-form-field>
            <mat-label><b>Course</b></mat-label>
            <mat-select formControlName="courseCode" required>
              <mat-option>Choose your Course</mat-option>
              <mat-option value="PA">Pennsylvania 30 Hour Theory Online Driving Course</mat-option>
              <mat-option value="IA">Iowa 30 Hour Online Theory Driver Education Course</mat-option>
              <mat-option value="IL">Illinois 30 Hour Online Theory Drivers Education Course</mat-option>
              <mat-option value="ATV">ATV Online Course</mat-option>
              <mat-option value="CO">Colorado 30 Hour Online Theory Drivers Education Course</mat-option>
              <mat-option value="MOTO">Motorcycle Online Course</mat-option>
              <mat-option value="MI">Michigan 30 Hour Theory Online Drivers Education Course</mat-option>
              <mat-option value="FL">Florida 30 Hour Theory Online Drivers Education Course</mat-option>
              <mat-option value="MO">Missouri 30 Hour Theory Online Drivers Education Course</mat-option>
              <mat-option value="OH">Ohio 30 Hour Online Theory Drive Education Course</mat-option>
              <mat-option value="AK">Arkansas 30 Hour Online Theory Drivers Education Course</mat-option>
              <mat-option value="ID">Idaho 30 Hour Theory Online Drivers Education Course</mat-option>
              <mat-option value="VA">Virginia 30 Hour Online Theory Drivers Course</mat-option>
              <mat-option value="WY">Wyoming 30 Hour Online Theory Drivers Education Course</mat-option>
            </mat-select>
          </mat-form-field>


          <mat-checkbox>I agree to the following: <a (click)="openDialog()" class="underline">Enrollment Agreement</a>
          </mat-checkbox>
        </div>
        <div class="row" fxflex class="fx-layout-row" fxlayout.xs="column" fxLayoutAlign="space-around center">
          <button class="fx-flex-40pc" mat-raised-button color="primary" type="submit"
            [disabled]="!signUpForm.valid">Sign
            Up</button>
        </div>
      </div>
    </form>
  </div>
</div>

<div id="confirmSignUp" class="row" *ngIf="currentState === 'ConfirmSignUp'">
  <div class="row col s12">
    <form class="col s12" [formGroup]="confirmSignUpForm" (ngSubmit)="confirmSignUp()">
      <div>
        <div class="row signup-container">
          <mat-form-field>
            <input matInput placeholder="User Name" formControlName="username" required />
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Verification Code" formControlName="verificationCode" />
          </mat-form-field>
        </div>
        <div class="row" fxflex class="fx-layout-row" fxlayout.xs="column" fxLayoutAlign="space-around center">
          <button class="fx-flex-40pc" mat-raised-button color="basic" type="button" (click)="resendCode()">Resend
            Code</button>
          <button class="fx-flex-40pc" mat-raised-button color="primary" type="submit"
            [disabled]="!confirmSignUpForm.valid">Complete Signup</button>
        </div>
      </div>
    </form>
  </div>
</div>

<div id="confirmSignUp" class="row" *ngIf="currentState === 'SignUpComplete'">
  <div class="row col s12">
    <span class="green-text">You have successfully Signed up. Kindly, Sign-In using your username/password.</span>
  </div>
</div>
