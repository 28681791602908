import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { fetchAuthSession } from "aws-amplify/auth";
import { NGXLogger } from "ngx-logger";
import { EMPTY, Observable, from } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class AuthIntercepter implements HttpInterceptor {
  constructor(private logger: NGXLogger) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!request.url.includes(environment.apiUrl)) {
      return next.handle(request);
    }
    return from(this.getSession()).pipe(
      switchMap(token => {
        // console.log(`Token - ${JSON.stringify(token)}`);
        if (token) {
          request = request.clone({
            setHeaders: {
              authorization: `Bearer ${token.toString()}`
            }
          });
        }
        return next.handle(request);
      }),
      catchError((err) => {
        return EMPTY;
      })
    );
  }

  private async getSession() {
    let token = null;
    try {
      const session = await fetchAuthSession();
      const tokens = session.tokens ?? {}
      token = tokens["accessToken"] ? tokens["accessToken"] : "";
    } catch (error) {
      this.logger.info(`Unable to get user session - ${JSON.stringify(error)}`);
      return EMPTY;
    }

    return token
  }
}
