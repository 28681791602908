import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eductaion-details',
  templateUrl: './eductaion-details.component.html',
  styleUrls: ['./eductaion-details.component.css']
})
export class EductaionDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
