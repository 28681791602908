import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { combineLatest, filter, lastValueFrom } from 'rxjs';
import { environment } from "../../environments/environment";
import { Quiz } from "../models/quiz.model";
import { isCourseNotSelected, isCourseSelected, getCourseSelected } from "src/app/components/members/courses/courses.selector";
import { select, Store } from "@ngrx/store";
import { CourseState } from "src/app/components/members/courses/courses.reducer";

import { QuizTestsState } from "src/app/components/members/quiz-tests/quiz-tests.model";
import { getSignedInUser } from "src/app/components/allusers/signin/signin.selector";
import { SignInState } from "src/app/components/allusers/signin/signin.model";
import { Course } from "../models/course.model";
import { ChaptersService } from "./chapters.service";
import { NGXLogger } from "ngx-logger";
import { QuizTestsActionTypes, InitializationAction } from "src/app/components/members/quiz-tests/quiz-tests.actions";

@Injectable({
  providedIn: "root",
})
export class QuizesService {
  baseURL: string = environment.apiUrl;
  currentCourse: Course;

  constructor(private http: HttpClient,
    private courseStore: Store<CourseState>,
    private quizStore: Store<QuizTestsState>,
    private loginStore: Store<SignInState>,
    private chapterService: ChaptersService,
    private logger: NGXLogger
  ) { 
    this.courseStore.pipe(select(isCourseNotSelected)).subscribe(() => {
      this.currentCourse = null;
      this.quizStore.dispatch( new InitializationAction({
        currentState: QuizTestsActionTypes.Initialization        
      } as QuizTestsState));
    });

    combineLatest([
      this.loginStore.pipe(select(getSignedInUser)),
      this.courseStore.pipe(select(getCourseSelected), filter(course => course !== null))]).subscribe(async ([user, course]) => { 
          this.logger.info(`Getting final quiz, course: ${course.id}`);
          this.currentCourse = course;          
          const finalChapter = await this.chapterService.getFinalChapterBy(course.id);
          const quizzes = await this.findByCourseIdAndChapterId(course.id, finalChapter.ID);
          if (quizzes && quizzes.length < 1) {
            this.logger.error(`Final quiz not found`, quizzes);
          }
        
          this.quizStore.dispatch(new InitializationAction({
            currentState: QuizTestsActionTypes.Initialization,
            finalQuiz: quizzes[0],
          } as QuizTestsState));
    });
  }

  async findAllPerCourse(courseId: number) {    
    return await lastValueFrom(
      this.http
        .get<Array<Quiz>>(
          `${environment.apiUrl}/courses/${courseId}/quizzes`
        )
    );
  }

  async findByCourseIdAndChapterId(courseId: number, chapterId: number) {
    return await lastValueFrom(
      this.http
        .get<Array<Quiz>>(
          `${environment.apiUrl}/courses/${courseId}/quizzes?quizLocation2=${chapterId}`
        )
    );
  }

  async findByCourseIdAndQuizId(courseId: number, quizId: number) {
    return await lastValueFrom(
      this.http
        .get<Quiz>(
          `${environment.apiUrl}/courses/${courseId}/quizzes/${quizId}`
        )
    );
  }

  async delete(courseId: number, quizId: number) {
    return await lastValueFrom(
      this.http
        .delete(
          `${environment.apiUrl}/courses/${courseId}/quizzes/${quizId}`
        ))
      ;
  }

  async update(courseId: number, quiz) {
    return await lastValueFrom(
      this.http
        .put<Quiz>(`${environment.apiUrl}/courses/${courseId}/quizzes/${quiz.QuizID}`, quiz)
    );
  }

  async create(courseId: number, quiz: Quiz): Promise<Quiz> {
    return await lastValueFrom(
      this.http
        .post<Quiz>(`${environment.apiUrl}/courses/${courseId}/quizzes`, quiz)
    );
  }
}
