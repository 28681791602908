import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Course } from 'src/app/models/course.model';
import { Progress } from 'src/app/models/progress.model';
import { Quiz } from 'src/app/models/quiz.model';
import { User } from 'src/app/models/user.model';
import { CoursesService } from 'src/app/services/courses.service';
import { ProgressService } from 'src/app/services/progress.service';
import { QuizesService } from 'src/app/services/quizes.service';
import { RegmembersService } from 'src/app/services/regmembers.service';


@Component({
  selector: 'app-member-course-progress',
  templateUrl: './member-course-progress.component.html',
  styleUrls: ['./member-course-progress.component.css']
})
export class MemberCourseProgressComponent implements OnInit {

  first = 0;
  rows = 15;
  sortField;
  sortOrder;
  filters;
  totalRecords = 0;

  memberCourseProgressList: Progress[] = [];
  loading: boolean;
  courseId: string;
  membername: string;
  cols: any[];
  courseQuizzes: Quiz[];
  errorMessage: string;
  member: User;
  course: Course;
  memberProgress: Progress;
  displayDialog: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private regmembersService: RegmembersService,
    private coursesService: CoursesService,
    private quizesService: QuizesService,
    private progressService: ProgressService) { }

  async ngOnInit() {
    this.activatedRoute.params.subscribe(async (params) => {
      this.membername = params["membername"];
      this.courseId = params["courseId"];
      this.member = await this.regmembersService.getUserBy(this.membername);
      this.course = await this.coursesService.findBy(this.courseId);
      await this.loadMemberCourseProgress();
    });

    this.cols = [
      { field: "quizTitle", header: "Quiz Title" },
      { field: "Score", header: "Score" },
      { field: "DTStamp", header: "Date" },
    ];
  }

  async loadMemberCourseProgress() {
    this.loading = true;
    this.errorMessage = null;
    this.totalRecords = 0;
    this.memberCourseProgressList = [];
    this.courseQuizzes = [];
    try {
      this.memberCourseProgressList = await this.progressService.getProgressByCourse(parseInt(this.courseId), this.member.memberid);
      this.courseQuizzes = await this.quizesService.findAllPerCourse(parseInt(this.courseId));
      this.memberCourseProgressList.map((progress) => {
        progress['quizTitle'] = this.courseQuizzes.find((q) => q.QuizID === progress.QuizID)?.QuizTitle;
      });
      this.totalRecords = this.memberCourseProgressList.length;
      this.loading = false;
    } catch (err) {
      this.errorMessage = err.error.error.description;
      this.loading = false;
    }
  }

  selectProgress(progress: Progress) {
    this.memberProgress = progress;
    this.displayDialog = true;
  }

  async deleteProgress(progress: Progress) {
    try {
      this.loading = true;
      await this.progressService.deleteProgress(parseInt(this.courseId), this.member.memberid, progress.ProgressID);
      this.memberProgress = null;
      this.displayDialog = false;
      await this.loadMemberCourseProgress();
      this.loading = false;
    } catch (err) {
      this.errorMessage = err.error.error.description;
      this.loading = false;
    }
    this.totalRecords = this.memberCourseProgressList.length;
  }

} 