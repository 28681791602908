import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { Timer } from "easytimer.js";
import { NGXLogger } from "ngx-logger";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { SignInState } from "src/app/components/allusers/signin/signin.model";
import { getSignedInUser } from "src/app/components/allusers/signin/signin.selector";
import { ChapterSection } from "src/app/models/chapter-sections.model";
import { Chapter } from "src/app/models/chapters.model";
import { Course } from "src/app/models/course.model";
import { Progress } from "src/app/models/progress.model";
import { Quiz } from "src/app/models/quiz.model";
import { User } from "src/app/models/user.model";
import { CertificatesService } from "src/app/services/certificates.service";
import { ChapterSectionsService } from "src/app/services/chapter-sections.service";
import { ChaptersService } from "src/app/services/chapters.service";
import { EssayAnswersService } from "src/app/services/essay-answers.service";
import { MemberlocService } from "src/app/services/memberloc.service";
import { ProgressService } from "src/app/services/progress.service";
import { QuestionsService } from "src/app/services/questions.service";
import { QuizesService } from "src/app/services/quizes.service";
import { CourseTimerState } from "../course-timer/course-timer.model";
import { isTimerIncomplete, isTimerStart, isTimerStopped } from "../course-timer/course-timer.selector";
import { CourseState } from "../courses/courses.reducer";
import { getCourseSelected, isCourseNotSelected } from "../courses/courses.selector";
import { QuizTestsComponent } from "../quiz-tests/quiz-tests.component";
import {
  ChapterShowAction,
  ChapterViewActionTypes,
  FinalShowAction,
  QuizzesIncompleteAction,
  UpdateChapterAction
} from "./chapter-view.actions";
import { ChapterViewState } from "./chapter-view.model";
import {
  getCurrentChapterViewState,
  isChapterShow,
  isFinalShow,
  isQuizzesIncomplete,
  isUnknownChapterShow
} from "./chapter-view.selector";

interface ChapterQuizzes {
  [key: string]: Quiz[];
}


@Component({
  selector: "app-chapter-view",
  templateUrl: "./chapter-view.component.html",
  styleUrls: ["./chapter-view.component.css"],
  encapsulation: ViewEncapsulation.None,

})
export class ChapterViewComponent implements OnInit {
  @ViewChild("chapters") chaptersComponent: QuizTestsComponent;
  @ViewChild("chapters") quizElement: ElementRef;
  @ViewChild("content") eChapterContent: ElementRef;
  @ViewChild("courseViewContent") courseViewContent: ElementRef;
  
  courseChapters: Chapter[];
  courseQuizzes: Quiz[];
  links = [];
  activeLink;

  isUnknownChapterShow$ = this.chapterViewStore.pipe(
    select(isUnknownChapterShow),
    filter((val) => (val ? true : false))
  );

  isChapterShow$ = this.chapterViewStore.pipe(
    select(isChapterShow),
    filter((val) => (val ? true : false))
  );

  isFinalShow$ = this.chapterViewStore.pipe(
    select(isFinalShow),
    filter((val) => (val ? true : false))
  );

  isQuizzesIncomplete$ = this.chapterViewStore.pipe(
    select(isQuizzesIncomplete),
    filter((val) => (val ? true : false))
  );

  currChapterViewState$ = this.chapterViewStore.pipe(
    select(getCurrentChapterViewState)
  );

  currChapterViewState: ChapterViewState;

  subscriptions$: Array<Subscription> = [];

  chapterContent;
  sectionContent;
  chapterSections: Array<ChapterSection> = [];
  allChapters: Array<Chapter> = [];
  quizId: string = null;
  videoId: string = null;
  soundId: string = null;
  chapterId: string;
  currentUser: User;
  incompleteQuizzes = [];
  progresses: Array<Progress> = [];
  chapterQuizzes: ChapterQuizzes = {};
  timer: Timer;
  timerString: string;

  courseSelected$ = this.courseStore.pipe(select(getCourseSelected), filter(val => !val));
  courseNotSelected$ = this.courseStore.pipe(select(isCourseNotSelected), filter(val => val))
  currentCourse: Course;

  courseTimerError$ = this.courseTimerStore.pipe(select(isTimerIncomplete), filter(val => val !== false));
  courseTimerStarted$ = this.courseTimerStore.pipe(select(isTimerStart), filter(val => val !== false));
  courseTimerStopped$ = this.courseTimerStore.pipe(select(isTimerStopped), filter(val => val !== false));

  constructor(
    private chaptersService: ChaptersService,
    private chapterSectionsService: ChapterSectionsService,
    private quizesService: QuizesService,
    private essayAnswersService: EssayAnswersService,
    private progressService: ProgressService,
    private cirtificatesService: CertificatesService,
    public router: Router,
    private questionsService: QuestionsService,
    private memberLocService: MemberlocService,
    private sanitizer: DomSanitizer,
    private logger: NGXLogger,
    private route: ActivatedRoute,
    private signInStore: Store<SignInState>,
    private chapterViewStore: Store<ChapterViewState>,
    private courseStore: Store<CourseState>,
    private courseTimerStore: Store<CourseTimerState>,
  ) {
    this.signInStore
      .pipe(select(getSignedInUser))
      .subscribe((user) => (this.currentUser = user));

    this.currChapterViewState$.subscribe(
      (state) => (this.currChapterViewState = state)
    );
  }

  async ngOnInit() {
    this.courseSelected$.subscribe(val => this.currentCourse = val);
    this.courseNotSelected$.subscribe(val => this.currentCourse = undefined);

    if (this.allChapters.length == 0) {
      this.allChapters = await this.getChapters();
    }

    this.route.paramMap.subscribe(async (paramMap) => {
      await this.getProgress();
      this.chapterViewStore.dispatch(
        new UpdateChapterAction(
          {
            ...this.currChapterViewState,
            chapterId: paramMap.get("chapterId"),
            quizId: paramMap.get("quizId"),
            videoId: paramMap.get("videoId"),
            sectionId: paramMap.get("sectionId"),
            incompleteQuizzes: await this.getPrevIncompleteQuizzes(
              paramMap.get("chapterId")
            ),
            currentState: ChapterViewActionTypes.UpdateChapter,
          } as ChapterViewState)
      );
    });


    this.subscriptions$.push(
      this.courseTimerError$.subscribe((val) => {
        this.chapterViewStore.dispatch(
          new UpdateChapterAction({ timerError: true } as ChapterViewState));
      }));

    this.subscriptions$.push(
      this.courseTimerStarted$.subscribe((val) => {
        this.chapterViewStore.dispatch(
          new UpdateChapterAction({ timerError: false } as ChapterViewState));
      }));

    this.subscriptions$.push(
      this.courseTimerStopped$.subscribe((val) => {
        this.chapterViewStore.dispatch(
          new UpdateChapterAction({ timerError: false } as ChapterViewState));
      }));

    this.subscriptions$.push(
      this.isUnknownChapterShow$.subscribe(() => {
        this.navigateToLastWorkedOn();
      })
    );

    this.subscriptions$.push(
      this.isChapterShow$.subscribe(async () => {
        this.chapterViewStore.dispatch(
          new ChapterShowAction(
            {
              ...this.currChapterViewState,
              incompleteQuizzes: await this.getPrevIncompleteQuizzes(
                this.currChapterViewState.chapterId
              ),
              currentState: ChapterViewActionTypes.ChapterShow,
            } as ChapterViewState
          )
        );

        await this.getChapterQuizzes(this.currChapterViewState.chapterId);
        await this.showChapter();
        this.focusContent();
      })
    );

    this.subscriptions$.push(
      this.isFinalShow$.subscribe(async () => {
        this.chapterViewStore.dispatch(
          new FinalShowAction(
            {
              ...this.currChapterViewState,
              currentState: ChapterViewActionTypes.FinalShow,
            } as ChapterViewState
          )
        );
        this.showFinal();
      })
    );

    this.subscriptions$.push(
      this.isQuizzesIncomplete$.subscribe(async () => {
        this.chapterViewStore.dispatch(
          new QuizzesIncompleteAction(
            {
              ...this.currChapterViewState,
              incompleteQuizzes: await this.getPrevIncompleteQuizzes(
                this.currChapterViewState.chapterId
              ),
              currentState: ChapterViewActionTypes.QuizzesIncomplete,
            } as ChapterViewState
          )
        );
        this.showIncompleteQuizzes();
      })
    );

    await this.prepareLinks();
  }

  ngOnDestroy() {
    if (this.subscriptions$ && this.subscriptions$.length > 0) {
      this.subscriptions$.forEach((item) => item.unsubscribe());
    }
  }

  async prepareLinks() {
    this.links = (await this.getChapters()).map(c => ({ label: c.ChapterName, path: "/courses/" + this.currentCourse.id + "/chapters/" + c.ID }));
    const final = this.links.find(l => l.label.trim().toUpperCase().indexOf("FINAL") != -1);
    final.label = "Take My Final Now";
    final.path = "/courses/" + this.currentCourse.id + "/chapters/final";
    this.activeLink = this.links[0];
  }

  async navigateToLastWorkedOn() {
    const memberloc = await this.memberLocService.getMemberLocationByCourse(
      this.currentCourse.id,
      this.currentUser.memberid
    );

    if (
      memberloc &&
      memberloc.section &&
      memberloc.quid &&
      memberloc.quid !== "final" &&
      memberloc.section !== "final"
    ) {
      this.router.navigate(["courses", this.currentCourse.id,
        "chapters",
        parseInt(memberloc.section),
        "quizzes",
        memberloc.quid,
      ]);
    } else if (
      memberloc &&
      memberloc.section &&
      memberloc.quid &&
      memberloc.quid === "final" &&
      memberloc.section === "final"
    ) {
      this.router.navigate(["courses", this.currentCourse.id, "chapters", "final"]);
    } else {
      const firstChapterId = (await this.getChapters())[0].ID;
      this.router.navigate(["courses", this.currentCourse.id, "chapters", firstChapterId]);
    }
  }

  async getProgress() {
    const progresses = await this.progressService.getProgressByCourse(
      this.currentCourse.id,
      this.currentUser.memberid
    );

    this.progresses = progresses.sort(
      (a, b) => new Date(b.DTStamp).getTime() - new Date(a.DTStamp).getTime()
    );
  }

  async getChapters() {
    if (!this.courseChapters) {
      this.courseChapters = await this.chaptersService.getChaptersBy(this.currentCourse.id);
      return this.courseChapters;
    }
    return this.courseChapters;
  }

  async showIncompleteQuizzes() {
    let content = `<div class="row media">
      <div class="fx-layout-column">
        <div class="row">
          <h5>Notice - Please complete previous chapter before progressing to the next one</h5>
          <div>`;

    for (const quiz of this.incompleteQuizzes) {
      content +=
        `<p class="red-text">Quiz Not Complete: <a href="` +
        this.getIncompleteLink(quiz) +
        `">` +
        quiz.QuizLocation +
        ` - quiz
        ` +
        quiz.QuizID +
        ` - ` +
        quiz.QuizTitle +
        `</a></p>`;
    }

    content += `</div>
        </div>
      </div>
    </div>`;
    this.chapterContent = this.sanitizer.bypassSecurityTrustHtml(content);
  }

  async showChapter() {
    if (this.currChapterViewState.chapterId == "final") {
      this.logger.info(`New Showing final...`);
      return;
    }

    this.chapterSections = await this.chapterSectionsService.findAll(this.currentCourse.id, parseInt(this.currChapterViewState.chapterId));

    this.showSection();

    const sectionHeaders = this.chapterSections.filter((s) => !s.parentId || s.parentId === 0).sort((a, b) => a.order - b.order);
    this.chapterSections = sectionHeaders.flatMap((s) => {
      const children = this.chapterSections.filter((c) => c.parentId === s.id).sort((a, b) => a.order - b.order);
      return [s, ...children]
    });
  }

  async showFinal() {
    this.logger.info(`Showing final...`);
    this.sectionContent = null;
  }

  async getChapterQuizzes(chapterId) {
    if (this.chapterQuizzes && this.chapterQuizzes[chapterId]) {
      return this.chapterQuizzes[chapterId];
    } else {
      this.courseQuizzes = await this.quizesService.findAllPerCourse(this.currentCourse.id);
      this.courseQuizzes.forEach((quiz) => {
        this.chapterQuizzes[quiz.QuizLocation2] = this.chapterQuizzes[quiz.QuizLocation2] || [];
        this.chapterQuizzes[quiz.QuizLocation2].push(quiz);
      });
      return this.chapterQuizzes[chapterId];
    }
  }

  async getPrevIncompleteQuizzes(chapterId) {
    if (this.currentUser.memberstatus === "admin") {
      return [];
    }

    if (chapterId) {
      let chapters = this.getPrevChapters(chapterId);

      this.logger.info(`Checking prev chapters - ${JSON.stringify(chapters)}`);

      for (const chapter of chapters) {
        const incompleteQuizzes = await this.getIncompleteQuizzesFor(chapter.ID);
        if (incompleteQuizzes.length > 0) {
          return incompleteQuizzes;
        }
      }
      return [];
    }

    this.logger.info(
      `No chapter id found. Skipping prev chapter completion check...`
    );
    return [];
  }

  getPrevChapters(chapterId) {
    if (!chapterId) {
      chapterId = this.currChapterViewState.chapterId;
    }

    let chapters = [];
    if (Number.parseInt(chapterId)) {
      this.logger.info(
        `Checking prev chapters completed for chapter - ${chapterId} - ${this.currentUser.memberid} - all chapter - ${this.allChapters.length}`
      );
      chapters = this.allChapters.filter(
        (c) => c.ID < parseInt(chapterId, null)
      );
    } else if (chapterId == "final") {
      chapters = this.allChapters.filter((c) => c.ChapterName.toUpperCase() !== 'FINAL');
    }

    return chapters;
  }

  async getIncompleteQuizzesFor(chapterId): Promise<Quiz[]> {
    if (!chapterId) {
      return [];
    }

    let quizzes = await this.getChapterQuizzes(chapterId);
    const incompleteQuizzes = [];
    for (const quiz of quizzes) {
      if (
        !this.progresses.find((p) => p.QuizID == quiz.QuizID && p.Score >= 60)
      ) {
        this.logger.info(
          `Failed prev chapter check for chapter - ${chapterId} - quiz - ${JSON.stringify(
            quiz.QuizID
          )}`
        );
        incompleteQuizzes.push(quiz);
      }
    }
    return incompleteQuizzes;
  }

  focusContent() {
    const chapterId = this.route.snapshot.paramMap.get("chapterId");
    const quizId = this.route.snapshot.paramMap.get("quizId");
    const videoId = this.route.snapshot.paramMap.get("videoId");
    const soundId = this.route.snapshot.paramMap.get("soundId");
    const sectionId = this.route.snapshot.paramMap.get("sectionId");

    if (quizId) {
      setTimeout(() => {
        const element = this.eChapterContent.nativeElement.querySelector(
          "a[href='courses/" + this.currentCourse.id + "/chapters/" + chapterId + "/quizzes/" + quizId + "']"
        );
        if (element) {
          element.focus();
        }
      }, 500);
    }
    if (videoId) {
      setTimeout(() => {
        const element = this.eChapterContent.nativeElement.querySelector(
          "a[href='courses/" + this.currentCourse.id + "/chapters/" + chapterId + "/videos/" + videoId + "']"
        );

        if (element) {
          element.focus();
        }
      }, 500);
    }
    if (sectionId) {
      setTimeout(() => {
        const element = this.eChapterContent.nativeElement.querySelector(
          "a[href='courses/" + this.currentCourse.id + "/chapters/" + chapterId + "/sections/" + sectionId + "']"
        );

        if (element) {
          element.focus();
        }
      }, 500);
    }
  }

  getIncompleteLink(quiz) {
    return "courses/" + this.currentCourse.id + "/chapters/" + quiz.QuizLocation2 + "/quizzes/" + quiz.QuizID;
  }

  getSectionLink(section: ChapterSection) {
    return "courses/" + this.currentCourse.id + "/chapters/" + section.chapterId + "/sections/" + section.id;
  }

  showSection() {
    if (this.currChapterViewState.sectionId) {
      const section = this.chapterSections.find(s => s.id == parseInt(this.currChapterViewState.sectionId))
      if (section && section.content) {
        this.sectionContent = this.sanitizer.bypassSecurityTrustHtml(section.content);
        return;
      }
    }

    this.sectionContent = null;
  }

}
