import {
  TextToSpeechActions,
  TextToSpeechActionTypes
} from "./text-to-speech.actions";
import { TextToSpeechState, TextToSpeechStates } from "./text-to-speech.model";

const initialState: TextToSpeechState = { state: TextToSpeechStates.NOT_INITIALIZED, text: "", rate: 0.5, pitch: 1 } as TextToSpeechState;

export function textToSpeechReducer(
  state = initialState,
  action: TextToSpeechActions
): TextToSpeechState {
  switch (action.type) {
    case TextToSpeechActionTypes.TextToSpeechNotInitialized:
      return { ...state, state: TextToSpeechStates.NOT_INITIALIZED };
    case TextToSpeechActionTypes.TextToSpeechInitialized:
      return { ...state, state: TextToSpeechStates.INITIALIZED };
    case TextToSpeechActionTypes.TextToSpeechPlaying:
      return { ...state, state: TextToSpeechStates.PLAYING };
    case TextToSpeechActionTypes.TextToSpeechStopped:
      return { ...state, state: TextToSpeechStates.STOPPED };
    case TextToSpeechActionTypes.TextToSpeechPaused:
      return { ...state, state: TextToSpeechStates.PAUSED };
    case TextToSpeechActionTypes.TextToSpeechCompleted:
      return { ...state, state: TextToSpeechStates.COMPLETED };
    default:
      return state;
  }
}
