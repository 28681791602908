import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from 'rxjs';
import { environment } from "../../environments/environment";
import { Question } from "../models/question.model";

@Injectable({
  providedIn: "root",
})
export class QuestionsService {
  baseURL: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  async findByQuiz(id: number) {
    return await lastValueFrom(
      this.http
        .get<Array<Question>>(`${environment.apiUrl}/questions?quizId=${id}`)
    );
  }

  async findBy(id: number) {
    return await lastValueFrom(
      this.http
        .get<Question>(`${environment.apiUrl}/questions/${id}`)
    );
  }

  async create(data) {
    return await lastValueFrom(
      this.http.post(`${environment.apiUrl}/questions`, data)
    );
  }

  async update(id, data) {
    return await lastValueFrom(
      this.http
        .put(`${environment.apiUrl}/questions/${id}`, data)
    );
  }

  async delete(id) {
    return await lastValueFrom(
      this.http
        .delete(`${environment.apiUrl}/questions/${id}`)
    );
  }
}
