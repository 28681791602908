<div class="container white backgrounDColor">
  <div class="row">
    <div class="">
      <div class="">
        <div class="row quiz-quest">
          <!-- <form> -->

          <div class="row">
            <div>
              <h4 class="padding-header">Edit Notification</h4>
            </div>
            <div class="row input-bottom-padding">
              <div class="input-field col m3 s6">
                <h6 class="text-padding-search">Notification Type</h6>
              </div>

              <div class="input-field col m5 s5">
                <input id="ip-search" type="text" class="validate black-text" placeholder="Skipping" />
                <label class="active" htmlFor=""></label>
              </div>
            </div>
            <div class="row input-bottom-padding">
              <div class="input-field col m3 s12">
                <h6 class="text-padding-search">Notification Text</h6>
              </div>

              <div class="col m8 s12">


                <textarea name="area1" cols="40">
                                          </textarea><br />

              </div>
            </div>

          </div>
          <div class="quiz-quest">
            <a class="waves-effect waves-light btn blue btn-border" type="submit">Save</a>
          </div>
          <!-- </form> -->

        </div>




      </div>
    </div>

  </div>
</div>