<div class="row padding-bottom">
  <div class="row col s12" *ngIf="errorMessage">
    <span class="red-text">{{ errorMessage }}</span>
  </div>
  <div class="row col s12" *ngIf="successMessage">
    <span class="green-text">{{ successMessage }}</span>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col s12">
      <div class="backgrounDColor">
        <div class="container">
          <div class="row"></div>
          <div class="row white">
            <div class="col s3"></div>
            <div class="col s6">
              <form [formGroup]="profile" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="col s3"></div>
                  <div class="col s6">
                    <div class="black-text text-padding-profile">
                      <h5>Profile Update</h5>
                    </div>
                  </div>


                  <div class="row col s12">
                    <div class="row">
                      <div class="input-field col m12 s12">
                        <input id="given_name" type="text" class="validate black-text" formControlName="given_name" />
                        <label class="active" htmlFor="given_name">First Name</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="input-field col m12 s12">
                        <input id="family_name" type="text" class="validate black-text" formControlName="family_name" />
                        <label class="active" htmlFor="family_name">Last Name</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="input-field col m12 s12">
                        <input id="" type="text" class="validate black-text" formControlName="email" />
                        <label class="active" htmlFor="">Email</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="input-field col m12 s12">
                        <input id="" type="text" class="validate black-text" formControlName="phone_number" />
                        <label class="active" htmlFor="">Phone</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="input-field col m12 s12">
                        <input id="streetAddress" type="text" class="validate black-text"
                          formControlName="street_address" />
                        <label class="active" htmlFor="streetAddress">Street Address</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="input-field col m12 s12">
                        <input id="middleName" type="text" class="validate black-text active" formControlName="city" />
                        <label class="active" htmlFor="city">City</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="input-field col m12 s12">
                        <input id="" type="text" class="validate black-text" formControlName="postal_code" />
                        <label class="active" htmlFor="">Postal Code</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col m12 s12">
                        <select class="browser-default grey-text" formControlName="state">
                          <option value="" disabled selected>Choose your state</option>
                          <option value="us-al">Alabama</option>
                          <option value="us-ak">Alaska</option>
                          <option value="us-az">Arizona</option>
                          <option value="us-ar">Arkansas</option>
                          <option value="us-ca">California</option>
                          <option value="us-co">Colorado</option>
                          <option value="us-ct">Connecticut</option>
                          <option value="us-de">Delaware</option>
                          <option value="us-dc">District Of Columbia</option>
                          <option value="us-fl">Florida</option>
                          <option value="us-ga">Georgia</option>
                          <option value="us-hi">Hawaii</option>
                          <option value="us-id">Idaho</option>
                          <option value="us-il">Illinois</option>
                          <option value="us-in">Indiana</option>
                          <option value="us-ia">Iowa</option>
                          <option value="us-ks">Kansas</option>
                          <option value="us-ky">Kentucky</option>
                          <option value="us-la">Louisiana</option>
                          <option value="us-me">Maine</option>
                          <option value="us-md">Maryland</option>
                          <option value="us-ma">Massachusetts</option>
                          <option value="us-mi">Michigan</option>
                          <option value="us-mn">Minnesota</option>
                          <option value="us-ms">Mississippi</option>
                          <option value="us-mo">Missouri</option>
                          <option value="us-mt">Montana</option>
                          <option value="us-ne">Nebraska</option>
                          <option value="us-nv">Nevada</option>
                          <option value="us-nh">New Hampshire</option>
                          <option value="us-nj">New Jersey</option>
                          <option value="us-nm">New Mexico</option>
                          <option value="us-ny">New York</option>
                          <option value="us-nc">North Carolina</option>
                          <option value="us-nd">North Dakota</option>
                          <option value="us-oh">Ohio</option>
                          <option value="us-ok">Oklahoma</option>
                          <option value="us-or">Oregon</option>
                          <option value="us-pa">Pennsylvania</option>
                          <option value="us-ri">Rhode Island</option>
                          <option value="us-sc">South Carolina</option>
                          <option value="us-sd">South Dakota</option>
                          <option value="us-tn">Tennessee</option>
                          <option value="us-tx">Texas</option>
                          <option value="us-ut">Utah</option>
                          <option value="us-vt">Vermont</option>
                          <option value="us-va">Virginia</option>
                          <option value="us-wa">Washington</option>
                          <option value="us-wv">West Virginia</option>
                          <option value="us-wi">Wisconsin</option>
                          <option value="us-wy">Wyoming</option>
                        </select>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col s3"></div>
                  <div class="col s6">
                    <div class="center-align">
                      <div class="padding-card-button center">
                        <button class="waves-effect waves-light btn sign-in-btn button-width" type="submit"
                          [disabled]="!profile.valid">Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>


</div>