import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { NGXLogger } from "ngx-logger";
import { filter } from "rxjs/operators";
import { User } from "src/app/models/user.model";
import { AuthService } from "src/app/services/auth-service/auth.service";
import {
  ForgotPasswordFailed,
  ForgotPasswordSubmitted,
  ForgotPasswordSubmitting,
  SignedOutAction,
  SignInActionTypes
} from "../signin/signin.actions";
import { SignInState } from "../signin/signin.model";
import {
  getSignIn,
  isForgotPassword,
  isForgotPasswordFailed
} from "../signin/signin.selector";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"]
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordVerifyCodeForm = new FormGroup({
    verificationCode: new FormControl("", Validators.required),
    newPassword: new FormControl("", Validators.required),
    reNewPassword: new FormControl("", Validators.required)
  });

  forgotPasswordUser$ = this.signInStore.pipe(select(isForgotPassword));
  forgotPasswordUser: User;

  isForgotPasswordFailed$ = this.signInStore.pipe(
    select(isForgotPasswordFailed),
    filter(val => (val ? true : false))
  );

  currentState$ = this.signInStore.pipe(select(getSignIn));
  currentState: string;

  errorMessage;

  constructor(
    private signInStore: Store<SignInState>,
    private authService: AuthService,
    private logger: NGXLogger
  ) {
    this.currentState$.subscribe(
      state => (this.currentState = state.currentState.toString())
    );
    this.forgotPasswordUser$.subscribe(loginState => {
      if (loginState && loginState.user) {
        this.forgotPasswordUser = loginState.user;
        return;
      }
    });

    this.isForgotPasswordFailed$.subscribe(
      loginState => (this.errorMessage = loginState.error)
    );
  }

  ngOnInit() {}

  private clearMessages() {
    this.errorMessage = null;
  }

  gotoSignIn() {
    this.signInStore.dispatch(
      new SignedOutAction({
        currentState: SignInActionTypes.SignedOut
      } as SignInState)
    );
  }

  forgotPasswordVerifyCode() {
    if (
      this.forgotPasswordVerifyCodeForm.valid &&
      this.forgotPasswordVerifyCodeForm.controls.newPassword.value ===
        this.forgotPasswordVerifyCodeForm.controls.reNewPassword.value
    ) {
      this.signInStore.dispatch(
        new ForgotPasswordSubmitting({
          currentState: SignInActionTypes.ForgotPasswordSubmitting
        } as SignInState)
      );

      this.authService
        .forgotPasswordVerifyCode(
          this.forgotPasswordUser.membername,
          this.forgotPasswordVerifyCodeForm.controls.verificationCode.value,
          this.forgotPasswordVerifyCodeForm.controls.newPassword.value
        )
        .then(res => {
          this.logger.info("Forgot password successful...");
          this.signInStore.dispatch(
            new ForgotPasswordSubmitted({
              currentState: SignInActionTypes.ForgotPasswordSubmitted
            } as SignInState)
          );
        })
        .catch(err => {
          this.logger.error(`Error - ${JSON.stringify(err)}`);
          this.signInStore.dispatch(
            new ForgotPasswordFailed({
              currentState: SignInActionTypes.ForgotPasswordFailed,
              error: err.message
            } as SignInState)
          );
        });
    } else {
      this.errorMessage = "Password's do not match";
    }
  }
}
