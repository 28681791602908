import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { EdisonCode } from "src/app/models/edison-code.model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class EdisonService {
  constructor(private http: HttpClient) { }

  authorize(code: EdisonCode) {
    return lastValueFrom(this.http
      .post<{ membername, token, href, refreshToken }>(
        `${environment.apiUrl}/edison/authorize`, code
      ));
  }

}
