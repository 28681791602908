<div class="row padding-row backgrounDColor">

  <div class="col s12 m4 l4">
    <div class="card">
      <div class="card-content black-text">
        <div class="center"><img src="{{'assets/img/edit-banner.png'}}" class="image-width"> </div>
        <p class="center">STUDENT
        </p>
      </div>
      <div class="card-action center">
        <a class="waves-effect waves-light btn-small btn-border" routerLink="/admin/regmembers">search</a>
      </div>
    </div>
  </div>
  <div class="col s12 m4 l4">
    <div class="card">
      <div class="card-content black-text">
        <div class="center"><img src="{{'assets/img/pages.png'}}" class="image-width"> </div>
        <p class="center">UPDATE / EDIT
        </p>
      </div>
      <div class="card-action center">
        <a class="waves-effect waves-light btn-small btn-border" routerLink="/admin/page-list">pages</a>
        <a class="waves-effect waves-light btn-small btn-border" routerLink="/admin/banner-list">banner</a>
      </div>
    </div>
  </div>
  <div class="col s12 m4 l4">
    <div class="card">
      <div class="card-content black-text">
        <div class="center"><img src="{{'assets/img/study.png'}}" class="image-width"> </div>
        <p class="center">UPDATE / EDIT
        </p>
      </div>
      <div class="card-action center">
        <a class="waves-effect waves-light btn-small btn-border" routerLink="/admin/courses">Courses Manager</a>
        <a class="waves-effect waves-light btn-small btn-border" routerLink="/admin/quizzes">Quizzes Manager</a>
        <a class="waves-effect waves-light btn-small btn-border" routerLink="/admin/assets">Assets Manager</a>
      </div>
    </div>
  </div>
  <div class="col s12 m4 l4">
    <div class="card">
      <div class="card-content black-text">
        <div class="center"><img src="{{'assets/img/bookshelf.png'}}" class="image-width"> </div>
        <p class="center">Manager
        </p>
      </div>
      <div class="card-action center">
        <a class="waves-effect waves-light btn-small btn-border" routerLink="/admin/referred-by">Referred By manager</a>
        <a class="waves-effect waves-light btn-small btn-border" routerLink="/admin/area-unit">Area Unit manger</a>
        <!-- <a class="waves-effect waves-light btn-small btn-border" routerLink="/admin/school-manager">School manger</a> -->
      </div>
    </div>
  </div>
  <div class="col s12 m4 l4">
    <div class="card">
      <div class="card-content black-text">
        <div class="center">
          <fa-icon [icon]="reportIcon" size="7x"></fa-icon>
        </div>
        <p class="center">Area Units Reports
        </p>
      </div>
      <div class="card-action center">
        <a class="waves-effect waves-light btn-small btn-border" routerLink="/admin/area-unit-reports/16">CLIU Web </a>
        <a class="waves-effect waves-light btn-small btn-border" routerLink="/admin/area-unit-reports/18"> CAOLA </a>
      </div>
    </div>
  </div>

  <div class="col s12 m4 l4">
    <div class="card">
      <div class="card-content black-text">
        <div class="center"><img src="{{'assets/img/test-quiz.png'}}" class="image-width"> </div>
        <p class="center">Course
        </p>
      </div>
      <div class="card-action center">
        <a class="waves-effect waves-light btn-small btn-border" routerLink="/courses">Courses</a>
      </div>
    </div>
  </div>
  <!-- <div class="col s12 m4 l4">
              <div class="card">
                <div class="card-content black-text">
                  <div class="center"><img src="images/chat.png" class="image-width"> </div>  
                  <p class="center">NOTIFICATION
                  </p>
                 </div>
                <div class="card-action">
                <a class="waves-effect waves-light btn blue btn-border">notification</a>
                </div>
              </div>
     </div> -->

</div>
