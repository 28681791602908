import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BannerService } from "../../../services/banner.service";

@Component({
  selector: "app-edit-banner",
  templateUrl: "./edit-banner.component.html",
  styleUrls: ["./edit-banner.component.css"],
})
export class EditBannerComponent implements OnInit {
  editBanner;
  mycontent: string;
  log: string = "";

  constructor(
    private bannerService: BannerService,
    private route: ActivatedRoute,
    private routerNav: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.editBanner = this.formBuilder.group({
      Id: ["", Validators.required],
      Name: ["", Validators.required],
      Content: ["", Validators.required],
      Active: ["", Validators.required],
    });
    this.route.queryParams.subscribe((params) => {
      this.getBanner(params["bannerId"]);
    });
  }

  addOrUpdateRegmember() {
    this.bannerService
      .addOrEditBanner("update", this.editBanner.value)
      .then((res) => {
        this.routerNav.navigate([`/admin/banner-list`]);
      });
  }

  getBanner(BannerId) {
    this.bannerService.getBannerBy(BannerId).then((res) => {
      this.editBanner.patchValue(res);
    });
  }

  goBack() {
    this.routerNav.navigate([`/admin/banner-list`]);
  }
}
