<div class="fx-layout-column">
  <div class="fx-flex">
    <nav mat-tab-nav-bar mat-stretch-tabs="false" mat-align-tabs="center" class="background-color chapters-nav"
      [tabPanel]="tabPanel">
      <a mat-tab-link *ngFor="let link of links" [routerLink]="link.path" routerLinkActive #rla="routerLinkActive"
        [active]="rla.isActive" class="white-text"> {{link.label}}</a>
      <!-- <button mat-raised-button color="mat-teal" (click)="ttsPlay()" class="text-speech talkify-control-center"
        *ngIf="links && synth && !synth.speaking">Text To Speech</button>
      <button color="mat-teal" (click)="ttsTogglePause()" *ngIf="links && synth && synth.speaking && !synth.paused"><i
          class="material-icons">pause_circle</i></button>
      <button color="mat-teal" (click)="ttsTogglePause()" *ngIf="links && synth && synth.speaking && synth.paused"><i
          class="material-icons">play_circle</i></button>
      <button color="mat-teal" (click)="ttsStop()" *ngIf="links && synth && synth.speaking"><i
          class="material-icons">stop_circle</i></button> -->
      <app-text-to-speech></app-text-to-speech>
      <app-course-timer [showTimer]="true" [showTimerIncomplete]="false" [courseChapters]="courseChapters"
        [courseQuizzes]="courseQuizzes" [progresses]="progresses" *ngIf="courseChapters && courseQuizzes && progresses">
      </app-course-timer>
      <!-- <a mat-tab-link disabled style="color: white;"
        *ngIf="(currChapterViewState$ | async).currentState === 'ChapterShow' || (currChapterViewState$ | async).currentState === 'QuizzesIncomplete' || (currChapterViewState$ | async).currentState === 'TimeInComplete'">
        <span *ngIf="timerString">Time remaining in Chapter: {{ timerString }}</span></a> -->
    </nav>
    <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
  </div>
  <div class="fx-flex" #courseViewContent>
    <div class="fx-layout-row fx-layout-align-space-evenly-start"
      *ngIf="(currChapterViewState$ | async).currentState === 'ChapterShow' || (currChapterViewState$ | async).currentState === 'ChapterFinalShow'">
      <!-- <div #content [innerHTML]="chapterContent" class="chapter-content fx-flex-28pc" routeTransformer
        *ngIf="(currChapterViewState$ | async).currentState !== 'FinalShow' && chapterSections.length == 0"></div> -->
      <div #content class="chapter-content fx-flex-28pc" routeTransformer
        *ngIf="(currChapterViewState$ | async).currentState !== 'ChapterFinalShow' && chapterSections.length > 0">
        <div class="fx-layout-column">
          <div *ngFor="let section of chapterSections">
            <div class="section" *ngIf="!section.parentId || section.parentId == 0">
              <div class="title" *ngIf="section.location"><a [href]="section.location"
                  [target]="section.target ? section.target : '_blank'">{{ section.name }}</a></div>
              <div class="title" *ngIf="!section.location">{{ section.name }}</div>
              <div class="description" *ngIf="section.description">{{ section.description }}</div>
              <div class="hint" *ngIf="section.hint">{{ section.hint }}</div>
            </div>
            <div class="subsection" *ngIf="section.parentId && section.parentId != 0">
              <div class="title" *ngIf="section.location && !section.content"><a [href]="section.location"
                  [target]="section.target ? section.target : '_blank'">{{ section.name }}</a></div>
              <div class="title" *ngIf="section.content"><a [href]="getSectionLink(section)">{{ section.name }}</a>
              </div>
              <div class="description" *ngIf="section.description">{{ section.description }}</div>
              <div class="hint" *ngIf="section.hint">{{ section.hint }}</div>
            </div>

          </div>
        </div>
      </div>
      <div class="quiz-content fx-flex-68pc">
        <div class="section-content" [innerHTML]="sectionContent" routeTransformer *ngIf="sectionContent"></div>
        <app-quiz-tests #chapters></app-quiz-tests>
      </div>
    </div>

    <div class="fx-layout-row fx-layout-align-space-evenly-start"
      *ngIf="(currChapterViewState$ | async).currentState === 'QuizzesIncomplete'">
      <div class="final fx-flex-auto" routeTransformer>
        <div #content [innerHTML]="chapterContent" class="chapter-content fx-flex-auto" routeTransformer>
        </div>
      </div>
    </div>

    <app-course-timer [showTimer]="false" [showTimerIncomplete]="true" [courseChapters]="courseChapters"
      [courseQuizzes]="courseQuizzes" [progresses]="progresses"
      *ngIf="courseChapters && courseQuizzes && progresses && (currChapterViewState$ | async).currentState !== 'QuizzesIncomplete'">
    </app-course-timer>


  </div>
