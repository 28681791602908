<div class="backgroundColor">
  <div class="fx-layout-row" fxFlexFill fxLayoutAlign="center center">
    <div fxFlex="90%" class="white">
      <div [innerHTML]="aboutUsContent"></div>
    </div>
  </div>
</div>

<!-- <div class="row">
<div class="col s12">
  <div class="container">
      <div [innerHTML]="dom"></div>
  </div>
</div>
</div> -->
