import {
    TokenProvider,
    decodeJWT
} from 'aws-amplify/auth';

// Note: This example requires installing `@aws-sdk/client-cognito-identity` to obtain Cognito credentials
// npm i @aws-sdk/client-cognito-identity
import { CognitoIdentity } from '@aws-sdk/client-cognito-identity';
import { environment } from 'src/environments/environment';

// You can make use of the sdk to get identityId and credentials
const cognitoidentity = new CognitoIdentity({
    region: environment.cognito.region,
});


export interface FederatedLogin {
    domain: string;
    token: string;
}

export interface EdisonFedertedLogin extends FederatedLogin {
    edisonDomain: string;
    edisonRefreshToken: string;
}

export class CommonTokenProvider implements TokenProvider {
    constructor(private tokenProvider: TokenProvider) { }

    federatedLogin?: FederatedLogin | EdisonFedertedLogin;

    // Custom method to load the federated login information
    loadFederatedLogin(login?: typeof this.federatedLogin) {
        // You may also persist this by caching if needed
        this.federatedLogin = login;
    }

    async getTokens({ forceRefresh }) {
        if (forceRefresh) {
            // try to obtain new tokens if possible
        }

        if (this.federatedLogin && this.federatedLogin.token) {
            const accessTokenString = this.federatedLogin.token;
            const decoded = decodeJWT(accessTokenString);

            return {
                accessToken: decoded,
            };
        }

        return this.tokenProvider.getTokens({ forceRefresh });
    }
};