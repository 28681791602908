<div class="container-fluid">
  <div class="row backgroundColor">
    <div class="col s12">
      <div class="row"></div>
      <div class="row"></div>
      <div class="row"></div>
      <div class="container white">
        <div class="row testimonial-container">
          <div class="col s7">
            <div class="col s12">
              <div class="row"></div>
              <div class="row">
                <div class="col s12">
                  <span class="dot"></span>
                  <span class="testimonial-span-head">What They're Saying About Our Driving School</span>
                </div>
                <!-- <div class="col s9">
                  
              </div> -->
              </div>
            </div>
            <div class="col s12">
              <p class="testimonial-font-Quotation">"Thank goodness for the Edward Savioz Driver Training School! The
                lessons were amazing and I learned so much so fast!"</p>
              <p class="testimonial-font-student">- a student from Secane, PA</p>
            </div>
            <div class="col s12">
              <p class="testimonial-font-Quotation">"The Edward Savioz Driver Training School helped to calm my nerves
                and learn that driving can be fun and safe. " </p>
              <p class="testimonial-font-student">- a student from Broomall, PA</p>
            </div>
            <div class="col s12">
              <p class="testimonial-font-Quotation">"The Edward Savioz Driver Training School provided service that was
                unbelievable. The driver was always courteous and on time. I would recommend him to anyone!"</p>
              <p class="testimonial-font-student">- a student from Drexel Hill, PA</p>
            </div>
            <div class="col s12">
              <p class="testimonial-font-Quotation">This is our own testimonial to all of our wonderful customers. We
                never forget that your satisfaction keeps us in business. Thank you for your business.</p>
            </div>
            <div class="col s12">
              <p class="testimonial-font-student">Edward Savioz Driver Training School</p>
              <p class="testimonial-font-student">Owner and Operator of the Edward Savioz Driver Training School</p>
            </div>
          </div>
          <div class="col s4">
            <div class="row"></div>
            <div class="row"></div>
            <div class="row"></div>
            <div class="row"></div>
            <div class="row">
              <div class="col s10 testimonial-image">
                <div class="row"></div>
                <img src="{{'assets/img/students.jpg'}}" class="responsive-img">
              </div>

            </div>
          </div>
          <div class="col s1">

          </div>
        </div>
      </div>
      <div class="row"></div>
      <div class="row"></div>
    </div>
  </div>
</div>