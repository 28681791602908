import { Action } from '@ngrx/store';

export enum AmplifyActionTypes {
  AmplifyStateChanged = 'AmplifyStateChanged',
}

export interface AmplifyAuthEvent {
  event: string,
  data?: any,
  message: string
}

export class AmplifyStateChangedAction implements Action {
  readonly type = AmplifyActionTypes.AmplifyStateChanged;

  constructor(public payload: AmplifyAuthEvent) { }
}

export type AmplifyActions = AmplifyStateChangedAction;