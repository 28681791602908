import { Action } from "@ngrx/store";
import { PaymentState } from "../payment/payment.model";

export enum PaymentActionTypes {
  PaymentLoading = "PaymentLoading",
  PaymentShow = "PaymentShow",
  PaymentSubmitting = "PaymentSubmitting",
  PaymentSuccessful = "PaymentSuccessful",
  PaymentFailed = "PaymentFailed"
}

export class PaymentLoadingAction implements Action {
  readonly type = PaymentActionTypes.PaymentLoading;
  constructor(public payload: PaymentState) { }
}

export class PaymentShowAction implements Action {
  readonly type = PaymentActionTypes.PaymentShow;
  constructor(public payload: PaymentState) { }
}

export class PaymentSubmittingAction implements Action {
  readonly type = PaymentActionTypes.PaymentSubmitting;
  constructor(public payload: PaymentState) { }
}

export class PaymentSuccessfulAction implements Action {
  readonly type = PaymentActionTypes.PaymentSuccessful;
  constructor(public payload: PaymentState) { }
}

export class PaymentFailedAction implements Action {
  readonly type = PaymentActionTypes.PaymentFailed;
  constructor(public payload: PaymentState) { }
}

export type PaymentActions =
  | PaymentLoadingAction
  | PaymentShowAction
  | PaymentSubmittingAction
  | PaymentSuccessfulAction
  | PaymentFailedAction;
