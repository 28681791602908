import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { NGXLogger } from "ngx-logger";
import { Table } from "primeng/table";
import { Question } from "src/app/models/question.model";
import { Quiz } from "src/app/models/quiz.model";
import { QuestionsService } from "src/app/services/questions.service";
import { QuizesService } from "src/app/services/quizes.service";

@Component({
  selector: "app-questions-list",
  templateUrl: "./questions-list.component.html",
  styleUrls: ["./questions-list.component.css"],
})
export class QuestionsListComponent implements OnInit {
  @ViewChild("dt") private dt: Table;

  first = 0;
  rows = 15;
  sortField;
  sortOrder;
  filters;
  totalRecords = 0;

  displayDialog: boolean;

  quiz: Quiz;

  selectedQuestion: Question;

  newQuestion: boolean;

  questions: Question[];
  question: Question;

  cols: any[];

  errorMessage;

  questionForm = new FormGroup({
    QuestionText: new FormControl("", Validators.required),
    questiontype: new FormControl("", Validators.required),
  });

  loading = true;
  courseId: number;

  constructor(
    private logger: NGXLogger,
    private questionsService: QuestionsService,
    private quizesService: QuizesService,
    public router: Router,
    private activateRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activateRoute.params.subscribe(async (params) => {
      if (params["courseId"]) {
        this.courseId = parseInt(params["courseId"]);
      }
      await this.quizesService.findByCourseIdAndQuizId(this.courseId, params["quizId"]).then(async (quiz) => {
        this.quiz = quiz;
        await this.loadQuestions();
      });
    });
    this.cols = [
      { field: "QuestionText", header: "Text" },
      { field: "questiontype", header: "Type" },
    ];
  }

  async loadQuestions() {
    if (!this.quiz) {
      return;
    }

    this.questions = await this.questionsService.findByQuiz(this.quiz.QuizID);

    this.totalRecords = this.questions.length;
    this.loading = false;
  }

  gotoAnswerOptions(question) {
    let navigationExtras: NavigationExtras = {
      queryParams: { questionId: question.QuestionID },
      // fragment: 'anchor'
    };
    this.router.navigate([
      "admin", "courses", this.courseId, "quizzes",
      this.quiz.QuizID,
      "questions",
      this.question.QuestionID,
      "answerOptions",
    ]);
  }

  showDialogToAdd() {
    this.newQuestion = true;
    this.displayDialog = true;
    this.clearAll();
    this.question = {
      QuizID: this.quiz.QuizID,
      questiontype: "mch",
    } as Question;
  }

  save() {
    let p: Promise<any>;
    if (this.newQuestion) {
      p = this.questionsService.create(this.question);
    } else {
      p = this.questionsService.update(this.question.QuestionID, this.question);
    }

    return p
      .then(() => {
        this.loadQuestions();
        this.clearAll();
        this.displayDialog = false;
      })
      .catch((err) => {
        if (
          err &&
          err.status === 400 &&
          err.error &&
          err.error.error &&
          err.error.error.description
        ) {
          this.errorMessage = err.error.error.description;
        }
      });
  }

  onRowSelect(event) {
    this.newQuestion = false;
    this.clearAll();
    this.question = Object.assign({} as Question, event.data);
    this.loadQuestions();
    this.displayDialog = true;
  }

  clearAll() {
    this.question = {} as Question;
    this.errorMessage = null;
  }

  delete() {
    let c = confirm("Are you sure you want to delete this question ?");
    if (c) {
      this.questionsService.delete(this.question.QuestionID).then(() => {
        this.loadQuestions();
        this.clearAll();
        this.displayDialog = false;
      });
    }
  }
}
