import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Page } from "../models/pages.model";

@Injectable({
  providedIn: "root",
})
export class PagesService {
  constructor(private http: HttpClient) { }

  getPages(): Promise<Page[]> {
    return this.http
      .get<Page[]>(`${environment.apiUrl}/pages`)
      .toPromise();
  }

  getPage(id: number): Promise<Page> {
    return this.http
      .get<Page>(`${environment.apiUrl}/pages/${id}`)
      .toPromise();
  }

  createPage(page: Page) {
    return this.http
      .post(`${environment.apiUrl}/pages`, page)
      .toPromise();
  }

  updatePage(id: number, page: Page) {
    return this.http
      .put(`${environment.apiUrl}/pages/${id}`, page)
      .toPromise();
  }
}
