import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { } from "@ngrx/router-store";
import { PagesService } from "src/app/services/pages.service";
@Component({
  selector: "app-courseinfo",
  templateUrl: "./courseinfo.component.html",
  styleUrls: ["./courseinfo.component.css"]
})
export class CourseinfoComponent implements OnInit {
  content;

  constructor(
    private pagesService: PagesService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.getPage();
  }

  getPage() {
    this.pagesService
      .getPage(2)
      .then(
        res =>
        (this.content = this.sanitizer.bypassSecurityTrustHtml(
          res["PageContent"]
        ))
      );
  }
}
