import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Affiliate } from "../models/affiliate.model";

@Injectable({
  providedIn: "root"
})
export class AffiliatesService {
  constructor(private http: HttpClient) {}

  async getAffiliates(): Promise<Affiliate[]> {
    return this.http
      .get<Affiliate[]>(environment.apiUrl + "/affiliates")
      .toPromise();
  }

  async getAffiliate(affiliateId: number): Promise<Affiliate> {
    return this.http
      .get<Affiliate>(`${environment.apiUrl}/affiliates/${affiliateId}`)
      .toPromise();
  }

  createAffiliate(affiliate: Affiliate) {
    return this.http
      .post(environment.apiUrl + `/affiliates`, affiliate)
      .toPromise();
  }

  updateAffiliate(affiliate: object, affiliateId: number) {
    return this.http
      .put(environment.apiUrl + `/affiliates/${affiliateId}`, affiliate)
      .toPromise();
  }

  deleteAffiliate(affiliateId) {
    return this.http
      .delete(environment.apiUrl + `/affiliates/${affiliateId}`)
      .toPromise();
  }
}
