import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NGXLogger } from "ngx-logger";
import { Table } from "primeng/table";
import { Chapter } from "src/app/models/chapters.model";
import { Course } from "src/app/models/course.model";
import { ChaptersService } from "src/app/services/chapters.service";
import { CoursesService } from "src/app/services/courses.service";
import { QuizesService } from "src/app/services/quizes.service";
import { Editor } from "tinymce";

@Component({
  selector: "app-chapters",
  templateUrl: "./chapters.component.html",
  styleUrls: ["./chapters.component.css"]
})
export class ChaptersComponent implements OnInit {
  @ViewChild("dt") private dt: Table;
  @ViewChild("editor") private editor: Editor;

  first = 0;
  rows = 15;
  sortField;
  sortOrder;
  filters;
  totalRecords = 0;

  displayDialog: boolean;

  selectedChapter: Chapter;

  newChapter: boolean;

  chapters: Chapter[];
  chapter: Chapter;
  currentCourse: Course;
  currentCourseId: number;

  cols: any[];

  errorMessage;
  successMessage;

  dateFilters: any;

  chapterQuizzes = [];

  chapterForm = new FormGroup({
    chapterName: new FormControl("", Validators.required),
    chapterContent: new FormControl(""),
  });

  loading = true;

  constructor(
    private logger: NGXLogger,
    private chaptersService: ChaptersService,
    private coursesService: CoursesService,
    private quizzesService: QuizesService,
    public router: Router,
    private activateRoute: ActivatedRoute
  ) { }

  async loadChapters() {
    this.chapters = await this.chaptersService.getChaptersBy(this.currentCourseId);

    this.totalRecords = this.chapters.length;
    this.loading = false;
  }

  async ngOnInit() {
    this.cols = [
      { field: "ID", header: "ID" },
      { field: "ChapterName", header: "Chapter Name" },
    ];


    this.activateRoute.paramMap.subscribe(async (paramMap) => {
      const params = paramMap && paramMap["params"];
      if (params && params["courseId"]) {
        this.currentCourseId = params["courseId"];
        this.currentCourse = await this.coursesService.findBy(this.currentCourseId);
        await this.loadChapters();
      } else {
        this.errorMessage = "No course found";
        this.loading = false;
        return;
      }
    });


  }

  showDialogToAdd() {
    this.newChapter = true;
    this.displayDialog = true;
    this.clearAll();
  }

  save() {
    let p: Promise<any>;
    if (this.newChapter) {
      p = this.chaptersService.createChapterBy(this.currentCourse.id, this.chapter);
    } else {
      p = this.chaptersService.updateChapterBy(this.currentCourse.id, this.chapter.ID, this.chapter);
    }

    return p
      .then(() => {
        this.loadChapters();
        this.successMessage = "Chapter saved successfully";
      })
      .catch((err) => {
        if (
          err &&
          err.status === 400 &&
          err.error &&
          err.error.error &&
          err.error.error.description
        ) {
          this.errorMessage = err.error.error.description;
        }
      });
  }

  async onRowSelect(event) {
    this.newChapter = false;
    this.clearAll();
    this.chapter = Object.assign({} as Chapter, event.data);
    if (this.chapter && this.chapter.ID && this.chapterQuizzes.length == 0) {
      const courseQuizzes = await this.quizzesService.findAllPerCourse(this.currentCourseId);
      this.chapterQuizzes = courseQuizzes.filter(q => this.chapter.ID == parseInt(q.QuizLocation2))
        .map((q) => ({ "title": q.QuizTitle, "value": "./courses/" + this.currentCourse.id + "/chapters/" + q.QuizLocation2 + "/quizzes/" + q.QuizID }));
    }
    await this.loadChapters();
    this.displayDialog = true;
  }


  clearAll() {
    this.chapter = { ChapterContent: "" } as Chapter;
    this.chapterQuizzes = [];
    this.errorMessage = null;
    this.successMessage = null;
  }

  handleClose() {
    this.displayDialog = false;
    this.clearAll();
    this.logger.info(`Closing dialog...`);
  }

  goToSections() {
    this.router.navigate(["admin", "courses", this.currentCourse.id, "chapters", this.chapter.ID, "sections"]);
  }
}
