import { AmplifyActions, AmplifyActionTypes, AmplifyAuthEvent } from './amplify.actions';


const initialState: AmplifyAuthEvent = {} as AmplifyAuthEvent;

export function amplifyReducer(state = initialState, action: AmplifyActions): AmplifyAuthEvent {
  switch (action.type) {
    case AmplifyActionTypes.AmplifyStateChanged:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}