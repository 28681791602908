import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import * as Highcharts from "highcharts";
import { NGXLogger } from "ngx-logger";
import { Observable, from } from "rxjs";
import {
  count,
  distinct,
  filter,
  map,
  mergeAll,
  reduce,
  toArray,
} from "rxjs/operators";
import { SignInState } from "src/app/components/allusers/signin/signin.model";
import { getSignedInUser } from "src/app/components/allusers/signin/signin.selector";
import { Progress } from "src/app/models/progress.model";
import { Quiz } from "src/app/models/quiz.model";
import { User } from "src/app/models/user.model";
import { ChaptersService } from "src/app/services/chapters.service";
import { MemberlocService } from "src/app/services/memberloc.service";
import { ProgressService } from "src/app/services/progress.service";
import { QuizesService } from "src/app/services/quizes.service";
import { RegmembersService } from "src/app/services/regmembers.service";
import { CourseState } from "../courses/courses.reducer";
import { getCourseSelected, isCourseNotSelected } from "../courses/courses.selector";
import { Course } from "src/app/models/course.model";
import { QuizTestsState } from "../quiz-tests/quiz-tests.model";
import { getFinalQuiz } from "src/app/components/members/quiz-tests/quiz-tests.selector";
// import 'rxjs/Rx' ;
@Component({
  selector: "app-course-progress",
  templateUrl: "./course-progress.component.html",
  styleUrls: ["./course-progress.component.css"],
})
export class CourseProgressComponent implements OnInit {
  memberProgress$: Observable<Progress[]>;
  progressPercentage = 0;
  completedFinal;

  highcharts = Highcharts;
  displayedColumns: string[] = [
    "Location",
    "QuizTitle",
    "Attempts",
    "Score",
    "DTStamp",
  ];
  chartOptions = {
    chart: {
      type: "pie",
      renderTo: "container",
    },
    title: {
      verticalAlign: "middle",
      floating: true,
      text: "Loading...",
    },
    plotOptions: {
      pie: {
        innerSize: "80%",
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        data: [
          { name: "Completed", y: 100 - this.progressPercentage },
          { name: "Not Completed", y: this.progressPercentage },
          // ['IE6', 20],
          // ['Chrome', 3.1],
          // ['Other', 5.4]
        ],
      },
    ],
  };

  scoreAverage = 0;
  scoreChartOptions = {
    chart: {
      type: "pie",
      renderTo: "container",
    },
    title: {
      verticalAlign: "middle",
      floating: true,
      text: "Loading...",
    },
    plotOptions: {
      pie: {
        innerSize: "80%",
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        data: [
          { name: "Correct", y: this.scoreAverage },
          { name: "Incorrect", y: 100 - this.scoreAverage },

          // ['IE6', 20],
          // ['Chrome', 3.1],
          // ['Other', 5.4]
        ],
      },
    ],
  };

  getLoggedInUser$ = this.loginStore.pipe(select(getSignedInUser));
  currentUser: User = null;

  courseSelected$ = this.courseStore.pipe(select(getCourseSelected), filter(val => !val));
  courseNotSelected$ = this.courseStore.pipe(select(isCourseNotSelected), filter(val => val))
  currentCourse: Course;
  currCourseQuizzes: Quiz[];

  finalQuiz$ = this.quizStore.pipe(select(getFinalQuiz), filter(val => val !== null));
  finalQuiz: Quiz;

  constructor(
    private progressService: ProgressService,
    private quizesService: QuizesService,
    private loginStore: Store<SignInState>,
    private logger: NGXLogger,
    private route: ActivatedRoute,
    private regmemService: RegmembersService,
    private chaptersService: ChaptersService,
    private router: Router,
    private courseStore: Store<CourseState>,
    private memberLocService: MemberlocService,
    private quizStore: Store<QuizTestsState>
  ) { }

  async ngOnInit() {
    this.courseSelected$.subscribe(async val => {
      this.currentCourse = val;
    });
    this.courseNotSelected$.subscribe(val => this.currentCourse = undefined);

    const membername = this.route.snapshot.paramMap.get("membername");
    if (membername) {
      this.currentUser = await this.regmemService.getUserBy(membername);
    } else {
      this.getLoggedInUser$.subscribe((user) => (this.currentUser = user));
    }

    const memberProgress = await this.getMemberProgress();
    const quizProgress: Progress[] = [];

    for (const progress of memberProgress) {
      if (!quizProgress.find((i) => i.QuizID === progress.QuizID)) {
        progress["attempts"] = 1;
        quizProgress.push(progress);
      } else {
        const p = quizProgress.find((i) => i.QuizID == progress.QuizID);
        if (p && p["attempts"]) {
          p["attempts"] = ++p["attempts"];
        }
      }

      if (
        +progress.Score >
        +quizProgress.find((i) => i.QuizID == progress.QuizID).Score
      ) {
        const curr =
          quizProgress[
          quizProgress.findIndex((i) => i.QuizID == progress.QuizID)
          ];

        progress["attempts"] = curr["attempts"];
        quizProgress[
          quizProgress.findIndex((i) => i.QuizID == progress.QuizID)
        ] = progress;
      }

      if (
        new Date(progress.DTStamp).getTime() >
        new Date(
          quizProgress.find((i) => i.QuizID == progress.QuizID).DTStamp
        ).getTime()
      ) {
        quizProgress[
          quizProgress.findIndex((i) => i.QuizID == progress.QuizID)
        ].DTStamp = progress.DTStamp;
      }
    }

    this.memberProgress$ = from(
      quizProgress.sort(
        (a, b) => new Date(b.DTStamp).getTime() - new Date(a.DTStamp).getTime()
      )
    ).pipe(toArray());

    this.getProgressPercentage();
    this.getAverageScore();
  }

  getLocalDate(strDate: string) {
    return new Date(strDate).toLocaleDateString("en-US");
  }

  async getMemberProgress() {
    try {
      const memberProgress: Progress[] = await this.progressService.getProgressByCourse(
        this.currentCourse.id,
        this.currentUser.memberid
      );

      this.currCourseQuizzes = await this.quizesService.findAllPerCourse(this.currentCourse.id);
      this.completedFinal = memberProgress.find(
        (p) => p.QuizID == this.finalQuiz?.QuizID && p.Score > 60
      );

      for (const progress of memberProgress) {
        for (const quiz of this.currCourseQuizzes) {
          if (progress.QuizID === quiz.QuizID) {
            memberProgress[memberProgress.indexOf(progress)].quiz = quiz;
          }
        }
      }

      return memberProgress;
    } catch (err) {
      this.logger.error(err);
    }
  }

  getProgressPercentage() {
    this.memberProgress$
      .pipe(
        mergeAll(),
        filter((val) => val.Score > 60),
        distinct((val) => val.QuizID),
        count()
      )
      .subscribe((total) => {
        this.progressPercentage = (total / this.currCourseQuizzes.length) * 100;
        this.chartOptions.title.text = this.progressPercentage + "%";

        this.chartOptions.series[0].data[0].y = this.progressPercentage;
        this.chartOptions.series[0].data[1].y = 100 - this.progressPercentage;
      });
  }

  getAverageScore() {
    let totalQuizAttempted;
    this.memberProgress$.pipe(mergeAll(), count()).subscribe((total) => {
      totalQuizAttempted = total;
    });

    this.memberProgress$
      .pipe(
        mergeAll(),
        map((val) => val.Score),
        reduce(
          (acc, val) => parseInt(acc.toString()) + parseInt(val.toString())
        )
      )
      .subscribe((total) => {
        this.scoreAverage = total / totalQuizAttempted;
        this.scoreChartOptions.title.text = this.scoreAverage + "";
        this.scoreChartOptions.series[0].data[0].y = this.scoreAverage;
        this.scoreChartOptions.series[0].data[1].y = 100 - this.scoreAverage;
      });
  }

  downloadCertificate() {
    const membername = this.route.snapshot.paramMap.get("membername");
    if (membername) {
      this.router.navigate([
        this.route.parent.snapshot.routeConfig.path,
        "download-certificate",
        membername,
      ]);
      return;
    }
    this.router.navigate(["courses", this.currentCourse.id, "download-certificate"]);
  }

  gotoCourseView() {
    this.navigateToLastWorkedOn();
  }

  async navigateToLastWorkedOn() {
    const memberloc = await this.memberLocService.getMemberLocationByCourse(
      this.currentCourse.id,
      this.currentUser.memberid
    );

    if (
      memberloc &&
      memberloc.section &&
      memberloc.quid &&
      memberloc.quid !== "final" &&
      memberloc.section !== "final"
    ) {
      this.router.navigate(["courses", this.currentCourse.id,
        "chapters",
        parseInt(memberloc.section),
        "quizzes",
        memberloc.quid,
      ]);
    } else if (
      memberloc &&
      memberloc.section &&
      memberloc.quid &&
      memberloc.quid === "final" &&
      memberloc.section === "final"
    ) {
        this.router.navigate(["courses", this.currentCourse.id, "chapters", "final"]);
    } else {
      const firstChapterId = (await this.chaptersService.getChaptersBy(this.currentCourse.id))[0].ID;
      this.router.navigate(["courses", this.currentCourse.id, "chapters", firstChapterId]);
    }
  }
}
