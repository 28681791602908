<div>
  <button mat-raised-button color="mat-teal" (click)="ttsInitialize()" class="text-speech talkify-control-center"
    *ngIf="(isTtsNotInitialized$ | async).state == 'not_initialized'">Text To Speech</button>
  <select *ngIf="(isTtsPlaying$ | async).state == 'playing' || (isTtsPaused$ | async).state == 'paused'" [model]="rate">
    <option *ngFor="let rate of rates" [value]="rate">{{ rate }}x</option>
  </select>
  <select *ngIf="(isTtsPlaying$ | async).state == 'playing' || (isTtsPaused$ | async).state == 'paused'"
    [model]="pitch">
    <option *ngFor="let pitch of pitches" [value]="pitch">{{ pitch }}x</option>
  </select>
  <button color="mat-teal" (click)="ttsToggle()" *ngIf="(isTtsPlaying$ | async).state"><i
      class="material-icons">pause_circle</i></button>
  <button color="mat-teal" (click)="ttsToggle()" *ngIf="(isTtsPaused$ | async).state"><i
      class="material-icons">play_circle</i></button>
  <button color="mat-teal" (click)="ttsStop()" *ngIf="(isTtsPlaying$ | async).state || (isTtsPaused$ | async).state"><i
      class="material-icons">stop_circle</i></button>
</div>
