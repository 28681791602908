import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class PaymentsService {
  constructor(private http: HttpClient) { }

  async getPaymentIntent(courseId: number, membername: string) {
    return await lastValueFrom(this.http
      .get(`${environment.apiUrl}/payments/intent/courses/${courseId}/regmembers/${membername}`));
  }


}
