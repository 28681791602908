import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from "../../environments/environment";
import { MemberCourse } from "../models/member-course.model";

@Injectable({
  providedIn: "root"
})
export class RegmemCoursesService {
  constructor(private http: HttpClient) { }

  async getMemberCourses(membername: string): Promise<MemberCourse[]> {
    return await lastValueFrom(this.http
      .get<MemberCourse[]>(`${environment.apiUrl}/regmembers/${membername}/courses`))
      ;
  }

  async createMemberCourse(membername: string, memberCourse: MemberCourse): Promise<MemberCourse> {
    return await lastValueFrom(this.http
      .post<MemberCourse>(`${environment.apiUrl}/regmembers/${membername}/courses`, memberCourse))
      ;
  }

  async updateMemberCourse(membername: string, memberCourse: MemberCourse): Promise<MemberCourse> { 
    return await lastValueFrom(this.http
      .put<MemberCourse>(`${environment.apiUrl}/regmembers/${membername}/courses/${memberCourse.courseId}`, memberCourse))
      ;
  }

}
