import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { School } from "../models/school.model";
import { environment } from "./../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class SchoolsService {
  baseURL: string = environment.apiUrl; // development

  constructor(private http: HttpClient) {}

  getSchools(): Promise<School[]> {
    return this.http.get<School[]>(`${environment.apiUrl}/schools`).toPromise();
  }

  getSchoolsBy(areaUnitId: number): Promise<School[]> {
    const path = `schools/reports/${areaUnitId}`;

    return this.http.get<School[]>(`${environment.apiUrl}/${path}`).toPromise();
  }

  getSchool(schoolId: number): Promise<School> {
    return this.http
      .get<School>(environment.apiUrl + `/schools/${schoolId}`)
      .toPromise();
  }

  updateSchool(schoolId: number, school: object) {
    return this.http
      .put(`${environment.apiUrl}/schools/${schoolId}`, school)
      .toPromise();
  }

  createSchool(school: object) {
    return this.http.post(`${environment.apiUrl}/schools`, school).toPromise();
  }

  deleteSchool(schoolId: number) {
    return this.http
      .delete(environment.apiUrl + `/schools/${schoolId}`)
      .toPromise();
  }
}
