<div class="row fx-layout-row fx-layout-align-center-center" *ngIf="!certificate">
  <div class="fx-flex-80pc">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Certificate Loading</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row fx-layout-column fx-layout-align-center-center">
          <div class="row fx-flex-7pc" *ngIf="errorMessage">
            <span class="red-text">{{ errorMessage }}</span>
          </div>
          <div class="row fx-flex-auto">
            <mat-spinner strokeWidth="4" diameter="50"></mat-spinner>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="row fx-layout-row fx-layout-align-center-start" *ngIf="certificate">
  <div class="fx-flex-80pc">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Certificate Download</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="fx-layout-row fx-layout-align-space-between-center">
          <div class="fx-flex-60pc">
            <h6>You can download your Certificate by clicking the button</h6>
          </div>
          <div class="fx-flex-40pc">
            <button mat-raised-button color="primary" class="right" (click)="download()">Download</button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
