import { Injectable, Injector } from "@angular/core";
import { getCurrentUser, signIn, type SignInInput, type SignInOutput } from "@aws-amplify/auth";
import { Hub } from '@aws-amplify/core';
import { Store, select } from "@ngrx/store";
import { ConfirmResetPasswordInput, ConfirmSignInInput, ConfirmSignInOutput, ResetPasswordInput, ResetPasswordOutput, SignUpInput, SignUpOutput, UpdateUserAttributeOutput, autoSignIn, confirmResetPassword, confirmSignIn, confirmSignUp, fetchUserAttributes, resendSignUpCode, resetPassword, signOut, signUp, updatePassword, updateUserAttributes } from "aws-amplify/auth";
import { NGXLogger } from "ngx-logger";
import { BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators";
import { ConfirmSignUpAction, SignUpActionTypes, SignUpCompleteAction, SignUpFailedAction } from "src/app/components/allusers/signup/signup.actions";
import { SignUpState } from "src/app/components/allusers/signup/signup.model";
import { User } from "../../../app/models/user.model";
import {
  ForgotPasswordAction,
  ForgotPasswordFailed,
  ForgotPasswordSubmitted,
  NewPasswordRequiredAction,
  SignInActionTypes,
  SignInFailedAction,
  SignedInAction,
  SignedOutAction
} from "../../components/allusers/signin/signin.actions";
import { SignInState } from "../../components/allusers/signin/signin.model";
import { RegmembersService } from "../regmembers.service";
import { AmplifyAuthEvent, AmplifyStateChangedAction } from "./amplify.actions";
import { isAmplifySignedIn, isAmplifySignedOut } from "./amplify.selectors";
import { RouterService } from "src/app/services/route-service/router.service";


const initialAuthState = {
  currentState: SignInActionTypes.SignedOut
} as SignInState;

@Injectable({
  providedIn: "root"
})
export class AuthService {
  private readonly _authState = new BehaviorSubject<SignInState>(
    initialAuthState
  );

  private isAmplifySignedIn$ = this.amplifyStore.pipe(
    select(isAmplifySignedIn),
    filter(val => (val ? true : false))
  );
  private isAmplifySignedOut$ = this.amplifyStore.pipe(
    select(isAmplifySignedOut),
    filter(val => (val ? true : false))
  );
  currentUser;

  constructor(
    private regmembersService: RegmembersService,
    private signInStore: Store<SignInState>,
    private amplifyStore: Store<AmplifyAuthEvent>,
    private signUpStore: Store<SignUpState>,
    private injector: Injector,
    private logger: NGXLogger,
  ) {
    this.isAmplifySignedIn$.subscribe(authEvent => {
      if (authEvent && authEvent.event == "signIn") {
        logger.info("Amplify User Signed In...");
      }
    });

    this.isAmplifySignedOut$.subscribe(authEvent => {
      if (
        window &&
        !(
          window.location.href.indexOf("token") > 0 &&
          window.location.href.indexOf("ssologin")
        )
      ) {
        if (authEvent && authEvent.event == "signOut") {
          logger.info("Amplify User Signed Out...");
          this.logoutUser();
        }
      }
    });

    this.registerAmplifyEvents();
  }

  private registerAmplifyEvents() {
    // Use Hub channel 'auth' to get notified on changes
    Hub.listen('auth', ({ payload: { event, message } }) => {
      this.logger.info(`Amplify Event - ${event}`);

      this.amplifyStore.dispatch(new AmplifyStateChangedAction({ event, message } as AmplifyAuthEvent));

    });
  }

  public async loggedInUser(cognitoUser: any) {
    if (!this.currentUser) {
      let username;
      if (cognitoUser && cognitoUser.username) {
        username = cognitoUser.username;
      } else {
        throw new Error("Error - Unable to get loggedIn user");
      }

      try {
        const user = await this.regmembersService.getUserBy(username);

        if (user) {
          this.signInStore.dispatch(
            new SignedInAction({
              currentState: SignInActionTypes.SignedIn,
              user
            } as SignInState)
          );
          this.currentUser = user;          
          return this.currentUser;
        }
      } catch (error) {
        this.logger.error(error);
        throw new Error("Error - Unable to get loggedIn user");
      }
    }
    return this.currentUser;
  }

  private logoutUser() {
    this.signOut();

    return this.currentUser;
  }

  public async federatedSignIn(username, password) {
    try {
      this.currentUser = null;
      const signInInput = { username, password } as SignInInput;
      const signInOutput = await signIn(signInInput);
      this.handleSignInSteps(username, signInOutput);
    } catch (error) {
      this.logger.error(`User Logged In Failed - ${JSON.stringify(error)}`);

      this.logger.error(`Sign Error - ${JSON.stringify(error)}`);
      this.signInStore.dispatch(
        new SignInFailedAction({
          currentState: SignInActionTypes.SignInFailed,
          error: error.message,
        } as SignInState)
      );
    }
  }

  public async signIn(username, password) {
    try {
      this.currentUser = null;
      const signInInput = {
        username, password, options: {
          authFlowType: "USER_PASSWORD_AUTH"
        }
      } as SignInInput;
      const signInOutput = await signIn(signInInput);
      this.handleSignInSteps(username, signInOutput);
    } catch (error) {
      this.logger.error(`User Logged In Failed - ${JSON.stringify(error)}`);

      this.logger.error(`Sign Error - ${JSON.stringify(error)}`);
      this.signInStore.dispatch(
        new SignInFailedAction({
          currentState: SignInActionTypes.SignInFailed,
          error: error.message,
        } as SignInState)
      );
      this.signOut();
    }
  }

  private async handleSignInSteps(username: string | null, signInOutput: SignInOutput | ConfirmSignInOutput) {
    this.logger.info(`Handling SignInSteps - ${JSON.stringify(signInOutput)}`);
    const { isSignedIn, nextStep } = signInOutput;

    switch (nextStep.signInStep) {
      case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
        // CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED - The user was created with a temporary password and must set a new one. Complete the process with confirmSignIn.
        this.logger.info("SignInStep - CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED...");
        this.signInStore.dispatch(
          new NewPasswordRequiredAction({
            currentState: SignInActionTypes.NewPasswordRequired,
          } as SignInState)
        );
        break;
      case 'CONFIRM_SIGN_UP':
        // CONFIRM_SIGN_UP - The user hasn't completed the sign-up flow fully and must be confirmed via confirmSignUp.
        this.logger.info("SignInStep - CONFIRM_SIGN_UP...");
        this.signUpStore.dispatch(
          new ConfirmSignUpAction({
            currentState: SignUpActionTypes.ConfirmSignUp,
            signInOutput
          } as SignUpState)
        );
        break;
      case 'RESET_PASSWORD':
        // RESET_PASSWORD - The user must reset their password via resetPassword.
        this.logger.info("SignInStep - RESET_PASSWORD...");
        this.resetPassword(username);
        break;
      case 'DONE':
        this.logger.info("User Logged In Succesfully...");
        this.logger.info(`currentUser - ${this.currentUser}`);
        await this.getLoggedInUser();
        break;
    }
  }

  public async confirmSignIn(code) {
    try {
      const confirmSignInOutput = await confirmSignIn({ challengeResponse: code } as ConfirmSignInInput);
      this.handleSignInSteps(null, confirmSignInOutput);
    } catch (error) {
      this.logger.error(`ConfirmSignIn Failed - ${JSON.stringify(error)}`);

      this.signInStore.dispatch(
        new SignInFailedAction({
          currentState: SignInActionTypes.SignInFailed,
          error: error.message,
        } as SignInState));
    }
  }

  public async resetPassword(username) {
    try {
      const resetPasswordOutput = await resetPassword({ username } as ResetPasswordInput);
      this.handleResetPassword(resetPasswordOutput);
    } catch (error) {
      this.logger.error(`ResetPassword Failed - ${JSON.stringify(error)}`);

      this.signInStore.dispatch(
        new ForgotPasswordFailed({
          currentState: SignInActionTypes.ForgotPasswordFailed,
          error: error.message,
        } as SignInState));
    }
  }

  public async confirmResetPassword({
    username,
    confirmationCode,
    newPassword
  }: ConfirmResetPasswordInput) {
    try {
      await confirmResetPassword({ username, confirmationCode, newPassword });
    } catch (error) {
      this.logger.error(`Unable to confirmResetPassword - ${JSON.stringify(error)}`);
      this.signInStore.dispatch(
        new ForgotPasswordFailed({
          currentState: SignInActionTypes.ForgotPasswordFailed,
          error: error.message,
        } as SignInState)
      );
    }
  }

  private async handleResetPassword(resetPasswordOutput: ResetPasswordOutput) {
    this.logger.info(`Handling ResetPassword - ${JSON.stringify(resetPasswordOutput)}`);
    const { isPasswordReset, nextStep } = resetPasswordOutput;

    switch (nextStep.resetPasswordStep) {
      case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
        this.logger.info("ForgotPassword - CONFIRM_RESET_PASSWORD_WITH_CODE...");
        this.signInStore.dispatch(
          new ForgotPasswordAction({
            currentState: SignInActionTypes.ForgotPassword,
          } as SignInState)
        );
        break;
      case 'DONE':
        this.logger.info("ForgotPassword - reset Succesfully...");
        this.signInStore.dispatch(
          new ForgotPasswordSubmitted({
            currentState: SignInActionTypes.ForgotPasswordSubmitted,
          } as SignInState)
        );
    }
  }

  public async newPasswordRequired(cognitoUser, password) {
    await this.confirmSignIn(password);
  }

  public async signOut() {
    try {
      await signOut({ global: true });
      this.logger.info("Amplify User Logged Out Successfully...");

      if (this.currentUser) {
        this.currentUser = null;
        this.signInStore.dispatch(
          new SignedOutAction({
            currentState: SignInActionTypes.SignedOut
          } as SignInState)
        );
      }
    } catch (error) {
      this.logger.error(
        `Amplify User Logged Out Failed - ${JSON.stringify(error)}`
      );
    }
  }

  public async signUp(signUpInput: SignUpInput) {

    try {
      const signUpOutput = await signUp(signUpInput);
      this.handleSignUpSteps(signUpInput, signUpOutput);
    } catch (error) {
      this.logger.error(
        `Amplify Failed to SignUp - ${JSON.stringify(error)}`
      );
      throw error;
    }
  }

  async confirmSignUp(userName: string, verificationCode: string) {
    // return Auth.confirmSignUp(userName, verificationCode);
    try {
      this.logger.info(`ConfirmSignUp Starting... - ${JSON.stringify(arguments)}`);
      const confirmSignUpOutput = await confirmSignUp({ username: userName, confirmationCode: verificationCode });
      this.handleSignUpSteps(null, confirmSignUpOutput);
    } catch (error) {
      this.logger.error(`ConfirmSignUp Failed - ${JSON.stringify(error)}`);

      this.signUpStore.dispatch(
        new SignUpFailedAction({
          currentState: SignUpActionTypes.SignUpFailed,
          error: error.message,
        } as SignUpState));
    }
  }

  private async handleSignUpSteps(signUpInput: SignUpInput | null, signUpOutput: SignUpOutput) {
    this.logger.info(`Handling SignUpSteps - ${JSON.stringify(signUpOutput)}`);
    const { isSignUpComplete, nextStep } = signUpOutput;

    switch (nextStep.signUpStep) {
      case 'COMPLETE_AUTO_SIGN_IN':
        this.logger.info("SignUpStep - COMPLETE_AUTO_SIGN_IN...");
        this.autoSignIn();
        break;
      case 'CONFIRM_SIGN_UP':
        // CONFIRM_SIGN_UP - The user hasn't completed the sign-up flow fully and must be confirmed via confirmSignUp.
        this.logger.info("SignUpStep - CONFIRM_SIGN_UP...");
        this.signUpStore.dispatch(
          new ConfirmSignUpAction({
            currentState: SignUpActionTypes.ConfirmSignUp,
            signUpOutput,
            signUpInput,
          } as SignUpState)
        );
        break;
      case 'DONE':
        this.logger.info("SignUpStep - DONE - User SignedUp");
        this.signUpStore.dispatch(
          new SignUpCompleteAction({
            currentState: SignUpActionTypes.SignUpComplete
          } as SignUpState)
        )
        break;
    }
  }

  async autoSignIn() {
    try {
      const signInOutput = await autoSignIn();
      this.handleSignInSteps(null, signInOutput);
    } catch (error) {
      this.logger.error(`AutoSignIn Failed - ${JSON.stringify(error)}`);

      this.signInStore.dispatch(
        new SignInFailedAction({
          currentState: SignInActionTypes.SignInFailed,
          error: error.message,
        } as SignInState));
    }

  }

  async resendVerificationCode(username: string) {
    try {
      const resendSignUpOutput = await resendSignUpCode({ username });
      return resendSignUpOutput;
    } catch (error) {
      this.logger.error(`Failed to send signup code - ${JSON.stringify(error)}`);
      throw error;
    }
  }

  async getLoggedInUser(): Promise<User> {
    try {
      const user = await getCurrentUser();

      if (user) {
        return this.loggedInUser(user);
      }

      return this.currentUser;
    } catch (e) {
      this.logger.info(`Unable to get loggedIn user - ${JSON.stringify(e)}`);
      // return this.logoutUser();
    }
  }

  async forgotPassword(username: string) {
    await this.resetPassword(username);
  }

  async forgotPasswordVerifyCode(
    username: string,
    code: string,
    newPassword: string
  ) {
    this.confirmResetPassword({ username, confirmationCode: code, newPassword });
  }

  public async updatePassword(oldPassword, newPassword) {
    try {
      this.logger.info(`Updating user password...`);
      await updatePassword({ oldPassword, newPassword });
      this.logger.info(`Updated user password successfully...`);
    } catch (error) {
      this.logger.error(`Failed to update password - ${JSON.stringify(error)}`);
      throw error;
    }
  }

  public async fetchUserAttributes() {
    try {
      this.logger.info(`FetchingUserAttributes...`);
      const userAttributes = await fetchUserAttributes();
      return userAttributes;
    } catch (error) {
      this.logger.error(`Failed to FetchingUserAttributes - ${JSON.stringify(error)}`);
      throw error;
    }
  }

  public async updateUserAttributes(attributes) {
    try {
      const output = await updateUserAttributes({
        userAttributes: {
          attributes
        }
      });

      for (const attribute of Object.keys(output)) {
        const updateUserAttributeOutput = output[attribute];
        await this.handleUpdateUserAttributeNextSteps(updateUserAttributeOutput);
      }
    } catch (error) {
      this.logger.error(`Failed to UpdateUserAttribute - ${JSON.stringify(error)}`);
      throw error;
    }
  }

  private async handleUpdateUserAttributeNextSteps(output: UpdateUserAttributeOutput) {
    const { nextStep } = output;

    switch (nextStep.updateAttributeStep) {
      case 'CONFIRM_ATTRIBUTE_WITH_CODE':
        // const codeDeliveryDetails = nextStep.codeDeliveryDetails;
        // console.log(
        //   `Confirmation code was sent to ${codeDeliveryDetails?.deliveryMedium}.`
        // );
        // Collect the confirmation code from the user and pass to confirmUserAttribute.
        this.logger.info(`UpdateUserAttribute - CONFIRM_ATTRIBUTE_WITH_CODE Not supported`);
        throw new Error("Unable of update user info");
        break;
      case 'DONE':
        this.logger.info(`UpdateUserAttribute - attribute was successfully updated.`);
        break;
    }
  }
}
