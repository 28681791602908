import { Action } from "@ngrx/store";
import { QuizTestsState } from "./quiz-tests.model";

export enum QuizTestsActionTypes {
  Initialization = "Initialization",
  QuizShow = "QuizShow",
  QuizSkipped = "QuizSkipped",
  QuizLoaded = "QuizLoaded",
  QuizSubmitting = "QuizSubmitting",
  QuizSubmitted = "QuizSubmitted",
  Failure = "Failure",
  AudioShow = "AudioShow",
  VideoShow = "VideoShow",
  AssetShow = "AssetShow",
  FinalQuizShow = "FinalQuizShow",
  FinalQuizLoaded = "FinalQuizLoaded",
  FinalQuizAlreadyDone = "FinalQuizAlreadyDone",
  FinalNotAllowedQuizIncomplete = "FinalNotAllowedQuizIncomplete",
  FinalQuizSubmitting = "FinalQuizSubmitting",
  FinalQuizSubmitted = "FinalQuizSubmitted",
}

export class InitializationAction implements Action {
  readonly type = QuizTestsActionTypes.Initialization;
  constructor(public payload: QuizTestsState) { }
}

export class QuizShowAction implements Action {
  readonly type = QuizTestsActionTypes.QuizShow;
  constructor(public payload: QuizTestsState) { }
}

export class QuizSkippedAction implements Action {
  readonly type = QuizTestsActionTypes.QuizSkipped;
  constructor(public payload: QuizTestsState) { }
}

export class QuizLoadedAction implements Action {
  readonly type = QuizTestsActionTypes.QuizLoaded;
  constructor(public payload: QuizTestsState) { }
}

export class QuizSubmittingAction implements Action {
  readonly type = QuizTestsActionTypes.QuizSubmitting;
  constructor(public payload: QuizTestsState) { }
}

export class QuizSubmittedAction implements Action {
  readonly type = QuizTestsActionTypes.QuizSubmitted;
  constructor(public payload: QuizTestsState) { }
}

export class FailureAction implements Action {
  readonly type = QuizTestsActionTypes.Failure;
  constructor(public payload: QuizTestsState) { }
}

export class AudioShowAction implements Action {
  readonly type = QuizTestsActionTypes.AudioShow;
  constructor(public payload: QuizTestsState) { }
}

export class AssetShowAction implements Action {
  readonly type = QuizTestsActionTypes.AssetShow;
  constructor(public payload: QuizTestsState) { }
}

export class VideoShowAction implements Action {
  readonly type = QuizTestsActionTypes.VideoShow;
  constructor(public payload: QuizTestsState) { }
}

export class FinalQuizShowAction implements Action {
  readonly type = QuizTestsActionTypes.FinalQuizShow;
  constructor(public payload: QuizTestsState) { }
}

export class FinalQuizLoadedAction implements Action {
  readonly type = QuizTestsActionTypes.FinalQuizLoaded;
  constructor(public payload: QuizTestsState) { }
}

export class FinalQuizAlreadyDoneAction implements Action {
  readonly type = QuizTestsActionTypes.FinalQuizAlreadyDone;
  constructor(public payload: QuizTestsState) { }
}

export class FinalNotAllowedQuizIncompleteAction implements Action {
  readonly type = QuizTestsActionTypes.FinalNotAllowedQuizIncomplete;
  constructor(public payload: QuizTestsState) { }
}

export class FinalQuizSubmittingAction implements Action {
  readonly type = QuizTestsActionTypes.FinalQuizSubmitting;
  constructor(public payload: QuizTestsState) { }
}

export class FinalQuizSubmittedAction implements Action {
  readonly type = QuizTestsActionTypes.FinalQuizSubmitted;
  constructor(public payload: QuizTestsState) { }
}

export type QuizTestsActions =
  | InitializationAction
  | QuizShowAction
  | QuizSkippedAction
  | QuizLoadedAction
  | QuizSubmittingAction
  | QuizSubmittedAction
  | FailureAction
  | AudioShowAction
  | VideoShowAction
  | AssetShowAction
  | FinalQuizShowAction
  | FinalQuizLoadedAction
  | FinalQuizAlreadyDoneAction
  | FinalNotAllowedQuizIncompleteAction
  | FinalQuizSubmittingAction
  | FinalQuizSubmittedAction;
