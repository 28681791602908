import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { PagesService } from "../../../services/pages.service";

@Component({
  selector: "app-privacy",
  templateUrl: "./privacy.component.html",
  styleUrls: ["./privacy.component.css"],
})
export class PrivacyComponent implements OnInit {
  privacyContent;

  constructor(
    private pagesService: PagesService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.getPage();
  }

  getPage() {
    this.pagesService
      .getPage(4)
      .then(
        (res) =>
          (this.privacyContent = this.sanitizer.bypassSecurityTrustHtml(
            res["PageContent"]
          ))
      );
  }
}
