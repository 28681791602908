import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AnswerOptions } from "src/app/models/answer-options.model";
import { AnswerOptionsService } from "../../../services/answer-options.service";

@Component({
  selector: "app-edit-question-option",
  templateUrl: "./edit-question-option.component.html",
  styleUrls: ["./edit-question-option.component.css"],
})
export class EditQuestionOptionComponent implements OnInit {
  option: AnswerOptions;
  editQuestionOptionForm = new FormGroup({
    AnswerOptID: new FormControl(""),
    QuestionID: new FormControl(""),
    AnswerText: new FormControl(""),
    Correct: new FormControl(""),
  });
  loading: Boolean = true;

  constructor(
    private route: ActivatedRoute,
    private answerOptionsService: AnswerOptionsService,
    private location: Location,
    private routerNav: Router
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.getAnswers(params["answerOptionId"]);
    });
  }

  async getAnswers(id) {
    try {
      this.option = await this.answerOptionsService.findAnswerOptionBy(id);
      this.editQuestionOptionForm.patchValue({ ...this.option } as unknown);
      this.loading = false;
    } catch (err) { }
  }

  update() {
    this.answerOptionsService
      .updateAnswerOptions(
        this.option.AnswerOptID,
        {
          ...this.editQuestionOptionForm.value
        } as unknown as AnswerOptions
      )
      .then((res) => {
        this.location.back();
      });
  }
}
