import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { Table } from "primeng/table";
import { Affiliate } from "src/app/models/affiliate.model";
import { School } from "src/app/models/school.model";
import { AffiliatesService } from "src/app/services/affiliates.service";
import { SchoolsService } from "../../../services/schools.service";

@Component({
  selector: "app-school-manager",
  templateUrl: "./school-manager.component.html",
  styleUrls: ["./school-manager.component.css"]
})
export class SchoolManagerComponent implements OnInit, AfterViewInit {
  @ViewChild(Table) table: Table;

  constructor(
    private schoolService: SchoolsService,
    private affiliatesService: AffiliatesService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe(params => (this.params = params));
  }

  displayDialog: boolean;

  school: School;

  affiliates: Affiliate[];

  selectedSchool: School;

  newSchool: boolean;

  schools: School[];

  cols: any[];

  areaUnits: Affiliate[];

  affiliatesSelect: Affiliate[];

  selectedAreaUnit;

  params: Params;

  async ngOnInit() {
    this.affiliates = await this.affiliatesService.getAffiliates();
    this.loadSchools();
    this.areaUnits = [];
    this.areaUnits.push(
      ...this.affiliates
    );

    this.affiliatesSelect = this.affiliates;

    this.cols = [
      { field: "schoolname", header: "School Name" },
      { field: "regmemCount", header: "Member Count" },
      { field: "aucompany", header: "Area Unit" }
    ];
  }

  ngAfterViewInit() {
    if (this.params.areaUnitId) {
      setTimeout(() => {
        const au = this.affiliates.find(
          a => a.affiliateid == this.params.areaUnitId
        );
        this.selectedAreaUnit = au.company;
        this.table.filter(au.company, "aucompany", "contains");
      }, 500);
    }
  }

  loadSchools() {
    this.schoolService.getSchools().then(schools => {
      this.schools = schools;
      this.schools.forEach(item => {
        const au = this.affiliates.find(a => a.affiliateid === item.areaunitid);
        if (au) {
          item["aucompany"] = au.company;
        }
      });
    });
  }

  showDialogToAdd() {
    this.newSchool = true;
    this.school = {} as School;
    this.displayDialog = true;
  }

  save() {
    let p: Promise<any>;
    // remove regmenCount as it is not a property of school
    delete this.school.regmemCount;
    if (this.school && this.school["aucompany"]) {
      delete this.school["aucompany"];
    }
    if (this.selectedAreaUnit) {
      this.school.areaunitid = this.selectedAreaUnit;
    }
    if (this.newSchool) {
      p = this.schoolService.createSchool(this.school);
    } else {
      p = this.schoolService.updateSchool(this.school.schoolid, this.school);
    }

    return p.then(() => {
      this.loadSchools();
      this.school = null;
      this.displayDialog = false;
    });
  }

  delete() {
    this.schoolService.deleteSchool(this.selectedSchool.schoolid).then(() => {
      this.loadSchools();
      this.school = null;
      this.displayDialog = false;
    });
  }

  onRowSelect(event) {
    this.newSchool = false;
    this.school = this.cloneSchool(event.data);
    this.displayDialog = true;
  }

  cloneSchool(s: School): School {
    const school = {} as School;
    for (const prop in s) {
      school[prop] = s[prop];
    }
    return school;
  }
}
