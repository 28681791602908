import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from "../../environments/environment";
import { MemberCourse } from "../models/member-course.model";
import { User } from "../models/user.model";

@Injectable({
  providedIn: "root"
})
export class RegmembersService {
  constructor(private http: HttpClient) { }

  getUsersByAreaUnit(
    areaUnitId: number,
    first: number,
    rows: number,
    sortField: string,
    sortOrder: string,
    filters: string
  ): Promise<[User[], number]> {
    let params = {
      first: first.toString(),
      rows: rows.toString()
    };

    if (sortField) {
      params["sortField"] = sortField;
    }
    if (sortOrder) {
      params["sortOrder"] = sortOrder;
    }
    if (filters) {
      params["filters"] = filters;
    }

    return this.http
      .get<[User[], number]>(
        `${environment.apiUrl}/regmembers/reports/${areaUnitId}`,
        { params }
      )
      .toPromise();
  }

  getUsersBy(
    first: number,
    rows: number,
    sortField: string,
    sortOrder: string,
    filters: string
  ) {
    let params = {
      first: first.toString(),
      rows: rows.toString()
    };

    if (sortField) {
      params["sortField"] = sortField;
    }
    if (sortOrder) {
      params["sortOrder"] = sortOrder;
    }
    if (filters) {
      params["filters"] = filters;
    }

    return this.http
      .get<[User[], number]>(`${environment.apiUrl}/regmembers`, {
        params
      })
      .toPromise();
  }

  getUserBy(membername: string): Promise<User> {
    return this.http
      .get<User>(`${environment.apiUrl}/regmembers/${membername}`)
      .toPromise();
  }

  createRegmem(member: User): Promise<User> {
    return this.http
      .post<User>(`${environment.apiUrl}/regmembers`, member)
      .toPromise();
  }

  updateRegmem(membername: string, member: User) {
    return this.http
      .put(`${environment.apiUrl}/regmembers/${membername}`, member)
      .toPromise();
  }

  deleteRegmem(membername: string) {
    return this.http
      .delete(`${environment.apiUrl}/regmembers/${membername}`)
      .toPromise();
  }

  resetPassword(member: User) {
    return this.http
      .post(
        `${environment.apiUrl}/regmembers/${member.membername}/resetPassword`,
        member
      )
      .toPromise();
  }

  async getCoursesBy(membername: string): Promise<MemberCourse[]> {
    return await lastValueFrom(this.http
      .get<MemberCourse[]>(`${environment.apiUrl}/regmembers/${membername}/courses`))
      ;
  }

}
