import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { } from "@ngrx/router-store";
import { Store } from "@ngrx/store";
import { fetchAuthSession } from "aws-amplify/auth";
import { NGXLogger } from "ngx-logger";
import { EdisonCode } from "src/app/models/edison-code.model";
import { EdisonFedertedLogin } from "src/app/services/auth-service/base.token.provider";
import { EdisonService } from "src/app/services/edison.service";
import { commonTokenProvider } from "src/main";
import { AuthService } from "../../../services/auth-service/auth.service";
import { SignUpState } from "../../allusers/signup/signup.model";

@Component({
  selector: "app-edison-home",
  templateUrl: "./edison-home.component.html",
  styleUrls: ["./edison-home.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class EdisonHomeComponent implements OnInit {
  @Input() edisonCode: EdisonCode;

  jwtHelperService: JwtHelperService;
  errorMessage: string;

  constructor(
    private route: ActivatedRoute,
    private signUpStore: Store<SignUpState>,
    private authService: AuthService,
    private edisonService: EdisonService,
    private logger: NGXLogger,
    private router: Router,
  ) {
    this.jwtHelperService = new JwtHelperService();
  }

  async ngOnInit() {
    if (this.edisonCode) {
      this.logger.info(`Edison Code - ${JSON.stringify(this.edisonCode)}`);
      if (this.edisonCode && !this.edisonCode.domain) {
        this.logger.info(`Domain is missing.`);
        this.errorMessage = "Unable to log you in, please contact your administrator."
        return;
      }
      if (this.edisonCode && !this.edisonCode.code) {
        this.logger.info(`Code is missing.`);
        this.errorMessage = "Unable to log you in, please contact your administrator."
        return;
      }

      try {
        const authResp = await this.edisonService.authorize(this.edisonCode);
        await this.login(authResp);
      } catch (error) {
        this.logger.error(`Error - ${JSON.stringify(error)}`);
        this.errorMessage = "Unable to log you in, please contact your administrator."
      }
      return;
    }
  }

  async login({ token, refreshToken }) {
    const { sub, exp } = this.jwtHelperService.decodeToken(token);
    const now = new Date().getTime() / 1000;
    if (now > exp) {
      this.logger.info(`Token is expired.`);
      return;
    }

    try {
      commonTokenProvider.loadFederatedLogin({
        domain: "login.keystodriving.edison",
        token: token,
        edisonRefreshToken: refreshToken,
        edisonDomain: this.edisonCode.domain,
      } as EdisonFedertedLogin);
      const session = await fetchAuthSession();
      this.logger.info(`AMR - ${JSON.stringify(session)}`);
      const { amr } = session.tokens.accessToken.payload;
      this.logger.info(`AMR - ${JSON.stringify(amr)}`);
      if (Array.isArray(amr)) {
        const username = amr[2]
          ? (<string>amr[2]).split(":")
            ? (<string>amr[2]).split(":")[3]
            : ""
          : "";
        if (username) {
          await this.authService.loggedInUser({ username });
        }
      }
    } catch (error) {
      this.logger.error(`Error - ${JSON.stringify(error)}`);
      this.errorMessage = "Unable to log you in, please contact your administrator."
    }
  }

}
