import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Store, select } from "@ngrx/store";
import { SignUpInput } from "aws-amplify/auth";
import { parsePhoneNumber } from "libphonenumber-js/min";
import { NGXLogger } from "ngx-logger";
import { ToastrService } from 'ngx-toastr';
import { filter } from "rxjs/operators";
import { getSignUp } from "../../../../app/components/allusers/signup/signup.selector";
import { AmplifyAuthEvent } from "../../../../app/services/auth-service/amplify.actions";
import { isAmplifyConfirmSignUp } from "../../../../app/services/auth-service/amplify.selectors";
import { AuthService } from "../../../../app/services/auth-service/auth.service";
import { RouterService } from "../../../../app/services/route-service/router.service";
import { AgreementComponent } from "../agreement/agreement.component";
import {
  ConfirmSignUpAction,
  SignUpActionTypes,
  SignUpFailedAction,
  SignUpInProcessAction,
  SignUpUserInfoAction
} from "./signup.actions";
import { SignUpState } from "./signup.model";

interface Address {
  street_address: string;
  locality: string;
  region: string;
  postal_code: string;
  country: string;
}

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.css"]
})
export class SignupComponent implements OnInit {
  signUpForm = new FormGroup({
    username: new FormControl("", [Validators.required, Validators.pattern("[a-z0-9]+")]),
    firstName: new FormControl("", Validators.required),
    lastName: new FormControl(""),
    phoneNumber: new FormControl("", [Validators.required, Validators.pattern("[0-9]+")]),
    email: new FormControl("", Validators.required),
    password: new FormControl("", Validators.required),
    rePassword: new FormControl("", Validators.required),
    courseCode: new FormControl("", Validators.required),
  });

  confirmSignUpForm = new FormGroup({
    username: new FormControl("", [Validators.required, Validators.pattern("[a-z0-9]+")]),
    verificationCode: new FormControl("", Validators.required)
  });

  currentState$ = this.signUpStore.pipe(select(getSignUp));
  currentState;

  private isAmplifyConfirmSignup$ = this.amplifyStore.pipe(
    select(isAmplifyConfirmSignUp),
    filter(val => (val ? true : false))
  );
  errorMessage;
  successMessage;

  constructor(
    private authService: AuthService,
    private routeService: RouterService,
    private signUpStore: Store<SignUpState>,
    private amplifyStore: Store<AmplifyAuthEvent>,
    public dialog: MatDialog,
    public logger: NGXLogger,
    public authenticator: AuthenticatorService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.authenticator.route
    this.currentState$.subscribe(async signUpState => {
      if (signUpState.currentState) {
        this.currentState = signUpState.currentState.toString();
        if (signUpState.currentState === SignUpActionTypes.ConfirmSignUp) {
          if (
            signUpState &&
            signUpState.signUpOutput
          ) {
            this.successMessage =
              "Check for verification code at - " +
              signUpState.signUpOutput.nextStep["codeDeliveryDetails"].destination;
          }
          this.confirmSignUpForm.controls.username.setValue(
            signUpState.signUpInput.username
          );
        }
      } else {
        this.currentState = SignUpActionTypes.SignUpUserInfo.toString();
      }
    });

    this.isAmplifyConfirmSignup$.subscribe(authEvent => {
      this.signUpStore.dispatch(
        new ConfirmSignUpAction({
          currentState: SignUpActionTypes.ConfirmSignUp,
          signUpInput: authEvent.data
        } as SignUpState)
      );
    });
  }

  clearMessages() {
    this.errorMessage = null;
    this.successMessage = null;
  }

  gotoUserInfo() {
    this.signUpStore.dispatch(
      new SignUpUserInfoAction({
        currentState: SignUpActionTypes.SignUpUserInfo,
      } as SignUpState)
    );
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(AgreementComponent, {
      width: "800px"
    });
  }

  signUp() {
    if (
      this.signUpForm.value.password === this.signUpForm.value.rePassword &&
      this.signUpForm.valid
    ) {
      this.clearMessages();

      const signUpParams = {
        username: this.signUpForm.controls.username.value,
        password: this.signUpForm.controls.password.value,
        options: {
          userAttributes: {
            email: this.signUpForm.controls.email.value,
            given_name: this.signUpForm.controls.firstName.value,
            family_name: this.signUpForm.controls.lastName.value,
            phone_number: parsePhoneNumber(
              this.signUpForm.controls.phoneNumber.value ? this.signUpForm.controls.phoneNumber.value : "",
              "US"
            ).format("E.164"),
            address: '',
            "custom:cert_state": this.signUpForm.controls.courseCode.value
          }
        }
      } as SignUpInput;

      this.signUpStore.dispatch(
        new SignUpInProcessAction({
          currentState: SignUpActionTypes.SignUpInProcess,
        } as SignUpState)
      );

      this.authService.signUp(signUpParams).then(
        success => {
          this.signUpStore.dispatch(
            new ConfirmSignUpAction({
              currentState: SignUpActionTypes.ConfirmSignUp,
            } as SignUpState)
          );
        },
        err => {
          this.logger.error(`User SignUp Failed - ${JSON.stringify(err)}`);
          this.errorMessage = err.message;
          if (err.code === "UserLambdaValidationException") {
            this.signUpStore.dispatch(
              new SignUpFailedAction({
                currentState: SignUpActionTypes.SignUpFailed,
                error: err.message
              } as SignUpState)
            );
            return;
          }
          this.signUpStore.dispatch(
            new SignUpFailedAction({
              currentState: SignUpActionTypes.SignUpFailed,
              error: err.message
            } as SignUpState)
          );
        }
      );
    } else {
      this.toastr.error("Sorry, Passwords do not match...");
    }
  }

  gotoHome() {
    this.routeService.gotoHome();
  }

  resendCode() {
    this.signUpStore.dispatch(
      new SignUpInProcessAction({
        currentState: SignUpActionTypes.SignUpInProcess
      } as SignUpState)
    );
    this.clearMessages();
    this.authService
      .resendVerificationCode(this.confirmSignUpForm.value.username)
      .then(success => {
        this.successMessage = `Verification code sent to Email - ${success.destination}`;
        this.signUpStore.dispatch(
          new ConfirmSignUpAction({
            currentState: SignUpActionTypes.ConfirmSignUp,
            signUpInput: { username: this.confirmSignUpForm.value.username }
          } as SignUpState)
        );
      })
      .catch(err => {
        this.errorMessage = err.message;
        this.signUpStore.dispatch(
          new ConfirmSignUpAction({
            currentState: SignUpActionTypes.ConfirmSignUp,
            signUpInput: { username: this.confirmSignUpForm.value.username }
          } as SignUpState)
        );
      });
  }

  async confirmSignUp() {
    this.signUpStore.dispatch(
      new SignUpInProcessAction({
        currentState: SignUpActionTypes.SignUpInProcess
      } as SignUpState)
    );
    this.clearMessages();
    try {
      await this.authService
        .confirmSignUp(
          this.confirmSignUpForm.value.username,
          this.confirmSignUpForm.value.verificationCode
        );
    } catch (error) {
      this.errorMessage = error.message;
    }
  }
}
