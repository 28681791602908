<div class="backgrounDColor row" style="margin: 10px;" *ngIf="currentUser">

  <div class="row" style="margin: 10px;">
    <h5 style="color: grey">Courses Registered for {{ currentUser.fname }} {{ currentUser.lname }}</h5>
  </div>

  <div class="row" style="margin: 30px;" *ngIf="!memberCourses">
    <mat-spinner strokeWidth="3" diameter="100"></mat-spinner>
  </div>
  <div class="row" style="margin: 30px;" *ngFor="let memberCourse of memberCourses">
    <div class="fx-layout-row fx-layout-align-space-around-center">
      <div class="fx-flex-59pc">
        <mat-card *ngIf="findCourse(memberCourse.courseId)">
          <mat-card-header>
            <mat-card-title>{{ findCourse(memberCourse.courseId)?.courseName }}</mat-card-title>
          </mat-card-header>
          <mat-card-content *ngIf="findCourse(memberCourse.courseId)">
            <div class="left" [innerHTML]="sanitizeHtml(findCourse(memberCourse.courseId)?.courseDesc)">
            </div>
          </mat-card-content>
          <mat-card-actions align="end">
            <a class="waves-effect waves-light btn-small btn-border" (click)="selectCourse(memberCourse.courseId)"
              color="primary" *ngIf="memberCourse?.status == 'paid'">Continue Course</a>
            <a class="waves-effect waves-light btn-small btn-border"
              routerLink="/courses/{{memberCourse.courseId}}/payments" *ngIf="memberCourse?.status !== 'paid'"
              color="primary">Payment</a>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
</div>
