import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { NGXLogger } from "ngx-logger";
import { Chapter } from "src/app/models/chapters.model";
import { Course } from "src/app/models/course.model";
import { Quiz } from "src/app/models/quiz.model";
import { School } from "src/app/models/school.model";
import { ChaptersService } from "src/app/services/chapters.service";
import { CoursesService } from "src/app/services/courses.service";
import { QuizesService } from "../../../services/quizes.service";

interface CourseChapters {
  course: Course;
  chapters: Chapter[];
}

@Component({
  selector: "app-quiz-list",
  templateUrl: "./quiz-list.component.html",
  styleUrls: ["./quiz-list.component.css"],
})
export class QuizListComponent implements OnInit {
  faCopy = faCopy;

  first = 0;
  rows = 15;
  sortField;
  sortOrder;
  filters;
  totalRecords = 0;

  displayDialog: boolean;

  selectedQuiz: Quiz;

  newQuiz: boolean;

  quizzes: Quiz[] = [];
  quiz: Quiz;

  cols: any[];

  schoolsSelect: School[];

  errorMessage;
  successMessage;

  dateFilters: any;

  quizLocations = [];

  courses = [];
  chapters = [];
  courseChapters: CourseChapters[] = [];
  chapterIdToCourse = {};

  quizForm = new FormGroup({
    QuizTitle: new FormControl("", Validators.required),
    QuizLocation: new FormControl("", Validators.required),
    QuizOrder: new FormControl("", Validators.required),
  });

  loading = true;
  link;

  constructor(
    private logger: NGXLogger,
    private quizesService: QuizesService,
    public router: Router,
    private activateRoute: ActivatedRoute,
    private chapterService: ChaptersService,
    private coursesService: CoursesService,
    private clipboard: Clipboard,
  ) { }

  async loadCourseChapters() {
    const courses = await this.coursesService.findAll();
    for (let i = 0; i < courses.length; i++) {
      const chapters = await this.chapterService.getChaptersBy(courses[i].id);
      this.courseChapters.push({ course: courses[i], chapters });
      for (let j = 0; j < chapters.length; j++) {
        this.chapterIdToCourse[chapters[j].ID] = courses[i];
      }
    };

    this.courses = courses.map(i => ({ label: i.courseName, value: i.id }));
  }

  async loadQuizzes() {
    await this.loadCourseChapters();
    for (let index = 0; index < this.courses.length; index++) {
      const course = this.courses[index];
      const quizzes = await this.quizesService.findAllPerCourse(course.value);
      if (quizzes.length > 0) {
        this.quizzes.push(...quizzes);
      }
    }

    this.quizzes = this.quizzes.sort((a, b) => {
      let aloc = Number.parseInt(a.QuizLocation2);
      let bloc = Number.parseInt(b.QuizLocation2);
      if (aloc < bloc) {
        return -1;
      } else if (aloc == bloc) {
        let aorder = Number.parseInt(a.QuizOrder);
        let border = Number.parseInt(b.QuizOrder);
        if (aorder < border) {
          return -1;
        } else if (aorder == border) {
          return 0;
        }
        return 1;
      }
      return 1;
    });

    for (let i = 0; i < this.quizzes.length; i++) {
      console.log(`QuizLocation2: ${this.quizzes[i].QuizLocation2} course - ${JSON.stringify(this.chapterIdToCourse[this.quizzes[i].QuizLocation2])}`);
      this.quizzes[i]['courseId'] = this.chapterIdToCourse[this.quizzes[i].QuizLocation2]?.id;
      this.quizzes[i]['courseName'] = this.chapterIdToCourse[this.quizzes[i].QuizLocation2]?.courseName;
    }

    this.totalRecords = this.quizzes.length;
    this.loading = false;
  }

  async ngOnInit() {
    await this.loadQuizzes();

    this.cols = [
      { field: "QuizID", header: "ID" },
      { field: "QuizTitle", header: "Title" },
      { field: "courseName", header: "Course" },
      { field: "QuizLocation", header: "Chapter" },
      { field: "QuizOrder", header: "Order" },
    ];

  }

  showDialogToAdd() {
    this.newQuiz = true;
    this.displayDialog = true;
    this.clearAll();
  }

  save() {
    let p: Promise<any>;
    if (this.newQuiz) {
      const { courseId, courseName, ...quiz } = <any>this.quiz;
      p = this.quizesService.create(courseId, quiz);
    } else {
      const { courseId, courseName, ...quiz } = <any>this.quiz;
      p = this.quizesService.update(courseId, quiz);
    }

    return p
      .then(() => {
        this.loadQuizzes();
        this.successMessage = "Quiz saved successfully";
      })
      .catch((err) => {
        if (
          err &&
          err.status === 400 &&
          err.error &&
          err.error.error &&
          err.error.error.description
        ) {
          this.errorMessage = err.error.error.description;
        }
      });
  }

  async onRowSelect(event) {
    this.newQuiz = false;
    this.clearAll();
    this.quiz = event.data;
    const chapters = this.courseChapters.find(i => i.course.id == this.quiz['courseId']).chapters;

    this.quizLocations = chapters.map(i => ({ "label": i.ChapterName, "value": i.ID.toString() }));
    this.displayDialog = true;
    this.link = `/courses/${this.quiz['courseId']}/chapters/${this.quiz.QuizLocation2}/quizzes/${this.quiz.QuizID}`;
    this.logger.info(`Link: ${this.link}`);
  }

  clearAll() {
    this.quiz = {} as Quiz;
    this.quizLocations = [];
    this.errorMessage = null;
    this.successMessage = null;
    this.link = null;
  }

  onChangeLocation(event) {
    console.log(`Changing location to ${event.value}`);
    let quizLocation = this.quizLocations.find((ql) => ql.value == event.value);
    this.quiz.QuizLocation = quizLocation.label;
    this.quiz.QuizLocation2 = quizLocation.value;
  }

  async onChangeCourse(event) {
    console.log(`Changing course to ${event.value}`);
    const chapters = this.courseChapters.find(i => i.course.id == event.value).chapters;

    this.quizLocations = chapters.map(i => ({ "label": i.ChapterName, "value": i.ID.toString() }));
    console.log(JSON.stringify(this.quizLocations));
  }

  gotoQuestions(event) {
    this.router.navigate(["admin", "courses", this.quiz["courseId"], "quizzes", this.quiz.QuizID, "questions"]);
  }

  async delete() {
    try {
      this.errorMessage = null;
      await this.quizesService.delete(this.quiz['courseId'], this.quiz.QuizID);
      this.successMessage = "Quiz deleted successfully";
      this.quiz = {} as Quiz;
      await this.loadQuizzes();
    } catch (err) {
      console.error(err);
      this.errorMessage = err;
    }
  }

  handleClose() {
    this.displayDialog = false;
    this.clearAll();
    this.logger.info(`Closing dialog...`);
  }
}
