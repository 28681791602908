import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { NGXLogger } from "ngx-logger";
import { filter } from "rxjs/operators";
import { SignInActionTypes, SignedInAction } from "src/app/components/allusers/signin/signin.actions";
import { CourseState, CoursesActionTypes, UpdateAction } from "src/app/components/members/courses/courses.reducer";
import { Course } from "src/app/models/course.model";
import { MemberCourse } from "src/app/models/member-course.model";
import { User } from "src/app/models/user.model";
import { ChaptersService } from "src/app/services/chapters.service";
import { CoursesService } from "src/app/services/courses.service";
import { QuizesService } from "src/app/services/quizes.service";
import { RegmemCoursesService } from "src/app/services/regmem-courses.service";
import { RegmembersService } from "src/app/services/regmembers.service";
import { SignInState } from "../../allusers/signin/signin.model";
import { getSignedInUser } from "../../allusers/signin/signin.selector";


@Component({
  selector: "app-member-courses",
  templateUrl: "./member-courses.component.html",
  styleUrls: ["./member-courses.component.css"],
})
export class MemberCoursesComponent implements OnInit {
  constructor(
    private regmembersService: RegmembersService,
    private regmemCoursesService: RegmemCoursesService,
    private coursesService: CoursesService,
    private signInStore: Store<SignInState>,
    private courseStore: Store<CourseState>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private chapterService: ChaptersService,
    private quizesService: QuizesService,
    private logger: NGXLogger
  ) { }

  first = 0;
  rows = 15;
  sortField;
  sortOrder;
  filters;
  totalRecords = 0;

  displayDialog: boolean;

  coursesSelect: any[];
  memberName: string;
  member: User = {} as User;
  courses: Course[];

  newMemberCourse: boolean;
  memberCourses: MemberCourse[];
  memberCourse: MemberCourse;

  cols: any[];

  dateFilters: any;

  memberCourseform = new FormGroup({
    courseId: new FormControl("", Validators.required),
    status: new FormControl("", Validators.required),
  });
  loading = true;

  currentUser$ = this.signInStore.pipe(
    select(getSignedInUser),
    filter((val) => (val ? true : false))
  );
  currentUser: User;
  errorMessage;
  successMessage;

  memberCourseStatuses = [
    { label: "Not Paid", value: "not_paid" },
    { label: "Paid", value: "paid" },
  ];

  async ngOnInit() {
    this.courses = await this.coursesService.findAll();
    this.activatedRoute.params.subscribe(async (params) => {
      this.memberName = params["membername"];
      await this.loadMemberCourses();
    });

    this.cols = [
      { field: "courseName", header: "Course Name" },
      { field: "status", header: "Status" },
      { field: "registeredDate", header: "Registered" },
      { field: "lastVisitedDate", header: "Last Visited" },
    ];
    this.currentUser$.subscribe((user) => (this.currentUser = user));

    this.coursesSelect = this.courses.map((c) => {
      return { label: c.courseName, value: c.id };
    });
  }

  async loadMemberCourses() {
    const memberCourses = await this.regmemCoursesService.getMemberCourses(this.memberName);
    this.member = await this.regmembersService.getUserBy(this.memberName);
    this.memberCourses = memberCourses.map((mc) => {
      const course = this.courses.find((c) => c.id === mc.courseId);
      return {
        ...mc,
        courseName: course.courseName,
      };
    });
    this.totalRecords = this.memberCourses.length;
    this.loading = false;
  }

  showDialogToAdd() {
    this.newMemberCourse = true;
    this.displayDialog = true;
    this.clearAll();
    this.memberCourse = { memberId: this.member.memberid } as MemberCourse;
  }

  save() {
    let p: Promise<any>;
    delete this.memberCourse["courseName"];
    if (this.newMemberCourse) {
      p = this.regmemCoursesService.createMemberCourse(this.memberName, this.memberCourse);
    } else {
      p = this.regmemCoursesService.updateMemberCourse(this.memberName, this.memberCourse);
    }

    return p
      .then(() => {
        this.loadMemberCourses();
        this.clearAll();
        this.displayDialog = false;
      })
      .catch((err) => {
        if (
          err &&
          err.status === 400 &&
          err.error &&
          err.error.error &&
          err.error.error.description
        ) {
          this.errorMessage = err.error.error.description;
        }
      });
  }

  onRowSelect(event) {
    this.newMemberCourse = false;
    this.clearAll();
    this.memberCourse = { ...event.data } as MemberCourse;
    this.displayDialog = true;
  }

  clearAll() {
    this.memberCourse = {} as MemberCourse;
    this.errorMessage = null;
    this.successMessage = null;
  }

  async gotoProgress(courseId) {
    const finalChapter = await this.chapterService.getFinalChapterBy(courseId);
    const quizzes = await this.quizesService.findByCourseIdAndChapterId(courseId, finalChapter.ID);
    if (quizzes && quizzes.length < 1) {
      this.logger.error(`Final quiz not found`, quizzes);
    }
    this.courseStore.dispatch(new UpdateAction({
      currentState: CoursesActionTypes.UpdateCourse,
      course: this.courses.find(c => c.id == courseId),
      memberCourse: this.memberCourses.find(mc => mc.courseId == courseId),
      finalQuiz: quizzes[0]
    }));
    this.signInStore.dispatch(new SignedInAction({
      currentState: SignInActionTypes.SignedIn,
      user: this.member
    } as SignInState));
    this.router.navigate(["admin", "regmembers", this.member.membername, "courses", courseId, "course-progress"]);
  }

}
