import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SignUpActionTypes } from './signup.actions';
import { SignUpState } from './signup.model';

export const getSignUp = createFeatureSelector<SignUpState>(
  'signup'
);

export const isSignUpUserInfo = createSelector(
  getSignUp,
  (signupState: SignUpState) => (signupState.currentState && signupState.currentState === SignUpActionTypes.SignUpUserInfo) && signupState
);

export const isSignUpInProcess = createSelector(
  getSignUp,
  (signupState: SignUpState) => (signupState.currentState && signupState.currentState === SignUpActionTypes.SignUpInProcess) && signupState
);

export const isSignUpComplete = createSelector(
  getSignUp,
  (signupState: SignUpState) => (signupState.currentState && signupState.currentState === SignUpActionTypes.SignUpComplete) && signupState
);

export const isConfirmSignup = createSelector(
  getSignUp,
  (signupState: SignUpState) => (signupState.currentState && signupState.currentState === SignUpActionTypes.ConfirmSignUp) && signupState
);
