import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { Progress } from '../models/progress.model';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {

  constructor(private http: HttpClient) { }

  async getProgressByCourse(courseId: number, memberId: number): Promise<Progress[]> {
    return await lastValueFrom(this.http.get<Progress[]>(`${environment.apiUrl}/courses/${courseId}/regmembers/${memberId}/progress`));
  }

  async memberProgressByCourse(courseId: number, memberId: number, quizSubmission: any, params?: any): Promise<any> {
    return await lastValueFrom(this.http.post<Progress>(`${environment.apiUrl}/courses/${courseId}/regmembers/${memberId}/progress`, quizSubmission, {
      params
    }));
  }

  async deleteProgress(courseId: number, memberId: number, progressId: number): Promise<any> {
    return await lastValueFrom(this.http.delete(`${environment.apiUrl}/courses/${courseId}/regmembers/${memberId}/progress/${progressId}`));
  }
}
