import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Course } from '../models/course.model';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {
  baseURL: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  async findAll() {
    return await lastValueFrom(
      this.http
        .get<Array<Course>>(`${environment.apiUrl}/courses`)
    );
  }

  async findBy(id) {
    return await lastValueFrom(
      this.http
        .get<Course>(`${environment.apiUrl}/courses/${id}`)
    );
  }

  async update(course: Course) {
    return await lastValueFrom(
      this.http
        .put<Course>(`${environment.apiUrl}/courses/${course.id}`, course)
    );
  }

  async create(course: Course): Promise<Course> {
    return await lastValueFrom(
      this.http
        .post<Course>(`${environment.apiUrl}/courses`, course)
    );
  }

  async delete(id: number) {
    return await lastValueFrom(
      this.http
        .delete(`${environment.apiUrl}/courses/${id}`)
    );
  }
}
