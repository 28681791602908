import { Component, OnInit } from "@angular/core";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { select, Store } from "@ngrx/store";
import { User } from "src/app/models/user.model";
import { SignInState } from "../../allusers/signin/signin.model";
import { getSignedInUser } from "../../allusers/signin/signin.selector";

@Component({
  selector: "app-area-unit-dashboard",
  templateUrl: "./area-unit-dashboard.component.html",
  styleUrls: ["./area-unit-dashboard.component.css"]
})
export class AreaUnitDashboardComponent implements OnInit {
  reportIcon = faUsers;
  currentUser$ = this.signInStore.pipe(select(getSignedInUser));
  currentUser: User;

  constructor(private signInStore: Store<SignInState>) {
    this.currentUser$.subscribe(user => (this.currentUser = user));
  }

  ngOnInit() {}
}
