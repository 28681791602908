import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { Banner } from '../models/banner.model';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  constructor(private http: HttpClient) { }

  async addOrEditBanner(option, data) {
    var tempUrl = ``;
    if (option == 'update') {
      tempUrl = `banners/${data.Id}`;
    } else {
      tempUrl = `banners`;
    }
    return await lastValueFrom(this.http.put(`${environment.apiUrl}/${tempUrl}`, data));
  }

  async getBannerBy(bannerId): Promise<Banner> {
    return await lastValueFrom(this.http.get<Banner>(`${environment.apiUrl}/banners/${bannerId}`));
  }

  async getBanners() {
    return await lastValueFrom(this.http.get(`${environment.apiUrl}/banners`));

  }
}
