import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NGXLogger } from "ngx-logger";
import { Table } from "primeng/table";
import { Course } from "src/app/models/course.model";
import { School } from "src/app/models/school.model";
import { CoursesService } from "src/app/services/courses.service";

@Component({
  selector: "app-course-list",
  templateUrl: "./course-list.component.html",
  styleUrls: ["./course-list.component.css"],
})
export class CourseListComponent implements OnInit {
  @ViewChild("dt") private dt: Table;

  first = 0;
  rows = 15;
  sortField;
  sortOrder;
  filters;
  totalRecords = 0;

  displayDialog: boolean;

  selectedCourse: Course;

  newCourse: boolean;

  courses: Course[];
  course: Course;

  cols: any[];

  schoolsSelect: School[];

  errorMessage;

  dateFilters: any;

  courseForm = new FormGroup({
    courseName: new FormControl("", Validators.required),
    courseDesc: new FormControl("", Validators.required),
  });

  loading = true;

  courseStatuses = [
    { label: "Draft", value: "draft" },
    { label: "Active", value: "active" },
    { label: "In Active", value: "inactive" },
  ];

  editorConfig: any;
  tinyMceInit = {
    plugins: 'advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen media table code wordcount',
    toolbar:
      'undo redo | formatselect | bold italic backcolor | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | removeformat | link image',
    target_list: [
      { title: 'Same page', value: '_self' },
      { title: 'New page', value: '_blank' }
    ],
    default_link_target: '_blank',
    height: '700', width: '100%',
    paste_data_images: false,    
  };

  constructor(
    private logger: NGXLogger,
    private coursesService: CoursesService,
    public router: Router,
    private activateRoute: ActivatedRoute
  ) { }

  async loadCourses() {
    this.courses = await this.coursesService.findAll();

    this.totalRecords = this.courses.length;
    this.loading = false;
  }

  ngOnInit() {
    this.cols = [
      { field: "id", header: "ID" },
      { field: "courseName", header: "Course Name" },
      { field: "courseDesc", header: "Course Desc" },
      { field: "status", header: "Status" },
    ];

    this.loadCourses();
    this.updateConfig();
  }

  updateConfig() {
    this.editorConfig = {
      plugins: 'advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen media table code wordcount',
      toolbar:
        'undo redo | formatselect | bold italic backcolor | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | removeformat | link image',
      target_list: [
        { title: 'Same page', value: '_self' },
        { title: 'New page', value: '_blank' }
      ],
      default_link_target: '_blank',
      height: '300', width: '100%',
      paste_data_images: false,
    };
  }

  showDialogToAdd() {
    this.newCourse = true;
    this.displayDialog = true;
    this.clearAll();
  }

  save() {
    let p: Promise<any>;
    if (this.newCourse) {
      p = this.coursesService.create(this.course);
    } else {
      p = this.coursesService.update(this.course);
    }

    return p
      .then(() => {
        this.loadCourses();
        this.clearAll();
        this.displayDialog = false;
      })
      .catch((err) => {
        if (
          err &&
          err.status === 400 &&
          err.error &&
          err.error.error &&
          err.error.error.description
        ) {
          this.errorMessage = err.error.error.description;
        }
      });
  }

  onRowSelect(event) {
    this.newCourse = false;
    this.clearAll();
    this.course = Object.assign({} as Course, event.data);
    this.loadCourses();
    this.displayDialog = true;
  }

  clearAll() {
    this.course = {} as Course;
    this.errorMessage = null;    
  }

  gotoChapters(event) {
    this.router.navigate(["admin", "courses", this.course.id, "chapters"]);
  }

  async delete() {
    await this.coursesService.delete(this.course.id);
    this.loadCourses();
    this.clearAll();
    this.displayDialog = false;
  }

  handleClose() {
    this.clearAll();
    this.displayDialog = false;
    this.logger.info(`Closing dialog...`);
  }
}
