<div class="fx-layout-row fx-layout-align-center-center">
  <div class="fx-flex-80pc">
    <p-table #dt [columns]="cols" [value]="schools" [paginator]="true" selectionMode="single" [rows]="15"
      [(selection)]="selectedSchool" (onRowSelect)="onRowSelect($event)">
      <ng-template pTemplate="caption">
        <div style="text-align: right">
          <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
          <input type="text" pInputText size="50" placeholder="Global Filter"
            (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            {{col.header}}
          </th>
        </tr>
        <tr>
          <th *ngFor="let col of columns" [ngSwitch]="col.field">
            <input *ngSwitchCase="'schoolname'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)">
            <input *ngSwitchCase="'regmemberCount'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)">
            <p-dropdown *ngSwitchCase="'aucompany'" [options]="areaUnits" [style]="{'width':'100%'}"
              (onChange)="dt.filter($event.value, col.field, 'equals')" [(ngModel)]="selectedAreaUnit"
              optionLabel="company" optionValue="affiliateid"></p-dropdown>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td *ngFor="let col of columns">
            {{rowData[col.field]}}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary" let-rowData>
        <div style="text-align:left">
          <button type="button" pButton (click)="showDialogToAdd()" label="Add"></button>
        </div>
      </ng-template>
    </p-table>

    <p-dialog header="School Details" [(visible)]="displayDialog" [responsive]="true" showEffect="fade" [modal]="true"
      [style]="{width: '500px'}">
      <div class="ui-g ui-fluid" *ngIf="school">
        <div class="ui-g-12">
          <div class="ui-g-4">
            <label for="areaunitid">Area Unit</label>
          </div>
          <div class="ui-g-8">
            <p-dropdown [options]="affiliatesSelect" [(ngModel)]="selectedAreaUnit"></p-dropdown>
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4">
            <label for="schoolname">Name</label>
          </div>
          <div class="ui-g-8">
            <input pInputText id="schoolname" [(ngModel)]="school.schoolname" />
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4">
            <label for="schooladdress">Address</label>
          </div>
          <div class="ui-g-8">
            <input pInputText id="schooladdress" [(ngModel)]="school.schooladdress" />
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4">
            <label for="schoolcity">City</label>
          </div>
          <div class="ui-g-8">
            <input pInputText id="schoolcity" [(ngModel)]="school.schoolcity" />
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4">
            <label for="schoolstate">State</label>
          </div>
          <div class="ui-g-8">
            <input pInputText id="schoolstate" [(ngModel)]="school.schoolstate" />
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4">
            <label for="schoolzip">Zip</label>
          </div>
          <div class="ui-g-8">
            <input pInputText id="schoolzip" [(ngModel)]="school.schoolzip" />
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4">
            <label for="schoolnotes">Notes</label>
          </div>
          <div class="ui-g-8">
            <textarea pInputText id="schoolnotes" [(ngModel)]="school.schoolnotes"></textarea>
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4">
            <label for="schoolADCgrid">ADC Grid</label>
          </div>
          <div class="ui-g-8">
            <input pInputText id="schoolADCgrid" [(ngModel)]="school.schoolADCgrid" />
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4">
            <label for="schoolcrossstreets">Cross Streets</label>
          </div>
          <div class="ui-g-8">
            <input pInputText id="schoolcrossstreets" [(ngModel)]="school.schoolcrossstreets" />
          </div>
        </div>
      </div>
      <p-footer>
        <div class="ui-dialog-buttonpane ui-helper-clearfix">
          <button type="button" pButton (click)="delete()" label="Delete"></button>
          <button type="button" pButton (click)="save()" label="Save"></button>
        </div>
      </p-footer>
    </p-dialog>
  </div>
</div>
