import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CourseTimerActionTypes } from "./course-timer.actions";
import { CourseTimerState } from "./course-timer.model";

export const getCourseTimer = createFeatureSelector<CourseTimerState>(
  "courseTimer"
);

export const getCurrentCourseTimerState = createSelector(
  getCourseTimer,
  (state: CourseTimerState) => state
);

export const isTimerStart = createSelector(
  getCourseTimer,
  (state: CourseTimerState): CourseTimerState | boolean =>
    state && state.timeRemaining > 0 && state.prevChapterTimeRemaining == 0 && state.currentState == CourseTimerActionTypes.TimerStart && state
);

export const isTimerStopped = createSelector(
  getCourseTimer,
  (state: CourseTimerState): CourseTimerState | boolean =>
    state && state.currentState == CourseTimerActionTypes.TimerStopped && state
);


export const isTimerCompleted = createSelector(
  getCourseTimer,
  (state: CourseTimerState): CourseTimerState | boolean =>
    state && state.timeRemaining <= 0 && state.currentState == CourseTimerActionTypes.TimerCompleted && state
);

export const isTimerRunning = createSelector(
  getCourseTimer,
  (state: CourseTimerState): CourseTimerState | boolean =>
    state && state.timeRemaining > 0 && state.currentState == CourseTimerActionTypes.TimerRunning && state
);

export const isTimerIncomplete = createSelector(
  getCourseTimer,
  (state: CourseTimerState): CourseTimerState | boolean =>
    state && state.prevChapterTimeRemaining > 0 && state.currentState == CourseTimerActionTypes.TimerIncomplete && state
);
