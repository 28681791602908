import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { Amplify } from 'aws-amplify';
import {
  createUserPoolsTokenProvider
} from 'aws-amplify/adapter-core';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { defaultStorage } from 'aws-amplify/utils';
import { AppModule } from "./app/app.module";
import { CommonTokenProvider } from "./app/services/auth-service/base.token.provider";
import * as awsamplify from "./aws-exports";
import { environment } from "./environments/environment";

// Create an instance of your custom provider
// NOTE do not change this, took multiple tries to make it work

const authConfig = {
  Cognito: {
    ...awsamplify.awsmobile,
  },
};

cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);
cognitoUserPoolsTokenProvider.setAuthConfig(authConfig)

const tokenProvider = createUserPoolsTokenProvider(
  authConfig!,
  defaultStorage
);

export const commonTokenProvider = new CommonTokenProvider(tokenProvider);

Amplify.configure({
  Auth: authConfig,
}, {
  Auth: {
    // Supply the custom credentials provider to Amplify
    tokenProvider: commonTokenProvider,
  }
}
);



if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
