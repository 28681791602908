<!-- <button (click)="increment()">Increment</button>
 
<div>Current Count: {{ count$ | async }}</div>

<button (click)="decrement()">Decrement</button>

<button (click)="reset()">Reset Counter</button> -->
<!-- <link href="http://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<script src="https://code.jquery.com/jquery-2.1.1.min.js"></script>
  <script src="js/materialize.js"></script>
  <script src="js/init.js"></script> -->



<!-- <app-header></app-header>
  <main>
    <router-outlet></router-outlet>
  </main>
  <app-footer></app-footer> -->

<app-edison-home [edisonCode]="edisonCode" *ngIf="edisonCode"></app-edison-home>

<div class="fx-layout-row fx-flex-fill fx-wrap" style="flex-flow: column;" *ngIf="!edisonCode">
  <div class="fx-flex-7pc">
    <app-header></app-header>
  </div>
  <div class="background-color fx-flex-auto">
    <router-outlet></router-outlet>
  </div>
  <div class="grey lighten-4 fx-flex-3rem">
    <app-footer></app-footer>
  </div>
</div>
