import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { filter } from "rxjs/operators";
import { SignInState } from "../components/allusers/signin/signin.model";
import {
  getSignedInUser,
  isSignedOut
} from "../components/allusers/signin/signin.selector";

@Injectable()
export class TeacherGuard {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;
  getLoggedInUser$ = this.loginStore.pipe(
    select(getSignedInUser),
    filter(val => (val ? true : false))
  );
  loggedInUser;
  isLoggedOut$ = this.loginStore.pipe(
    select(isSignedOut),
    filter(val => (val ? true : false))
  );
  constructor(
    private router: Router,
    private loginStore: Store<SignInState>
  ) {
    this.getLoggedInUser$.subscribe(user => (this.loggedInUser = user));
    this.isLoggedOut$.subscribe(() => (this.loggedInUser = null));
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.loggedInUser && this.loggedInUser.memberstatus === "admin") {
      return true;
    }

    if (
      this.loggedInUser &&
      (this.loggedInUser.memberstatus && this.loggedInUser.memberstatus.indexOf("teacher") !== -1 ||
        this.loggedInUser.memberstatus.indexOf("admin") !== 1)
    ) {
      return true;
    }

    // not logged in so redirect to login page
    this.router.navigate(["/home"]);
    return false;
  }
}
