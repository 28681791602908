<div class="fx-layout-row fx-layout-align-center-center">
  <div class="fx-flex-80pc">
    <h4 class="white" *ngIf="quiz">{{ quiz.QuizTitle }}</h4>
    <p-table #dt [columns]="cols" [value]="questions" [paginator]="true" selectionMode="single" [rows]="rows"
      [totalRecords]="totalRecords" [loading]="loading" (onRowSelect)="onRowSelect($event)">
      <!-- <ng-template pTemplate="caption">
          <div style="text-align: right">
            <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
            <input type="text" pInputText class="margin0"  size="50" placeholder="Global Filter"
              (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
          </div>
        </ng-template> -->
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
              ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order">
            </p-sortIcon>
          </th>
        </tr>
        <tr>
          <th *ngFor="let col of columns" [ngSwitch]="col.field">
            <input *ngSwitchCase="'QuestionText'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')">
            <input *ngSwitchCase="'questiontype'" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, 'contains')">
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td *ngFor="let col of columns">
            <span>{{rowData[col.field]}}</span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary" let-rowData>
        <div style="text-align:left">
          <button type="button" pButton (click)="showDialogToAdd()" label="Add"></button>
        </div>
      </ng-template>
    </p-table>

    <p-dialog header="Question Details" [(visible)]="displayDialog" [responsive]="true" showEffect="fade" [modal]="true"
      [style]="{width: '500px'}">
      <div class="red-text" *ngIf="errorMessage">{{ errorMessage }}</div>

      <div class="ui-g ui-fluid" *ngIf="question">
        <div class="ui-g-12">
          <div class="ui-g-4">
            <label for="sourceschool">Quiz</label>
          </div>
          <div class="ui-g-8">
            {{ quiz.QuizTitle }}
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4">
            <label for="sourceschool">QuestionText</label>
          </div>
          <div class="ui-g-8">
            <input pInputText class="margin0" id="QuestionText" [(ngModel)]="question.QuestionText" required />
          </div>
        </div>
        <div class="ui-g-12">
          <div class="ui-g-4 pad0">
            <label for="QuestionType">QuestionType</label>
          </div>
          <div class="ui-g-8 pad0">
            <input pInputText class="margin0" id="QuestionType" [(ngModel)]="question.questiontype" required />
          </div>
        </div>

      </div>
      <ng-template pTemplate="footer">
        <div class="ui-dialog-buttonpane ui-helper-clearfix" *ngIf="question">
          <a target="_blank" (click)="gotoAnswerOptions($event)" type="button" pButton label="Answer Options"
            class="left"></a>
          <button type="button" pButton (click)="delete()" label="Delete" class="left"></button>
          <button type="button" pButton (click)="save()" [disabled]="!question.QuestionText || !question.questiontype"
            label="Save"></button>
        </div>

      </ng-template>

    </p-dialog>
  </div>
</div>
