import { createFeatureSelector, createSelector } from "@ngrx/store";
import { QuizTestsActionTypes } from "./quiz-tests.actions";
import { QuizTestsState } from "./quiz-tests.model";

export const getQuizTests = createFeatureSelector<QuizTestsState>("quizTests");

export const getCurrentQuizTestsState = createSelector(
  getQuizTests,
  (state: QuizTestsState) => state.currentState
);

export const getFinalQuiz = createSelector(
  getQuizTests,
  (state: QuizTestsState) => state.finalQuiz
);

export const isQuiz = createSelector(
  getQuizTests,
  (state: QuizTestsState) =>
    state &&
    state.quizId &&
    (state.currentState === QuizTestsActionTypes.QuizShow ||
      state.currentState === QuizTestsActionTypes.QuizSubmitted ||
      state.currentState === QuizTestsActionTypes.QuizSubmitting) &&
    state
);

export const isQuizShow = createSelector(
  getQuizTests,
  (state: QuizTestsState) => {
    if (
      state &&
      state.quizId &&
      state.currentState === QuizTestsActionTypes.QuizShow
    ) {
      return state;
    }
    return null;
  }
);

export const isSound = createSelector(
  getQuizTests,
  (state: QuizTestsState) =>
    state &&
    state.soundId &&
    state.currentState === QuizTestsActionTypes.AudioShow &&
    state
);

export const isVideo = createSelector(
  getQuizTests,
  (state: QuizTestsState) =>
    state &&
    state.videoId &&
    state.currentState === QuizTestsActionTypes.VideoShow &&
    state
);

export const isAsset = createSelector(
  getQuizTests,
  (state: QuizTestsState) =>
    state &&
    state.assetId &&
    state.currentState === QuizTestsActionTypes.AssetShow &&
    state
);

export const isFinalShow = createSelector(
  getQuizTests,
  (state: QuizTestsState) =>
    (state &&
      state.quizId &&
      state.currentState === QuizTestsActionTypes.FinalQuizShow &&
      state) ? state : null as unknown as QuizTestsState
);

export const isFinal = createSelector(getQuizTests, (state: QuizTestsState) => {
  return (
    state &&
    (state.currentState === QuizTestsActionTypes.FinalQuizShow ||
      state.currentState === QuizTestsActionTypes.FinalQuizSubmitting ||
      state.currentState === QuizTestsActionTypes.FinalQuizSubmitted) &&
    state
  );
});

export const isFinalNotAllowedQuizIncomplete = createSelector(
  getQuizTests,
  (state: QuizTestsState) =>
    state.currentState === QuizTestsActionTypes.FinalNotAllowedQuizIncomplete &&
    state
);

export const isQuizSkippingNotAllowed = createSelector(
  getQuizTests,
  (state: QuizTestsState) =>
    state.currentState === QuizTestsActionTypes.QuizSkipped && state
);
