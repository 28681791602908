import {
  ChapterViewActionTypes,
  ChapterViewActions,
} from "./chapter-view.actions";
import { ChapterViewState } from "./chapter-view.model";

const initialState: ChapterViewState = {} as ChapterViewState;

export function chapterViewReducer(
  state = initialState,
  action: ChapterViewActions
): ChapterViewState {
  switch (action.type) {
    case ChapterViewActionTypes.UpdateChapter:
      return {
        ...state,
        ...action.payload,
      };
    case ChapterViewActionTypes.ChapterShow:
      return { ...state, ...action.payload };
    case ChapterViewActionTypes.FinalShow:
      return { ...state, ...action.payload };
    case ChapterViewActionTypes.QuizzesIncomplete:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
