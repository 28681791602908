import { SignUpActions, SignUpActionTypes } from './signup.actions';
import { SignUpState } from './signup.model';

export type SignupAction = SignUpActions;

const initialState: SignUpState = {} as SignUpState;

export function signupReducer(state = initialState, action: SignupAction): SignUpState {
  switch (action.type) {
    case SignUpActionTypes.SignUpUserInfo:
      return { ...state, ...action.payload };
    case SignUpActionTypes.SignUpInProcess:
      return { ...state, ...action.payload };
    case SignUpActionTypes.SignUpComplete:
      return { ...state, ...action.payload };
    case SignUpActionTypes.SignUpFailed:
      return { ...state, ...action.payload };
    case SignUpActionTypes.ConfirmSignUp:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}