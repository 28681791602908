// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: "https://y5pzz4pw5l.execute-api.us-east-1.amazonaws.com/dev",
  // apiUrl: "http://localhost:3000/local",
  s3BucketUrl: "https://s3.amazonaws.com/ktd-dev-website/",
  cognito: {
    region: "us-east-1",
    identityPoolId: "us-east-1:1a0b0830-674d-46fb-b515-72a5159f7b1b",
    userPoolId: "us-east-1_JRGXZLBzL",
    userPoolClientId: "52d0vi22efsoflok782id50gr8",
    authenticationFlowType: "USER_PASSWORD_AUTH",
    userPoolWebClientId: "52d0vi22efsoflok782id50gr8",
  },
  // apiUrl: "https://9snk6xuync.execute-api.us-east-1.amazonaws.com/prod",
  // s3BucketUrl: "https://s3.amazonaws.com/www.keystodriving.com/assets",
  // cognito: {
  //   region: "us-east-1",
  //   userPoolId: "us-east-1_YwvBOeBm5",
  //   userPoolClientId: "15d18654o93n0nsltklstimep9",
  //   authenticationFlowType: "USER_PASSWORD_AUTH"
  // },
  debugMode: true,
  stripeKey: "pk_test_51MP9U3LFCE48FLZzptSet4ysUqoy4y1kBqskU5PoeqoVNAw1gq3Vij7XC1OHIp3hPW0vI2HSRbjoBk3cwTZes2tC00sewLlpoU"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
