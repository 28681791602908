<div class="row" class="fx-layout-row fx-layout-align-center-start" *ngIf="currentUser">
  <div class="fx-flex-59pc">
    <mat-card>
      <mat-card-content>
        <div>
          <div style="color:red" *ngIf="(currentPayment$ | async).currentState === 'PaymentFailed'">
            {{ (currentPayment$ | async).error }}
          </div>

          <div class="fx-layout-row fx-layout-align-center-center">
            <div id="card-element" #cardElement></div>
          </div>

          <div class="fx-layout-column fx-layout-align-center-center" style="padding-top: 20px;"
            *ngIf="(showCC$ | async)">
            <button mat-raised-button color="primary" type="button" (click)="paymentSubmit()">
              Continue to Payment (${{ amount }})
            </button>
          </div>

          <div class="row" class="fx-layout-column fx-layout-align-center-center"
            *ngIf="(currentPayment$ | async).currentState === 'PaymentSubmitting'">
            <mat-spinner strokeWidth="3" diameter="80" title="Processing Payment..."></mat-spinner>
            <p>Processing Payment...</p>
          </div>

          <div class="row" class="fx-layout-column fx-layout-align-center-center"
            *ngIf="(currentPayment$ | async).currentState === 'PaymentLoading'">
            <mat-spinner strokeWidth="3" diameter="80" title="Loading Payment..."></mat-spinner>
            <p>Loading Payment...</p>
          </div>

          <div class="row" class="fx-layout-row fx-layout-align-center-center">
            <div class="fx-layout-column fx-layout-align-space-around-start"
              *ngIf="(currentPayment$ | async).currentState === 'PaymentSuccessful'">
              <div class="fx-flex-60pc">
                <h5>Thank you, your payment was successful.</h5>
              </div>
              <div class="fx-layout-align-center-center">
                <button mat-raised-button color="primary" (click)="goToCourse()" class="right">Continue
                  Course</button>
              </div>
              <div class="fx-flex-80pc">
                <p>If you do not see the course, please re-fresh the page and try again.</p>
              </div>
            </div>
          </div>
        </div>


      </mat-card-content>
    </mat-card>
  </div>
</div>
