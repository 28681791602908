import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { NGXLogger } from "ngx-logger";
import { filter } from "rxjs/operators";
import { SignInState } from "../../components/allusers/signin/signin.model";
import {
  isSignedIn,
  isSignedOut
} from "../../components/allusers/signin/signin.selector";

@Injectable({
  providedIn: "root"
})
export class RouterService {
  isUserLoggedIn$ = this.signInStore.pipe(
    select(isSignedIn),
    filter(val => (val ? true : false))
  );
  isUserLoggedOut$ = this.signInStore.pipe(
    select(isSignedOut),
    filter(val => val)
  );

  constructor(
    private signInStore: Store<SignInState>,
    private router: Router,
    private logger: NGXLogger
  ) { }

  gotoDashboard() {
    this.router.navigate(["/courses"]);
  }

  gotoBase() {
    this.router.navigate(["/"]);
  }

  gotoHome() {
    this.router.navigate(["/home"]);
  }

  gotoProfile() {
    this.router.navigate(["/profile"]);
  }

  gotoAdminDashboard() {
    this.router.navigate(["admin", "dashboard"]);
  }
}
