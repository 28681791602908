import { Action } from "@ngrx/store";
import { SignUpState } from './signup.model';

export enum SignUpActionTypes {
  SignUpUserInfo = "SignUpUserInfo",
  SignUpInProcess = "SignUpInProcess",
  SignUpComplete = "SignUpComplete",
  SignUpFailed = "SignUpFailed",
  ConfirmSignUp = "ConfirmSignUp",
}

export class SignUpUserInfoAction implements Action {
  readonly type = SignUpActionTypes.SignUpUserInfo;
  constructor(public payload: SignUpState) { }
}

export class SignUpInProcessAction implements Action {
  readonly type = SignUpActionTypes.SignUpInProcess;
  constructor(public payload: SignUpState) { }
}

export class SignUpCompleteAction implements Action {
  readonly type = SignUpActionTypes.SignUpComplete;
  constructor(public payload: SignUpState) { }
}

export class SignUpFailedAction implements Action {
  readonly type = SignUpActionTypes.SignUpFailed;
  constructor(public payload: SignUpState) { }
}

export class ConfirmSignUpAction implements Action {
  readonly type = SignUpActionTypes.ConfirmSignUp;
  constructor(public payload: SignUpState) { }
}

export type SignUpActions =
  | SignUpUserInfoAction
  | SignUpInProcessAction
  | SignUpCompleteAction
  | SignUpFailedAction
  | ConfirmSignUpAction;
