import { Action } from "@ngrx/store";
import { TextToSpeechState } from "./text-to-speech.model";

export enum TextToSpeechActionTypes {
  TextToSpeechNotInitialized = "TextToSpeechNotInitialized",
  TextToSpeechInitialized = "TextToSpeechInitialized",
  TextToSpeechStopped = "TextToSpeechStopped",
  TextToSpeechPlaying = "TextToSpeechPlaying",
  TextToSpeechPaused = "TextToSpeechPaused",
  TextToSpeechCompleted = "TextToSpeechCompleted",
}


export class TextToSpeechNotInitialized implements Action {
  readonly type = TextToSpeechActionTypes.TextToSpeechNotInitialized;
  constructor(public payload: TextToSpeechState) { }
}

export class TextToSpeechInitialized implements Action {
  readonly type = TextToSpeechActionTypes.TextToSpeechInitialized;
  constructor(public payload: TextToSpeechState) { }
}

export class TextToSpeechStopped implements Action {
  readonly type = TextToSpeechActionTypes.TextToSpeechStopped;
  constructor(public payload: TextToSpeechState) { }
}

export class TextToSpeechPlaying implements Action {
  readonly type = TextToSpeechActionTypes.TextToSpeechPlaying;
  constructor(public payload: TextToSpeechState) { }
}

export class TextToSpeechPaused implements Action {
  readonly type = TextToSpeechActionTypes.TextToSpeechPaused;
  constructor(public payload: TextToSpeechState) { }
}

export class TextToSpeechCompleted implements Action {
  readonly type = TextToSpeechActionTypes.TextToSpeechCompleted;
  constructor(public payload: TextToSpeechState) { }
}


export type TextToSpeechActions =
  | TextToSpeechNotInitialized
  | TextToSpeechInitialized
  | TextToSpeechStopped
  | TextToSpeechPlaying
  | TextToSpeechPaused
  | TextToSpeechCompleted;