<div class="fx-layout-row fx-flex-fill fx-wrap" style="flex-flow: column;">
  <div class="fx-flex-4rem">
    <app-header></app-header>
  </div>
  <div class="background-color fx-flex-auto">
    <div class="backgroundColor fx-layout-column fx-flex-fill fx-layout-align-space-between-stretch">
      <div class="row padding-bottom">
        <div class="fx-layout-column fx-layout-align-space-around-center">
          <div class=" row"></div>
          <div class="row"></div>
          <div class="row"></div>
          <div class="row" *ngIf="!errorMessage">
            <div class="white-text padding-bottom">Please wait while we try to Log you in...</div>
          </div>
          <div class="row" *ngIf="!errorMessage">
            <mat-spinner strokeWidth="5" diameter="125"></mat-spinner>
          </div>
          <div class="row col s12" *ngIf="errorMessage">
            <span class="red-text">{{ errorMessage }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="grey lighten-4 fx-flex-3rem">
    <app-footer></app-footer>
  </div>
</div>


<!-- </main> -->
