import { Action } from "@ngrx/store";
import { Course } from "src/app/models/course.model";
import { MemberCourse } from "src/app/models/member-course.model";
import { Quiz } from "src/app/models/quiz.model";


export interface CourseState {
  currentState: CoursesActionTypes;
  course: Course,
  memberCourse: MemberCourse,
  finalQuiz: Quiz
}

export enum CoursesActionTypes {
  NoCourse = "NoCourse",
  UpdateCourse = "UpdateCourse",
}

export class NoCourseAction implements Action {
  readonly type = CoursesActionTypes.NoCourse;
  constructor(public payload: CourseState) { }
}

export class UpdateAction implements Action {
  readonly type = CoursesActionTypes.UpdateCourse;
  constructor(public payload: CourseState) { }
}

export type CourseActions =
  | NoCourseAction
  | UpdateAction;

export const initialState: CourseState = { currentState: CoursesActionTypes.NoCourse, course: undefined } as CourseState;

export function coursesReducer(
  state = initialState,
  action: CourseActions
): CourseState {
  switch (action.type) {
    case CoursesActionTypes.UpdateCourse:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
