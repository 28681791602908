<div class="row padding-bottom">
  <div class="row col s12" *ngIf="errorMessage">
    <span class="red-text">{{ errorMessage }}</span>
  </div>
  <div class="row" *ngIf="currentState === 'NewPasswordRequiredSubmitting'">
    <div class="row"></div>
    <div class="row"></div>
    <div class="row"></div>
    <div class="row"></div>
    <div class="row"></div>
    <div class="col s5"></div>
    <div class="col s7">
      <mat-spinner strokeWidth="1" diameter="25"></mat-spinner>
    </div>
  </div>
</div>

<div id="newPasswordRequired" class="row s12" *ngIf="currentState === 'NewPasswordRequired'">
  <form class="col s12" [formGroup]="newPasswordRequiredForm" (ngSubmit)="newPasswordRequired()">
    <div class="center newpwd-container">
      <mat-form-field>
        <input matInput placeholder="New Password" formControlName="newPassword" type="password">
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="Re Enter New Password" formControlName="reNewPassword" type="password">
      </mat-form-field>

      <div class="padding-card-button center ">
        <button class="waves-effect waves-light btn sign-in-btn button-width" type="submit"
          [disabled]="!newPasswordRequiredForm.valid">Submit</button>
      </div>
      <div class="center-align">
        <label class="center-align">
          <!-- <span class="white-text" (click)="changeUserPassword()">Change Password</span>/ -->
          <a><span class="grey-text" (click)="gotoSignIn()" style="font-size: 14px;">Sign In</span></a>
        </label>
      </div>
    </div>
  </form>
</div>