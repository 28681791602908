<div class="backgrounDColor">
  <div class="container">
    <div class="row"></div>
    <div class="row"></div>

    <div class="row white" *ngIf="!passwordChanged">
      <div class="row"></div>
      <div class="col s3"></div>
      <div class="col s6">
        <form class="col s12" [formGroup]="changePasswordForm" (ngSubmit)="onChangePassword()">
          <div class="input-field col m12 s12">
            <label class="left grey-text">Old Password</label>
            <input type="password" formControlName="oldPassword" class="validate black-text" required />
          </div>
          <div class="input-field col m12 s12">
            <label class="left grey-text">New Password</label>
            <input type="password" formControlName="newPassword" class="validate black-text" required />
          </div>
          <div class="input-field col m12 s12">
            <label class="left grey-text"> Re Enter New Password</label>
            <input type="password" formControlName="reNewPassword" class="validate black-text" required />
          </div>
          <div class="row">
            <div class="col s3"></div>
            <div class="col s6">
              <div class="padding-card-button center ">
                <button class="waves-effect waves-light btn sign-in-btn button-width" type="submit"
                  [disabled]="!changePasswordForm.valid">change password</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row" *ngIf="passwordChanged">
      <div class="col s3"></div>
      <div class="col s6">
        <div class="row">
          <div class="col s12 m12 l12">
            <div class="card white black-text center darken-1">
              <div class="row">
                <div class="col s4"></div>
                <div class="col s4">
                  <div class="card-image">
                    <img src="{{'assets/img/correct.png'}}" class="responsive-img">
                  </div>
                </div>
              </div>
              <div class="card-content">
                <span class="card-title" style="font-weight: 300;">Your Password Has Successfully Changed</span>
              </div>
              <div class="card-action">
                <a style="cursor: pointer;" routerLink="/course-progress">Done</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col s3"></div>
    </div>
    <div class="row"></div>
  </div>
</div>