import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EssayAnswersService {

  

  constructor(private http: HttpClient) { }

  postQuizNormalAns(data) {
    return this.http.post(`${environment.apiUrl}/essayanswers`, data)
        .toPromise()
  }

  postQuizFinalAns(data) {
    return this.http.post(`${environment.apiUrl}/insertFinalAnswers`, data)
        .toPromise()
  }

}
