import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "src/app/services/auth-service/auth.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"]
})
export class ProfileComponent implements OnInit {
  profile: FormGroup;
  successMessage: string;
  errorMessage: string;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.profile = this.formBuilder.group({
      given_name: ["", Validators.required],
      family_name: ["", Validators.required],
      street_address: ["", Validators.required],
      city: ["", Validators.required],
      state: ["", Validators.required],
      postal_code: ["", Validators.required],
      country: ["", Validators.required],
      email: ["", Validators.required],
      phone_number: ["", Validators.required]
    });
    this.authService.fetchUserAttributes()
      .then(res => {
        this.profile.patchValue(res);
        this.profile.setValue({ given_name: res.given_name });
        this.profile.setValue({
          street_address: res.address
        });
        this.profile.setValue({ city: res.locality });
        this.profile.setValue({ state: res.region });
        this.profile.setValue({
          postal_code: res.postal_code
        });
      });
  }

  async onSubmit() {
    const address = {
      street_address: this.profile.value.address.street_address,
      locality: this.profile.value.address.city,
      region: this.profile.value.address.state,
      postal_code: this.profile.value.address.postal_code,
      country: "USA"
    };
    const attributes = {
      given_name: this.profile.value.given_name,
      family_name: this.profile.value.family_name,
      email: this.profile.value.email,
      phone_number: this.profile.value.phone_number,
      address: JSON.stringify(address)
    };

    try {
      await this.authService.updateUserAttributes(attributes);
      this.successMessage = "Profile saved Successfully";
    } catch (error) {
      this.errorMessage = error.message;
    }

  }
}
