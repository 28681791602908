import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { HearAbout } from "src/app/models/hearabouts.model";
import { HearaboutService } from "src/app/services/hearabout.service";

@Component({
  selector: "app-referred-by",
  templateUrl: "./referred-by.component.html",
  styleUrls: ["./referred-by.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class ReferredByComponent implements OnInit {
  displayDialog: boolean;

  hearAbout: HearAbout = {} as HearAbout;

  selectedHearAbout: HearAbout;

  newHearAbout: boolean;

  hearAbouts: HearAbout[];

  cols: any[];

  constructor(private hearAboutService: HearaboutService) {}

  ngOnInit() {
    this.loadHearAbouts();
    this.cols = [{ field: "optionname", header: "Name" }];
  }

  private loadHearAbouts() {
    this.hearAboutService
      .getHearAbouts()
      .then(hearAbouts => (this.hearAbouts = hearAbouts));
  }

  showDialogToAdd() {
    this.newHearAbout = true;
    this.hearAbout = {} as HearAbout;
    this.displayDialog = true;
  }

  save() {
    let p: Promise<any>;
    if (this.newHearAbout) {
      p = this.hearAboutService.createHearAbout(this.hearAbout);
    } else {
      p = this.hearAboutService.updateHearAbout(
        this.hearAbout,
        this.hearAbout.optionid
      );
    }

    return p.then(() => {
      this.loadHearAbouts();
      this.hearAbout = null;
      this.displayDialog = false;
    });
  }

  delete() {
    this.hearAboutService
      .deleteHearAbout(this.selectedHearAbout.optionid)
      .then(() => {
        this.loadHearAbouts();
        this.hearAbout = null;
        this.displayDialog = false;
      });
  }

  onRowSelect(event) {
    this.newHearAbout = false;
    this.hearAbout = this.cloneHearAbout(event.data);
    this.displayDialog = true;
  }

  cloneHearAbout(h: HearAbout): HearAbout {
    const hearAbout = {} as HearAbout;
    for (const prop in h) {
      hearAbout[prop] = h[prop];
    }
    return hearAbout;
  }
}
