<div class="row" class="fx-layout-row fx-layout-align-center-start" *ngIf="currentUser">
  <div class="fx-flex-59pc">
    <mat-card>
      <div class="fx-layout-column fx-layout-align-center-center" style="color:red" *ngIf="(currentPayment$ | async).currentState === 'PaymentFailed'">        
        <p>{{ (currentPayment$ | async).error }}</p>
      </div>
      <mat-card-header *ngIf="(currentPayment$ | async).currentState === 'PaymentFailed' || (currentPayment$ | async).currentState === 'PaymentShow' ">
        <mat-card-title>Payment</mat-card-title>
        <mat-card-subtitle>
          <h5>{{ course?.courseName }}</h5>          
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div>
          <!-- <div class="fx-layout-row fx-layout-align-center-center">
            <div id="checkout-element" #checkoutElement></div>
          </div> -->

          <mat-card-actions class="fx-layout-align-space-between-center" *ngIf="(currentPayment$ | async).currentState === 'PaymentFailed' || (currentPayment$ | async).currentState === 'PaymentShow' ">            
              <button mat-raised-button color="primary" type="button" (click)="goBack()">
                Back
              </button>
              <button mat-raised-button color="primary" type="button" (click)="paymentSubmit()">
                Continue to Payment
              </button>
          </mat-card-actions>


          <div class="row" class="fx-layout-column fx-layout-align-center-center"
            *ngIf="(currentPayment$ | async).currentState === 'PaymentSubmitting'">
            <mat-spinner strokeWidth="3" diameter="80" title="Processing Payment..."></mat-spinner>
            <p>Processing Payment...</p>
          </div>

          <div class="row" class="fx-layout-column fx-layout-align-center-center"
            *ngIf="(currentPayment$ | async).currentState === 'PaymentLoading'">
            <mat-spinner strokeWidth="3" diameter="80" title="Loading Payment..."></mat-spinner>
            <p>Loading Payment...</p>
          </div>

          <div class="row" class="fx-layout-row fx-layout-align-center-center">
            <div class="fx-layout-column fx-layout-align-space-around-start"
              *ngIf="(currentPayment$ | async).currentState === 'PaymentSuccessful'">
              <div class="fx-flex-60pc">
                <h5>Thank you, your payment was successful.</h5>
              </div>
              <div class="fx-layout-align-center-center">
                <button mat-raised-button color="primary" (click)="goToCourse()" class="right">Continue
                  Course</button>
              </div>
              <div class="fx-flex-80pc">
                <p>If you do not see the course, please re-fresh the page and try again.</p>
              </div>
            </div>
          </div>
        </div>


      </mat-card-content>
    </mat-card>
  </div>
</div>
